import { HTMLAttributes, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Select } from 'antd'
import { LINKS } from 'routes/constants'
import jobsIcon from 'assets/jobs.svg'
import ActiveJobItem from './components/ActiveJobItem'
import PendingJobItem from './components/PendingJobItem'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import PendingBanner from 'components/PendingBanner/PendingBanner'
import PinnedJobSection from 'components/PinnedCard/PinnedJobSection'
import Button from 'components/forms/Button/Button'

import sortAscIcon from 'assets/sort_asc.svg'
import sortDescIcon from 'assets/sort_desc.svg'

import { IJobItem } from './Jobs'
import './jobs.sass'
import JobCategoryBar from 'components/Dashboard/jobCategoryBar/JobCategoryBar'
import NoData from 'components/NoData/NoData'

interface IJobsListingProps extends HTMLAttributes<HTMLDivElement> {
  jobs: IJobItem[]
  onClickRevokeJob?: (e: any) => void
  onClickSortJob?: any
  handlePinJob?: any
  handleUnpinJob?: any
  handleDeleteJob?: any
  fetchjobs_fn: (params: any) => any
  hideHeader?: boolean
  hidejobdescription?: boolean,
  unFilteredJobs?:any
  paymentHandler?:(params: any)=> void
}

const JobsListing = ({
  jobs,
  handlePinJob,
  handleUnpinJob,
  handleDeleteJob,
  hideHeader=false,
  hidejobdescription=false,
  unFilteredJobs=false,
  paymentHandler=()=>{},
  ...props
}: IJobsListingProps) => {
  const location = useLocation()
  const Option = Select.Option
  const [sort, setSort] = useState<any>(1)
  const [sortOption, setSortOption] = useState('invited')

  const isPendingPage = useMemo(() => {
    return location.pathname === LINKS.JOBS_PENDING 
  }, [location])

  const activeJobs = useMemo(() => {
    return jobs.filter((job) => job.isSubmitted)
  }, [jobs])

  const pendingJobs = useMemo(() => {
    return jobs.filter((job) => job.invited && !job.revoked && !job.isSubmitted)
  }, [jobs])

  const pinnedJobs = useMemo(() => {
    return jobs.filter((job) => job.pinned === true)
  }, [jobs])

  const handleSort = (e: any) => {
    setSortOption(e)
  }

  const renderSort = () => {
    return (
      <>
        <div className="sort-title">Sort by</div>
        <Select
          style={{ width: 180 }}
          onChange={(e) => handleSort(e)}
          defaultValue={'invited'}
          popupClassName	="custom-dropdown" 
          className='custom-dropdown-main'
        >
          <Option value="name">App Name</Option>
          <Option value="customerName">Customer Name</Option>
          <Option value="customerBusinessName">Business Name</Option>
          <Option value="invited">Date</Option>
        </Select>
        <Button
          theme="transparent"
          className="close-button"
          onClick={() => {
            setSort(sort === 1 ? -1 : 1)
            if (props.onClickSortJob) {
              props.onClickSortJob(sort, sortOption)
            }
          }}
        >
          <img
            src={sort === 1 ? sortAscIcon : sortDescIcon}
            style={{ height: '24px', width: '24px' }}
            alt="close"
          />
        </Button>
      </>
    )
  }

  return (
      <div className="job-listing" {...props}>
        {!hideHeader && <PageHeader
          heading={isPendingPage ? 'Pending jobs' : 'Jobs'}
          showBack={isPendingPage}
          rightSlot={renderSort()}
        />}
      <div className="pinned-job">
        {/* {!hideHeader &&  <div className="pending-jobs">
            {!isPendingPage && pendingJobs.length > 0 && (
              <PendingBanner
                to={LINKS.JOBS_PENDING}
                text={`${pendingJobs.length} pending jobs`}
              />
            )}
          </div>} */}
          {unFilteredJobs && <JobCategoryBar jobs={unFilteredJobs} />}

          <div className={`jobs-container ${pinnedJobs.length ? "have-pinned-jobs" : ""}`}>
            <div className="list">
              {isPendingPage
                ? pendingJobs.map((job) => (
                    <PendingJobItem
                      fetchjobs_fnitm={props?.fetchjobs_fn}
                      onClickRevokeJob={(e) => {
                        if (props?.onClickRevokeJob) props?.onClickRevokeJob(e)
                      }}
                      job={job}
                      key={job.id}
                    />
                  ))
                : activeJobs.length > 0 ? activeJobs.map((job: any) => (
                    <ActiveJobItem
                      fetchjobs_fnitm={props?.fetchjobs_fn}
                      job={job}
                      key={job.id}
                      handlePinJob={() => handlePinJob(job.id)}
                      handleDeleteJob={() => handleDeleteJob(job.id)}
                      hidejobdescription={hidejobdescription}
                      paymentHandler={()=> paymentHandler(job)}
                    />
                  ))
                  :

                  <NoData
                    icon={jobsIcon}
                    title="No job is added yet"
                    // cta={
                    //   <Link to={LINKS.JOBS_CREATE}>
                    //     <Button>Create Job</Button>
                    //   </Link>
                    // }
                  />

                  }
            </div>
            {!isPendingPage && pinnedJobs.length > 0 && (
              <PinnedJobSection
                pinnedJobs={pinnedJobs}
                handleUnpinJob={handleUnpinJob}
              />
            )}
          </div>
        </div>
      </div>
  )
}

export default JobsListing
