import {
  HTMLAttributes,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Input, Select, Space } from 'antd'
import { AuthContext } from 'context/AuthContext'
import { LINKS } from 'routes/constants'


import UserMenu from './UserMenu/UserMenu'
import './header.sass'

import 'react-toastify/dist/ReactToastify.css';

const { Option } = Select
const { Search } = Input
interface IHeaderProps extends HTMLAttributes<HTMLDivElement> {
  onSearch?: (value: any) => void
}

const checkIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z" fill="#00B8D9" />
</svg>;

const crossIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757" />
</svg>
  ;

interface PaymentRequest {
  email?: string;
  user_Id?: string;
  paymentMethodId?: string; // Optional property,
  subscription_Id: string;
  plan: string
}

const Header = ({ ...props }: IHeaderProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('Jobs')
  const auth = useContext(AuthContext)


  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation()
  const params = useParams();
  const navigate = useNavigate()
  const Option = Select.Option
  const { Search } = Input
  const user = useMemo(() => {
    return auth?.user
  }, [auth])







  const userRole = useMemo(() => {
    return String(user?.permissions?.[0])
  }, [user])
  const isCustomer = auth?.user?.permissions?.includes("customer");
  const isTemp = auth?.user?.permissions?.includes("temp");

  const [pageTitle, setPageTitle] = useState(isCustomer ? "Apps" : "Jobs");

  const pageTitles = {
    [LINKS.DASHBOARD]: 'Dashboard',
    [LINKS.CustomerDashboard]: 'Apps',
    [LINKS.CUSTOMERASSETS]: 'Assets',
    [LINKS.CUSTOMER_PAYMENTS]: 'Payments',
    [LINKS.PAYMENT_SUCCESS]: "Payments / Success",
    [LINKS.PAYMENT_FAILED]: "Payments / Failed",
    [LINKS.EDITPROFILE]: 'Edit Profile',
    [LINKS.JOBS]: 'Jobs',
    [LINKS.CUSTOMER_USERS]: "Customers",
    [LINKS.JOBS_PENDING]: 'Pending Jobs',
    [LINKS.JOBS_PENDING_ITEM]: 'Pending Job Details',
    [LINKS.JOBS_DETAILS]: 'Job Details',
    [LINKS.TASKS_EDIT]: 'Job Details / Task',
    [LINKS.JOBS_ADDTASK]: 'Job Details / Task / Add',
    [LINKS.NEWJOBS]: 'New Jobs',
    [LINKS.ACTIVEJOBS]: 'Active Jobs',
    [LINKS.INACTIVEJOBS]: 'Inactive Jobs',
    [LINKS.JOBS_CREATE]: 'Create Job',
    [LINKS.STAFF]: 'Staff',
    [LINKS.STAFF_INVITE]: 'Invite Staff',
    [LINKS.STAFF_PENDING]: 'Pending Staff',
    [LINKS.STAFF_PENDING_DETAIL]: 'Pending Staff Details',
    [LINKS.STAFF_EDIT]: 'Edit Staff',
    [LINKS.TEMPLATES]: 'Templates',
    [LINKS.TEMPLATES_EMAIL]: 'Email Templates',
    [LINKS.TEMPLATES_EMAIL_ADD]: 'Email Templates / Add',
    [LINKS.TEMPLATES_EMAIL_VIEW]: 'Email Templates / Edit',
    [LINKS.TEMPLATES_APP]: 'App Templates',
    [LINKS.TEMPLATES_APP_ADD]: 'App Templates / Add',
    [LINKS.TEMPLATES_APP_VIEW]: 'App Templates / Edit',
    [LINKS.TEMPLATES_TASK]: "Tasks Templates",
    [LINKS.TEMPLATES_TASK_ADD]: "Tasks Templates / Add",
    [LINKS.TEMPLATES_TASK_VIEW]: "Tasks Templates / Edit",
    [LINKS.SUBSCRIPTION]: "Subscription package",
    [LINKS.SUBSCRIPTION_ADD]: "Subscription package / Add",
    [LINKS.SUBSCRIPTION_VIEW]: "Subscription package / Edit",
    [LINKS.PAYMENT_AddCard]: "Payment / Add",
    [LINKS.PAYMENT_EditCard]: "Payment /  Edit",
    // Add more page titles as needed...
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPageTitle = (pathname: string) => {
    if (Object.keys(params).length) {
      let keyName = Object.keys(params)[0] || "null";
      let paramValue = params[keyName];

      if (typeof paramValue === 'string') {
        return pageTitles[pathname.replace(paramValue, ":" + keyName)];
      } else {
        // Handle the case where params[keyName] is not a string or undefined
        console.error("Invalid parameter value:", paramValue);
        return pageTitles[pathname] || 'Page Not Found';
      }
    }
    // Check if the pathname exists in the pageTitles object
    // If it does, return the corresponding title, otherwise return a default title
    return pageTitles[pathname] || 'Page Not Found';
  };

  useEffect(() => {
    setPageTitle(getPageTitle(location.pathname))
  }, [getPageTitle, location])
  const handleSelectChange = (value: string) => {
    setSelectedOption(value)
    switch (value) {
      case 'Jobs':
        if (location.pathname !== LINKS.JOBS) {
          navigate(LINKS.JOBS)
        }
        break
      case 'Staff':
        if (location.pathname !== LINKS.STAFF) {
          navigate(LINKS.STAFF)
        }
        break
      case 'Email-Template':
        if (location.pathname !== LINKS.TEMPLATES_EMAIL) {
          navigate(LINKS.TEMPLATES_EMAIL)
        }
        break
      case 'App-Template':
        if (location.pathname !== LINKS.TEMPLATES_APP) {
          navigate(LINKS.TEMPLATES_APP)
        }
        break
      default:
        navigate(LINKS.DASHBOARD)
        break
    }
  }




  return (
    <>
      <div className="app-header" {...props}>
        <div className="logo">
          {/* <img src={protonLogo} alt="Proton Logo" /> */}
          <p className='bread-crumb'>{pageTitle}</p>
        </div>
        {/* {!(isCustomer || isTemp) && (
          <div className="search-input">
            <Select
              className="selectBox"
              value={selectedOption}
              onChange={handleSelectChange}
              style={{ width: 130, marginTop: "-7px" }}
            >
              <Option value="Jobs">Jobs</Option>
              <Option value="Staff">Staff</Option>
              <Option value="Email-Template">Email Template</Option>
              <Option value="App-Template">App Template</Option>
            </Select>
            <Search
              className="test-search"
              onChange={(e) => {
                if (props?.onSearch) {
                  props?.onSearch(e.target.value);
                }
              }}
              placeholder="Type to search..."
              style={{ width: 350 }}
            />
          </div>
        )} */}
        <div className="header-right">
          <div className="search">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.12171 3.07527C6.61732 -0.358423 12.2848 -0.358423 15.7804 3.07527C19.158 6.39298 19.272 11.7024 16.1227 15.1547L19.8548 18.8211C19.9336 18.8985 19.9347 19.0252 19.8573 19.1039C19.8564 19.1048 19.8556 19.1056 19.8548 19.1065L19.0853 19.8623C19.0075 19.9388 18.8828 19.9388 18.805 19.8623L15.0388 16.1624C11.5243 18.9276 6.37968 18.71 3.12171 15.5098C-0.373902 12.0761 -0.373902 6.50896 3.12171 3.07527ZM4.1766 4.11148C1.26359 6.97289 1.26359 11.6121 4.1766 14.4736C7.08961 17.335 11.8125 17.335 14.7255 14.4736C17.6385 11.6121 17.6385 6.97289 14.7255 4.11148C11.8125 1.25007 7.08961 1.25007 4.1766 4.11148Z" fill="#00334F" />
            </svg>
            <span>Search</span>
          </div>
          <div className="notification">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.5 17L1.50002 16.9823C0.651939 16.861 0 16.1316 0 15.25V14.3284C0 13.798 0.210714 13.2893 0.585786 12.9142L1.5 12V8.5C1.5 3.80558 5.30558 0 10 0C14.6944 0 18.5 3.80558 18.5 8.5V12L19.4142 12.9142C19.7893 13.2893 20 13.798 20 14.3284V15.25C20 16.1315 19.3483 16.8607 18.5005 16.9822L18.5 17L13.4645 17.0001C13.2219 18.6962 11.7632 20 10 20C8.2368 20 6.77812 18.6962 6.53546 17.0001L1.5 17ZM11.9369 17.0005L8.0625 17L8.08995 17.0949C8.34321 17.9089 9.10258 18.5 10 18.5C10.8972 18.5 11.6565 17.9092 11.9099 17.0953L11.9369 17.0005ZM10 1.5C6.18625 1.5 3.08484 4.54988 3.00171 8.34368L3 8.5V12.5385C3 12.5915 2.97893 12.6424 2.94142 12.6799L1.64645 13.9749C1.56831 14.053 1.5187 14.1544 1.50434 14.2627L1.5 14.3284V15.25C1.5 15.3589 1.57079 15.4535 1.66884 15.4869L1.7124 15.4974L1.733 15.5H18.2665L18.2877 15.4974C18.3926 15.4823 18.4765 15.3995 18.4958 15.2957L18.5 15.25V14.3284C18.5 14.2179 18.4634 14.1112 18.397 14.0244L18.3536 13.9749L17.0586 12.6799C17.0211 12.6424 17 12.5915 17 12.5385V8.5C17 4.63401 13.866 1.5 10 1.5Z" fill="#00314D" />
            </svg>
          </div>
          <div className="user-options">
            <UserMenu name={user?.name} role={userRole} avatar={user?.avatar} />
          </div>
        </div>
        {/* <div className="user">
          <div className="notification"></div>
          <UserMenu name={user?.name} role={userRole} avatar={user?.avatar} />
        </div> */}

      </div>
      

    </>
  )
}

export default Header
