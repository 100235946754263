import { HTMLAttributes } from 'react'

import emailSentGraphic from 'assets/proton_email_send.png'

import './email-sent.sass'

interface IEmailSentProps extends HTMLAttributes<HTMLDivElement> {
  email?: string
}

const EmailSent = ({ email, ...props }: IEmailSentProps) => {
  return (
    <div className="email-sent" {...props}>
      <img
        src={emailSentGraphic}
        alt="email sent"
        width={'250px'}
        height={'300px'}
      />
      {/* <div className="title">Email sent!</div> */}
      <p>
        Your email was successfully sent to
        <span className="email-address">{email}</span>
      </p>
    </div>
  )
}

export default EmailSent
