import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactModal from 'react-modal'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import router from 'routes/router'
import 'styles/index.sass'

import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from 'store/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
ReactModal.setAppElement('#root')

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
