import { useContext, useEffect } from 'react'
import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import TaskSidebar from '../components/TaskSidebar'
import AddTaskActivity from './components/AddTaskActivity'
import EditTask from './components/EditTask'
import ShowInfoModal from './components/ShowInfoModal'
import BaseModal from 'components/ActionModal/BaseModal'
import DeleteModal from 'components/ActionModal/DeleteModal'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import KebabMenu from 'components/KebabMenu/KebabMenu'

import deleteIcon from 'assets/delete.svg'
import editIcon from 'assets/edit.svg'
import infoIcon from 'assets/info-circle.svg'

import '../jobs.sass'
import { deleteTask, editTask, getFileUrl, uploadFiles } from '../utils'

export interface JobMember {
  id: string
  avatar: string
  name: string
  email: string
  job: string
}
export const TaskDetails = () => {
  const [isEditDisabled, setEditDisabled] = useState(false);
  const auth = useContext(AuthContext)
  const isTemp = auth?.user?.permissions?.includes("temp");
  const isCustomers = auth?.user?.permissions?.includes("customer");
  const kebabMenuItems = useMemo(
    () => [
      // {
      //   label: 'Edit',
      //   icon: editIcon,
      //   onClick: () => {
      //     setEditDisabled(!isEditDisabled)
      //   },
      // },
      {
        label: 'Delete',
        icon: deleteIcon,
        onClick: () => {
          if(!(isCustomers || isTemp)){
            setIsDeleteOpen(true)
          }
        },
        disabled:  (isCustomers || isTemp),
        style:{
          opacity: !(isCustomers || isTemp) ? 1 : 0.5,
          cursor: !(isCustomers || isTemp) ? "pointer" : "not-allowed"
        },
      },
      {
        label: 'Show Info',
        icon: infoIcon,
        onClick: () => {
          setShowInfoModalOpen(!isShowInfoModalOpen)
        },
      },
    ],
    []
  )
  const navigate = useNavigate()
  // const auth = useContext(AuthContext)
  const [taskTitle, setTaskTitle] = useState('')
  const [taskDescription, setTaskDescription] = useState('')
  const [estimatedTime, setEstimatedTime] = useState<string>('1h 30min')
  const [selectedAssignee, setSelectedAssignee] = useState(null)
  const [selectedValue, setSelectedValue] = useState<string>('New')
  const [loggedTime, setLoggedTime] = useState<string>('1h 30min')
  const [selectedPriority, setSelectedPriority] = useState<string>('Low')
  const [isAssignee, setIsAssignee] = useState(true)
  const [isEditData, setIsEditData] = useState<any>('')
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { editId } = useParams()
  const [fileList, setFilelist] = useState<any>([])
  const [isShowInfoModalOpen, setShowInfoModalOpen] = useState<boolean>(false)

  const fetchData = async () => {
    if (auth && editId) {
      const headers = {
        Authorization: `Bearer ${auth.accessToken}`,
      }
      try {
        const response = await client
          .service(SERVICES.TASK)
          .get(editId, { headers: headers })
        setIsEditData(response)
        setTaskTitle(response.title)
        setTaskDescription(response.description)
        setFilelist(response.file)
        setEstimatedTime(response.estimated_time)
        setLoggedTime(response.logged_time)
        setSelectedPriority(response.set_priority)
        setSelectedAssignee(response.assignee)
        setSelectedValue(response.status)
        console.log(response);
        
      } catch (error) {
        console.error('Error making API call:', error)
      }
    }
  }
  useEffect(() => {
    fetchData()
  }, [isEditDisabled, auth, editId])

  const handleDeletetask = async (editId: any) => {
    deleteTask(editId, setLoading, navigate)
  }

  const handleEditTask = async () => {
    if (taskTitle.length && taskTitle.trim() != '') {
      setLoading(true)
      if (auth && editId) {
        const headers = {
          Authorization: `Bearer ${auth.accessToken}`,
        }
        const payload = {
          title: taskTitle,
          description: taskDescription,
          status: selectedValue,
          assignee: selectedAssignee,
          file: fileList,
          reporter: 'Reporter',
          set_priority: selectedPriority,
          estimated_time: estimatedTime,
          logged_time: loggedTime,
        }
        editTask(editId, payload, headers, setLoading, navigate)
      } else {
        setLoading(false)
      }
    } else {
      toast.error('Task title is required', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 600,
      })
      setTaskTitle('')
    }
  }

  const rightSlot = (
    <div className="header-button-container">
      <button className="cnl-btn" onClick={() => navigate(-1)}>
          Cancel
        </button>
        <button
          className="sub-btn"
          onClick={handleEditTask}
          // type="submit"
        >
          Update Task
        </button>
        <KebabMenu items={kebabMenuItems} />
    </div>
  )

  return (
    <div className="full-page pending-job-detail">
      <PageHeader
        heading={isEditData?.title}
        showBack
        rightSlot={rightSlot}
      />
      <div className="addTask-wrapper">
        <div className="addTask-wrapper-left">
          {!isEditDisabled ? (
            <EditTask
              loading={loading}
              taskTitle={taskTitle}
              setTaskTitle={setTaskTitle}
              taskDescription={taskDescription}
              setTaskDescription={setTaskDescription}
              fileList={fileList}
              setFilelist={setFilelist}
            />
          ) : (
            <AddTaskActivity loading={loading} />
          )}

         
        </div>
        <div className="addTask-status">
          <TaskSidebar
            setLoggedTime={setLoggedTime}
            loggedTime={loggedTime}
            isAssignee={isAssignee}
            setIsAssignee={setIsAssignee}
            selectedAssignee={selectedAssignee}
            setSelectedAssignee={setSelectedAssignee}
            setEstimatedTime={setEstimatedTime}
            estimatedTime={estimatedTime}
            setSelectedPriority={setSelectedPriority}
            selectedPriority={selectedPriority}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            isEditDisabled={isEditDisabled}
          />
        </div>
      </div>
      {/* {!isEditDisabled && (
        <div className="btn">
          <button
            className="cnl-btn"
            onClick={() => setEditDisabled(!isEditDisabled)}
          >
            Cancel
          </button>
          <button
            className="sub-btn"
            // disabled={isbuttonEnabled}
            onClick={handleEditTask}
            // type="submit"
          >
            Edit Task
          </button>
        </div>
      )} */}
      <DeleteModal
        isOpen={isDeleteOpen}
        handleClose={() => {
          setIsDeleteOpen(false)
        }}
        handleDelete={() => handleDeletetask(editId)}
        title="Delete task"
        subtitle="Are you sure you want to delete this Task"
      />
      <BaseModal
        isOpen={isShowInfoModalOpen}
        children={
          <ShowInfoModal
            taskTitle={taskTitle}
            taskDescription={taskDescription}
            selectedValue={selectedValue}
            selectedAssignee={selectedAssignee}
            selectedPriority={selectedPriority}
            estimatedTime={estimatedTime}
            loggedTime={loggedTime}
          />
        }
        handleClose={() => setShowInfoModalOpen(!isShowInfoModalOpen)}
      />
    </div>
  )
}
