import { HTMLAttributes } from 'react'

import './no-data.sass'

interface INoDataProps extends HTMLAttributes<HTMLDivElement> {
  icon: string
  title: string
  description?: string
  cta?: any
}

const NoData = ({
  icon,
  title,
  description,
  cta = null,
  ...props
}: INoDataProps) => {
  return (
    <div className="no-data" {...props}>
      <img src={icon} alt={title} />
      <h5>{title}</h5>
      {cta}
    </div>
  )
}

export default NoData
