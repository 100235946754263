import { DEFAULT_AVATAR } from 'utils/constants'

import Avatar from 'components/Avatar/Avatar'

interface IModalPreviewAppTemplateProps {
  icon?: string
  title: any
  subtitle: string
}

const ModalPreviewAppTemplate = ({
  icon,
  title,
  subtitle,
}: IModalPreviewAppTemplateProps) => {
  return (
    <div className="delete-app-template">
      <div className="left">
        <Avatar name={title} url={icon ? icon : DEFAULT_AVATAR} size={56} />
        {/* <img src={icon ? icon : DEFAULT_AVATAR} alt="" /> */}
      </div>
      <div className="right">
        <h6 className="title">{title}</h6>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  )
}

export default ModalPreviewAppTemplate
