import React, { HTMLAttributes, useState, useEffect } from 'react'
import Button from 'components/forms/Button/Button';
import protonLogo from 'assets/Proton-accent.svg'
import "./staffinvitation.sass";
import { SERVICES, URL_PATH, crypto_secret, generateRandomPassword } from 'utils/constants';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import client from 'feathers-client';
import emailSentGraphic from 'assets/proton_email_send.png'
import axios, { AxiosRequestConfig } from 'axios';
interface IAddEmailTemplateProps extends HTMLAttributes<HTMLDivElement> { }

const StaffInvitation = () => {
    const [userStatus, setUserStatus] = useState<any>(null)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encryptUserID = queryParams.get('e') || "";


    const siteRootUrl: string = window.location.origin;
    const newPass = generateRandomPassword(10);
    const stringWithPlus = encryptUserID.replace(/ /g, '+');
    const bytes = CryptoJS.AES.decrypt(stringWithPlus, crypto_secret);
    const decryptedDataString = bytes.toString(CryptoJS.enc.Utf8);
    const decryptedData = JSON.parse(decryptedDataString);
    const [templateData, setTemplateData] = useState<any>({});
    const [template, setTemplate] = useState<string>("");
    const requestData = {
        password: newPass,
    };


    useEffect(()=>{
        const fetchUserStatus = setTimeout(async()=>{
            try {
                const config: AxiosRequestConfig = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: `${URL_PATH.CLIENT1}/customerUserStatus`,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  data: { id: decryptedData[2].userid },
                };
          
                const response = await axios.request(config);
                if (response?.data.status == 200) {
                    // console.log("response>>>>", response?.data);
                    setUserStatus(response?.data?.isExpired)
                } else {
                //   setJobMessage(response?.data?.message);
                //   setJobStatus(false)
                }
              } catch (error: any) {
                toast.error(error.message, {
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }
        }, 200)

        return () => clearTimeout(fetchUserStatus)
    },[])

    
    useEffect(()=>{
  
      const fetchTemplateTimer = setTimeout(async()=>{
        const configE: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            "type": "staff_access"
          }),
        };
      
  
        try{
          const templateResult = await axios.request(configE);
          if(templateResult.data.status === 200){
            setTemplate(templateResult.data.template.data[0].body)
            setTemplateData(templateResult.data.template.data[0])
          }else{
            console.log("Template not found !!!")
          }
        }
        catch(err:any){
          console.log("Something went wrong!!!", err.message)
        }
      },200)
  
      return () => clearTimeout(fetchTemplateTimer)
    },[])

 


    
    const SendEmailToUser = async () => {
        // Define the URL for the Feathers.js service.
        const serviceUrl = `${URL_PATH.CLIENT1}/password-update/${decryptedData[2]?.userid}`;

        // Define the request configuration.
        const requestConfig = {
            method: 'put',
            url: serviceUrl,
            headers: {
                'Content-Type': 'application/json',
            },
            data: requestData,
        };
        try {
            await axios.request(requestConfig);
            let customerFirstName =  decryptedData[1]?.name?.split(" ")?.shift();
            let INVITE:any = template.replaceAll("[Full Name]", customerFirstName || decryptedData[1]?.name).replaceAll("[Link]", `${siteRootUrl}/login?email=${decryptedData[0]?.email}`).replaceAll("[Email]", decryptedData[0]?.email).replaceAll("[Password]", newPass).replaceAll("[Customer Name]", customerFirstName || decryptedData[1]?.name);

          const emailObj = {
            to: decryptedData[0]?.email,
            subject: templateData.subject,
            text: INVITE,
          }
          const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
            toast.success("Email sent successfully", {
                position: toast.POSITION.BOTTOM_CENTER,
            });
            setEmailSuccess(true)
            // window.location. = "/login";
        } catch (error:any) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
    }


if(userStatus !== null){
    if(emailSuccess){
        return (
            <div className="email-sent" style={{ display: 'flex', maxWidth: "400px", margin: "auto", alignItems: "center", justifyContent: "center", flexDirection: 'column', height: "100vh" }}>
                <img
                    src={emailSentGraphic}
                    alt="email sent"
                    width={'250px'}
                    height={'300px'}
                />
                {/* <div className="title">Email sent!</div> */}
                <p>
                    Your email was successfully sent to
                    <span className="email-address">{decryptedData[0]?.email}</span>
                </p>
            </div>
        )
    }



    return (
        <div style={{ display: 'flex', maxWidth: "400px", margin: "auto", alignItems: "center", justifyContent: "center", flexDirection: 'column', height: "100vh" }}>
            <div className="email-sent" >
                <img
                    src={protonLogo}
                    alt="email sent"
                    width={'250px'}

                />
                {/* <div className="title">Email sent!</div> */}
                <p>
                    {!userStatus ? 
                        `We're excited about the prospect of having you on board. Please accept this exclusive invitation.`
                        :
                        `Invitation is Expired.`
                    }
                    <span className="email-address">{decryptedData[0]?.email}</span>
                </p>
            </div>
            {/* <button style={{height:"auto"}}></button> */}
            {!userStatus  && <Button
                style={{
                    marginTop: '8px',
                    color: "white"
                }}
                onClick={(e) => { SendEmailToUser() }}
            >
                Accept Invitation
            </Button>}
        </div>
    )
}else{
    return null;
}
    
}

export default StaffInvitation;