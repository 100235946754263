import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { SERVICES, URL_PATH } from 'utils/constants'

import EmailSent from 'components/ActionModal/components/EmailSent'
import EmailPreview from 'components/EmailPreview/EmailPreview'
import Button from 'components/forms/Button/Button'
import TextField from 'components/forms/TextField/TextField'

// import { INVITE } from '../CreateJob/CreateJob'
import './create-job-form.sass'
import { AuthContext } from 'context/AuthContext'
import axios, { AxiosRequestConfig } from 'axios';

interface ICreateJobFormData {
  // name: string
  customer_name: string
  customer_email: string
  //business_name: string
}

interface ICreateJobForm { }

export const submitCreateJob = async (
  values: ICreateJobFormData,
  actions: FormikHelpers<ICreateJobFormData>,
  auth: any
) => {
  try {
    const headers = {
      Authorization: `Bearer ${auth.accessToken}`,
    }
    /** check user if exists  */
    // const jobData = await client.service(SERVICES.USERS).find({
    //   query: {
    //     email: values.customer_email,
    //   },
    //   headers: headers 
    // })
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}/password-reset`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { email: values.customer_email },
    };
    let INVITE: any;
    const jobData = await axios.request(configE);
    if (!(jobData?.data?.permissions?.includes("temp") || jobData?.data?.permissions?.includes("customer")) && jobData?.data?.message !== "User with this email does not exist") {
      toast.error(`User already registered as ${jobData?.data?.permissions[0]}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      return false
    } else {

      const data = await client.service(SERVICES.JOBS).create({
        name: "",
        customer_name: values.customer_name,
        customer_email: values.customer_email,
        //business_name: values.business_name,
        invite_email: ``, // TODO: build email preview and send
        isSubmitted: false, // At first time to create by default
        isJobActive: false
      }, { headers: headers })

      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "create_job"
        }),
      };

      const template = await axios.request(configE);
     
      if (template?.data?.template?.data?.length > 0) {
        const tempStr = template?.data?.template?.data[0]
        let customerFirstName =  values.customer_name?.split(" ")?.shift();
        tempStr.body = tempStr.body
          .replaceAll("[Customer Name]", (customerFirstName || values.customer_name) ?? '')
          //  .replace('[id]', data._id)
          .replaceAll('[Link]', `${window.location.origin}/customer/onboarding/${data._id}`)
        const emailObj = {
          to: values.customer_email, // Replace with your email address
          subject: tempStr.subject,
          text: tempStr.body,
        }
       
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
      }
      actions.setSubmitting(false)
      return data

    }
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}

const InviteSuccess = ({ email }: { email: string }) => {
  return (
    <div className="invite-success">
      <EmailSent email={email} />
      <Link to={LINKS.JOBS}>
        <Button>Go to Jobs</Button>
      </Link>
    </div>
  )
}

const FormikForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isSuccess,
  emailTemplate
}: any) => {
  const navigate = useNavigate()
  const buildEmail = useMemo(() => {
    let template = emailTemplate;
    Object.keys(values).forEach((key) => {

      if (values[key]) {
        if(key === "customer_name"){  
          let firstName =  values[key].split(" ").shift()
          template = template.replaceAll(`[Customer Name]`, firstName || values[key])
        }
        template = template.replaceAll(`[${key}]`, values[key])
      }
    })
    return template
  }, [values, emailTemplate])


  return (
    <div className="create-job-form-container half-container">
      <div className="left">
        {isSuccess ? (
          <InviteSuccess email={values.customer_email} />
        ) : (
          <form onSubmit={handleSubmit}>
            {/* <TextField
              label="Full name"
              id="full_name-input"
              placeholder="Type your full name..."
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.full_name}
              errors={errors}
              required
            /> */}
            <TextField
              label="Customer name"
              id="customer_name-input"
              placeholder="Type name..."
              type="text"
              name="customer_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.customer_name}
              errors={errors}
              required
            />
            <TextField
              label="Customer email"
              id="customer_email-input"
              placeholder="Type email..."
              type="text"
              name="customer_email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.customer_email}
              errors={errors}
              required
            />
             {/*<TextField
              label="Type business name"
              id="business_name-input"
              placeholder="Type business name..."
              type="text"
              name="business_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.business_name}
              errors={errors}
              required
            /> */}
            <div className="buttons-container">
              <Button
                type="button"
                disabled={isSubmitting}
                theme="secondary"
                style={{
                  marginRight: '12px',
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Create job & send email
              </Button>
            </div>
          </form>
        )}
      </div>
      <div className="right">
        <EmailPreview body={buildEmail} />
      </div>
    </div>
  )
}

const CreateJobForm = ({ ...props }: ICreateJobForm) => {
  const auth = useContext(AuthContext)
  const handleSubmit = useCallback(submitCreateJob, [auth])
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [template, setTemplate] = useState<string>("")
  const onSubmit = useCallback(
    async (
      values: ICreateJobFormData,
      actions: FormikHelpers<ICreateJobFormData>
    ) => {
      const form_res = await handleSubmit(values, actions, auth)
      form_res !== false ? setEmailSent(true) : setEmailSent(false)
    },
    [handleSubmit, auth]
  )

  useEffect(() => {

    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "create_job"
        }),
      };


      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setTemplate(templateResult.data.template.data[0].body)
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [])

  return (
    <Formik
      initialValues={{
        // name: '',
        customer_name: '',
        customer_email: '',
       // business_name: '',
      }}
      validate={(values: ICreateJobFormData) => {
        const errors: any = {}
        // if (!values.name) {
        //   errors.name = 'Required'
        // }

        if (!values.customer_name) {
          errors.customer_name = 'Required'
        }

        if (!values.customer_email) {
          errors.customer_email = 'Required'
        }

        // if (!values.business_name) {
        //   errors.business_name = 'Required'
        // }

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({ ...props }) => <FormikForm {...props} isSuccess={emailSent} emailTemplate={template} />}
    </Formik>
  )
}

export default CreateJobForm
