import React from 'react'; // Import React
import RoundedChart from 'components/Dashboard/RoundedChart/roundedChart'

import './TotalJobs.sass';
type TotallJobsProps = {
    jobsumrytotal : number 
    jobsumrynew : number 
    jobsumryin_progress : number 
    jobsumryreview : number 
    jobsumrypromoted : number 
    jobsumryfinished : number 
  };
  const totalJobs: React.FC<TotallJobsProps>= (props) => {
    const { jobsumryfinished,jobsumryin_progress,jobsumrynew,jobsumrypromoted,jobsumryreview,jobsumrytotal } = props;
    const jobStatusData = [
        { status: 'New', count: jobsumrynew },
        { status: 'In progress', count: jobsumryin_progress },
        { status: 'Review', count: jobsumryreview },
        { status: 'Promoted', count: jobsumrypromoted },
        { status: 'Finished', count: jobsumryfinished },
      ];

    return (
        <div className='job_box_inner'>
            <h4 className='head_bg_darkblue'>
                Jobs Summary
            </h4>
            
            <div className='flex align_items pl20'>
                <div className="RoundedChart">
                    <RoundedChart jobsumrytotal={jobsumrytotal} jobStatusData={jobStatusData}  />
                </div>
                <div className='w100'>
                <div className='box_li mt10'>
                <div className='flex_wrap'>
                    <h3 className='big_text'>TOTAL JOBS</h3>
                </div>
                <strong className='big_text'>{jobsumrytotal}</strong>
            </div>
                    <div className='box_li mt10'>
                        <div className='flex'>
                            <div className='new_colur'></div>
                            <div>NEW</div>
                        </div>
                        <strong>{jobsumrynew}</strong>
                    </div>
                    <div className='box_li mt10'>
                        <div className='flex'>
                            <div className='new_colur pro_c'></div>
                            <div>IN PROGRESS</div>
                        </div>
                        <strong>{jobsumryin_progress}</strong>
                    </div>
                    <div className='box_li mt10'>
                        <div className='flex'>
                            <div className='new_colur org'></div>
                            <div>REVIEW</div>
                        </div>
                        <strong>{jobsumryreview}</strong>
                    </div>
                    <div className='box_li mt10'>
                        <div className='flex'>
                            <div className='new_colur green1'></div>
                            <div>PROMOTED</div>
                        </div>
                        <strong>{jobsumrypromoted}</strong>
                    </div>
                    <div className='box_li mt10'>
                        <div className='flex'>
                            <div className='new_colur red'></div>
                            <div>FINISHED</div>
                        </div>
                        <strong>{jobsumryfinished}</strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default totalJobs;
