import React from 'react'
import ReactModal from 'react-modal'

import Button from 'components/forms/Button/Button'

import closeIcon from 'assets/close.svg'

import './modal.sass'

interface IBaseModalProps {
  isOpen: boolean
  handleClose?: () => void
  actionButtons?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const BaseModal = ({
  isOpen,
  handleClose,
  actionButtons = null,
  children = null,
  className="",
  ...props
}: IBaseModalProps) => {
  return (
    <ReactModal
      isOpen={isOpen}
      {...props}
      shouldCloseOnEsc={true}
      onRequestClose={() => {
        if(handleClose){
          handleClose()
        }
      }}
      className={"base-modal " + className}
    >
      <div className="container">
        {handleClose && <Button
          theme="transparent"
          className="close-button"
          onClick={handleClose}
        >
          <img src={closeIcon} alt="close" />
        </Button>}
        {children}
        {actionButtons && <div className="modal-footer">{actionButtons}</div>}
      </div>
    </ReactModal>
  )
}

export default BaseModal
