import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { TGenericObject } from 'types/base'

import UploadAvatar from 'components/Avatar/UploadAvatar'
import Button from 'components/forms/Button/Button'
import TextArea from 'components/forms/TextArea/TextArea'
import TextField from 'components/forms/TextField/TextField'
import SubscriptionPreview from 'components/SubscriptionPreview/SubscriptionPreview'
import { AuthContext } from 'context/AuthContext'


const crossIcon = <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.30425 0.0587852L9.08842 6.90976L15.8722 0.0587895C15.9097 0.0208548 15.9609 -0.000488281 16.0143 -0.000488281H17.552C17.6625 -0.000488281 17.752 0.0890548 17.752 0.199512C17.752 0.252214 17.7312 0.302786 17.6941 0.340235L10.1387 7.97042L17.7511 15.6588C17.8288 15.7373 17.8282 15.8639 17.7497 15.9416C17.7122 15.9787 17.6617 15.9995 17.609 15.9995H16.0717C16.0183 15.9995 15.9671 15.9782 15.9296 15.9402L9.08842 9.03108L2.24682 15.9402C2.20926 15.9782 2.15809 15.9995 2.10471 15.9995H0.56792C0.457463 15.9995 0.36792 15.91 0.36792 15.7995C0.36792 15.7468 0.38872 15.6962 0.425798 15.6588L8.03817 7.97042L0.482768 0.340235C0.405049 0.261746 0.405672 0.135115 0.484161 0.0573957C0.52161 0.0203137 0.572182 -0.000488281 0.624884 -0.000488281H2.16214C2.21552 -0.000488281 2.26669 0.0208532 2.30425 0.0587852Z" fill="#80949D"/>
</svg>

const checkFillIcon = <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0ZM16.9101 7.5H15.3543C15.3013 7.5 15.2504 7.52107 15.2129 7.55857L9.69612 13.0746L7.17958 10.5582C7.14207 10.5207 7.0912 10.4996 7.03816 10.4996H5.4827C5.42967 10.4996 5.37881 10.5207 5.34131 10.5582C5.26319 10.6363 5.26316 10.7629 5.34125 10.841C5.42917 10.929 5.5171 11.0169 5.60502 11.1049C5.62887 11.1288 5.65325 11.1532 5.67813 11.1781L5.83305 11.3334C6.15375 11.655 6.53643 12.0404 6.92601 12.4333L7.21862 12.7286C8.12764 13.6461 8.99919 14.5287 9.13331 14.6643L9.1505 14.6816C9.4434 14.9745 9.91827 14.9745 10.2112 14.6816L17.0516 7.84142C17.0891 7.80392 17.1101 7.75304 17.1101 7.7C17.1101 7.58954 17.0206 7.5 16.9101 7.5Z" fill="#00B8D9"/>
</svg>


const crossFillIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757"/>
</svg>

export interface IAddSubscriptionFormData {
  packageTitle: string
  monthlySubscription: string
  yearlyDiscount: string
}

interface ISubscriptionForm {
  handleSubmit: (
    values: IAddSubscriptionFormData,
    actions: FormikHelpers<IAddSubscriptionFormData>,
    extraData?:any
  ) => boolean | TGenericObject
  initialValues?: IAddSubscriptionFormData | null,
  extraData?: any
}

const SubscriptionForm = ({ handleSubmit, initialValues, extraData }: ISubscriptionForm) => {
  const navigate = useNavigate()
  const location = useParams()
  const auth = useContext(AuthContext)
  const [featuresList, setFeaturesList] = useState<any>(extraData?.features  || []);
  const [discountType, setDiscountType] = useState<string>(extraData?.yearlyDiscountType ||  "PERCENTAGE") //PERCENTAGE//FIXED
  const [discountDropdown, setDiscountDropdown]= useState<boolean>(false)

  useEffect(()=>{
    setFeaturesList(extraData?.features || [])
    setDiscountType(extraData?.yearlyDiscountType || "PERCENTAGE" )
  },[extraData])

  const onSubmit = useCallback(
    async (
      values: IAddSubscriptionFormData,
      actions: FormikHelpers<IAddSubscriptionFormData>,
    ) => {
      if(Object.keys(location).length > 0){
        const data = await handleSubmit(values, actions, {
          yearlyDiscountType: discountType,
          features: featuresList,
          auth,
          id: location.subscriptionId
        })
        if (data) {
          navigate(LINKS.SUBSCRIPTION)
        }
      }else{
        const data = await handleSubmit(values, actions,  {
          yearlyDiscountType: discountType,
          features: featuresList,
          auth,
          id: location.subscriptionId
        })
        if (data) {
          navigate(LINKS.SUBSCRIPTION)
        }
      }
    },
    [handleSubmit, navigate, location, auth, featuresList, discountType]
  )

  const computedInitialValues = useMemo(() => {
    if (initialValues) {
      return {
        packageTitle: initialValues.packageTitle,
        monthlySubscription: initialValues.monthlySubscription ,
        yearlyDiscount: initialValues.yearlyDiscount
      }
    } else {
      return {
        packageTitle: '',
        monthlySubscription: "",
        yearlyDiscount: ""
      }
    }
  }, [initialValues])
  
  
  return (
    <Formik
      initialValues={computedInitialValues}
      enableReinitialize
      validate={(values: IAddSubscriptionFormData) => {
        const errors: any = {}
        if (!values.packageTitle) {
          errors.packageTitle = 'Required'
        }
        if (!values.monthlySubscription && +values.monthlySubscription !== 0) {
          errors.monthlySubscription = 'Required'
        }
        if (+values.monthlySubscription < 0) {
          errors.monthlySubscription = 'Amount must be greater than 0 or 0.'
        }
        if (!values.yearlyDiscount && +values.yearlyDiscount !== 0) {
          errors.yearlyDiscount = 'Required'
        }
        if (+values.yearlyDiscount < 0) {
          errors.yearlyDiscount = 'Discount must be greater than 0 or 0.'
        }
        if(+values.yearlyDiscount > 100) {
          // errors.yearlyDiscount = 'Discount should be less than 100'
          setDiscountType("FIXED")
        }
      

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {

        return (
          <div className="form-container">
            <div className="left">
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Package type title*"
                  id="app-type-title-input"
                  placeholder="Type"
                  type="text"
                  name="packageTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.packageTitle}
                  errors={errors}
                  required
                />
      

                <TextField
                  label="Subscription (per month)"
                  id="app-monthlysubscription-input"
                  placeholder="App monthly subscription"
                  type="number"
                  name="monthlySubscription"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.monthlySubscription}
                  errors={errors}
                  capText={"USD"}
                  required
                />
                <div className={`textfield input-field ${errors["yearlyDiscount"] ? "error" : ""}`}>
                  <div className='header'>
                    <label htmlFor='yearlyDiscount'>Yearly discount</label>
                  </div>
                  <div className={`input-group-container input-cap-space`}>
                      <span
                          onClick={()=> setDiscountDropdown(true)}
                          onBlur={()=> setTimeout(()=>{setDiscountDropdown(false)},500)}
                          tabIndex={0}
                          className='cap-text'
                      >
                          {discountType === "FIXED" ? "$" : "%"}
                          <svg className='dropdown-icon' width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.556096 2.19728L9.33063 11.2882C9.60629 11.5651 10.0432 11.5814 10.3378 11.3371L10.3913 11.2882L19.4417 2.19768C19.479 2.1602 19.5 2.10946 19.5 2.05657V0.493213C19.5 0.382756 19.4105 0.293213 19.3 0.293213C19.2468 0.293213 19.1958 0.314404 19.1583 0.3521L9.89085 9.65998L0.843341 0.361735C0.766311 0.28257 0.639689 0.28084 0.560524 0.35787C0.521829 0.395522 0.5 0.44722 0.5 0.501211V2.05838C0.5 2.1102 0.520111 2.15999 0.556096 2.19728Z" fill="#80949D"/>
                          </svg>

                      </span>
                      {discountDropdown && 
                          <ul className='feature-dropdown'>
                              <li onClick={()=> setDiscountType("FIXED")}>$</li>
                              <li onClick={()=> setDiscountType("PERCENTAGE")}>%</li>
                          </ul>
                      }
                      <input 
                          placeholder="Type" 
                          type="number" 
                          className="input"
                          name="yearlyDiscount"
                          id="yearlyDiscount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.yearlyDiscount}
                      />
                  </div>
                  <div className="error" style={{marginTop: 8}}>{errors["yearlyDiscount"]}</div>
                </div> 

                {featuresList.length > 0 && 
                     <div className="features-list-container input-field">
                        <p className='header'>Features</p>
                        {featuresList.map((feature:any, index:number)=>(
                            <FeatureItem key={feature.id} feature={feature} index={index} setFeaturesList={setFeaturesList} />
                        ))}
                    </div>
                }
               

                <div className="features-list-container input-field" style={{marginTop: 15}}>
                    <p className='header'>Add features</p>
                    <div 
                        className='add-feature-btn'
                        onClick={()=>{
                            setFeaturesList((prev:any) => [...prev, {
                                checked: true,
                                value: "",
                                id: Math.random()
                            }])
                        }}
                    >+</div>
                </div>
       
                <div className="buttons-container right">
                  <Button
                    className='button medium-btn cancel-btn'
                    type="button"
                    disabled={isSubmitting}
                    theme="secondary"
                    style={{
                      marginRight: '12px',
                    }}
                    onClick={()=>navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className='button medium-btn' disabled={isSubmitting}>
                    Done
                  </Button>
                </div>
              </form>
            </div>
            <div className="right">
              {(()=>{
                const subscription={
                    packageTitle: values.packageTitle || "___",
                    monthlySubscription: values.monthlySubscription || "0",
                    yearlyDiscount: values.yearlyDiscount || "0",
                    yearlyDiscountType: discountType,
                    features: featuresList
                }
                return (
                  <SubscriptionPreview subscription={subscription}  />
                )
              })()}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

const FeatureItem = ({...props}:any) => {
    const {setFeaturesList, index, feature} = props;
    const [checked, setChecked] = useState<boolean>(feature.checked);
    const [isOpen, setIsOpen]  = useState<boolean>(false);

    const checkedHandler = (type:boolean) => {
        setChecked(type)
        setFeaturesList((prev:any) => {
            return prev.map((feat:any, key:number)=>{
                if(index === key){
                    feat.checked = type;
                    return feat;
                }else{
                    return feat;
                }
            })
        })
        setIsOpen(false)
    }
 
    
    return (
        <div className="features-item">
            <div className="input-group-container input-cap-space">
                <span
                    onClick={()=> setIsOpen(true)}
                    onBlur={()=> setTimeout(()=>{setIsOpen(false)},500)}
                    tabIndex={0}
                    className='cap-text'
                >
                    {checked ? checkFillIcon : crossFillIcon}
                    <svg className='dropdown-icon' width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.556096 2.19728L9.33063 11.2882C9.60629 11.5651 10.0432 11.5814 10.3378 11.3371L10.3913 11.2882L19.4417 2.19768C19.479 2.1602 19.5 2.10946 19.5 2.05657V0.493213C19.5 0.382756 19.4105 0.293213 19.3 0.293213C19.2468 0.293213 19.1958 0.314404 19.1583 0.3521L9.89085 9.65998L0.843341 0.361735C0.766311 0.28257 0.639689 0.28084 0.560524 0.35787C0.521829 0.395522 0.5 0.44722 0.5 0.501211V2.05838C0.5 2.1102 0.520111 2.15999 0.556096 2.19728Z" fill="#80949D"/>
                    </svg>
                </span>
                    {isOpen && 
                        <ul className='feature-dropdown'>
                            <li onClick={()=> checkedHandler(true)}>{checkFillIcon}</li>
                            <li onClick={()=> checkedHandler(false)}>{crossFillIcon}</li>
                        </ul>
                    }
                <input 
                    placeholder="Type" 
                    type="text" 
                    className="input"
                    onChange={(e)=> setFeaturesList((prev:any) => {
                        return prev.map((feat:any, key:number)=>{
                            if(index === key){
                                feat.value = e.target.value
                                return feat;
                            }else{
                                return feat;
                            }
                        })
                    })}
                    value={feature.value}
                    style={{paddingLeft: 95}}
                />
                <i 
                    className='feature-cross-action'
                    onClick={()=>setFeaturesList((prev:any)=> prev.filter((item:any, indexx:number) => indexx !== index))}
                >{crossIcon}</i>
            </div>
        </div>
    )
}

export default SubscriptionForm
