import { useState, useEffect, useReducer, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button } from 'antd'
import client from 'feathers-client'
import { SERVICES, URL_PATH, crypto_secret, generateRandomPassword } from 'utils/constants'
import axios, { AxiosRequestConfig } from 'axios';
import AppInfo from './AppInfo/index'
import PaymentInfo from './PaymentInfo/index'
import Requirements from './Requirements/index'
import UpdateTime from './UpdateTime/index'
import CryptoJS from 'crypto-js';
import { AuthContext, authReducer } from 'context/AuthContext'


interface submitprops {
  payload: any
  template: any,
  template2: any,
  auth: any
}



interface CustomerPortalStepsProps {
  currentStep: number
  setCurrentStep: (e: any) => void
  appIndustry: string
  appDescription: string,
  appImage:any
  setShowStepper:any,
  jobData:any
  submitJobStepsHandler:any
  paymentsInfo:any
}

const handleSubmitInboarding = async ({ payload, template, template2, auth }: submitprops) => {

  const encryptionKey = crypto_secret;
  const encryptUserId = (userId: string) => {
    const encrypted = CryptoJS.AES.encrypt(userId, encryptionKey).toString();
    return encrypted;
  };

  if(auth?.user?.email && auth?.user?.name){
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}JobCreate`,
      headers: {
          'Content-Type': 'application/json',
      },
      data: JSON.stringify({
          name: "",
          customer_name: auth?.user?.name,
          customer_email: auth?.user?.email,
          //business_name: values.business_name,
          invite_email: ``, // TODO: build email preview and send
          isSubmitted: false, // At first time to create by default
          isJobActive: false
      }),
  };
  
  const jobCreate:any = await axios.request(config);

  const id:any = jobCreate.data.data._id


  if (payload.attachments.length >= 0) {
  
    const fileListArray = Array.from(payload.attachments)
    let images: any[] = [];
    const promises = fileListArray?.map((file: any) => {
      return new Promise<void>((resolve) => {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            const base64Image = e.target?.result as string;
            const image = {
              base64: base64Image,
              name: file.name,
              size: file.size,
              type: file.type,
            };
            images.push(image);
            resolve(); // Resolve the promise when the image is processed
          };
          reader.readAsDataURL(file);
        } else {
          resolve(); // Resolve the promise if there's no file
        }
      });
    });
    await Promise.all(promises);
  
    try {
      const response = await axios.post(
        `${URL_PATH.CLIENT1}uploads`,
        images
      );
      payload.attachments = response.data.data
      payload.isExpired = true;
    } catch (error) {
      console.error('Upload error:', error);
    }
  
    const config: AxiosRequestConfig = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}/customerJobUpdate/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: payload,
    };
  
    const dataUser = await axios.request(config);
    const cu_email = dataUser?.data?.data;
    /** create temp user */
  
    let config_name: AxiosRequestConfig = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}customerjobstatusGet/${id}`,
    };
    const dataUserName = await axios.request(config_name);
    const customer_name = dataUserName?.data?.job?.customerName;
    const newPassword: string = generateRandomPassword(10);
    const siteRootUrl: string = window.location.origin;
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}/password-reset`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { email: cu_email },
    };
    let INVITE: any;
    const response = await axios.request(configE);
    if (response.data.message == 'User with this email does not exist') {
      await client.service(SERVICES.USERS).create({
        name: customer_name,
        email: cu_email,
        password: newPassword,
        permissions: ['customer'],
        isTempUser: false
      })
      let customerFirstName = customer_name?.split(" ")?.shift();
      INVITE = template.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Link]", `${siteRootUrl}/login?email=${cu_email}`).replaceAll("[Email]", cu_email).replaceAll("[Password]", newPassword);
      // INVITE = `
      // Dear ${customer_name},
      // Thank you for providing the necessary details for your custom app. We've prepared a proposal with an estimated cost of [Insert Cost].
      // To review and confirm, click ${siteRootUrl}/login . Your login details are:
      // Username: ${cu_email}
      // Password: ${newPassword}
      // Within the portal, you'll find project specifics and can communicate with our team.
      // We appreciate your consideration and look forward to your feedback.
      // Best,
      // Shane
      // Proton`;
    } else if (response?.data?.userId) {
      let customerFirstName = customer_name?.split(" ")?.shift();
      INVITE = template2.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Link]", `${siteRootUrl}/login?email=${cu_email}`).replaceAll("[Email]", cu_email);
    }
    const emailObj = {
      to: cu_email,
      subject: response?.data?.message === 'User with this email does not exist' ? template.subject : template2.subject,
      text: INVITE,
    }
    const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
    toast.success('Email sent successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
  
    window.location.href = "/customer"
  } else {
    await client.service(SERVICES.JOBS).patch(id, payload)
    window.location.href = "/customer"
  }
  
  }else{
    window.location.href = "/login"
  }
  
// jobCreate.data.status

}
let firstCall = true;
const CustomerPortalSteps = ({
  setShowStepper,           
  currentStep,
  setCurrentStep,
  appIndustry,
  appDescription,
  appImage,
  jobData,
  paymentsInfo,
  submitJobStepsHandler
}: CustomerPortalStepsProps) => {
  const [auth, dispatch] = useReducer(authReducer, null)
  const [appTitle, setAppTitle] = useState(jobData?.appTitle || '')
  const [customerBusinessName, setCustomerBusinessName] = useState(jobData?.customerBusinessName || '')
  const [color, setColor] = useState(jobData?.color || '#6B71E5')
  const [developedFor, setDevelopedFor] = useState(jobData?.developedFor ||'ios&android')
  const [appTheme, setAppTheme] = useState(jobData?.appTheme || 'light&dark')
  const [appPrice, setAppPrice] = useState(jobData?.appPrice || 'free')
  const [appUpdates, setAppUpdates] = useState(jobData?.appUpdates || 'none')
  const [attachments, setAttachments] = useState([])
  const [appRequirements, setAppRequirements] = useState(jobData?.appRequirements || '')
  const { id } = useParams()
  const [template, setTemplate] = useState<any>({})
  const [template2, setTemplate2] = useState<any>({})

  const checkAuthentication = useCallback(async () => {
    try {
      const auth = await client.reAuthenticate()
      dispatch({
        type: 'added',
        auth,
      })
    } catch (error) {
      // navigate('/login')
    }
  }, [])
  useEffect(() => {
    checkAuthentication()
  }, [checkAuthentication])

  useEffect(()=>{
    const fetchTemplateTimer = setTimeout(async()=>{
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "customer_access"
        }),
      };
    

      try{
        const templateResult = await axios.request(configE);
        if(templateResult.data.status === 200){
          setTemplate(templateResult.data.template.data[0])
        }else{
          console.log("Template not found !!!")
        }
      }
      catch(err:any){
        console.log("Something went wrong!!!", err.message)
      }
    },200)

    return () => clearTimeout(fetchTemplateTimer)
  },[])

  useEffect(()=>{
    const fetchTemplateTimer = setTimeout(async()=>{
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "existing_customer_access"
        }),
      };
    

      try{
        const templateResult = await axios.request(configE);
        if(templateResult.data.status === 200){
          setTemplate2(templateResult.data.template.data[0])
        }else{
          console.log("Template not found !!!")
        }
      }
      catch(err:any){
        console.log("Something went wrong!!!", err.message)
      }
    },200)

    return () => clearTimeout(fetchTemplateTimer)
  },[])

  // useEffect(()=>{
  //   const patchJobDataTimer = setTimeout(() => {
  //     if(!firstCall){
  //       submitJobStepsHandler({
  //         step: currentStep,
  //         appTitle,
  //         customerBusinessName,
  //         appRequirements,
  //         appPrice,
  //         developedFor,
  //         appUpdates,
  //         appTheme,
  //         color,
  //       })
  //     }else{
  //       firstCall = false;
  //     }
  //   }, 200);

  //   return ()=> clearTimeout(patchJobDataTimer)
  // },[appTitle, appRequirements, appPrice, developedFor, appUpdates, appTheme, color, customerBusinessName])


  const handleBack = async () => {
    if (currentStep > 1) {
      await setCurrentStep(currentStep - 1)
    }
  }

  const Content = ({ currentStep, template, template2 }: any) => {
    switch (currentStep) {
      case 0:
        return (
          <AppInfo
            appTitle={appTitle}
            customerBusinessName={customerBusinessName}
            setCustomerBusinessName={setCustomerBusinessName}
            setAppTitle={setAppTitle}
            color={color}
            setColor={setColor}
            developedFor={developedFor}
            appTheme={appTheme}
            setDevelopedFor={setDevelopedFor}
            setAppTheme={setAppTheme}
          />
        )
      case 1:
        return <PaymentInfo appPrice={appPrice} setAppPrice={setAppPrice} footerJSX={footerJSX} />
      case 2:
        return (
          <UpdateTime setAppUpdates={setAppUpdates} appUpdates={appUpdates } footerJSX={footerJSX} />
        )
      case 3:
        return (
          <Requirements
            appTitle={appTitle}
            customerBusinessName={customerBusinessName}
            setCustomerBusinessName={setCustomerBusinessName}
            setAppTitle={setAppTitle}
            attachments={attachments}
            setAttachments={setAttachments}
            appRequirements={appRequirements}
            setAppRequirements={setAppRequirements}
            footerJSX={footerJSX}
          />
        )
      default:
        return null
    }
  }

  const footerJSX = (
   
    <div
      style={{
        display: 'flex',
        justifyContent:  'space-between',
        alignItems: "center"
      }}
    >
      {/* {currentStep > 0 ? ( */}
        <Button className='back-button' onClick={()=>{
        if(currentStep === 1){
          setShowStepper(false)
        }else{
          handleBack()
        } 
        }}>
          <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.141 2.26301V0.753246C10.141 0.622386 9.99063 0.550121 9.88907 0.630199L1.08438 7.50715C1.00957 7.56533 0.949039 7.63982 0.9074 7.72495C0.865761 7.81008 0.844116 7.9036 0.844116 7.99836C0.844116 8.09313 0.865761 8.18665 0.9074 8.27177C0.949039 8.3569 1.00957 8.4314 1.08438 8.48957L9.88907 15.3665C9.99258 15.4466 10.141 15.3743 10.141 15.2435V13.7337C10.141 13.638 10.0961 13.5462 10.0219 13.4876L2.99063 7.99934L10.0219 2.5091C10.0961 2.45051 10.141 2.35871 10.141 2.26301Z" fill="#60747E"/>
          </svg>
          &nbsp;&nbsp;
          Back
        </Button>
      {/* ) : null} */}
      <Button
        type="primary"
        className='button new'
        onClick={() => handleNext(currentStep)}
        style={{ background: '#2CBBE8' }}
      >
        {currentStep === 3 ? 'Save' : 'Continue'}
      </Button>
    </div>
  
  )


  const handleNext = async (currentStep: number) => {
    switch (currentStep) {
      case 0:
        if (appTitle.trim() === '') {
          toast.error('App title is required', {
            autoClose: 600,
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
        // else if(customerBusinessName.trim() === ''){
        //   toast.error('Business name is required', {
        //     autoClose: 600,
        //     position: toast.POSITION.BOTTOM_CENTER,
        //   })
        // }
         else {
          setCurrentStep(currentStep + 1)
        }
        break
      case 1:
        setCurrentStep(currentStep + 1)
        break
      case 2:
        setCurrentStep(currentStep + 1)
        break
      case 3:
        const payload = {
          appTitle: appTitle,
          customerBusinessName,
          appIndustry: appIndustry,
          appDescription: appDescription,
          appImage: appImage,
          appRequirements: appRequirements,
          attachments: attachments,
          appPrice: appPrice,
          developedFor: developedFor,
          appUpdates: appUpdates,
          appTheme: appTheme,
          color: color,
          isSubmitted: true,
          depositReceived: false,
          finalPaymentReceived: false,
          buildingFinished: false,
          isJobActive:false,
          deposit: paymentsInfo.deposit,
          finalPayment: paymentsInfo.finalPayment,
          monthlySubscription: paymentsInfo.monthlySubscription,
        }
        if (appTitle.trim() === '') {
          toast.error('App title is required', {
            autoClose: 600,
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
        // else if(customerBusinessName.trim() === ''){
          //   toast.error('Business name is required', {
            //     autoClose: 600,
            //     position: toast.POSITION.BOTTOM_CENTER,
            //   })
            // }
        else {
        
          handleSubmitInboarding({ payload, template, template2, auth})
          // setCurrentStep(currentStep + 1)
        }
        // setCurrentStep(currentStep + 1)
        break

      default:
        return <></>
    }
  }

  return (
    <>
      <AuthContext.Provider 
        value={auth}
      >
    
          
            {Content({ currentStep, template, template2 })}
        
        

      </AuthContext.Provider>
    </>
  )
}

export default CustomerPortalSteps
