import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import client from 'feathers-client'
import { LINKS, getJobDetailsLink, getJobDetailsUrl } from 'routes/constants'
import { DEFAULT_AVATAR, SERVICES, URL_PATH } from 'utils/constants'

import DeleteModal from 'components/ActionModal/DeleteModal'
import ModalPreviewJobTemplate from 'components/ActionModal/components/ModalPreviewJobTemplate'
import Avatar from 'components/Avatar/Avatar'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import StateBadge, { StateBadgeType } from 'components/StateBadge/StateBadge'

import briefcaseIcon from 'assets/briefcase-4-fill.svg'
import deleteIcon from 'assets/delete.svg'
import infoIcon from 'assets/info-circle.svg'
import checklistIcon from 'assets/list-check.svg'
import thumbTackIcon from 'assets/thumbtack.svg'
import userIcon from 'assets/user-3-fill.svg'

import { JobSummery_type } from '../../../utils/constants'
import { IJobItem } from '../Jobs'
import BaseModal from 'components/ActionModal/BaseModal'
import ShowInfoModal from './ShowInfoModal'
import { AuthContext } from 'context/AuthContext'
import axiosInstance from 'utils/axios'
import axios, { AxiosRequestConfig } from 'axios'

interface IActiveJobItemProps {
  job: IJobItem
  handlePinJob: any
  fetchjobs_fnitm: (params: any) => any
  handleDeleteJob: () => void
  hidejobdescription?: boolean
  paymentHandler?: () => void
}



/** Edit by sandeep Start */
export enum JobStatusMapping {
  New = 'new',
  InProgress = 'in_progress',
  Review = 'review',
  Promoted = 'promoted',
  Finished = 'finished',
}

const badgeColors = {
  [StateBadgeType.New]: {
    foreground: '#344563',
    background: '#EBECF0',
  },
  [StateBadgeType.Finished]: {
    background: '#FFEBE6',
    foreground: '#BF2600',
  },
  [StateBadgeType.InProgress]: {
    foreground: '#0747A6',
    background: '#DEEBFF',
  },
  [StateBadgeType.Promoted]: {
    foreground: '#006644',
    background: '#E3FCEF',
  },
  [StateBadgeType.Review]: {
    foreground: '#FF8B00',
    background: '#FFFAE6',
  },
}

const checkIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z" fill="#00B8D9" />
</svg>;

const crossIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757" />
</svg>
  ;

/** Edit by sandeep end */

const ActiveJobItem = ({
  job,
  handlePinJob,
  fetchjobs_fnitm,
  handleDeleteJob,
  hidejobdescription = false,
  paymentHandler=()=>{}
}: IActiveJobItemProps) => {
  const navigate = useNavigate()
  //   const {jobID} = job

  const jobId = job?.id
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [progressCount, setProgressCount] = useState<number>(0)
  const [isShowInfoModalOpen, setShowInfoModalOpen] = useState(false);
  // const [isJobActive, setIsJobActive] = useState(job?.isJobActive === false ? false : true);
  const [image, setImage] = useState(DEFAULT_AVATAR)
  // const [paymentTemplate, setPaymentTemplate] = useState<any>({});
  // const [activeTemplate, setActiveTemplate] = useState<any>({});
  const location = useLocation()

  // const [showPaymentModal, setShowPaymentModal] = useState(false)



  const auth = useContext(AuthContext)
  const isTemp = auth?.user?.permissions?.includes("temp");
  const isCustomers = auth?.user?.permissions?.includes("customer");
  const isUser = auth?.user?.permissions?.includes("user");
  const isAdmin = auth?.user?.permissions?.includes("administrator");

  useEffect(() => {
    const fetchJobTasksTimer = setTimeout(() => {
      fetchJobTaksHandler(jobId)
    }, 200)

    return () => clearTimeout(fetchJobTasksTimer)
  }, [])

  useEffect(() => {
    const fetchTemplateImage = setTimeout(async () => {
      if (job.appImage) {
        const response: any = await axiosInstance.get(`getSignedFileUrl/?fileName=${job.appImage}`)
        if (response?.status) {
          // console.log(response.data.url);
          setImage(response.data.url)
        }
      }
    }, 200)

    return () => clearTimeout(fetchTemplateImage)
  }, [])

  // useEffect(() => {
  //   const fetchTemplateTimer = setTimeout(async () => {
  //     const configE: AxiosRequestConfig = {
  //       method: 'post',
  //       maxBodyLength: Infinity,
  //       url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       data: JSON.stringify({
  //         "type": "payment_received"
  //       }),
  //     };

  //     try {
  //       const templateResult = await axios.request(configE);
  //       if (templateResult.data.status === 200) {
  //         // setPaymentTemplate(templateResult.data.template.data[0])
  //       } else {
  //         console.log("Template not found !!!")
  //       }
  //     }
  //     catch (err: any) {
  //       console.log("Something went wrong!!!", err.message)
  //     }
  //   }, 200)

  //   return () => clearTimeout(fetchTemplateTimer)
  // }, [auth])
  // useEffect(() => {
  //   const fetchTemplateTimer = setTimeout(async () => {
  //     const configE: AxiosRequestConfig = {
  //       method: 'post',
  //       maxBodyLength: Infinity,
  //       url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       data: JSON.stringify({
  //         "type": "active_job"
  //       }),
  //     };

  //     try {
  //       const templateResult = await axios.request(configE);
  //       if (templateResult.data.status === 200) {
  //         // setActiveTemplate(templateResult.data.template.data[0])
  //       } else {
  //         console.log("Template not found !!!")
  //       }
  //     }
  //     catch (err: any) {
  //       console.log("Something went wrong!!!", err.message)
  //     }
  //   }, 200)

  //   return () => clearTimeout(fetchTemplateTimer)
  // }, [auth])

  const fetchJobTaksHandler = useCallback(async (Id: any) => {
    const query = {
      jobId: job.id,
    }
    const jobTasksData = await client.service(SERVICES.TASK).find({ query })
    if (Array.isArray(jobTasksData?.data)) {
      // resolve({jobTitle: job.name, tasks: jobTasksData?.data.filter((item:any) => item.status === 'review')})
      const finishedcountNum = finishedcount(jobTasksData?.data);
      const totalTasksCount = jobTasksData?.data?.length;
      const percentage = ((finishedcountNum / totalTasksCount) * 100).toFixed(0)
      setProgressCount(+percentage)
    } else {
      // resolve({jobTitle: job.name, tasks: []})
    }
  }, [job])

  const finishedcount = useCallback(
    (taskList: any) => {
      let finishedCount = 0
      for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].status === 'finished') {
          finishedCount++
        }
      }
      return finishedCount
    }, [job])

  const kebabMenuItems = useMemo(() => {
    let items;
    if (!(isCustomers || isTemp)) {
      items = [
        {
          label: 'Pin',
          icon: thumbTackIcon,
          disabled: false,
          onClick: () => {
            handlePinJob(job.id);
          },
        },
        {
          label: 'Job details',
          icon: checklistIcon,
          disabled: false,
          onClick: () => {
            navigate(getJobDetailsUrl(job.id));
          },
        },
        {
          label: 'Delete',
          icon: deleteIcon,
          disabled: false,
          onClick: () => {
            setDeleteOpen(true);
          },
        },
        {
          label: 'Show info',
          icon: infoIcon,
          disabled: false,
          onClick: () => {
            setShowInfoModalOpen(!isShowInfoModalOpen);
          },
        },
      ];
    } else {
      items = [
        {
          label: 'Job details',
          icon: checklistIcon,
          disabled: false,
          onClick: () => {
            navigate(getJobDetailsUrl(job.id));
          },
        },
        {
          label: 'Show info',
          icon: infoIcon,
          disabled: false,
          onClick: () => {
            setShowInfoModalOpen(!isShowInfoModalOpen);
          },
        },
      ];
    }

    return items;
  }, [job, navigate]);


  const isCustomer = useMemo(() => location.pathname === LINKS.CustomerDashboard, [location]);
  const isPendingTask = location.pathname === LINKS.PendingTask;
  /** Edit by sandeep Start */
  const jobTypeData = JobSummery_type.type

  /** update jo status in db */
  const handleJobStatusChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedJobStatus = e.target.value
      if (jobId) {
        const jobobj = {
          jobStatusChanged: true,
          jobStatus: selectedJobStatus,
        }
        try {
          await client.service(SERVICES.JOBS).patch(jobId, jobobj)
          toast.success('Status updated successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        } catch (error: any) {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
        if (fetchjobs_fnitm) {
          fetchjobs_fnitm(true)
        }
      }
    },
    []
  )

  const isJobActiveHandler = useCallback(
    async (status: boolean) => {
      const jobobj = {
        isJobActive: status,
      }

      try {
        await client.service(SERVICES.JOBS).patch(jobId, jobobj)
        // setIsJobActive(status)
      } catch (err) {
        // setIsJobActive(false)
        console.log(err)
      }
    }, []
  )

  function mapJobStatusToStateBadgeType(
    jobStatus: string | undefined
  ): StateBadgeType {
    if (jobStatus === JobStatusMapping.New) {
      return StateBadgeType.New
    } else if (jobStatus === JobStatusMapping.InProgress) {
      return StateBadgeType.InProgress
    } else if (jobStatus === JobStatusMapping.Review) {
      return StateBadgeType.Review
    } else if (jobStatus === JobStatusMapping.Promoted) {
      return StateBadgeType.Promoted
    } else if (jobStatus === JobStatusMapping.Finished) {
      return StateBadgeType.Finished
    } else {
      return StateBadgeType.New // Handle unknown statuses
    }
  }

  function getBadgeColors(status: StateBadgeType) {
    return badgeColors[status]
  }

  function calculateDiscountedPrice(productPrice: any, discountPercentage: any) {
    // if (discountPercentage <= 0 || discountPercentage > 100) {
    //     throw new Error("Invalid discount percentage.");
    // }

    const discountAmount = (productPrice * discountPercentage) / 100;
    const discountedPrice = productPrice - discountAmount;

    return discountedPrice;
  }

  const stateBadgeType = mapJobStatusToStateBadgeType(job?.jobStatus)
  const badgeStyle = getBadgeColors(stateBadgeType)

  const defaultStyles = { color: 'black', backgroundColor: '#EBECF0', marginTop: 0 }

  // const paymentReceivedEmailHandler = useCallback(
  //   async () => {
  //     let INVITE: any;
  //     let customer_name = auth?.user?.name
  //     let customerFirstName = customer_name?.split(" ")?.shift();


  //     INVITE = paymentTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Payment]", "$" + job?.finalPayment?.toFixed(2)).replaceAll("[Payment Type]", "final payment");

  //     try {
  //       const emailObj = {
  //         to: auth?.user?.email,
  //         subject: paymentTemplate.subject,
  //         text: INVITE,
  //       }
  //       const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
  //       // toast.success(' successfully', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //       // setResendEmail({
  //       //   status:true,
  //       //   email:staff.email
  //       // })
  //     } catch (err: any) {
  //       console.log(err?.message)
  //       // toast.success('Something went wrong!!!', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //     }

  //     // console.log(INVITE)
  //   }, [auth, paymentTemplate]
  // )
  // const activeJobEmailHandler = useCallback(
  //   async () => {
  //     let INVITE: any;
  //     let customer_name = auth?.user?.name
  //     let customerFirstName = customer_name?.split(" ")?.shift();


  //     INVITE = activeTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name);

  //     try {
  //       const emailObj = {
  //         to: auth?.user?.email,
  //         subject: activeTemplate.subject,
  //         text: INVITE,
  //       }
  //       const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
  //       // toast.success(' successfully', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //       // setResendEmail({
  //       //   status:true,
  //       //   email:staff.email
  //       // })
  //     } catch (err: any) {
  //       console.log(err?.message)
  //       // toast.success('Something went wrong!!!', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //     }

  //     // console.log(INVITE)
  //   }, [auth, activeTemplate]
  // )

  // const paymentModalJSX = (
  //   <div className='payment-modal-main'>
  //     <div className=''>
  //       <img src="./assets/logo-black-text.png" alt="" />
  //     </div>
  //     <div className="credit_card_heading" style={{
  //       marginTop: '20px',
  //     }}>
  //       <h4 style=
  //         {{
  //           fontWeight: '600',
  //           color: '#00314D',
  //           marginBottom: '10px',
  //           fontSize: '40px',
  //           lineHeight: '48px',
  //         }}>Make Payment</h4>
  //       <p style={{
  //         marginBottom: '20px',
  //         color: '#60747E',
  //         fontSize: '16px',
  //         lineHeight: '19.2px',
  //         fontWeight: '400',
  //       }}
  //       >Manage your credit cards and payment options.</p>
  //     </div>
  //     <div className="payment_method">
  //       <div className="mastercard_ending active">
  //         <div className="check_image">
  //           <img src="/assets/CheckCircleFill.png" />
  //         </div>
  //         <img src="assets/card_48.png" />
  //         <h4>Mastercard ending in 1075</h4>
  //       </div>
  //       <div className="mastercard_ending">
  //         <div className="check_image">
  //           {/* <img src="/assets/CheckCircleFill.png" /> */}
  //         </div>
  //         <img src="/assets/paypalcard48.png" />
  //         <h4>example@gmail.com</h4>
  //       </div>
  //       <div className="mastercard_ending">
  //         <div className="check_image">
  //           {/* <img src="/assets/CheckCircleFill.png" /> */}
  //         </div>
  //         <img src="assets/visa_card_48.png" />
  //         <h4>Visa ending in 8020</h4>
  //       </div>
  //     </div>
  //     <div className="credit-card-form">
  //       <div className="form-row">
  //         <label>
  //           Cardholder's Name:
  //           <input
  //             type="text"
  //             value={cardholderName}
  //             onChange={(e) => setCardholderName(e.target.value)}
  //             required
  //           />
  //         </label>
  //         <label>
  //           Card Number:
  //           <input
  //             type="text"
  //             value={cardNumber}
  //             onChange={(e) => setCardNumber(e.target.value)}
  //             required
  //           />
  //         </label>
  //       </div>
  //       <div className="form-row">
  //         <label>
  //           <p>Expiration Date:</p>
  //           <div className="select_month" style={{
  //             display: 'flex',
  //             gap: '10px',
  //             justifyContent: 'space-between',
  //           }}>
  //             <select
  //               value={expiryMonth}
  //               onChange={(e) => setExpiryMonth(e.target.value)}
  //               required
  //             >
  //               <option value="">Month</option>
  //               <option value="01">January</option>
  //               <option value="02">February</option>
  //               <option value="03">March</option>
  //               <option value="04">April</option>
  //               <option value="05">May</option>
  //               <option value="06">June</option>
  //               <option value="07">July</option>
  //               <option value="08">August</option>
  //               <option value="09">September</option>
  //               <option value="10">October</option>
  //               <option value="11">November</option>
  //               <option value="12">December</option>
  //               {/* Add month options */}
  //             </select>
  //             <select
  //               value={expiryYear}
  //               onChange={(e) => setExpiryYear(e.target.value)}
  //               required
  //             >
  //               <option value="">Year</option>
  //               {Array.from({ length: 76 }, (v, i) => (
  //                 <option key={i} value={new Date().getFullYear() + i}>
  //                   {new Date().getFullYear() + i}
  //                 </option>
  //               ))}

  //               {/* Add year options */}
  //             </select>
  //           </div>
  //         </label>
  //         <label>
  //           CVV:
  //           <input
  //             type="text"
  //             value={cvv}
  //             onChange={(e) => setCvv(e.target.value)}
  //             required
  //           />
  //         </label>
  //       </div>
  //       {/* <button type="submit">Submit</button> */}
  //     </div>
  //     <div
  //       className='payment_logo'
  //       style={{
  //         display: 'flex',
  //         gap: '0px 10px',
  //         marginTop: '50px',
  //       }}
  //     >
  //       <img src="assets/cardlogo.png" />
  //       <img src="assets/visacardlogo.png" />
  //       <img src="/assets/paypallogo.png" />
  //       <img src="/assets/discoverlogo.png" />
  //       <img src="/assets/amricanlogo.png" />
  //     </div>
  //   </div>
  // )

  // const paymentModalActionsJSX = (
  //   <>
  //     <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>
  //     <button className="primary button" onClick={() => fullPaymentHandler(job.id)} >Pay Now{job?.finalPayment && `Final Payment $${job.finalPayment.toFixed(2)}`}</button>
  //   </>
  // )


  //  const fullPaymentHandler = useCallback(
  //   async (jobId:any) => {
  //     const jobobj = {
  //       finalPaymentReceived: true
  //     }

  //     try{
  //       await client.service(SERVICES.JOBS).patch(jobId, jobobj)
  //       await paymentReceivedEmailHandler();
  //      if(job.buildingFinished){
  //       await activeJobEmailHandler();
  //       await deleteJobPendingtasks(jobId);
  //      }
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 1000);

  //     }catch(err){
  //       toast.error('Something went wrong!!!', {
  //         position: toast.POSITION.BOTTOM_CENTER,
  //       })
  //       // setIsJobActive(false)
  //       console.log(err)
  //     }
  //   },[auth, activeTemplate, paymentTemplate]
  // )
  // const fullPaymentHandler = async (jobId: any) => {
  //   const configE: AxiosRequestConfig = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: `${URL_PATH.CLIENT1}create-payment`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     data: JSON.stringify({
  //       "amount": 1000, // Amount in cents (Stripe uses cents)
  //       "currency": "usd", // Desired currency code
  //       "email": auth?.user?.email,
  //       "userId": auth?.user?.id,
  //       "jobId": jobId,
  //       "paymentType": "DEPOSIT"
  //     }),
  //   };

  //   try {
  //     const apiResult = await axios.request(configE);
  //     console.log("apiResult >>", apiResult)
  //     // if(apiResult.data.status === 200){

  //     // }else{
  //     //   console.log("Template not found !!!")
  //     // }
  //   }
  //   catch (err: any) {
  //     console.log("Something went wrong!!!", err.message)
  //   }
  // }

  // const deleteJobPendingtasks = async (jobId: any) => {
  //   try {
  //     const query = {
  //       jobId: jobId,
  //     }
  //     const getAllTask = await client.service(SERVICES.TASK).find({ query })
  //     if (Array.isArray(getAllTask?.data)) {
  //       let pendingTasks = getAllTask?.data.filter((item: any) => item.status === 'review')
  //       let promiseArray: any = [];
  //       pendingTasks.map((task: any) => {
  //         const promise = new Promise(async (resolve, reject) => {
  //           try {
  //             const response = await client.service(SERVICES.TASK).remove(task.id)
  //             resolve(true)
  //           } catch (err) {
  //             reject(false)
  //           }
  //         })
  //         promiseArray.push(promise)
  //       })

  //       Promise.all(promiseArray).then(async (result) => {
  //         // await fetchJob();
  //         // await fetchTask();
  //         return true
  //       })
  //     }
  //   } catch (error: any) {
  //     console.log(error?.message)
  //   }
  // }

  /** Edit by sandeep End */
  return (

    <div className="job-item" style={isPendingTask ? { flexWrap: "wrap", paddingTop: "10px", margin: "0px", boxShadow: "none", paddingBottom: "10px", paddingLeft: "20px", paddingRight: "20px", border: "0px", borderBottom: "1px solid #00000030", borderRadius: "0px" } : {}}>
      <div className="left" style={isPendingTask ? { width: "100%" } : {}}>
        <Avatar
          size={56}
          name={job.appIndustry}
          url={image}
        />
        <div className="details">
          <div className="title">{job.appTitle}</div>
          {/* {!isCustomer ?  (
              <div className="info">
                <img src={userIcon} alt="" /> {job.customerName}
              </div>
              ) : (
                <div className=''>
                  <div className="app-type">
                    {job.appIndustry}
                  </div>
                  <span className='spacer' style={{ display:'block' ,width:'1px',background:'#B7C5CC',height: '20px'}}></span>
                  <p className='description'>{progressCount || 0}%</p>
                  <p className='job_status' style={{background:'#FFD3D3', color:'#A13030'}}>New</p>
                </div>
            )} */}
          <div className="job_middle_content">
            <div className="app-type">
              {job.appIndustry}
            </div>
            <span className='spacer' style={{ display: 'block', width: '1px', background: '#B7C5CC', height: '20px' }}></span>
            {(isCustomers && job.depositReceived && !job.finalPaymentReceived) ?
              <>
               <p className='description'>{progressCount || 0}%</p>
                <button onClick={paymentHandler} className="primary button new small" style={{ marginRight: 10 }} >Final Pay</button>
                {/* <ProgressBar progress={progressCount} style={{ marginRight: '20px' }} /> */}
                {/* <BaseModal
                  className='paymentModal'
                  isOpen={showPaymentModal}
                  children={paymentModalJSX}
                  actionButtons={paymentModalActionsJSX}
                  handleClose={() => setShowPaymentModal(false)}
                /> */}
              </>
              :
              <>
                {isAdmin ?
                  <ProgressBar progress={progressCount} />
                  :
                  <p className='description'>{progressCount || 0}%</p>
                }
                {isAdmin &&
                  <>
                    {job.isJobActive ?
                      job?.depositReceived === false ?
                        <div className="progress-badge" style={{ background: "#ff00001f", color: 'red', marginRight: 10 }}>Inactive</div>
                        :
                        <div className="progress-badge" style={{ background: "#00800033", color: 'green', marginRight: 10 }}>Active</div>

                      :
                      <div className="progress-badge" style={{ background: "#ff00001f", color: 'red', marginRight: 10 }}>Inactive</div>
                    }
                  </>
                }
                {(isCustomers || isUser) && job.depositReceived && job.finalPaymentReceived && !job.buildingFinished &&
                  <div className="progress-badge" style={{ background: "#00800033", color: 'green', marginRight: 10 }}>Payment Received</div>
                }

                {(isCustomers || isUser) && job.depositReceived && !job.finalPaymentReceived && job.buildingFinished &&
                  <div className="progress-badge" style={{ background: "#00800033", color: 'green', marginRight: 10 }}>Building Finished</div>
                }
              </>
            }
          </div>
          <span className="spacer" style={{ display: "block", height: "15px" }}></span>
          <div className='cart-bottom-info'>
            <div className="cart-bottom-info-item" >
              <span className='info-icon'>
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_301_3701)">
                    <path d="M9.5 2.5H8.95C8.83395 1.93571 8.52691 1.42867 8.08063 1.06436C7.63434 0.700045 7.0761 0.500727 6.5 0.5L5.5 0.5C4.9239 0.500727 4.36566 0.700045 3.91937 1.06436C3.47309 1.42867 3.16605 1.93571 3.05 2.5H2.5C1.8372 2.50079 1.20178 2.76444 0.73311 3.23311C0.264441 3.70178 0.000793929 4.3372 0 5L0 6.5H12V5C11.9992 4.3372 11.7356 3.70178 11.2669 3.23311C10.7982 2.76444 10.1628 2.50079 9.5 2.5ZM4.092 2.5C4.19504 2.20855 4.38562 1.95605 4.63765 1.77704C4.88969 1.59804 5.19087 1.50128 5.5 1.5H6.5C6.80913 1.50128 7.11031 1.59804 7.36235 1.77704C7.61438 1.95605 7.80496 2.20855 7.908 2.5H4.092Z" fill="#265068" />
                    <path d="M6.5 8C6.5 8.13261 6.44732 8.25979 6.35355 8.35355C6.25979 8.44732 6.13261 8.5 6 8.5C5.86739 8.5 5.74021 8.44732 5.64645 8.35355C5.55268 8.25979 5.5 8.13261 5.5 8V7.5H0V10C0.000793929 10.6628 0.264441 11.2982 0.73311 11.7669C1.20178 12.2356 1.8372 12.4992 2.5 12.5H9.5C10.1628 12.4992 10.7982 12.2356 11.2669 11.7669C11.7356 11.2982 11.9992 10.6628 12 10V7.5H6.5V8Z" fill="#265068" />
                  </g>
                  <defs>
                    <clipPath id="clip0_301_3701">
                      <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <p className="business">{job.customerBusinessName || "N/A"}</p>
            </div>
            {!isCustomer &&
              <>
                <span className='spacer' style={{ display: 'block', width: '1px', background: '#B7C5CC', height: '20px' }}></span>
                <div className="cart-bottom-info-item" >
                  <span className='info-icon'>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_301_3707)">
                        <path d="M6 6.5C7.65685 6.5 9 5.15685 9 3.5C9 1.84315 7.65685 0.5 6 0.5C4.34315 0.5 3 1.84315 3 3.5C3 5.15685 4.34315 6.5 6 6.5Z" fill="#265068" />
                        <path d="M6 7.5C3.51586 7.50277 1.50277 9.51586 1.5 12C1.5 12.2761 1.72385 12.5 1.99999 12.5H9.99998C10.2761 12.5 10.5 12.2761 10.5 12C10.4972 9.51586 8.48414 7.50274 6 7.5Z" fill="#265068" />
                      </g>
                      <defs>
                        <clipPath id="clip0_301_3707">
                          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <p className="business">{job.customerName || "N/A"}</p>
                </div>
              </>
            }
          </div>
        </div>









      </div>
      <div className="right" style={isPendingTask ? { width: "100%", marginLeft: "70px" } : {}}>


        {/* {isAdmin && job.depositReceived && location.pathname === LINKS.JOBS  && <button onClick={() => navigate(getJobDetailsUrl(job.id))} className="primary button" style={{marginRight:10}} >Tasks</button>} */}

        {!hidejobdescription &&
          <>
            {/* {(isCustomer ?  isJobActive : true) && <select
            name=""
            id=""
            className="gray"
            onChange={(e) => {
              if(!isCustomers){
                handleJobStatusChange(e)
              }
            }}
            disabled={isCustomers ? true : false}
            style={
              badgeStyle
                ? {
                  color: badgeStyle.foreground,
                  backgroundColor: badgeStyle.background,
                  marginTop:0
                }
                : defaultStyles
            }
          >
            {Object.entries(jobTypeData).map(([key, value]) => (
              <option
                style={defaultStyles}
                selected={key === job?.jobStatus}
                key={key}
                value={key}
                disabled={isCustomers ? true : false}
              >
                {value}
              </option>
            ))}
          </select>} */}
            {/* <StateBadge
            type={StateBadgeType.New}
            style={{ marginRight: '60px' }}
            isDropdown             
          /> */}
            <KebabMenu items={kebabMenuItems} disabled={isCustomers && !job?.isJobActive ? true : false} />
            <DeleteModal
              isOpen={deleteOpen}
              handleClose={() => {
                setDeleteOpen(false)
              }}
              body={<ModalPreviewJobTemplate job={job} image={image} />}
              handleDelete={handleDeleteJob}
              title="Delete job"
              subtitle="Do you wish to delete below job?"
            />
            <BaseModal
              isOpen={isShowInfoModalOpen}
              children={<ShowInfoModal job={job} />}
              handleClose={() => setShowInfoModalOpen(!isShowInfoModalOpen)}
            />
          </>
        }
      </div>

    </div>
  )
}

export default ActiveJobItem
