import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import AppTemplateForm from './forms/AppTemplateForm'
import './templates.sass'
import { getAppTemplate, updateAppTemplate } from './utils'

interface IEditAppTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const EditAppTemplate = ({ ...props }: IEditAppTemplateProps) => {
  const { templateId } = useParams()
  const auth = useContext(AuthContext)
  const [template, setTemplate] = useState(null)

  const fetchAppTemplate = useCallback(async () => {
    if (auth) {
      const data = await getAppTemplate(String(templateId))
      setTemplate(data)
    }
  }, [templateId, auth])

  useEffect(() => {
    fetchAppTemplate()
  }, [fetchAppTemplate])

  const handleSubmit = useCallback(updateAppTemplate, [])
  return (
    <div {...props} className="add-app-template templates">
      <PageHeader heading="Edit app template" showBack />
        <AppTemplateForm handleSubmit={handleSubmit} initialValues={template} />
    </div>
  )
}

export default EditAppTemplate
