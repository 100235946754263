import { ChangeEvent, useContext, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from 'axios'
import { AuthContext } from 'context/AuthContext'
import { URL_PATH } from 'utils/constants'

import TaskSidebar from '../components/TaskSidebar'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import Loader from 'components/Loader/Loader'

import AddTaskicon from 'assets/Addtask.svg'
import { ReactComponent as CancelIcon } from 'assets/CancelIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/uploadIcon.svg'

import '../jobs.sass'
import { createTask } from '../utils'

export interface JobMember {
  id: string
  avatar: string
  name: string
  email: string
}

const AddTask = () => {
  const fileRef = useRef<HTMLInputElement>(null)
  const { jobId } = useParams()
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const [fileList, setFilelist] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [isbuttonEnabled, setIsButtonEnabled] = useState<boolean>(false)
  const [estimatedTime, setEstimatedTime] = useState<string>('1h 30min')
  const [selectedAssignee, setSelectedAssignee] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<string>('New')
  const [loggedTime, setLoggedTime] = useState<string>('1h 30min')
  const [selectedPriority, setSelectedPriority] = useState<string>('Low')
  const [isAssignee, setIsAssignee] = useState(true)
  const [loader, setLoader] = useState<boolean>(false)
  const [uploadedFile] = useState([])

  const handleCreateTask = async () => {
    if (title.length && title.trim() != '') {
      setLoader(true)
      setIsButtonEnabled(true)
      if (auth) {
        const headers = {
          Authorization: `Bearer ${auth.accessToken}`,
        }
        const payload = {
          jobId: jobId,
          title: title,
          description: description,
          file: uploadedFile,
          status: selectedValue,
          assignee: selectedAssignee,
          reporter: 'Reporter',
          set_priority: selectedPriority,
          estimated_time: estimatedTime,
          logged_time: loggedTime,
        }

        if (fileList.length > 0) {
          const fileListArray = Array.from(fileList)
          let images: any[] = []
          const promises = fileListArray?.map((file: any) => {
            return new Promise<void>((resolve) => {
              if (file) {
                const reader = new FileReader()
                reader.onload = (e: ProgressEvent<FileReader>) => {
                  const base64Image = e.target?.result as string
                  const image = {
                    base64: base64Image,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                  }
                  images.push(image)
                  resolve() // Resolve the promise when the image is processed
                }
                reader.readAsDataURL(file)
              } else {
                resolve() // Resolve the promise if there's no file
              }
            })
          })

          // Wait for all promises to resolve before sending the POST request
          await Promise.all(promises)

          try {
            const response = await axios.post(
              `${URL_PATH.CLIENT1}uploads`,
              images
            )
            payload.file = response.data.data
          } catch (error) {
            console.error('Upload error:', error)
          }
          // const fileUploadUrl = `${URL_PATH.CLIENT1}uploads`
          // let UploadFormData = new FormData()
          // fileList.forEach((file: any) => {
          //   UploadFormData.append('files', file as any)
          // })
          // const fileResponse = await axios.post(fileUploadUrl, UploadFormData)

          // // const files = (
          // //   await axios.post(fileUploadUrl, UploadFormData, {
          // //     headers: headers,
          // //   })
          // // ).data?.data
          // payload.file = fileResponse?.data?.data
        }
        createTask(payload, headers, setLoader, navigate, setIsButtonEnabled)
      } else {
        setLoader(false)
      }
    } else {
      toast.error('Task title is required', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 600,
      })
      setTitle('')
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const uploadedFiles = []
      for (let i = 0; i < files.length; i++) {
        uploadedFiles.push(files[i])
      }
      setFilelist([...fileList, ...uploadedFiles])
    }
  }
  const removeFile = (file: any) => {
    if (file) {
      const data = fileList.filter((val: any) => val.name !== file.name)
      setFilelist(data)
    }
  }

  const rightSlot = (
    <div className="header-button-container">
      <button className="cnl-btn" onClick={() => navigate(-1)}>
          Cancel
        </button>
        <button
          className="sub-btn"
          disabled={isbuttonEnabled}
          onClick={handleCreateTask}
          // type="submit"
        >
          Create Task
        </button>
    </div>
  )

  return (
    <div className="full-page pending-job-detail">
      <PageHeader heading="Add task" rightSlot={rightSlot} showBack />
      {/* <form onSubmit={() => handleCreateTask()}> */}
      <div className="addTask-wrapper">
        <div className="addTask-wrapper-left">
          <div className="job-box">
            <div className="show_loader">{loader && <Loader />}</div>
            <div className="job-box-title">
              <div className="job-box-title-left">
                <div className="user-pic">
                  <img src={AddTaskicon} alt="AddtaskIcon" />
                </div>
                <div className="user-infos">
                  <h2>East and West Weather</h2>
                  <span>Weather app</span>
                </div>
              </div>
            </div>

            <div>
              <label style={{ fontSize: '16px' }}>Task Title</label>
              <br />
              <input
                id="full_name-input"
                placeholder="Type task title..."
                name="name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                style={{
                  width: '100%',
                  margin: '5px 0px',
                }}
              />
            </div>
            <div>
              <label style={{ fontSize: '16px' }}>Description</label>
              <br />
              <textarea
                id="full_name-input"
                placeholder="Write here details about this task.."
                value={description}
                name="name"
                onChange={(e) => setDescription(e.target.value)}
                rows={10}
                style={{
                  width: '100%',
                 
                  margin: '5px 0px',
                  
                }}
              />
            </div>
            <input
              ref={fileRef}
              style={{ display: 'none' }}
              id="file_name-input"
              type="file"
              name="file"
              onChange={handleFileChange}
              multiple
            />
            <label>Attachments :</label>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {fileList &&
                fileList.map((file: any) => {
                  return (
                    <div className="files">
                      <div>{file?.name}</div>
                      <i>
                        {file && (
                          <CancelIcon onClick={() => removeFile(file)} />
                        )}
                      </i>
                    </div>
                  )
                })}
            </div>
            <div className="cta-btn upload_btn" onClick={() => fileRef.current?.click()}>
              <img src="/assets/upload_image.png" alt="" />
              <div>Choose File</div>
            </div>
          </div>
        </div>
        <div className="addTask-status">
          <TaskSidebar
            setLoggedTime={setLoggedTime}
            loggedTime={loggedTime}
            isAssignee={isAssignee}
            setIsAssignee={setIsAssignee}
            selectedAssignee={selectedAssignee}
            setSelectedAssignee={setSelectedAssignee}
            setEstimatedTime={setEstimatedTime}
            estimatedTime={estimatedTime}
            setSelectedPriority={setSelectedPriority}
            selectedPriority={selectedPriority}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
        </div>
      </div>
      {/* <div className="btn">
        <button className="cnl-btn" onClick={() => navigate(-1)}>
          Cancel
        </button>
        <button
          className="sub-btn"
          disabled={isbuttonEnabled}
          onClick={handleCreateTask}
          // type="submit"
        >
          Create Task
        </button>
      </div> */}
      {/* </form> */}
    </div>
  )
}
export default AddTask
