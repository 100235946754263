import Avatar from "components/Avatar/Avatar"
import RoleBadge from "components/RoleBadge/RoleBadge"
import { USER_ROLES } from "utils/constants"

export interface IStaffMember {
  id:string
  avatar: string
  name: string
  email: string
  role: keyof typeof USER_ROLES
}
const ModalPreviewStaffTemplate = ({ staff }: any) => {
    return (
        <div className="modalPrev">
          <Avatar size={56} name={staff.name} url={staff.avatar} />
          <div className="details">
            <div className="name">{staff.name}</div>
            <div className="bottom">
              <RoleBadge role={staff.role} />
              <span className="mid-dot">&middot;</span>
              <span>{staff.email}</span>
            </div>
          </div>
        </div>
  
    )
  
  }

export default ModalPreviewStaffTemplate

