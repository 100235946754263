import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from 'react'
  
  import { AuthContext } from 'context/AuthContext'
  import client from 'feathers-client'
  import { SERVICES } from 'utils/constants'
  
  import Loader from 'components/Loader/Loader'
  import jobsIcon from 'assets/jobs.svg'
  import TaskTemplatesList from './TaskTemplatesList'
  import TemplatesHeader from '../TemplateHeader'
  import '../templates.sass'
  import NoData from 'components/NoData/NoData'
  
  interface IAppTemplatesProps extends HTMLAttributes<HTMLDivElement> { }
  
  export interface IAppTemplate {
    id: string
    status: string
    title: string
    description: string
  }
  
  const TaskTemplates = ({ ...props }: IAppTemplatesProps) => {
    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [appTemplates, setAppTemplates] = useState<IAppTemplate[]>([])
    const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
    const fetchAppTemplates = useCallback(async () => {
      if (auth && isSuperAdmin) {
        if (
          auth &&
          (auth?.data === undefined || auth?.data === null || auth?.data === '')
        ) {
          const appTemplates = await client.service(SERVICES.TASK_TEMPLATES).find({
            query: {
              $sort: {
                added: 1,
              },
            }
          })
          if (Array.isArray(appTemplates?.data)) {
            setAppTemplates(
              appTemplates.data.map((template: any) => ({
                id: String(template.id),
                status: String(template.status),
                title: String(template.title),
                description: String(template.description),
              }))
            )
          }
        }
        if (
          auth?.data !== undefined &&
          auth?.data !== null &&
          auth?.data !== ''
        ) {
          setTimeout(async () => {
            const appTemplates = await client
              .service(SERVICES.TASK_TEMPLATES)
              .find({
                query: {
                  $sort: {
                    added: 1,
                  },
                }
              })
            if (Array.isArray(appTemplates?.data)) {
              setAppTemplates(
                appTemplates.data.map((template: any) => ({
                  id: String(template.id),
                  status: String(template.status),
                  title: String(template.title),
                  description: String(template.description),
                }))
              )
            }
          }, 1000)
        }
      }
  
      setLoading(false)
    }, [auth])
  
    useEffect(() => {
      fetchAppTemplates()
    }, [fetchAppTemplates])
  
    const shortAppTemplate = useCallback(async (param: any) => {
      const appTemplates = await client.service(SERVICES.TASK_TEMPLATES).find({
        query: {
          // search: 'af',
          $sort: {
            type: param,
          },
        },
      })
      if (Array.isArray(appTemplates?.data)) {
        setAppTemplates(
          appTemplates.data.map((template: any) => ({
            id: String(template.id),
            status: String(template.status),
            title: String(template.title),
            description: String(template.description),
          }))
        )
      }
    }, [])
  
    return (
      <div className="templates" {...props}>
        {isSuperAdmin ?
          <><TemplatesHeader sortTemplate={shortAppTemplate} /><div className="templates-body">
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Loader />
              </div>
            ) : (
              <>
                {appTemplates.length >0 ? 
                  <TaskTemplatesList
                    templates={appTemplates}
                    refetch={fetchAppTemplates} 
                  />
                :
                  <NoData
                    icon={jobsIcon}
                    title="No task template is added yet."
                  />
                }
              </>
            )}
          </div></>
          :
          <NoData
            icon={jobsIcon}
            title="You do not have the correct permissions."
          />
        }
      </div>
  
    )
  }
  
  export default TaskTemplates;
  