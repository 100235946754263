import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import { getStaffPendingLink } from 'routes/constants'

import ActionModal from 'components/ActionModal/ActionModal'
import EmailSentModal from 'components/ActionModal/EmailSentModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import Avatar from 'components/Avatar/Avatar'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import Button from 'components/forms/Button/Button'

import closeIcon from 'assets/close.svg'
import planeIcon from 'assets/paper-plane 1.svg'
import viewIcon from 'assets/view-on.svg'
import "./pendingStaffList.sass"
// import { revokeJob } from '../utils'
import client from 'feathers-client'
import { SERVICES, URL_PATH } from 'utils/constants'
import { IStaffMember } from '../Staff'
import ModalPreviewStaffTemplate from 'components/ActionModal/components/ModalPreviewStaffTemplate'
import RoleBadge from 'components/RoleBadge/RoleBadge'
import axios, { AxiosRequestConfig } from 'axios'

interface IPendingJobItemProps {
  staff: IStaffMember,
  onClickRevokeJob?: (e: any) => void;
}

const PendingStaffListing = ({ staff, onClickRevokeJob }: IPendingJobItemProps) => {
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [revokeJobOpen, setRevokeJobOpen] = useState<boolean>(false)

  const closeEmailSent = useCallback(() => {
    setEmailSent(false)
  }, [setEmailSent])

  const closeRevokeJob = useCallback(() => {
    setRevokeJobOpen(false)
  }, [setRevokeJobOpen])

  const revokeJobProposal = useCallback((param: any) => {
    if (onClickRevokeJob) {
      onClickRevokeJob(param);
    }
    closeRevokeJob()
  }, [closeRevokeJob])

  const onClickEmailSent = useCallback(async (param: any) => {
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        "type": "invite_staff"
      }),
    };
    const template = await axios.request(configE);
    if (template?.data?.template?.data?.length > 0) {
      const tempStr = template?.data?.template?.data[0];
      let customerFirstName =  param.customerName.split(" ")?.shift();
      tempStr.body = tempStr.body.replace('[Customer Name]', (customerFirstName || param.customerName) ?? '').replace('[Link]', window.location.origin);
      const emailObj = {
        to: param.customerEmail,
        subject: tempStr.subject,
        text: tempStr.body,
      };
      await client.service(SERVICES.SENDEMAIL).create(emailObj);
    }
    setEmailSent(true);
  }, []);

  const kebabMenuItems = useMemo(
    () => [
      {
        label: 'Revoke',
        icon: closeIcon,
        disabled: false, 
        onClick: () => {
          setRevokeJobOpen(true)
        },
      },
      {
        label: 'Resend',
        icon: planeIcon,
        disabled: false, 
        onClick: () => {
          onClickEmailSent(staff);
        },
      },
      {
        label: 'View details',
        icon: viewIcon,
        disabled: false, 
        onClick: () => {
          navigate(getStaffPendingLink(staff.id))
        },
      },
    ],
    [staff, navigate]
  )

  return (
    <div className="staff-item">
      <div className="left">
        <Avatar
          size={56}
          name={staff.name}
          // url="https://cdn4.iconfinder.com/data/icons/the-weather-is-nice-today/64/weather_24-512.png"
        />
        <div className="details">
          <div className="name">{staff.name}</div>
          <div className="bottom">
            <span className='email'>{staff.email}</span>
            <span className='spacer' style={{ display:'block' ,width:'1px',background:'#B7C5CC',height: '20px'}}></span>
            {/* <RoleBadge role={staff.role} /> */}
            <div className="progress-badge" style={{background:"#00800033", color:'green', marginRight:10}}>{staff.role}</div>
            {/* {showAdded && (
              <>
                <span className="mid-dot">&middot;</span>
                <span>
                  added on {dayjs(staff.added).format('MMM DD, YYYY h:mm A')}
                </span>
              </>
            )}
            {showInvited && (
              <>
                <span className="mid-dot">&middot;</span>
                <span>
                  invited on{' '}
                  {dayjs(staff.invited).format('MMM DD, YYYY h:mm A')}
                </span>
              </>
            )} */}
          </div>
          <div className="bottom">
            <span className='date'>
              add on {dayjs(staff.added).format('MMM DD, YYYY h:mm A')}
            </span>
          </div>
          {/* <div className="info">
            Sent offff {dayjs(staff.invited).format('MMM DD, YYYY h:mm A')} to{' '}
            {staff.name}
          </div> */}
        </div>
      </div>
      <div className="right">
        <KebabMenu items={kebabMenuItems} />
      </div>
      <EmailSentModal
        isOpen={emailSent}
        handleClose={closeEmailSent}
        email={staff.email}
      />
      <ActionModal
        isOpen={revokeJobOpen}
        handleClose={closeRevokeJob}
        title="Revoke proposal"
        subtitle="Do you wish to revoke the below staff proposal?"
        actionButtons={
          <>
            <Button theme="secondary" onClick={closeRevokeJob}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '12px' }} onClick={() => revokeJobProposal(staff)}>
              Revoke
            </Button>
          </>
        }
        body={
          <ModalPreviewStaffTemplate staff={staff} />
        }
      />
    </div>
  )
}

export default PendingStaffListing
