import { HTMLAttributes } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import CreateJobForm from '../forms/CreateJobForm'
import './create-job.sass'

// export const INVITE = `Hi there, [customer_name],

// Thanks for joining the Postal Apps mission, and welcome!

// At Postal Apps, we believe in [brand principles] and providing top-of-the-line [product/service] for our customers.

// Share the love on social media with #postalapp or send [link] to a friend and you’ll get [special offer] as a token of our appreciation.

// [Sign off]`

interface ICreateJob extends HTMLAttributes<HTMLDivElement> {}
const CreateJob = ({ ...props }: ICreateJob) => {
  return (
    <div className="create-job-page full-page">
      <PageHeader heading="Create job" showBack />
      <CreateJobForm />
    </div>
  )
}

export default CreateJob
