import { Typography } from 'antd'

import '../../modal.sass'

interface ShowInfoModalProps {
  taskTitle: string
  taskDescription: string
  selectedValue: string
  selectedAssignee: any
  selectedPriority: string
  loggedTime: any
  estimatedTime: any
}
function ShowInfoModal({
  taskTitle,
  taskDescription,
  selectedValue,
  selectedAssignee,
  selectedPriority,
  loggedTime,
  estimatedTime,
}: ShowInfoModalProps) {
  return (
    <div className="main-container">
      <Typography className="heading">Show info</Typography>
      <div className="data-container">
        <Typography className="title">Task Title</Typography>
        <Typography className="value">{taskTitle}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Task Description</Typography>
        <Typography className="value" style={{ width: '80%' }}>
          {taskDescription}
        </Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Task Status</Typography>
        <Typography className="value">{selectedValue}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Task Assignee</Typography>
        <Typography className="value">{selectedAssignee}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Task Priority</Typography>
        <Typography className="value">{selectedPriority}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Estimated Time</Typography>
        <Typography className="value">{estimatedTime}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Logged Time</Typography>
        <Typography className="value">{loggedTime}</Typography>
      </div>
    </div>
  )
}

export default ShowInfoModal
