import { ChangeEvent, useRef } from 'react'

import { removeFile, uploadFiles } from 'pages/jobs/utils'

import Loader from 'components/Loader/Loader'
import ReadMore from 'components/ReadMore/ReadMore'

import AddTaskIcon from 'assets/Addtask.svg'
import { ReactComponent as CancelIcon } from 'assets/CancelIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/uploadIcon.svg'

const EditTask = ({
  loading,
  taskTitle,
  setTaskTitle,
  taskDescription,
  setTaskDescription,
  fileList,
  setFilelist,
}: any) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    uploadFiles(event, setFilelist, fileList)
  }


  
  return (
    <div className="job-box">
      <div className="show_loader">{loading && <Loader />}</div>
      <div className="job-box-title">
        <div className="job-box-title-left">
          <div className="user-pic">
            <img src={AddTaskIcon} alt="AddtaskIcon" />
          </div>
          <div className="user-infos">
            <h2>East and West Weather</h2>
            <span>Weather app</span>
          </div>
        </div>
      </div>
      <div className="special-notes">
        <h3>Special Notes</h3>
        <ReadMore>
          Weather mobile app should be designed to deliver precise and timely
          weather updates and forecasts. It should have an intuitive and
          user-friendly interface that enables, Weather mobile app should be
          designed to deliver precise and timely weather updates and forecasts.
          It should have an intuitive and user-friendly interface that
          enables,Weather mobile app should be designed to deliver precise and
          timely weather updates and forecasts.
        </ReadMore>
      </div>
      <div>
        <div>
          <label style={{ fontSize: '16px' }}>Task Title</label>
          <br />
          <input
            id="full_name-input"
            placeholder="Type task title..."
            name="name"
            value={taskTitle}
            onChange={(e: any) => setTaskTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label style={{ fontSize: '16px' }}>Description</label>
          <br />
          <textarea
            id="full_name-input"
            placeholder="Write here details about this task.."
            value={taskDescription}
            name="name"
            onChange={(e: any) => setTaskDescription(e.target.value)}
            rows={10}
            style={{
              width: '100%',
              margin: '5px 0px',
            }}
          />
        </div>
        <input
          ref={fileRef}
          style={{ display: 'none' }}
          id="file_name-input"
          type="file"
          name="file"
          onChange={handleFileChange}
          multiple
        />
        <h6>Attachments :</h6>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {fileList &&
            fileList.map((file: any) => {
              return (
                <div className="files">
                  <div>{file}</div>
                  <i>
                    {file && (
                      <CancelIcon
                        onClick={() => removeFile(file, fileList, setFilelist)}
                      />
                    )}
                  </i>
                </div>
              )
            })}
        </div>
        <div className="cta-btn upload_btn" onClick={() => fileRef.current?.click()}>
          <img src="/assets/upload_image.png" alt="" />
          <div>Choose File</div>
        </div>
      </div>
    </div>
  )
}

export default EditTask
