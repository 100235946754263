import { HTMLAttributes } from 'react'

import './label-value-table.sass'

export interface ILabelValueTableItem {
  label: string
  value: string
}

interface ILabelValueTableProps extends HTMLAttributes<HTMLDivElement> {
  items: ILabelValueTableItem[]
}

const LabelValueTable = ({ items, ...props }: ILabelValueTableProps) => {
  return (
    <div className="label-value-table" {...props}>
      {items.map((item) => (
        <div className="row half-container" key={item.label + item.value}>
          <div className="left label">{item.label}</div>
          <div className="right value">{item.value}</div>
        </div>
      ))}
    </div>
  )
}

export default LabelValueTable
