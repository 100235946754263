import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

interface JobStatusData {
  status: string;
  count: number;
}

interface RoundedChartProps {
  jobsumrytotal: number;
  jobStatusData: JobStatusData[];
}

const RoundedChart: React.FC<RoundedChartProps> = ({ jobsumrytotal, jobStatusData }) => {
  const [selectedSegment, setSelectedSegment] = useState<null | number>(null);
  const defaultStrokeWidth = 8;

  const getColorForStatus = (status: string): string => {
    if (status === 'New') {
      return '#ddd';
    } else if (status === 'In progress') {
      return '#2688fb';
    } else if (status === 'Review') {
      return '#fe8b29';
    } else if (status === 'Promoted') {
      return '#3db481';
    } else if (status === 'Finished') {
      return '#fe5e44';
    } else {
      return '#ccc'; // Default color
    }
  };

  const chartData = jobStatusData.map((item) => ({
    title: item.status,
    value: item.count,
    color: getColorForStatus(item.status),
  }));

  // Create an array of segment styles
  const segmentStyles = chartData.map((_, index) => ({
    stroke: getColorForStatus(chartData[index].title),
    strokeWidth: index === selectedSegment ? 12 : defaultStrokeWidth,
  }));

  const handleSegmentClick = (index: number) => {
    setSelectedSegment(index);
  };

  const selectedTitle = selectedSegment !== null ? chartData[selectedSegment].title : 'Total Jobs';
  const selectedPercentage = selectedSegment !== null ? chartData[selectedSegment].value : jobsumrytotal;

  return (
    <div className="rounded-chart">
      <svg width={200} height={200}>
        <g transform="">
          <PieChart
            data={chartData}
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            radius={40}
            lineWidth={20}
            totalValue={jobsumrytotal}
            segmentsStyle={(index) => segmentStyles[index]}
            onClick={(_, index) => handleSegmentClick(index)}
          />
        </g>
        <text x="50%" y="45%" className="percentage" dy=".5em" textAnchor="middle">
          {selectedPercentage}
        </text>
        <text x="50%" y="55%" dy=".5em" className="title_val" textAnchor="middle">
          {selectedTitle}
        </text>
      </svg>
    </div>
  );
};

export default RoundedChart;
