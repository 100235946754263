import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import Loader from 'components/Loader/Loader'
import jobsIcon from 'assets/jobs.svg'
import AppTemplatesList from './AppTemplatesList'
import TemplatesHeader from './TemplateHeader'
import './templates.sass'
import NoData from 'components/NoData/NoData'

interface IAppTemplatesProps extends HTMLAttributes<HTMLDivElement> { }

export interface IAppTemplate {
  id: string
  icon: string
  title: string
  description: string
}

const AppTemplates = ({ ...props }: IAppTemplatesProps) => {
  const auth = useContext(AuthContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [appTemplates, setAppTemplates] = useState<IAppTemplate[]>([])
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  const fetchAppTemplates = useCallback(async () => {
    if (auth && isSuperAdmin) {
      if (
        auth &&
        (auth?.data === undefined || auth?.data === null || auth?.data === '')
      ) {
        const appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({
          query: {
            $sort: {
              added: 1,
            },
          }
        })
        if (Array.isArray(appTemplates?.data)) {
          setAppTemplates(
            appTemplates.data.map((template: any) => ({
              id: String(template.id),
              icon: String(template.icon),
              title: String(template.type),
              description: String(template.description),
            }))
          )
        }
      }
      if (
        auth?.data !== undefined &&
        auth?.data !== null &&
        auth?.data !== ''
      ) {
        setTimeout(async () => {
          const appTemplates = await client
            .service(SERVICES.APP_TEMPLATES)
            .find({
              query: {
                $sort: {
                  added: 1,
                },
              }
            })
          if (Array.isArray(appTemplates?.data)) {
            setAppTemplates(
              appTemplates.data.map((template: any) => ({
                id: String(template.id),
                icon: String(template.icon),
                title: String(template.type),
                description: String(template.description),
              }))
            )
          }
        }, 1000)
      }
    }

    setLoading(false)
  }, [auth])

  useEffect(() => {
    fetchAppTemplates()
  }, [fetchAppTemplates])

  const shortAppTemplate = useCallback(async (param: any) => {
    const appTemplates = await client.service(SERVICES.APP_TEMPLATES).find({
      query: {
        // search: 'af',
        $sort: {
          type: param,
        },
      },
    })
    if (Array.isArray(appTemplates?.data)) {
      setAppTemplates(
        appTemplates.data.map((template: any) => ({
          id: String(template.id),
          icon: String(template.icon),
          title: String(template.type),
          description: String(template.description),
        }))
      )
    }
  }, [])

  return (
    <div className="templates" {...props}>
      {isSuperAdmin ?
        <><TemplatesHeader sortTemplate={shortAppTemplate} /><div className="templates-body">
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Loader />
            </div>
          ) : (
            <AppTemplatesList
              templates={appTemplates}
              refetch={fetchAppTemplates} />
          )}
        </div></>
        :
        <NoData
          icon={jobsIcon}
          title="You do not have the correct permissions."
        />
      }
    </div>

  )
}

export default AppTemplates
