import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES, URL_PATH } from 'utils/constants'

import { getAllTaskActivities, removeFile, uploadFiles } from 'pages/jobs/utils'

import Loader from 'components/Loader/Loader'
import ReadMore from 'components/ReadMore/ReadMore'
import TextField from 'components/forms/TextField/TextField'

import AddTaskIcon from 'assets/Addtask.svg'
import { ReactComponent as CancelIcon } from 'assets/CancelIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/uploadIcon.svg'

import ActivityDetails from './ActivityDetails'

const AddTaskActivity = ({ loading }: any) => {
  const auth = useContext(AuthContext)
  const { editId } = useParams()
  const [activityType, setActivityType] = useState<string>('add')
  const [editCommnetId, setEditCommentId] = useState<string>('')
  const [activityDescription, setActivityDescription] = useState<string>('')
  const [activityFileList, setActivityFileList] = useState([])
  const [activityList, setActivityList] = useState([])
  const [isTrue, setIsTrue] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    uploadFiles(event, setActivityFileList, activityFileList)
  }
  const handleAddActivity = async () => {
    if (activityType === 'add') {
      if (activityDescription.length && activityDescription.trim() != '') {
        if (auth && editId) {
          console.log(activityFileList);
          
          const payload = {
            taskId: editId,
            description: activityDescription,
            fileName: activityFileList,
          }
          const url = URL_PATH.CLIENT1
          const headers = {
            Authorization: `Bearer ${auth.accessToken}`,
          }
          const response = await client
            .service(SERVICES.ACTIVITY)
            .create(payload, { headers: headers })

          if (response) {
            setActivityDescription('')
            setActivityFileList([])
            taskActivityList()
            setIsTrue(true)
            toast.success('Comment added successfully', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 600,
            })
          }
        }
      } else {
        setActivityDescription('')
        toast.error('Comments are mandatory', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 600,
        })
      }
    } else {
      if (activityDescription.length && activityDescription.trim() != '') {
        if (auth && editId) {
          const payload = {
            taskId: editId,
            description: activityDescription,
            fileName: activityFileList,
          }
          const headers = {
            Authorization: `Bearer ${auth.accessToken}`,
          }
          const response = await client
            .service(SERVICES.ACTIVITY)
            .patch(editCommnetId, payload, { headers: headers })

          if (response) {
            setActivityDescription('')
            setActivityFileList([])
            setActivityType('add')
            taskActivityList()
            toast.success('Comment updated successfully', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 600,
            })
          }
        }
      } else {
        setActivityDescription('')
        toast.error('Comments are mandatory', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 600,
        })
      }
    }
  }

  const taskActivityList = useCallback(async () => {
    if (auth && editId) {
      getAllTaskActivities(editId, setActivityList)
    }
    // if (auth && editId) {
    //   const query = {
    //     taskId: editId,
    //     $sort: {
    //       createdAt: -1,
    //     },
    //   }
    //   const res = await client.service('activity').find({ query })
    //   if (Array.isArray(res.data)) {
    //     setActivityList(res.data)
    //   }
    // }
  }, [editId, auth])

  useEffect(() => {
    taskActivityList()
  }, [editId, auth])

  const handleCancelActivity = () => {
    setActivityDescription('')
    setActivityFileList([])
    setEditCommentId('')
    setActivityType('add')
  }

  return (
    <>
      <div className="job-box">
        <div className="show_loader">{loading && <Loader />}</div>
        <div className="job-box-title">
          <div className="job-box-title-left">
            <div className="user-pic">
              <img src={AddTaskIcon} alt="AddtaskIcon" />
            </div>
            <div className="user-infos">
              <h2>East and West Weather</h2>
              <span>Weather app</span>
            </div>
          </div>
        </div>
        <div className="special-notes">
          <h3>Special Notes</h3>
          <ReadMore>
            Weather mobile app should be designed to deliver precise and timely
            weather updates and forecasts. It should have an intuitive and
            user-friendly interface that enables, Weather mobile app should be
            designed to deliver precise and timely weather updates and
            forecasts. It should have an intuitive and user-friendly interface
            that enables,Weather mobile app should be designed to deliver
            precise and timely weather updates and forecasts.
          </ReadMore>
        </div>
        <div>
          <h6>Activity*</h6>
          <textarea
            id="full_name-input"
            placeholder="Add Comment Here..."
            name="name"
            value={activityDescription}
            onChange={(e) => setActivityDescription(e.target.value)}
            required
            style={{
              width: '95%',
              padding: '8px',
              margin: '5px 0px',
              border: '1px solid lightgray',
              borderRadius: '5px',
            }}
          />
          <input
            ref={fileRef}
            style={{ display: 'none' }}
            id="file_name-input"
            type="file"
            name="file"
            onChange={handleFileChange}
            multiple
          />
          <h6>Attachments :</h6>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {activityFileList &&
              activityFileList.map((file: any) => {
                return (
                  <div className="files">
                    <div>{file}</div>
                    <i>
                      {file && (
                        <CancelIcon
                          onClick={() =>
                            removeFile(
                              file,
                              activityFileList,
                              setActivityFileList
                            )
                          }
                        />
                      )}
                    </i>
                  </div>
                )
              })}
          </div>
          <div className="cta-btn" onClick={() => fileRef.current?.click()}>
            <UploadIcon />
            <div>Choose File</div>
          </div>
        </div>
        <div className="btn">
          <button className="cnl-btn" onClick={() => handleCancelActivity()}>
            Cancel
          </button>
          <button className="sub-btn" onClick={() => handleAddActivity()}>
            {activityType === 'add' ? 'Done' : 'Edit'}
          </button>
        </div>
      </div>
      {activityList.length > 0 && (
        <ActivityDetails
          setIsTrue={isTrue}
          setEditCommentId={setEditCommentId}
          setActivityType={setActivityType}
          activityList={activityList}
          setActivityList={setActivityList}
          setActivityDescription={setActivityDescription}
          setActivityFileList={setActivityFileList}
        />
      )}
    </>
  )
}

export default AddTaskActivity
