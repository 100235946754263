import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import EditStaffForm from './forms/EditStaffForm'
import StaffInviteForm from './forms/StaffInviteForm'

const EditStaffDetail = () => {
  interface Staff {
    staff: any
  }

  const { staffId } = useParams()
  const auth = useContext(AuthContext)
  const [staff, setStaff] = useState(null)

  const fetchUser = useCallback(async () => {
    if (auth && staffId) {
      try {
        const staff = await client.service(SERVICES.USERS).get(staffId)
        setStaff(staff)
      } catch (error) {
        console.error(error)
      }
    }
  }, [auth, staffId])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  return (
    <div className="add-app-template templates ">
      <PageHeader heading="Edit user" showBack />
      <div className="add-staffa-form-container">
      <EditStaffForm staff={staff} staffId={staffId} />
      </div>
    </div>
  )
}

export default EditStaffDetail
