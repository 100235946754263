import Avatar from 'components/Avatar/Avatar'
import { DEFAULT_AVATAR } from 'utils/constants'

const ModalPreviewJobTemplate = ({ job, image="" }: any) => {
  return (
    <div className="modalPrev">
      <Avatar size={56} name={job?.appIndustry || ""} url={image || DEFAULT_AVATAR} />
      <div className="details">
        <div className="JobName">{job.appIndustry}</div>
        <div className="bottom">{job.customerBusinessName}</div>
      </div>
    </div>
  )
}

export default ModalPreviewJobTemplate
