import { useState } from "react"

const ReadMore = ({ children }: any) => {
  const text = children
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  return (
    <p>
      {isReadMore ? text.slice(0, 160) : text}
      <a href="#/" onClick={toggleReadMore}>
        {isReadMore ? '...Read more' : ' Show less'}
      </a>
    </p>
  )
}
export default ReadMore