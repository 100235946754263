import { HTMLAttributes, useContext } from 'react'
import { Link } from 'react-router-dom'

// import postalAppsLogo from 'assets/Postal-apps-neutral.svg'
import postalAppsLogo from 'assets/PostalApps_made_by_black.png'
import addIcon from 'assets/add.svg'
import { LINKS } from 'routes/constants'

import TextSeparator from 'components/TextSeparator/TextSeparator'
import Button from 'components/forms/Button/Button'

import Navigation from './Navigation/Navigation'
import './sidebar.sass'
import { AuthContext } from 'context/AuthContext'
interface ISidebarProps extends HTMLAttributes<HTMLDivElement> {}

const Sidebar = ({ ...props }: ISidebarProps) => {
  const auth = useContext(AuthContext)
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  const isCustomer = auth?.user?.permissions?.includes("customer");
  const isTemp = auth?.user?.permissions?.includes("temp");
  return (
    <div className="sidebar" {...props}>                           
      <div className="top">
        <Navigation />
      </div>
      <div className="bottom-sticky">
        {/* <TextSeparator text="" color="#253858" style={{ padding: '0 16px' }} /> */}
        <div className="made-by-postal">
          {/* <span>Made by</span> */}
          {/* <img src={postalAppsLogo} alt="Postal Apps Logo" /> */}
          <span>ⓒ Postal Apps I 2024</span>
        </div>
        {/* { !(isCustomer || isTemp ) && (
        <div className="create-job">
          <Link to={LINKS.JOBS_CREATE}>
            <Button fullWidth>
              <img src={addIcon} alt="" />
              Create Job
            </Button>
          </Link>
        </div>
        )} */}
      </div>
    </div>
  )
}

export default Sidebar
