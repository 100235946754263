import { Select } from 'antd'
import {
  Priority,
  estimated_time,
  logged_time,
  taskStatus,
} from 'utils/constants'

import EducationIcon from 'assets/Education.svg'
import NameIcon from 'assets/nameicon.svg'

export interface TaskSidebarProps {
  setLoggedTime: Function
  selectedValue: string
  setSelectedValue: Function
  isAssignee: boolean
  setIsAssignee: Function
  selectedAssignee: null | string
  selectedPriority: string
  setSelectedPriority: Function
  estimatedTime: string
  setEstimatedTime: Function
  loggedTime: string
  setSelectedAssignee: Function
  isEditDisabled?: boolean
}

const TaskSidebar = ({
  setLoggedTime,
  loggedTime,
  selectedAssignee,
  setEstimatedTime,
  selectedValue,
  selectedPriority,
  setSelectedPriority,
  estimatedTime,
  setSelectedValue,
  setIsAssignee,
  setSelectedAssignee,
  isAssignee,
  isEditDisabled,
}: TaskSidebarProps) => {
  const assignee_detail = [
    {
      value: 'omja123@gmail.com',
      label: 'omja123@gmail.com',
    },
    {
      value: 'soma923@gmail.com',
      label: 'soma923@gmail.com',
    },
    {
      value: 'ojha623@gmail.com',
      label: 'ojha623@gmail.com',
    },
    {
      value: 'haji023@gmail.com ',
      label: 'haji023@gmail.com',
    },
  ]
  const filterOption = (input: any, option: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const onSearch = (value: any) => {
    // console.log('search:', value)
  }
  return (
    <div className="job-activitys">
      <h5>General details</h5>
      <hr />
      <div className="details-container">
        <h6 className="sort-titles">Task status</h6>
        <Select
          disabled={isEditDisabled}
          defaultValue={selectedValue}
          style={{ width: '100%' }}
          onChange={(value) => setSelectedValue(value)}
          options={taskStatus}
          onSearch={onSearch}
          showSearch
          value={selectedValue}
          className='custom-dropdown-main'
          popupClassName='custom-dropdown'
        
        />
      </div>
      <div className="details-container">
        <h6 className="sort-titles">Assignee</h6>
        {!selectedAssignee && isAssignee ? (
          <img
            src={EducationIcon}
            alt="Education"
            onClick={() => setIsAssignee(!isAssignee)}
          />
        ) : (
          <Select
            disabled={isEditDisabled}
            style={{ width: '100%' }}
            placeholder="Select a assignee"
            showSearch
            onSearch={onSearch}
            optionFilterProp="children"
            value={selectedAssignee}
            onChange={(value) => setSelectedAssignee(value)}
            filterOption={filterOption}
            options={assignee_detail}
            className='custom-dropdown-main'
            popupClassName='custom-dropdown'
          />
        )}
      </div>
      <div className="details-container">
        <h6 className="sort-titles">Reporter</h6>
        <div className="report">
          <img src={NameIcon} alt="name" />
          <p className="name">Albert Flores</p>
        </div>
      </div>
      <div className="details-container">
        <div className="sort-titles">Set priority</div>
        <Select
          disabled={isEditDisabled}
          defaultValue={selectedPriority}
          style={{ width: '100%' }}
          onChange={(value) => setSelectedPriority(value)}
          showSearch
          onSearch={onSearch}
          value={selectedPriority}
          className='custom-dropdown-main'
          popupClassName='custom-dropdown'
        >
          {Priority.map((val: any) => {
            return (
              <Select.Option value={val.status}>
                <span
                  style={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: val?.backgroundColor,
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '3px',
                  }}
                ></span>
                <span>{val.status}</span>
              </Select.Option>
            )
          })}
        </Select>
      </div>
      <div className="details-container">
        <div className="sort-titles">Estimated Time</div>
        <Select
          disabled={isEditDisabled}
          defaultValue={estimatedTime}
          style={{ width: '100%' }}
          onChange={(value) => setEstimatedTime(value)}
          showSearch
          onSearch={onSearch}
          options={estimated_time}
          value={estimatedTime}
          className='custom-dropdown-main'
          popupClassName='custom-dropdown'
        />
      </div>
      <div className="details-container">
        <div className="sort-titles">Logged Time</div>
        <Select
          disabled={isEditDisabled}
          defaultValue={loggedTime}
          style={{ width: '100%' }}
          showSearch
          onSearch={onSearch}
          onChange={(value) => setLoggedTime(value)}
          options={logged_time}
          value={loggedTime}
          className='custom-dropdown-main'
          popupClassName='custom-dropdown'
        />
      </div>
    </div>
  )
}

export default TaskSidebar
