import { Card } from 'antd'
import Header from '../Header';

interface UpdateTimeProps {
  appUpdates: string
  setAppUpdates: (e: any) => void
  footerJSX: any
}

const UpdateTime = ({ appUpdates, setAppUpdates, footerJSX }: UpdateTimeProps) => {
  return (
   
      <div className="main_card_popup">
        <div className="header_new">
          <Header currentStep={1} />
          <div className="card_top_heading"
            style={{
              padding: '20px 40px 0px 40px'
            }}

          >

            <h1>Update time</h1>
            <p style={{
              color: '#60747E',
              marginTop: '10px',

            }}

            >  When we build the app, would you like no updates via email or once a
              week?</p>
          </div>
        </div>

        <div className="main_section_card"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
            padding: '40px 40px',
            cursor: 'pointer',
            height: "100%"
          }}
        >
          <Card
            style={{
              width: '49%',
              border:
                appUpdates === 'none'
                  ? '1px solid #2CBBE8'
                  : '1px solid #B7C5CC',
              height: "fit-content"
            }}
            onClick={() => setAppUpdates('none')}
          >
            <div className="cardStyles">
              <div className="card-inner">
                <div>
                  {appUpdates === 'none' ? <img src="/assets/cu_check_mark.png" alt="check mark" /> : <img src="/assets/cu_check_box.png" alt="check box" />}
                </div>
                <div>
                  <div className="steps-heading">No Update</div>
                  <div> I do not want any updates about the app and want it delivered on
                    agreed time.</div>
                </div>
              </div>
              <div>
                <img src='/assets/ban.png' alt='ban' />
              </div>
            </div>
          </Card>
          <Card
            style={{
              width: '49%',
              border:
                appUpdates === 'weekly'
                  ? '1px solid #2CBBE8'
                  : '1px solid #B7C5CC',
              height: "fit-content"
            }}
            onClick={() => setAppUpdates('weekly')}
          >
            <div className="cardStyles">
              <div className="card-inner">
                <div>
                  {appUpdates === 'weekly' ? <img src="/assets/cu_check_mark.png" alt="check mark" /> : <img src="/assets/cu_check_box.png" alt="check box" />}
                </div>
                <div>
                  <div className="steps-heading">Once a week</div>
                  <div>I would like to have weekly update about the app before it is
                    finalized</div>
                </div>
              </div>
              <div>
                <img src="/assets/refresh.png" alt='refresh' />
              </div>
            </div>
          </Card>
        </div>
        
        <div className="footer_new">
            {footerJSX}
        </div>

      </div>


  )
}

export default UpdateTime
