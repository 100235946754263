import { useEffect, useState } from 'react'

import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Card } from 'antd'

import Avatar from 'components/Avatar/Avatar'

import CustomerPortalSteps from './CustomerPortalSteps'
import SubmitSuccess from './CustomerPortalSteps/SubmitSuccess'
import GetStartedModal from './GetStartedModal'
import './customer.sass'
import axios, {AxiosRequestConfig} from 'axios'
import { DEFAULT_AVATAR, URL_PATH } from 'utils/constants'
import Loader from 'components/Loader/Loader'
import axiosInstance from 'utils/axios'
import { useParams } from 'react-router-dom'
import protonLogo from 'assets/Proton-accent.svg'

import Stepper from './Stepper'

const appInfo = [
  {
    appTitle: 'Weather app',
    appDescrition:
      'Our weather app provides accurate, up-to-date forecasts for your location. Stay informed of current conditions and plan accordingly.',
  },
  {
    appTitle: 'Finance app',
    appDescrition:
      'Our Finance app is an advanced tool designed for precision and ease. Access real-time financial insights tailored to your preferences. Stay informed, make strategic decisions with our user-friendly application, navigating the complex world of finance effortlessly. Elevate your financial journey with our meticulously crafted Finance app.',
  },
  {
    appTitle: 'Education app',
    appDescrition:
      'Our Education app is meticulously crafted to provide tailored-learning experiences. Access accurate, up-to-date educational content personalized to your needs. Stay informed, make informed decisions with our user-friendly application, enhancing your academic journey effortlessly.',
  },
  {
    appTitle: 'Health app',
    appDescrition:
      'Our Health app is a comprehensive solution designed for precision and wellness. Access personalized health insights and stay informed about your well-being. Make informed decisions with our user-friendly application, effortlessly managing your health journey. Elevate your wellness with our meticulously crafted Health app.',
  },
  {
    appTitle: 'Crypto app',
    appDescrition:
      'Our Crypto app is crafted for precision, delivering real-time insights tailored to your preferences. Stay informed about the latest crypto conditions and make informed decisions with our user-friendly application. Navigate the dynamic world of cryptocurrency effortlessly, enhancing your financial strategy with our meticulously designed Crypto app.',
  },
  {
    appTitle: 'Restaurant app',
    appDescrition:
      'Our Restaurant app is a culinary companion crafted for delightful dining experiences. Access a diverse array of restaurant options tailored to your preferences. Stay informed, make dining plans effortlessly with our user-friendly application. Elevate your gastronomic adventures with our meticulously designed Restaurant app.',
  },
  {
    appTitle: 'Entertainment app',
    appDescrition:
      'Our Entertainment app is meticulously crafted to provide tailored experiences. Designed for precision, it delivers current and engaging content personalized to your preferences. Stay immersed in real-time entertainment and make informed choices with our user-friendly application. Elevate your leisure moments effortlessly with our advanced Entertainment app.',
  },
]

interface appProps {
  appTitle: string
  appDescrition: string
}

const SelectApp = ({
  setShowStepper,
  showStepper,
  setCurrentStep,
  currentStep,
  jobData
}: any) => {

  const [loading, setLoading] = useState(true);
  const [appTemplates, setApptemplates] = useState([])
  const [openGetStartedModal, setOpenGetStartedModal] = useState(false)
  const [selectedApp, setSelectedApp] = useState(0)
  const [appIndustry, setAppIndustry] = useState('')
  const [appImage, setAppImage] = useState('')
  const [appDescription, setAppDescription] = useState('')
  const [deposit, setDeposit] = useState(0)
  const [finalPayment, setFinalPayment] = useState(0)
  const [monthlySubscription, setMonthlySubscription] = useState(0)
  const [yearlyDiscount, setYearlyDiscount] = useState(0)
  const [customApp, setCustomApp] = useState(false);


  const { id } = useParams();

  useEffect(()=>{
    const fetchAppTemplates = setTimeout(async()=>{
      try{
        let templates = await axios.get(`${URL_PATH.CLIENT1}GetAppTemplates`)
        setLoading(false)
        if(templates.data.status === 200){
          // console.log("sadsad >>>", templates.data.template.data)
          setApptemplates(templates.data.template.data)
          let templatesArray = templates.data.template.data;
          if(templatesArray.length > 0){
            if(!!jobData?.customApp){
              // checking if user have already selected custom app
              setCustomApp(true);
              setAppImage(jobData?.appImage || "")
              setAppIndustry(jobData?.appIndustry || "")
              setAppDescription(jobData?.appDescription || "")
              setDeposit(jobData?.deposit || 0);
              setFinalPayment(jobData?.finalPayment || 0)
              setMonthlySubscription(jobData?.monthlySubscription || 0)
              setYearlyDiscount(jobData?.yearlyDiscount || 0)

            }else{
              // checking if user have selected the default apps
              templatesArray.map((temp:any, index:number)=>{
                if(temp.type === jobData.appIndustry && temp.description === jobData.appDescription){
                  setSelectedApp(index)
                }else{
                  console.log("else")
                }  
              })
            }
          }
        }
      }
      catch(err){
        setLoading(false);
        console.log("ERROR >>>>", err)
      }
    },200)

    return () => clearTimeout(fetchAppTemplates);
  },[])

  

  useEffect(() => {
    if(appTemplates.length > 0){
      handleSelectApptype(0, appTemplates[0])
    }
  }, [appTemplates])

 

  if(loading){
    return <div style={{display:'flex', width: "100%", alignItems:"center", justifyContent:"center",height:"100%"}}><Loader /></div>
  }

  const submitJobStepsHandler = async (payload:any) =>{
   
    return new Promise(async(resolve, reject)=>{
      try{
        const config: AxiosRequestConfig = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}/customerJobUpdate/${id}`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: payload,
        };
    
        const dataUser = await axios.request(config);
        resolve(dataUser);
      }catch(err){
        console.log(err)
        reject(err)
      }
    })
  }

  const handleSelectApptype = (index: any, app:any) => {
    console.log(app)
    setSelectedApp(index)
    setAppIndustry(app.type)
    setAppDescription(app.description)
    setAppImage(app.icon)
    setDeposit(app.deposit)
    setFinalPayment(app.finalPayment)
    setMonthlySubscription(app.monthlySubscription)
    setYearlyDiscount(app.yearlyDiscount)

    // submitJobStepsHandler({
    //   step: "first",
    //   appIndustry: app.type,
    //   appImage: app.icon,
    //   appDescription: app.description,
    //   deposit: app.deposit,
    //   finalPayment: app.finalPayment,
    //   monthlySubscription: app.monthlySubscription,
    //   yearlyDiscount: app.yearlyDiscount,
    //   customApp:false
    // })

    setCustomApp(false);
  }
  return (
    <>
      {showStepper ? (
        <>
          {currentStep === 4 ? (
            <SubmitSuccess />
          ) : (
            <CustomerPortalSteps
              jobData={jobData}
              submitJobStepsHandler={submitJobStepsHandler}
              setShowStepper={setShowStepper}
              appIndustry={appIndustry}
              appDescription={appDescription}
              appImage={appImage}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              paymentsInfo={
               { deposit, finalPayment,monthlySubscription}
              }
            />
          )}
        </>
      ) : (
       
           <div className="main_card_popup">
              <div className="header_new">
                <div className="logo" style={{ display: 'flex',  padding: '20px 40px 0px 40px'}}>
                      <img src={`/assets/Proton-Original_black.png`} alt="Proton Logo" width={`111px`} height={`39px`} style={{ objectFit:'contain'}} / >
                </div>
                <div className="card_top_heading"
                  style={{
                      padding: '20px 40px 0px 40px'
                  }}
                  
                  >
                    
                <h1>Select app type</h1>
                <p style={{
                  color:'#60747E',
                  marginTop:'10px',

                }}
                
                >Biusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </div>
              <div className="main_section_card"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 8,
                    margin: '40px 40px',
                    cursor: 'pointer',
                    height: "100%",
                    overflow: "auto",
                    alignContent: "flex-start"
                  }}
              >
                {appTemplates.length > 0 ? appTemplates.map((app:any, index) => (
                  <AppTemplateItem 
                    key={index}
                    app={app}
                    onClick={()=> handleSelectApptype(index, app)}
                    selectedApp={selectedApp === index && !customApp }
                  />
                ))
                :
                  <div>There is no Templates</div>
                }
                {customApp && 
                  <Card 
                    style={{
                      width: '49%',
                      border:
                      customApp
                          ? '1px solid #2CBBE8'
                          : '1px solid transparent',
                    }}
                  >                           
                    <div className="cardStyles">
                      <div>
                          <Avatar
                            url={appImage || DEFAULT_AVATAR}
                            size={50}
                          />
                      </div>
                      <div>
                        <div className="steps-heading">{appIndustry}</div>
                        <div>{appDescription}</div>
                      </div>
                    </div>
                  </Card>
                }
              <Card
              style={{ width: '49%', height: "fit-content", minHeight: 118, border: "1px solid #B7C5CC"  }}
              onClick={() => setOpenGetStartedModal(!openGetStartedModal)}
            >
                  <div className="cardStyles">
                    <div className="card-inner">
                      <div>
                        {/* <Avatar
                        url={appImage || DEFAULT_AVATAR}
                        size={50}
                      /> */}
                        <img src="/assets/plus_customer.png" alt="plus_customer" />
                      </div>
                      <div>
                        <div className="steps-heading">Custom app</div>
                        <div>Add custom app details</div>
                      </div>
                    </div>
                  </div>
              </Card>
              </div>
              <div className="footer_new">
               
                  <Button
                    type="primary"
                    className='primary button new'
                    style={{
                      display: 'flex',
                      marginLeft: "auto",
                      backgroundColor: '#2CBBE8',
                      padding: "12px 24px 12px 24px"
                    }}
                    onClick={() => {
                      // submitJobStepsHandler({
                      //   step: "first",
                      //   appIndustry,
                      //   appImage,
                      //   appDescription
                      // }).then((result:any)=>{
                        setShowStepper(!showStepper)
                      //   console.log("result >>>>", result)
                      // }).catch(()=>{
                      //   // submitJobStepsHandler(!showStepper)
                      // })
                    }}
                  >
                    Get Started
                  </Button>


        
              </div>
            </div>
       
      )}
      {openGetStartedModal && (
        <GetStartedModal
          submitHandler={(values:any)=>{
            setAppImage("")
            submitJobStepsHandler({
              step: "first",
              customApp: true,
              appIndustry: values.title,
              deposit:deposit,
              finalPayment:finalPayment,
              monthlySubscription,
              yearlyDiscount,
              appImage: "",
              appDescription: values.description
            }).then((result)=>{
              console.log(result)
              setOpenGetStartedModal(false)
            }).catch((err)=>{
              setOpenGetStartedModal(false)
            })
            setCustomApp(true);
          }}
          setAppDescription={setAppDescription}
          setAppIndustry={setAppIndustry}
          openGetStartedModal={openGetStartedModal}
          setOpenGetStartedModal={setOpenGetStartedModal}
          appIndustry={appIndustry}
          appDescription={appDescription}
        />
      )}
    </>
  )
}

const AppTemplateItem = ({selectedApp, app, onClick}:any) =>{
  const [image, setImage] = useState(DEFAULT_AVATAR)
  useEffect(()=>{
    const fetchTemplateImage = setTimeout(async()=>{
      if(app.icon){
        const response:any = await axiosInstance.get(`getSignedFileUrl/?fileName=${app.icon}`)
        if(response?.status){
          // console.log(response.data.url);
          setImage(response.data.url)
        }
      }
    },200)

    return ()=> clearTimeout(fetchTemplateImage)
  },[])
  return (
    <Card 
      style={{
        width: '49%',
        border:
          selectedApp
            ? '1px solid #2CBBE8'
            : '1px solid #B7C5CC',
        height: "fit-content"        
       }}

      onClick={onClick}
    >
      <div className="cardStyles">
        <div className="card-inner">
        <div>
          {selectedApp ?  <img src="/assets/cu_check_mark.png" alt="check mark" /> : <img src="/assets/cu_check_box.png" alt="check box" />}
        </div>
        <div>
          <div className="steps-heading">{app.type}</div>
          <div>{app.description}</div>
          </div>
        </div>
        <div className='app-logo'>
        <img src={image} alt="cloud-showers" />
        </div>
      </div>
    </Card>
    
  )
}

export default SelectApp
