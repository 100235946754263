import { CSSProperties, InputHTMLAttributes, useMemo, useRef, useImperativeHandle, forwardRef } from 'react'

import { TGenericObject } from 'types/base'

import 'components/forms/input.sass'

interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  errors?: TGenericObject
  capText?: any
}
 interface ChildMethods {
  focusInput: () => void;
  // Define additional methods if needed
}

const TextField = forwardRef<ChildMethods, any>(({
  id,
  label,
  required,
  height,
  errors,
  capText=false,
  ...props
}: ITextFieldProps, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    // Define other methods
  }));

  const getError = useMemo(() => {
    return props?.name ? errors?.[props?.name] : ''
  }, [errors, props.name])
  const inputStyle: CSSProperties = {
    height: height ? `${height}px` : undefined,
  }
  return (
    <div className={`textfield input-field ${getError ? "error" : ""}`}>
      <div className="header">
        <label htmlFor={id}>
          {label}
          {required && <span className="required-indicator">*</span>}
        </label>
      </div>
      <div className={`input-group-container ${capText ?  "input-cap-space" : ""}`}>
        {capText && <span>{capText}</span>}
        <input id={id} style={inputStyle} {...props} className="input" ref={inputRef} />
      </div>
      <div className="error">{getError}</div>
    </div>
  )
})

export default TextField
