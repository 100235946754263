import { createContext } from 'react'

import { TGenericObject } from 'types/base'
import { IUserData } from 'types/data'

interface IAuthContext {
  accessToken: string
  authentication: TGenericObject
  user: IUserData
  data: string
}

export const AuthContext = createContext<IAuthContext | null>(null)

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'added': {
      return action.auth
    }
    case 'changed': {
      return action.auth
    }
    case 'search': {
      return {
        ...state,
        data: action.data,
      }
    }
    case 'deleted': {
      return null
    }
    default: {
      return state;
    }
  }
}
