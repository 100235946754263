import './subscription-preview.sass'

interface SubscriptionPreview {
    subscription?: any
}

const checkIcon = <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0ZM16.9101 7.5H15.3543C15.3013 7.5 15.2504 7.52107 15.2129 7.55857L9.69612 13.0746L7.17958 10.5582C7.14207 10.5207 7.0912 10.4996 7.03816 10.4996H5.4827C5.42967 10.4996 5.37881 10.5207 5.34131 10.5582C5.26319 10.6363 5.26316 10.7629 5.34125 10.841C5.42917 10.929 5.5171 11.0169 5.60502 11.1049C5.62887 11.1288 5.65325 11.1532 5.67813 11.1781L5.83305 11.3334C6.15375 11.655 6.53643 12.0404 6.92601 12.4333L7.21862 12.7286C8.12764 13.6461 8.99919 14.5287 9.13331 14.6643L9.1505 14.6816C9.4434 14.9745 9.91827 14.9745 10.2112 14.6816L17.0516 7.84142C17.0891 7.80392 17.1101 7.75304 17.1101 7.7C17.1101 7.58954 17.0206 7.5 16.9101 7.5Z" fill="#00B8D9"/>
</svg>


const crossIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757"/>
</svg>




const SubscriptionPreview = ({ subscription }: SubscriptionPreview) => {

    return (
        <div className="subscription-preview">
            <div className="subscription-preview-title ">Subscription Package Preview</div>
            <div className="subscription-preview-content">
                <div className='subscription-point'>
                    <p className='title'>Subscription package title</p>
                    <p className='title-value'>{subscription.packageTitle}</p>
                </div>
                <div className='subscription-point'>
                    <p className='title'>Subscription (per month)</p>
                    <p className='title-value'>${subscription.monthlySubscription}</p>
                </div>
                <div className='subscription-point'>
                    <p className='title'>Yearly discount</p>
                    {subscription.yearlyDiscountType === "FIXED" ? 
                        <p className='title-value'>${subscription.yearlyDiscount}</p>  
                        : 
                        <p className='title-value'>{subscription.yearlyDiscount}%</p>  
                    }
                </div>
                <div className='subscription-point'>
                    <p className='title'>Features</p>
                    <ul className='subs-features-list'>
                        {subscription.features.length > 0 && subscription.features.filter((feature:any) => !!feature.value.trim()).map((feature:any, index:number)=>(
                            <li className='subs-features-item' key={index}>
                                <span className='subs-features-icon'>
                                    {feature.checked ? checkIcon : crossIcon}
                                </span>
                                <span className='subs-features-text'>{feature.value}</span>
                                
                            </li>
                        ))}

                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPreview
