import { HTMLAttributes, useMemo } from 'react'

import Button from 'components/forms/Button/Button'

import dropdownIcon from 'assets/chevron-down.svg'

import './state-badge.sass'

export enum StateBadgeType {
  Promoted = 'Promoted',
  New = 'New',
  Review = 'Review',
  InProgress = 'In progress',
  Finished = 'Finished',
}

const badgeColors = {
  [StateBadgeType.New]: {
    foreground: '#344563',
    background: '#EBECF0',
  },
  [StateBadgeType.Finished]: {
    background: '#FFEBE6',
    foreground: '#BF2600',
  },
  [StateBadgeType.InProgress]: {
    foreground: '#0747A6',
    background: '#DEEBFF',
  },
  [StateBadgeType.Promoted]: {
    foreground: '#006644',
    background: '#E3FCEF',
  },
  [StateBadgeType.Review]: {
    foreground: '#FF8B00',
    background: '#FFFAE6',
  },
}

interface IStateBadgeProps extends HTMLAttributes<HTMLButtonElement> {
  type: StateBadgeType
  isDropdown?: boolean
}

const StateBadge = ({
  type,
  isDropdown = false,
  ...props
}: IStateBadgeProps) => {
  return (
    <Button
      {...props}
      style={{
        backgroundColor: badgeColors[type]?.background,
        color: badgeColors[type]?.foreground,
        ...props.style,
      }}
      className="state-badge"
    >
      {type}
      {isDropdown && <img src={dropdownIcon} alt="" />}
    </Button>
  )
}

export default StateBadge
