import axios from 'axios'
import { URL_PATH } from './constants'

const axiosInstance = axios.create({
  baseURL: URL_PATH.CLIENT1,
})

axiosInstance.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem('feathers-jwt')

    if (token) {
      req.headers.Authorization = `Bearer ${token}`
    }
    return req
  },
  (e) => {
    return Promise.reject(e)
  }
)
export default axiosInstance
