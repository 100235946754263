import { HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'

import { LINKS } from 'routes/constants'
import { useMountEffect } from 'utils/hooks'

import './templates.sass'

interface ITemplatesProps extends HTMLAttributes<HTMLDivElement> {}
const Templates = ({ ...props }: ITemplatesProps) => {
  const navigate = useNavigate()
  useMountEffect(() => {
    navigate(LINKS.TEMPLATES_EMAIL)
  })
  return null
}

export default Templates
