import React, { useState, useEffect } from 'react'; // Import React
import { Link } from 'react-router-dom';
import axiosInstance from 'utils/axios'
import attcheDemo from 'assets/attach-demo.jpg'
import './activity.sass';
import PDFIcon from 'assets/pdf-file.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/download_file.svg'

type ResendActivityProps = {
  data: any;
  allActivity: any
};

const RenderImage = (props: any) => {
  const { file } = props
  const [data, setData] = useState()

  const getUrlFileURL = async (str: string) => {
    try {
      const response = await axiosInstance.get(`getSignedFileUrl/?fileName=${str}`)
      return response.data.url
    } catch (error) {
      console.error('getSignedFileUrl error:', error)
      return ''
    }
  }

  //use effect to fetch on mount
  useEffect(() => {
    getUrlFileURL(file).then((val) => {
      setData(val)
    })
  }, [])

  if (data === undefined) return <img src={attcheDemo} alt="Option 1" />

  return (
    <>
      <div className="file-img">
        {['png', 'jpeg', 'jpg'].indexOf(file?.split('.').pop()) > -1 ? (
          <img src={data} title={file} alt="" />
        ) : (
          <img src={PDFIcon} alt="Option 1" />
        )}
        <button
          type="button"
          onClick={() => {
            window.open(data, '_blank', 'noopener,noreferrer')
          }}
        >
          <i>
            <DownloadFileIcon />
          </i>
        </button>
      </div>
    </>
  )
}


const ResendActivity: React.FC<ResendActivityProps> = (props) => {

  const formatDateToCustomFormat = (timestamp: number): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(timestamp);

    return formattedDate;
  };


  return (
    <div className='job_box_inner'>
      <h4 className='head_bg_darkblue'>
        Recent Activity
      </h4>
      {props.allActivity.data && props.allActivity.data.map((item: any) => (
        <div key={item.createdAt} className='box_li mt10'>
          <div className='flex_wrap'>
            <strong className='user_div2'>{item.customerName && item.customerName.split(" ").map((word: any) => word.charAt(0)).join("")}</strong>
            <div>
              <h3 className='big_text'>{item.customerName}</h3>
              <p>{formatDateToCustomFormat(item.createdAt)}</p>
              <p className='mt0'>{item.description}</p>
              {item.fileName && item.fileName.map((imageData: any) => (
                <div key={imageData} className='box_li pt0'>
                  <div className='flex_wrap'>
                    <div className='upload_img'>
                      <div className='box_img'>
                        <RenderImage file={imageData} />
                      </div>
                      <p className='img_text'>My weather <br></br> app skp</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='dots_icon'>
            <Link to="/" hrefLang="/">
              <img src="/assets/menu.png" alt="menu" />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ResendActivity;