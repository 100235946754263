import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import Loader from 'components/Loader/Loader'
import jobsIcon from 'assets/jobs.svg'
import ListSubscriptions from './ListSubscriptions'
import SubscriptionHeader from './SubscriptionHeader'
import './subscriptionStyle.sass'
import NoData from 'components/NoData/NoData'
import { LINKS } from 'routes/constants'
import {
    SubscriptionFetchingSort
} from './utils'

interface IAppTemplatesProps extends HTMLAttributes<HTMLDivElement> { }

export interface SubscriptionItem {
    _id: string;
    packageTitle: string;
    monthlySubscription: number;
    yearlyDiscount: number;
    features?: string[];
}


interface ISubscriptionProps extends HTMLAttributes<HTMLDivElement> { }

const Subscriptions = ({ ...props }: ISubscriptionProps) => {
    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [subscriptions, setSubscriptions] = useState<any[]>([])
    const location = useLocation()
    const fetchSubscriptions = (async () => {
        if (
            true
        ) {
            const subscriptions = await SubscriptionFetchingSort()
            if (Array.isArray(subscriptions?.data)) {
                setSubscriptions(subscriptions.data)
            }
        }
        setLoading(false)
    })

    useEffect(() => {
        fetchSubscriptions()
    }, [])

    if (loading) {
        return null
    }

    return (
        <div className="jobs flat" {...props}>
            <SubscriptionHeader />
            {subscriptions.length ? (
                <ListSubscriptions
                    subscriptions={subscriptions}
                    fetchSubscriptions_fn={fetchSubscriptions}
                // handleDeleteJob={handleDeleteJob}
                />
            ) : (
                <NoData
                    icon={jobsIcon}
                    title="No Subscriptions added yet"
                // cta={
                //   <Link to={LINKS.JOBS_CREATE}>
                //     <Button>Create Job</Button>
                //   </Link>
                // }
                />
            )}
        </div>
    )
}

export default Subscriptions
