import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from 'react'
  import { Link, useLocation } from 'react-router-dom'
  
  import { AuthContext } from 'context/AuthContext'
  import { LINKS } from 'routes/constants'
  
  import NoData from 'components/NoData/NoData'
  import Button from 'components/forms/Button/Button'
  
  import jobsIcon from 'assets/jobs.svg'
  
  import JobsListing from './JobsListing'
  import './jobs.sass'
  import {
    deleteJob,
    jobFetchingSearch,
    jobFetchingSort,
    jobProposalRevoke,
    jobProposalSorting,
    pinJobs,
    unpinJobs,
  } from './utils'
  
  export interface IJobItem {
    id: string
    name: string
    customerName: string
    customerEmail: string
    customerBusinessName: string
    invited: number
    invitedEmail: string
    pinned?: boolean
    appIndustry?: string
    appDescription?: string
    suggestedAppTitle?: string
    requestedAppName?: boolean
    developedFor?: 'ios' | 'android' | 'ios+android'
    appTheme?: string
    appPrice?: 'free' | 'paid'
    appUpdates?: 'none' | 'weekly'
    appRequirements?: string
    attachments?: any[]
    submitted?: number // timestamp customer submitted the request
    isSubmitted: boolean
    revoked?: number // timestamp job revoked
    revokedBy?: string // id of user who revoked proposal
    appImage?:string
    activity?: Array<{
      added: number
      addedBy: string
      comment: string
      attachments: any[]
    }>
    jobStatus: string | undefined,
    isJobActive: boolean
  }
  
  interface IJobsProps extends HTMLAttributes<HTMLDivElement> {}
  const ActiveJobs = ({ ...props }: IJobsProps) => {
    const location = useLocation();
    const auth = useContext(AuthContext)
    const [loading, setLoading] = useState<boolean>(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [jobs, setJobs] = useState<any[]>([])
    const fetchJobs = useCallback(async () => {
      if (
        auth &&
        (auth?.data === undefined || auth?.data === null || auth?.data === '')
      ) {
        const jobs = await jobFetchingSort()
        if (Array.isArray(jobs?.data)) {
          setJobs(jobs.data)
        }
      }
      if (auth?.data !== undefined && auth?.data !== null && auth?.data !== '') {
        setTimeout(async () => {
          const jobs = await jobFetchingSearch(auth)
          if (Array.isArray(jobs?.data)) {
            setJobs(jobs.data)
          }
        }, 1000)
      }
      setLoading(false)
    }, [auth])
  
    useEffect(() => {
      fetchJobs()
    }, [fetchJobs])
  
    const revokeJobProposal = useCallback(async (param: any) => {
      console.log("param.id",param.id);
      const jobs = await jobProposalRevoke(param)
      if (Array.isArray(jobs?.data)) {
        setJobs(jobs.data)
      }
    }, [])
  
    const handlePinJob = useCallback(async (param: any) => {
      const jobs = await pinJobs(param)
      if (Array.isArray(jobs?.data)) {
        setJobs(jobs.data)
      }
    }, [])
  
    const handleUnpinJob = useCallback(async (param: any) => {
      const jobs = await unpinJobs(param)
      if (Array.isArray(jobs?.data)) {
        setJobs(jobs.data)
      }
    }, [])
  
    const sortJobProposal = useCallback(async (param: any, option: any) => {
      const jobs = await jobProposalSorting(param, option)
      if (Array.isArray(jobs?.data)) {
        setJobs(jobs.data)
      }
    }, [])
  
    const handleDeleteJob = useCallback(async (param: any) => {
      if (auth) {
        const headers = {
          Authorization: `Bearer ${auth.accessToken}`,
        }
        await deleteJob(param, headers)
        fetchJobs()
      }
    }, [])
  
    if (loading) {
      return null
    }

    const jobsFIlterBarJSX = (
      <div className="jobs-tabs-bar" style={{marginBottom:20}}>
         {jobs.filter((job) => job.isSubmitted && (!!job?.jobStatus && job?.jobStatus !== "new" )).length > 0 && 
        <Link to={LINKS.JOBS}>
          <button className="primary button" style={{marginRight:10, background:location.pathname === LINKS.JOBS  ? "#00B8D9" :"transparent", border: "1px solid #00B8D9"}}>
            {jobs.filter((job) => job.isSubmitted && (!!job?.jobStatus && job?.jobStatus !== "new" )).length} Building Jobs
          </button>
        </Link>
      }
      {jobs.filter((job) => job.isSubmitted && job?.isJobActive !== false && (job?.jobStatus === undefined || job?.jobStatus === "new")).length > 0 && 
        <Link to={LINKS.ACTIVEJOBS}>
          <button className="primary button" style={{marginRight:10, border: "1px solid #00B8D9"}}>
            {jobs.filter((job) => job.isSubmitted && job?.isJobActive !== false &&  (job?.jobStatus === undefined || job?.jobStatus === "new")).length} Active Jobs
          </button>
        </Link>
      }
      {jobs.filter((job) => job.isSubmitted && (job?.jobStatus === undefined || job?.jobStatus === "new") && !(job?.isJobActive !== false)).length > 0 && 
        <Link to={LINKS.NEWJOBS}>
          <button className="primary button" style={{marginRight:10, background:"transparent", border: "1px solid #00B8D9"}}>
            {jobs.filter((job) => job.isSubmitted && (job?.jobStatus === undefined || job?.jobStatus === "new") && !(job?.isJobActive !== false)).length} New Jobs
          </button>
        </Link>
      }
      {jobs.filter((job) => job.invited && !job.revoked && !job.isSubmitted).length > 0 && <Link to={LINKS.JOBS_PENDING}>
        <button className="primary button" style={{marginRight:10, background:location.pathname === LINKS.JOBS_PENDING  ? "#00B8D9" :"transparent", border: "1px solid #00B8D9"}}>
          {jobs.filter((job) => job.invited && !job.revoked && !job.isSubmitted).length} Pending jobs
        </button>
      </Link>}
      </div>
    )

    return (
      <div className="jobs flat" {...props}>
        {jobs.length ? (
          <JobsListing
            jobs={jobs.filter((job:any) => job.isSubmitted &&  job.depositReceived && job.finalPaymentReceived && job.buildingFinished && job.isJobActive)}
            onClickRevokeJob={(e) => revokeJobProposal(e)}
            onClickSortJob={sortJobProposal}
            handlePinJob={handlePinJob}
            handleUnpinJob={handleUnpinJob}
            fetchjobs_fn={fetchJobs}
            handleDeleteJob={handleDeleteJob}
            unFilteredJobs={jobs}
          />
        ) : (
          <NoData
            icon={jobsIcon}
            title="No job is added yet"
            // cta={
            //   <Link to={LINKS.JOBS_CREATE}>
            //     <Button>Create Job</Button>
            //   </Link>
            // }
          />
        )}
      </div>
    )
  }
  export default ActiveJobs;
  