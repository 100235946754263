import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LINKS } from "routes/constants";

const JobCategoryBar = ({jobs}:any) => {
    const location = useLocation();
    const buildingJobsCount = jobs.filter((job:any) => job.isSubmitted && job.depositReceived === true && job.isJobActive &&( !job.finalPaymentReceived || !job.buildingFinished)).length;
    const activeJobsCount = jobs.filter((job:any) => job.isSubmitted &&  job.depositReceived && job.finalPaymentReceived && job.buildingFinished && job.isJobActive).length;
    const inactiveJobsCount = jobs.filter((job:any) => job.isSubmitted && (!job.depositReceived || !job.isJobActive)).length;
    const newJobsCount = jobs.filter((job:any) => job.isSubmitted && !job?.depositReceived ).length;
    const pendingJobsCount =  jobs.filter((job:any) => job.invited && !job.revoked && !job.isSubmitted).length;

    return(
        <div className="jobs-tabs-bar" style={{marginBottom:20}}>
        {/* {buildingJobsCount > 0 &&  */}
          <Link to={LINKS.JOBS}>
            <button className={`primary button pri_btn ${location.pathname === LINKS.JOBS  ? "primary_active" :""}`}>
              {buildingJobsCount} Building Jobs
            </button>
          </Link>
        {/* } */}
        {/* {activeJobsCount > 0 &&  */}
          <Link to={LINKS.ACTIVEJOBS}>
            <button className={`primary button pri_btn ${location.pathname === LINKS.ACTIVEJOBS  ? "primary_active" :""}`} >
              {activeJobsCount} Active Jobs
            </button>
          </Link>
        {/* } */}
        {/* {inactiveJobsCount > 0 &&  */}
          <Link to={LINKS.INACTIVEJOBS}>
            <button className={`primary button pri_btn ${location.pathname === LINKS.INACTIVEJOBS  ? "primary_active" :""}`} >
              {inactiveJobsCount} Inactive Jobs
            </button>
          </Link>
        {/* } */}
  
        {/* {newJobsCount > 0 &&  */}
          <Link to={LINKS.NEWJOBS}>
            <button className={`primary button pri_btn ${location.pathname === LINKS.NEWJOBS  ? "primary_active" :""}`} >
              {newJobsCount} New Jobs
            </button>
          </Link>
        {/* } */}
        {pendingJobsCount > 0 && <Link to={LINKS.JOBS_PENDING}>
          <button className={`primary button pri_btn ${location.pathname === LINKS.JOBS_PENDING  ? "primary_active" :""}`}>
            {pendingJobsCount} Pending jobs
          </button>
        </Link>}
      </div>
    )
}

export default JobCategoryBar;