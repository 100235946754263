import { HTMLAttributes, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import './progress-bar.sass'
import { LINKS, getJobDetailsLink, getJobDetailsUrl } from 'routes/constants'

interface IProgressBar extends HTMLAttributes<HTMLDivElement> {
  progress: number // Range 1-100
}

const ProgressBar = ({ progress, ...props }: IProgressBar) => {
  const progressPercent = useMemo(() => {
    let _progress = 0
    if (100 >= Number(progress) && Number(progress) > 0) {
      _progress = Number(progress)
    }
    return _progress
  }, [progress])
  const location = useLocation()
  const isPendingTask = location.pathname === LINKS.PendingTask;
  return (
    <div className="progress-bar" {...props} style={isPendingTask ? {width: "100%" , alignItems: "center", ...props?.style} : {...props?.style}}>
      <div className="progress-graph" style={isPendingTask ? {width: "100%"} : {}} >
        <div
          className="fill"
          style={{
            width: `${progressPercent}%`,
            transition:"all ease-in-out 0.5s"
          }}
        ></div>
      </div>
      <div className="percent">{progressPercent}%</div>
      {isPendingTask ? <p style={{marginLeft: "10px" , marginBottom: "0px" , marginTop: "0px"}}>Done</p> : null}
    </div>
  )
}

export default ProgressBar
