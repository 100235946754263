// import type { StepsProps } from 'antd';
// import { Popover, Steps } from 'antd';

// const customDot: StepsProps['progressDot'] = (dot, { status, index }) => (
//   <Popover
//     content={
//       <span>
//         step {index} status: {status}
//       </span>
//     }
//   >
//     {dot}
//   </Popover>
// );
// const Stepper = ({ currentStep }: any) => (
//   <Steps
//     current={currentStep}
//     progressDot={customDot}
//     items={
//       [
//         {
//           description: 'App Info',
//         },
//         {
//           description: 'Payment Info',
//         },
//         {
//           description: 'Update time',
//         },
//         {
//           description: 'Requirements',
//         },
//       ]}
//   />
// );

// export default Stepper;


import { List, Steps } from 'antd';
import Dark from '../../assets/color_border.png'
import Light from '../../assets/color_border_no.png'

interface StepItem {
  title: string;
  image: {
    light: string;
    dark: string;
  };
}

interface Props {
  currentStep: number;
}

const data: any[] = [{ title: "" }];



// const items = [
//   // {
//   //   title: 'App Info',
//   // },
//   {
//     title: 'Payment Info',
//   },
//   {
//     title: 'Update time',
//   },
//   {
//     title: 'Requirements',
//   },
// ];
const items: StepItem[] = [
  {
    title: 'Payment Info',
    image: {
      light:Light,
      dark: Dark
    }
  },
  {
    title: 'Update time',
    image: {
      light:Light,
      dark: Dark
    }
  },
  {
    title: 'Requirements',
    image: {
      light:Light,
      dark: Dark
    }
  },
];

// const Stepper = ({ currentStep }: any) => (
//   <List
//     itemLayout="horizontal"
//     dataSource={data}
//     renderItem={(item, index) => (
//       <List.Item>
//        <Steps
//           style={{ marginTop: 8 }}
//           type="inline"
//           current={currentStep}
//           items={items}
//         /> 
//       </List.Item>
//     )}
//   />
// );
const Stepper: React.FC<Props> = ({ currentStep }) => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item key={index}>
        <Steps
          style={{ marginTop: 8 }}
          type="inline"
          current={currentStep}
          items={items} // Pass items directly to the Steps component
        />
      </List.Item>
    )}
  />
);
export default Stepper;