import emailSentGraphic from 'assets/proton_email_send.png'

import '../../customer.sass'

const SubmitSuccess = () => {
  return (
    <div className="success-container">
      <div style={{ marginBottom: '10px' }}>
        <img
          src={emailSentGraphic}
          alt="email sent"
          width={'250px'}
          height={'300px'}
        />
      </div>
      <div style={{ fontSize: '16px', fontWeight: 600 }}>Request Submitted</div>
      <div style={{ fontSize: '14px', color: 'gray' }}>
        Your request has been submitted successfully.
      </div>
      <div style={{ fontSize: '14px', color: 'gray' }}>
        We will reach out to you via email to confirm
      </div>
    </div>
  )
}

export default SubmitSuccess
