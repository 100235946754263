import { useRouteError } from 'react-router-dom'

import { TGenericObject } from '../types/base'

const RouteError = () => {
  const error = useRouteError() as TGenericObject
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  )
}

export default RouteError
