import { useMemo, useState, useEffect, useCallback } from 'react'
import Avatar from 'components/Avatar/Avatar'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import ProgressBar from 'components/ProgressBar/ProgressBar'
import briefcaseIcon from 'assets/briefcase-4-fill.svg'
import userIcon from 'assets/user-3-fill.svg'
import unpin from 'assets/thumbtack.svg'
import "../PinnedCard.sass"
import { IJobItem } from 'pages/jobs/Jobs'
import { DEFAULT_AVATAR, SERVICES } from 'utils/constants'
import axiosInstance from 'utils/axios'
import client from 'feathers-client'

interface IPinnedJobItemProps {
    job:IJobItem
    handleUnpinJob:any
}

const PinnedCard =({job,handleUnpinJob}:IPinnedJobItemProps)=> {
  const [image, setImage] = useState(DEFAULT_AVATAR)
  const [progressCount, setProgressCount] = useState<number>(0)
  const kebabMenuItems = useMemo(
        () => [
          {
            label: 'Unpin',
            icon: unpin,
            onClick: () => {handleUnpinJob(job.id) },
          },
        ],
        []
  )
  useEffect(()=>{
    const fetchTemplateImage = setTimeout(async()=>{
      if(job.appImage){
        const response:any = await axiosInstance.get(`getSignedFileUrl/?fileName=${job.appImage}`)
        if(response?.status){
          // console.log(response.data.url);
          setImage(response.data.url)
        }
      }
    },200)

    return ()=> clearTimeout(fetchTemplateImage)
  },[])

  useEffect(()=>{
    const fetchJobTasksTimer = setTimeout(()=>{
      fetchJobTaksHandler(job.id)
    }, 200)

    return () => clearTimeout(fetchJobTasksTimer)
  },[])

  const fetchJobTaksHandler = useCallback(async(Id:any)=>{
    const query = {
      jobId: job.id,
    }
    const jobTasksData = await client.service(SERVICES.TASK).find({ query })
    if (Array.isArray(jobTasksData?.data)) {
        // resolve({jobTitle: job.name, tasks: jobTasksData?.data.filter((item:any) => item.status === 'review')})
        const finishedcountNum = finishedcount(jobTasksData?.data);
        const totalTasksCount = jobTasksData?.data?.length;
        const percentage = ((finishedcountNum / totalTasksCount) * 100).toFixed(0)
        setProgressCount(+percentage)
    }else{
        // resolve({jobTitle: job.name, tasks: []})
    }
  },[job])
  const finishedcount = useCallback(
    (taskList: any) => {
      let finishedCount = 0
      for (let i = 0; i < taskList.length; i++) {
        if (taskList[i].status === 'finished') {
          finishedCount++
        }
      }
      return finishedCount
  },[job])

  return (
    <div className='pinned-job-content'>
      <div className="pinned-job-left">
        <Avatar
          size={56}
          name={"profile Image"}
          url={image}
        />
      </div>
      <div className="pinned-job-right">
        <div className='job-detail'>
            <div>
            <div className='pinned-text'>
                <div className='title'>{job.appIndustry}</div>
                <KebabMenu items={kebabMenuItems}  />
            </div>
            <div className='job-progress-status'>
                <ProgressBar progress={progressCount}/>
                  {job.isJobActive ? 
                    job?.depositReceived === false ? 
                    <div className="progress-badge" style={{background:"#ff00001f", color:'red', marginRight:10}}>Inactive</div>
                    :
                    <div className="progress-badge" style={{background:"#00800033", color:'green', marginRight:10}}>Active</div>
            
                    :
                    <div className="progress-badge" style={{background:"#ff00001f", color:'red', marginRight:10}}>Inactive</div>
                  }
            </div>
            </div>
        </div>
        <div className='cart-bottom-info' style={{marginTop: 12}}>
              <div className="cart-bottom-info-item" >
                <span className='info-icon'>
                  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_301_3701)">
                      <path d="M9.5 2.5H8.95C8.83395 1.93571 8.52691 1.42867 8.08063 1.06436C7.63434 0.700045 7.0761 0.500727 6.5 0.5L5.5 0.5C4.9239 0.500727 4.36566 0.700045 3.91937 1.06436C3.47309 1.42867 3.16605 1.93571 3.05 2.5H2.5C1.8372 2.50079 1.20178 2.76444 0.73311 3.23311C0.264441 3.70178 0.000793929 4.3372 0 5L0 6.5H12V5C11.9992 4.3372 11.7356 3.70178 11.2669 3.23311C10.7982 2.76444 10.1628 2.50079 9.5 2.5ZM4.092 2.5C4.19504 2.20855 4.38562 1.95605 4.63765 1.77704C4.88969 1.59804 5.19087 1.50128 5.5 1.5H6.5C6.80913 1.50128 7.11031 1.59804 7.36235 1.77704C7.61438 1.95605 7.80496 2.20855 7.908 2.5H4.092Z" fill="#265068"/>
                      <path d="M6.5 8C6.5 8.13261 6.44732 8.25979 6.35355 8.35355C6.25979 8.44732 6.13261 8.5 6 8.5C5.86739 8.5 5.74021 8.44732 5.64645 8.35355C5.55268 8.25979 5.5 8.13261 5.5 8V7.5H0V10C0.000793929 10.6628 0.264441 11.2982 0.73311 11.7669C1.20178 12.2356 1.8372 12.4992 2.5 12.5H9.5C10.1628 12.4992 10.7982 12.2356 11.2669 11.7669C11.7356 11.2982 11.9992 10.6628 12 10V7.5H6.5V8Z" fill="#265068"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_301_3701">
                        <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <p className="business">{job.customerBusinessName || "N/A"}</p>
              </div>
          
                <span className='spacer' style={{ display:'block' ,width:'1px',background:'#B7C5CC',height: '20px'}}></span>
                <div className="cart-bottom-info-item" >
                  <span className='info-icon'>
                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_301_3707)">
                        <path d="M6 6.5C7.65685 6.5 9 5.15685 9 3.5C9 1.84315 7.65685 0.5 6 0.5C4.34315 0.5 3 1.84315 3 3.5C3 5.15685 4.34315 6.5 6 6.5Z" fill="#265068"/>
                        <path d="M6 7.5C3.51586 7.50277 1.50277 9.51586 1.5 12C1.5 12.2761 1.72385 12.5 1.99999 12.5H9.99998C10.2761 12.5 10.5 12.2761 10.5 12C10.4972 9.51586 8.48414 7.50274 6 7.5Z" fill="#265068"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_301_3707">
                          <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <p className="business">{job.customerName || "N/A"}</p>
                </div>
            </div>
      </div>
    </div>
  )
}

export default PinnedCard
