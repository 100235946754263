import React, { Dispatch } from "react";

export const UPDATE_FORM = "UPDATE_FORM";

type FormState = {
    [key: string]: {
        value: string;
        hasError: boolean;
        error: string;
        touched: boolean;
    };
};

type FormAction = {
    type: typeof UPDATE_FORM;
    data: {
        name: string;
        value: string;
        hasError: boolean;
        error: string;
        touched: boolean;
        isFormValid: boolean;
    };
};

export const validateInput = (name: string, value: string, formState: FormState) => {
    let hasError = false;
    let error = "";

    switch (name) {
        case "appTitle":
            if (value.trim() === "") {
                hasError = true;
                error = "App Title can't be empty";
            }
            break;
    }

    return { hasError, error };
};

export const onInputChange = (
    name: string,
    value: string,
    dispatch: Dispatch<FormAction>,
    formState: FormState
) => {
    const { hasError, error } = validateInput(name, value, formState);
    let isFormValid = true;

    for (const key in formState) {
        const item = formState[key];
        // Check if the current field has error
        if (key === name && hasError) {
            isFormValid = false;
            break;
        } else if (key !== name && item.hasError) {
            // Check if any other field has error
            isFormValid = false;
            break;
        }
    }

    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: false, isFormValid },
    });
};

export const onFocusOut = (
    name: string,
    value: string,
    dispatch: Dispatch<FormAction>,
    formState: FormState
) => {
    const { hasError, error } = validateInput(name, value, formState);
    let isFormValid = true;

    for (const key in formState) {
        const item = formState[key];
        if (key === name && hasError) {
            isFormValid = false;
            break;
        } else if (key !== name && item.hasError) {
            isFormValid = false;
            break;
        }
    }

    dispatch({
        type: UPDATE_FORM,
        data: { name, value, hasError, error, touched: true, isFormValid },
    });
};
