import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
    Fragment
} from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import { LINKS } from 'routes/constants'

import NoData from 'components/NoData/NoData'
import Button from 'components/forms/Button/Button'

import jobsIcon from 'assets/jobs.svg'

import JobsListing from '../../jobs/JobsListing'
import '../../jobs/jobs.sass'
import backIcon from 'assets/back.svg'
import {
    deleteJob,
    jobFetchingSearch,
    jobFetchingSort,
    jobProposalRevoke,
    jobProposalSorting,
    pinJobs,
    unpinJobs,
} from '../../jobs/utils'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'
import PendingTaskItem from './PendingTaskItem'
import Loader from 'components/Loader/Loader'


export interface IJobItem {
    id: string
    name: string
    customerName: string
    customerEmail: string
    customerBusinessName: string
    invited: number
    invitedEmail: string
    pinned?: boolean
    appIndustry?: string
    appDescription?: string
    suggestedAppTitle?: string
    requestedAppName?: boolean
    developedFor?: 'ios' | 'android' | 'ios+android'
    appTheme?: string
    appPrice?: 'free' | 'paid'
    appUpdates?: 'none' | 'weekly'
    appRequirements?: string
    attachments?: any[]
    submitted?: number // timestamp customer submitted the request
    isSubmitted: boolean
    revoked?: number // timestamp job revoked
    revokedBy?: string // id of user who revoked proposal
    activity?: Array<{
        added: number
        addedBy: string
        comment: string
        attachments: any[]
    }>
    jobStatus: string | undefined

}

interface IJobsProps extends HTMLAttributes<HTMLDivElement> { }
const PendingTask = ({ ...props }: IJobsProps) => {
    const auth = useContext(AuthContext)
    const isTemp = auth?.user?.permissions?.includes("temp");
    const [loading, setLoading] = useState<boolean>(true)
    const [jobsLoading, setJobsLoading] = useState<boolean>(true)
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [jobs, setJobs] = useState<any[]>([])
    const [jobsTasks, setJobsTasks] = useState<any[]>([])
    const [noTasks, setNoTasks] = useState<boolean>(true);
    const fetchJobs = useCallback(async () => {
      if (auth) {
        const Useremail = auth?.user?.email;
        const jobData = await client.service(SERVICES.JOBS).find({
          query: {
            customerEmail: Useremail,
          },
        })
        if (Array.isArray(jobData?.data)) {
          setJobs(jobData.data)
        }
      }
      setLoading(false)
    }, [auth])
  
    
    useEffect(() => {
        fetchJobs()
    }, [fetchJobs])

    useEffect(()=>{
        const fetchTasksTimer = setTimeout(()=>{
            if(jobs.length > 0) {
                setJobsLoading(true)
                let jobsTasksPromises:any[] = [];
                jobs.map((job, index)=>{
                    let jobPromise = new Promise(async (resolve, reject)=>{
                        const query = {
                            jobId: job.id,
                        }
                        const getAllTask = await client.service(SERVICES.TASK).find({ query })
                        if (Array.isArray(getAllTask?.data)) {
                            resolve({jobTitle: job.name, tasks: getAllTask?.data.filter((item:any) => item.status === 'review')})
                        }else{
                            resolve({jobTitle: job.name, tasks: []})
                        }
                    })

                    jobsTasksPromises.push(jobPromise);
                })

                Promise.all(jobsTasksPromises).then((result)=>{
                    setJobsLoading(false)
                    let tasksCount = 0;
                    result.map(item => tasksCount += item.tasks.length)
                    setNoTasks(tasksCount > 0 ? false : true);
                    setJobsTasks(result)
                })
                .catch((err)=>{
                    console.log(err.message)
                })
            }
        }, 200)

        return () => clearTimeout(fetchTasksTimer)
    },[jobs])

    const revokeJobProposal = useCallback(async (param: any) => {
        console.log("param.id", param.id);
        const jobs = await jobProposalRevoke(param)
        if (Array.isArray(jobs?.data)) {
            setJobs(jobs.data)
        }
    }, [])

    const handlePinJob = useCallback(async (param: any) => {
        const jobs = await pinJobs(param)
        if (Array.isArray(jobs?.data)) {
            setJobs(jobs.data)
        }
    }, [])

    const handleUnpinJob = useCallback(async (param: any) => {
        const jobs = await unpinJobs(param)
        if (Array.isArray(jobs?.data)) {
            setJobs(jobs.data)
        }
    }, [])

    const sortJobProposal = useCallback(async (param: any, option: any) => {
        const jobs = await jobProposalSorting(param, option)
        if (Array.isArray(jobs?.data)) {
            setJobs(jobs.data)
        }
    }, [])

    const handleDeleteJob = useCallback(async (param: any) => {
        if (auth) {
            const headers = {
                Authorization: `Bearer ${auth.accessToken}`,
            }
            await deleteJob(param, headers)
            fetchJobs()
        }
    }, [])

    if (loading) {
        return null
    }

    if(isTemp){
        return null;
    }



    return (
        <div className="jobs" {...props}>
            <h4 style={{ display: "flex", marginBottom: "30px", marginTop: "20px", gap: "10px" }}> <img src={backIcon} alt="" /> Pending task</h4>
            {jobs.length > 0 ? (
                <div style={{ display: "flex", gap: "30px" }}>
                    <div style={{ width: "70%" , display:"flex" , flexDirection:"column" ,  background:"#fff", boxShadow:"1px 1px 9px -2px #00000052", height:"max-content"}}>
                        
                        {jobsTasks.length > 0 && jobsTasks.map((jobTasks, jobIndex)=> (
                            <Fragment key={jobIndex}>
                                {jobTasks.tasks.length > 0 && jobTasks.tasks.map((task:any, index:number)=> (
                                    <PendingTaskItem job={jobTasks} task={task} key={`${jobIndex}-${index}`} />
                                ))}
                            </Fragment>
                        ))} 

                        {noTasks && 
                            <>
                                {jobsLoading ?
                                    <div style={{display:"flex", justifyContent:"center"}}><Loader /></div>
                                : 
                                    <NoData
                                        icon={jobsIcon}
                                        title="No job task is added yet"
                                        // cta={
                                        //     <Link to={LINKS.JOBS_CREATE}>
                                        //         <Button>Create Job</Button>
                                        //     </Link>
                                        // }
                                    />}  
                                
                            </>
                        }

                     
                    </div>
                    <div className="customers-apps-pending-tasks" style={{ width: "30%", background: "#fff", boxShadow: "1px 1px 2px 2px #0000001f", borderRadius: "10px" }}>
                        <p style={{ paddingBottom: "20px", marginBottom: "0px", padding: "20px", paddingTop: "20px", borderBottom: "1px solid #00000030", marginTop: "0px" }}>
                            <b>App Summary</b></p>
                        <JobsListing
                            jobs={jobs}
                            onClickRevokeJob={(e) => revokeJobProposal(e)}
                            onClickSortJob={sortJobProposal}
                            handlePinJob={handlePinJob}
                            handleUnpinJob={handleUnpinJob}
                            fetchjobs_fn={fetchJobs}
                            handleDeleteJob={handleDeleteJob}
                            hideHeader={true}
                            hidejobdescription={true}
                        />
                    </div>
                </div>
            ) : (
                <NoData
                    icon={jobsIcon}
                    title="No job task is added yet"
                    // cta={
                    //     <Link to={LINKS.JOBS_CREATE}>
                    //         <Button>Create Job</Button>
                    //     </Link>
                    // }
                />
            )}
        </div>

    )
}
export default PendingTask
