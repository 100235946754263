import React, { InputHTMLAttributes, useMemo, useState, useEffect, useCallback } from 'react'
import { TGenericObject } from 'types/base'
import { TEMPLATES_VARIABLE } from 'utils/constants'
import Button from '../Button/Button'
import { toast } from 'react-toastify';

interface ITextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
  errors?: TGenericObject,
  showTempVariables?: string
}

const TextArea = ({
  id,
  label,
  required,
  errors,
  showTempVariables="",
  ...props
}: ITextAreaProps) => {
  const [selectedVariable, setSelectedVariable]  = useState("")
  const [templateVariablesOptions, setTemplateVariablesOptions] = useState<any>([])
  useEffect(()=>{
    if(showTempVariables){
      let filteredData:any = TEMPLATES_VARIABLE.filter(item => item.type === showTempVariables);
      setTemplateVariablesOptions(filteredData.length > 0 ? filteredData : [])
      if(filteredData.length > 0){
        let selectedVal:any = Object.entries(filteredData[0].variables)[0];
        setSelectedVariable(selectedVal[1]);
      }
    }
  },[showTempVariables])

  // console.log(templateVariablesOptions, showTempVariables)
  const getError = useMemo(() => {
    return props?.name ? errors?.[props?.name] : ''
  }, [errors, props.name])

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVariable(e.target.value);
  };
  // console.log(selectedVariable)
  const handleCopyClick = useCallback((e:any) => {
    e.preventDefault();

    navigator.clipboard.writeText(selectedVariable)
      .then(() => {
        console.log('Text copied to clipboard:', selectedVariable);
        toast.success('Text copied to clipboard', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // You might want to show a success message or perform additional actions here
      })
      .catch(err => {
        console.error('Error copying text:', err);
        toast.error("Error copying text", {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // Handle errors, show an error message, etc.
      });
  },[selectedVariable]);

  return (
    <div className="textarea-field input-field">
      <div className="header">
        <label htmlFor={id}>
          {label}
          {required && <span className="required-indicator">*</span>}
        </label>
        {templateVariablesOptions.length > 0 && 
         <div
          className='placeholder-selector'
         style={{
          display:'flex',
          alignItems: "center",
          gap:"10px",
          marginTop:10
         }}>
           <select 
            onChange={handleInputChange}
            defaultValue={Object.keys(templateVariablesOptions[0].variables)[0]} 
            id="templateSelect" 
          >
            {Object.entries(templateVariablesOptions[0].variables).map(([key, value]) => (
              <option  key={key} value={String(value)}>
                {String(value)}
              </option> 
            ))}
          </select>

          <Button style={{height:"44px"}} onClick={handleCopyClick}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.25 16.5C7.74583 16.5 7.31424 16.3205 6.95521 15.9615C6.59618 15.6024 6.41667 15.1708 6.41667 14.6667V3.66668C6.41667 3.16251 6.59618 2.73091 6.95521 2.37189C7.31424 2.01286 7.74583 1.83334 8.25 1.83334H16.5C17.0042 1.83334 17.4358 2.01286 17.7948 2.37189C18.1538 2.73091 18.3333 3.16251 18.3333 3.66668V14.6667C18.3333 15.1708 18.1538 15.6024 17.7948 15.9615C17.4358 16.3205 17.0042 16.5 16.5 16.5H8.25ZM8.25 14.6667H16.5V3.66668H8.25V14.6667ZM4.58333 20.1667C4.07917 20.1667 3.64757 19.9872 3.28854 19.6281C2.92951 19.2691 2.75 18.8375 2.75 18.3333V5.50001H4.58333V18.3333H14.6667V20.1667H4.58333Z" fill="white"/>
            </svg>
            Copy
          </Button>
         </div>
        }
      </div>
      <textarea id={id} {...props} className="input" style={{height: 200}}>
        {props.value}
      </textarea>
      <div className="error">{getError}</div>
    </div>
  )
}

export default TextArea
