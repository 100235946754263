import { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAppTemplateLink } from 'routes/constants'
import { DEFAULT_AVATAR } from 'utils/constants'

import DeleteModal from 'components/ActionModal/DeleteModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import KebabMenu, { IKebabMenuItem } from 'components/KebabMenu/KebabMenu'

import deleteIcon from 'assets/delete.svg'
import editIcon from 'assets/edit.svg'

import { IAppTemplate } from './AppTemplates'
import { deleteAppTemplate } from './utils'
import "./templates.sass"

import axiosInstance from 'utils/axios'

interface IAppTemplateListItemProps {
  template: IAppTemplate
  refetch?: () => void
}

const AppTemplateListItem = ({
  refetch = () => {},
  template,
}: IAppTemplateListItemProps) => {
  const [templateImage, setTemplateImage] = useState(DEFAULT_AVATAR)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(()=>{
    const fetchTempImage = setTimeout(async()=>{
      if(template.icon){
        const response:any = await axiosInstance.get(`getSignedFileUrl/?fileName=${template.icon}`)
        if(response?.status){
          setTemplateImage(response.data.url);
        }
      }
    }, 200)

    return () => clearTimeout(fetchTempImage)
  },[])
  
  const deleteTemplate = useCallback(async () => {
    const data = await deleteAppTemplate(template.id)

    if (data) {
      refetch()
    }
  }, [template, refetch])

  const templateActions: IKebabMenuItem[] = [
    {
      label: 'Edit',
      icon: editIcon,
      onClick: () => {
        navigate(getAppTemplateLink(template.id))
      },
    },
    {
      label: 'Delete',
      icon: deleteIcon,
      onClick: () => {
        setDeleteOpen(true)
      },
    },
  ]



  return (
    <div className="app-template-item">
      <div className="left">
        <div className="icon">
          <img src={templateImage} alt="" />
        </div>
        <div className="details">
          <div className="title">{template.title}</div>
          <div className="description">{template.description}</div>
        </div>
      </div>
      <div className="right">
        <KebabMenu items={templateActions} />
      </div>
      <DeleteModal
        isOpen={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false)
        }}
        body={
          <ModalPreviewAppTemplate
            icon={templateImage}
            title={template.title}
            subtitle={template.description}
          />
        }
        handleDelete={deleteTemplate}
        title="Delete app template"
        subtitle="Do you wish to delete the app template?"
      />
    </div>
  )
}

interface IAppTemplatesProps extends HTMLAttributes<HTMLDivElement> {
  templates: IAppTemplate[]
  refetch: () => void
}
const AppTemplatesList = ({
  templates,
  refetch,
  ...props
}: IAppTemplatesProps) => {
  return (
    <div className="app-templates-list" {...props}>
      {templates.map((template) => (
        <AppTemplateListItem
          template={template}
          refetch={refetch}
          key={template.id}
        />
      ))}
    </div>
  )
}

export default AppTemplatesList
