import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from 'react'
  import { useLocation, useParams, useNavigate } from 'react-router-dom'
  
  import { AuthContext } from 'context/AuthContext'
  import dayjs from 'dayjs'
  import client from 'feathers-client'
  import { SERVICES, URL_PATH, USER_ROLES } from 'utils/constants'
  
  import ActionModal from 'components/ActionModal/ActionModal'
  import EmailSentModal from 'components/ActionModal/EmailSentModal'
  import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
  import Avatar from 'components/Avatar/Avatar'
  import EmailPreview from 'components/EmailPreview/EmailPreview'
  import PageHeader from 'components/Header/PageHeader/PageHeader'
  import KebabMenu from 'components/KebabMenu/KebabMenu'
  import Loader from 'components/Loader/Loader'
  import LabelValueTable, { ILabelValueTableItem } from 'components/Tables/LabelValueTable/LabelValueTable'
  import Button from 'components/forms/Button/Button'
  
  import closeIcon from 'assets/close.svg'
  import planeIcon from 'assets/paper-plane 1.svg'
  
  // import { revokeJob } from './utils'
  import { LINKS } from '../../routes/constants';
// import { INVITE } from './StaffInvite'
import ModalPreviewStaffTemplate from 'components/ActionModal/components/ModalPreviewStaffTemplate'
import axios, { AxiosRequestConfig } from 'axios';
// import { IStaffMember } from './Staff'
export interface IStaffMember {
  id:string
  avatar: string
  name: string
  email: string
  added?: number
  permissions?: keyof typeof USER_ROLES
  invited: number
  revoked: number
}


  interface IPendingStaffDetails extends HTMLAttributes<HTMLDivElement> { }
  const PendingStaffDetail = ({ ...props }: IPendingStaffDetails) => {
    const {staffId} = useParams()
    const navigate = useNavigate();
    const auth = useContext(AuthContext)
    const [staff, setStaff] = useState<IStaffMember | null>(null)
    const [emailSent, setEmailSent] = useState<boolean>(false)
    const [revokeJobOpen, setRevokeJobOpen] = useState<boolean>(false)
    const [emailBody, setEmailBody] = useState<any>()
    const [template, setTemplate] = useState<string>("");
    const [templateData, setTemplateData] = useState<any>({});
    useEffect(()=>{
  
      const fetchTemplateTimer = setTimeout(async()=>{
        const configE: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            "type": "invite_staff"
          }),
        };
      
  
        try{
          const templateResult = await axios.request(configE);
          if(templateResult.data.status === 200){
            setTemplate(templateResult.data.template.data[0].body)
            setTemplateData(templateResult.data.template.data[0])
          }else{
            console.log("Template not found !!!")
          }
        }
        catch(err:any){
          console.log("Something went wrong!!!", err.message)
        }
      },200)
  
      return () => clearTimeout(fetchTemplateTimer)
    },[])
  
  
    const closeEmailSent = useCallback(() => {
      setEmailSent(false)
    }, [setEmailSent])
  
    const closeRevokeJob = useCallback(() => {
      setRevokeJobOpen(false)
    }, [setRevokeJobOpen])
  
    const revokeJobProposal = useCallback((param: any) => {
      // revokeJob()
      closeRevokeJob()
    }, [closeRevokeJob])
  
    const fetchUser= useCallback(async () => {
      if (auth && staffId) {
        const staff = await client.service(SERVICES.USERS).get(staffId)
        setStaff(staff);
        let customerFirstName =  staff.name?.split(" ")?.shift();
        setEmailBody(template.replaceAll('[Full Name]', (customerFirstName || staff.name) ?? '').replaceAll('[Link]', window.location.origin).replaceAll(`[Role]`, staff.permissions?? ''));
      }
    }, [auth, staffId]);
  
    const onClickEmailSent = useCallback(async (param: any) => {
      // const query = { title: 'Add Staff' };
      // const template = await client.service(SERVICES.EMAIL_TEMPLATES).find({ query })
      // if (template?.data?.length > 0) {
        const tempStr = templateData;
        let customerFirstName =  staff?.name?.split(" ")?.shift();
        tempStr.body = tempStr.body.replaceAll('[Full Name]', (customerFirstName  || param.customerName) ?? '').replaceAll('[Link]', window.location.origin).replaceAll(`[Role]`, param.permissions?? '');
        const emailObj = {
          to: param.customerEmail,
          subject: tempStr.subject,
          text: tempStr.body,
        };
        await client.service(SERVICES.SENDEMAIL).create(emailObj);
      // }
      setEmailSent(true);
    }, []);
  
    const onclickRevokeJobProposal = useCallback(async (param: any) => {
      await client.service(SERVICES.JOBS).patch(param.id, {});
      setRevokeJobOpen(false);
      navigate(LINKS.JOBS_PENDING);
    }, []);
  
    const kebabMenuItems = useMemo(
      () => [
        {
          label: 'Revoke',
          icon: closeIcon,
          onClick: () => {
            setRevokeJobOpen(true)
          },
        },
        {
          label: 'Resend',
          icon: planeIcon,
          onClick: () => {
            onClickEmailSent(staff);
          },
        },
      ],
      [staff]
    )
    const tableItems = useMemo<ILabelValueTableItem[]>(
      () => [
        {
          label: 'Name:',
          value: String(staff?.name),
        },
        {
          label: 'Email:',
          value: String(staff?.email),
        },
        {
          label: 'Role:',
          value: String(staff?.permissions),
        },
      ],
      [staff]
    )
  
    useEffect(() => {
      fetchUser()
    }, [fetchUser])

    return (
      <div {...props} className="full-page pending-job-detail">
        <PageHeader
          heading="Staff details"
          showBack
          rightSlot={<KebabMenu items={kebabMenuItems} />}
        />
        {staff ? (
          <>
            <div className="job-container half-container">
              <div className="left">
                <div className="proposal-name">
                  <Avatar name={staff.name} size={64} />
                  <span className="job-name">{staff.name}</span>
                </div>
                <div className="data">
                  <LabelValueTable items={tableItems} />
                </div>
              </div>
              <div className="right">
                <EmailPreview body={emailBody} />
              </div>
            </div>
            <EmailSentModal
              isOpen={emailSent}
              handleClose={closeEmailSent}
              email={staff?.email}
            />
            <ActionModal
              isOpen={revokeJobOpen}
              handleClose={closeRevokeJob}
              title="Revoke invite"
              subtitle="Do you wish to revoke the below sent invitaion?"
              actionButtons={
                <>
                  <Button theme="secondary" onClick={closeRevokeJob}>
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '12px' }}
                    onClick={() => onclickRevokeJobProposal(staff)}
                  >
                    Revoke
                  </Button>
                </>
              }
              body={
                <ModalPreviewStaffTemplate staff={staff} />
                // <ModalPreviewAppTemplate
                //   title={staff.name}
                //   subtitle={` Sent on ${dayjs(staff.invited).format(
                //     'MMM DD, YYYY h:mm A'
                //   )} to ${staff.name}`}
                // />
              }
            />
          </>
        ) : (
          <div className="center">
            <Loader />
          </div>
        )}
      </div>
    )
  }
  
  export default PendingStaffDetail
  