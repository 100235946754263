import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import dayjs from 'dayjs'
import client from 'feathers-client'
import { SERVICES, URL_PATH } from 'utils/constants'

import ActionModal from 'components/ActionModal/ActionModal'
import EmailSentModal from 'components/ActionModal/EmailSentModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import Avatar from 'components/Avatar/Avatar'
import EmailPreview from 'components/EmailPreview/EmailPreview'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import Loader from 'components/Loader/Loader'
import LabelValueTable, { ILabelValueTableItem } from 'components/Tables/LabelValueTable/LabelValueTable'
import Button from 'components/forms/Button/Button'

import closeIcon from 'assets/close.svg'
import planeIcon from 'assets/paper-plane 1.svg'

import { IJobItem } from './Jobs'
import './jobs.sass'
// import { INVITE } from '../jobs/CreateJob/CreateJob'
import { LINKS } from '../../routes/constants';
import axios, { AxiosRequestConfig } from 'axios';

interface IPendingJobDetails extends HTMLAttributes<HTMLDivElement> { }
const PendingJobDetails = ({ ...props }: IPendingJobDetails) => {
  const { jobId } = useParams()
  const navigate = useNavigate();
  const auth = useContext(AuthContext)
  const [job, setJob] = useState<IJobItem | null>(null)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [revokeJobOpen, setRevokeJobOpen] = useState<boolean>(false)
  const [emailBody, setEmailBody] = useState<any>()
  const [emailTemplate, setEmailTemplate] = useState<string>("")

  useEffect(()=>{

    const fetchTemplateTimer = setTimeout(async()=>{
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "create_job"
        }),
      };
    

      try{
        const templateResult = await axios.request(configE);
        if(templateResult.data.status === 200){
          setEmailTemplate(templateResult.data.template.data[0].body)
        }else{
          console.log("Template not found !!!")
        }
      }
      catch(err:any){
        console.log("Something went wrong!!!", err.message)
      }
    },200)

    return () => clearTimeout(fetchTemplateTimer)
  },[])


  const closeEmailSent = useCallback(() => {
    setEmailSent(false)
  }, [setEmailSent])

  const closeRevokeJob = useCallback(() => {
    setRevokeJobOpen(false)
  }, [setRevokeJobOpen])

  const revokeJobProposal = useCallback((param: any) => {
    closeRevokeJob()
  }, [closeRevokeJob])

  const fetchJob = useCallback(async () => {
    if (auth && jobId) {
      const job = await client.service(SERVICES.JOBS).get(jobId)
      setJob(job);
      let customerFirstName =  job.customerName?.split(" ")?.shift();
      setEmailBody(emailTemplate.replaceAll('[Customer Name]', (customerFirstName || job.customerName) ?? '').replaceAll('[Link]', window.location.origin));
    }
  }, [auth, jobId, emailTemplate]);

  const onClickEmailSent = useCallback(async (param: any) => {
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        "type": "create_job"
      }),
    };
    const template = await axios.request(configE);
    if (template?.data?.template?.data?.length > 0) {
      const tempStr = template?.data?.template?.data[0]
      let customerFirstName =  param.customerName?.split(" ")?.shift();
      tempStr.body = tempStr.body.replace('[Customer Name]', (customerFirstName || param.customerName) ?? '').replace('[Link]', window.location.origin);
      const emailObj = {
        to: param.customerEmail,
        subject: tempStr.subject,
        text: tempStr.body,
      };
      await client.service(SERVICES.SENDEMAIL).create(emailObj);
    }
    setEmailSent(true);
  }, []);

  const onclickRevokeJobProposal = useCallback(async (param: any) => {
    await client.service(SERVICES.JOBS).patch(param.id, {});
    setRevokeJobOpen(false);
    navigate(LINKS.JOBS_PENDING);
  }, []);

  const kebabMenuItems = useMemo(
    () => [
      {
        label: 'Revoke',
        icon: closeIcon,
        onClick: () => {
          setRevokeJobOpen(true)
        },
      },
      {
        label: 'Resend',
        icon: planeIcon,
        onClick: () => {
          onClickEmailSent(job);
        },
      },
    ],
    [job]
  )
  const tableItems = useMemo<ILabelValueTableItem[]>(
    () => [
      {
        label: 'Customer name:',
        value: String(job?.customerName),
      },
      {
        label: 'Customer email:',
        value: String(job?.customerEmail),
      },
      {
        label: 'Sent on:',
        value: dayjs(job?.invited).format('MMM DD, YYYY'),
      },
    ],
    [job]
  )

  useEffect(() => {
    fetchJob()
  }, [fetchJob])

  return (
    <div {...props} className="full-page pending-job-detail">
      <PageHeader
        heading="Proposal details"
        showBack
        rightSlot={<KebabMenu items={kebabMenuItems} />}
      />
      {job ? (
        <>
          <div className="job-container half-container">
            <div className="left">
              <div className="proposal-name">
                <Avatar name={job.name} size={64} />
                <span className="job-name">{job.name}</span>
              </div>
              <div className="data">
                <LabelValueTable items={tableItems} />
              </div>
            </div>
            <div className="right">
              <EmailPreview body={emailBody} />
            </div>
          </div>
          <EmailSentModal
            isOpen={emailSent}
            handleClose={closeEmailSent}
            email={job?.customerEmail}
          />
          <ActionModal
            isOpen={revokeJobOpen}
            handleClose={closeRevokeJob}
            title="Revoke proposal"
            subtitle="Do you wish to revoke the below job proposal?"
            actionButtons={
              <>
                <Button theme="secondary" onClick={closeRevokeJob}>
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: '12px' }}
                  onClick={() => onclickRevokeJobProposal(job)}
                >
                  Revoke
                </Button>
              </>
            }
            body={
              <ModalPreviewAppTemplate
                title={job.name}
                subtitle={` Sent on ${dayjs(job.invited).format(
                  'MMM DD, YYYY h:mm A'
                )} to ${job.customerName}`}
              />
            }
          />
        </>
      ) : (
        <div className="center">
          <Loader />
        </div>
      )}
    </div>
  )
}

export default PendingJobDetails
