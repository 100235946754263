import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./index.sass";
import { AuthContext } from 'context/AuthContext'
import { LINKS, getJobDetailsUrl } from 'routes/constants'

import NoData from 'components/NoData/NoData'
import Button from 'components/forms/Button/Button'
import questionIcon from 'assets/questionMark.svg';
import jobsIcon from 'assets/jobs.svg'
import infoIcon from 'assets/info-circle.svg'
import checklistIcon from 'assets/list-check.svg'
import JobsListing from '../../jobs/JobsListing'

import '../../jobs/jobs.sass'
import {
  createTask,
  deleteJob,
  jobFetchingSearch,
  jobFetchingSort,
  jobProposalRevoke,
  jobProposalSorting,
  pinJobs,
  unpinJobs,
} from '../../jobs/utils'
import axios, { AxiosRequestConfig } from 'axios';
import client from 'feathers-client'
import { DEFAULT_AVATAR, SERVICES, URL_PATH } from 'utils/constants'
import KebabMenu from 'components/KebabMenu/KebabMenu';
import DeleteModal from 'components/ActionModal/DeleteModal';
import ModalPreviewJobTemplate from 'components/ActionModal/components/ModalPreviewJobTemplate';
import BaseModal from 'components/ActionModal/BaseModal';
import ShowInfoModal from 'pages/jobs/components/ShowInfoModal';
import deleteIcon from 'assets/delete.svg'
import { toast } from 'react-toastify';
import TextField from 'components/forms/TextField/TextField';
import PageHeader from 'components/Header/PageHeader/PageHeader';
import axiosInstance from 'utils/axios';

import Card from '../payments/Card';
import { SavedCard } from 'types/card';
import { getImageForBrand, getExpiryStatus } from 'utils/helpers'
import Loader from 'components/Loader/Loader';

export interface IJobItem {
  id: string
  name: string
  customerName: string
  customerEmail: string
  customerBusinessName: string
  invited: number
  invitedEmail: string
  pinned?: boolean
  appIndustry?: string
  appDescription?: string
  suggestedAppTitle?: string
  requestedAppName?: boolean
  developedFor?: 'ios' | 'android' | 'ios+android'
  appTheme?: string
  appPrice?: 'free' | 'paid'
  appUpdates?: 'none' | 'weekly'
  appRequirements?: string
  attachments?: any[]
  submitted?: number // timestamp customer submitted the request
  isSubmitted: boolean
  revoked?: number // timestamp job revoked
  revokedBy?: string // id of user who revoked proposal
  activity?: Array<{
    added: number
    addedBy: string
    comment: string
    attachments: any[]
  }>
  jobStatus: string | undefined
}

interface IJobsProps extends HTMLAttributes<HTMLDivElement> { }

interface PaymentRequest {
  amount: number;
  currency: string;
  email?: string;
  user_Id?: string;
  jobId: any;
  paymentType: string;
  description: string;
  paymentMethodId?: string; // Optional property
}

const CustomerDashboard = ({ ...props }: IJobsProps) => {
  const navigate = useNavigate()
  const auth = useContext(AuthContext)
  const isTemp = auth?.user?.permissions?.includes("temp");
  const [loading, setLoading] = useState<boolean>(true);
  const [template, setTemplate] = useState<any>({});
  const [jobTasksTemplates, setJobTasksTemplates] = useState<any>({});
  const [showBusinessNameModal, setShowBusinessNameModal] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>("");
  const [showBusinessError, setShowBusinessError] = useState<boolean>(false);

  const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState<any>(false)
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>(null)
  const [selectedJobDataPayment, setSelectedJobDataPayment] = useState<any>(null)

  const [paymentTemplate, setPaymentTemplate] = useState<any>({});
  const [activeTemplate, setActiveTemplate] = useState<any>({});

  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}get-payment-methods`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          user_Id: auth?.user?._id,
          name: auth?.user?.name,
          email: auth?.user?.email
        }),
      };
      const response = await axios.request(config);
      if (response && (response.status === 200 || response.status === 201)) {
        setSavedCards(response?.data?.data);
        setSelectedPaymentCard(response?.data?.data[0] || [])
      }
    } catch (error) {
      toast.error('Failed to fetch payment methods', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      console.error('Error fetching payment methods:', error);
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    if (auth) {
      fetchPaymentMethods();
    }
  }, [auth]);

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "active_job"
        }),
      };

      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setActiveTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  const paymentHandler = async (paymentType: any) => {
    setPaymentLoader(true)
    const paymentRequest: PaymentRequest = {
      amount: (paymentType === "deposit" ? selectedJobDataPayment.deposit : selectedJobDataPayment.finalPayment) * 100, // Amount in cents (Stripe uses cents)
      currency: "usd", // Desired currency code
      email: auth?.user?.email,
      user_Id: auth?.user?._id,
      jobId: selectedJobDataPayment.id,
      paymentType: paymentType.toLocaleUpperCase(),
      description: "Your payment description here", // Add a description for the payment
    };

    if (selectedPaymentCard) {
      paymentRequest.paymentMethodId = selectedPaymentCard.id;
    }
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}create-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(paymentRequest),
    };

    try {
      // setLoading(true);
      const apiResult = await axios.request(configE);
      console.log("apiResult >>", apiResult)
      if (apiResult.status === 200 || apiResult.status === 201) {
        if (paymentType === "deposit") {
          isJobActiveHandler(selectedJobDataPayment.id)
        } else {
          fullPaymentHandler(selectedJobDataPayment.id)
        }
        // navigate(LINKS.PAYMENT_SUCCESS);
      } else {
        setPaymentLoader(false)
        navigate(LINKS.PAYMENT_FAILED);
      }
    }
    catch (err: any) {
      setPaymentLoader(false)
      console.log("Something went wrong!!!", err.message)
      navigate(LINKS.PAYMENT_FAILED);
    } finally {
      // setLoading(false);
    }
  }

  const paymentReceivedEmailHandler = useCallback(
    async (paymentType: any) => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();

      console.log("selectedJobDataPayment >>", selectedJobDataPayment)
      INVITE = paymentTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Payment]", ` $${paymentType === "final" ? selectedJobDataPayment?.finalPayment?.toFixed(2) : selectedJobDataPayment?.deposit?.toFixed(2)}`).replaceAll("[Payment Type]", paymentType);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: paymentTemplate.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, paymentTemplate, selectedJobDataPayment]
  )
  const activeJobEmailHandler = useCallback(
    async () => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();


      INVITE = activeTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: activeTemplate.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, activeTemplate]
  )

  const renderedCards = () => {
    return (

      <div>
        <div className="cards-list" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
          {loading && (
            <div className="loader"></div>
          )}
          {savedCards?.length ? savedCards.map((card, index) => (
            // card
            <div key={index} style={{ width: '180px', height: '180px' }}>
              <Card
                key={index}
                title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                image={getImageForBrand(card.card.brand)}
                showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                checkbox={true}
                checked={selectedPaymentCard?.card?.last4 === card.card.last4} // Pass checked prop based on selectedCardIndex
                onClick={() => {
                  setSelectedPaymentCard(card)
                }} // Pass onClick function
              // paymentMethodId={paymentMethodId}
              />
            </div>
          ))
            :
            <p>No cards saved yet.</p>
          }
        </div>
      </div>
    )
  }

  const paymentModalJSX = (
    <div className='payment-modal-main'>
      <div className=''>
        <img src="./assets/logo-black-text.png" alt="" />
      </div>
      <div className="credit_card_heading" style={{
        marginTop: '20px',
      }}>
        <h4 style=
          {{
            fontWeight: '600',
            color: '#00314D',
            marginBottom: '10px',
            fontSize: '40px',
            lineHeight: '48px',
          }}>Make Payment</h4>
        <p style={{
          marginBottom: '20px',
          color: '#60747E',
          fontSize: '16px',
          lineHeight: '19.2px',
          fontWeight: '400',
        }}
        >Manage your credit cards and payment options.</p>
      </div>
      <div className="payment_method">
        {renderedCards()}
      </div>
      <div
        className='payment_logo'
        style={{
          display: 'flex',
          gap: '0px 10px',
          marginTop: '50px',
        }}
      >
        <img src="assets/cardlogo.png" />
        <img src="assets/visacardlogo.png" />
        <img src="/assets/paypallogo.png" />
        <img src="/assets/discoverlogo.png" />
        <img src="/assets/amricanlogo.png" />
      </div>
    </div>
  )

  const paymentModalActionsJSX = (
    <>
      <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>
      {selectedJobDataPayment &&
        <button
          disabled={paymentLoader}
          style={{ minWidth: paymentLoader ? 180 : "fit-content" }}
          className="primary button"
          onClick={() => {
            console.log("savedCards", savedCards);

            if (!savedCards.length) {
              navigate(LINKS.CUSTOMER_PAYMENTS)
            } else {
              paymentHandler(selectedJobDataPayment?.depositReceived ? "final" : "deposit")
            }
          }}
        >{savedCards.length ?
          paymentLoader ? <Loader className='white-color' /> : `Pay Now $${selectedJobDataPayment?.depositReceived ? selectedJobDataPayment?.finalPayment.toFixed(2) : selectedJobDataPayment?.deposit.toFixed(2)}`
          :
          "Add Card"
          }
        </button>}
    </>
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [jobs, setJobs] = useState<any[]>([])
  const fetchJobs = useCallback(async () => {
    if (auth) {
      const Useremail = auth?.user?.email;
      const jobData = await client.service(SERVICES.JOBS).find({
        query: {
          customerEmail: Useremail,
        },
      })
      if (Array.isArray(jobData?.data)) {
        setJobs(jobData.data)
      }
    }
    setLoading(false)
  }, [auth])

  useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  useEffect(() => {
    if (auth !== null && !!auth?.user?.businessName === false) {
      setShowBusinessNameModal(true)
    }
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "building_job"
        }),
      };


      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "payment_received"
        }),
      };

      try {
        const templateResult = await axios.request(configE);
        if (templateResult.data.status === 200) {
          setPaymentTemplate(templateResult.data.template.data[0])
        } else {
          console.log("Template not found !!!")
        }
      }
      catch (err: any) {
        console.log("Something went wrong!!!", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  useEffect(() => {
    const fetchTemplateTimer = setTimeout(async () => {
      try {
        const appTemplates = await client.service(SERVICES.TASK_TEMPLATES).find()
        if (Array.isArray(appTemplates?.data)) {
          setJobTasksTemplates(
            appTemplates.data.map((template: any) => ({
              id: String(template.id),
              status: String(template.status),
              title: String(template.title),
              description: String(template.description),
            }))
          )
        }
      } catch (err: any) {
        console.log("error fetch job tasks>>", err.message)
      }
    }, 200)

    return () => clearTimeout(fetchTemplateTimer)
  }, [auth])

  const revokeJobProposal = useCallback(async (param: any) => {
    // const jobs = await jobProposalRevoke(param)
    // if (Array.isArray(jobs?.data)) {
    //   setJobs(jobs.data)
    // }
  }, [])

  const handleDeleteJob = useCallback(async (param: any) => {
    if (auth) {
      const headers = {
        Authorization: `Bearer ${auth.accessToken}`,
      }
      await deleteJob(param, headers)
      fetchJobs()
      return true
    }
  }, [auth])
  const siteRootUrl: string = window.location.origin;
  // const paymentReceivedEmailHandler = useCallback(
  //   async (jobId: any) => {
  //     let INVITE: any;
  //     let customer_name = auth?.user?.name
  //     let customerFirstName = customer_name?.split(" ")?.shift();

  //     let extractedJob = jobs.filter((job: any) => job.id === jobId)[0]
  //     INVITE = paymentTemplate.body.replaceAll("[Customer Name]", customerFirstName || customer_name).replaceAll("[Payment]", "$" + extractedJob?.deposit?.toFixed(2)).replaceAll("[Payment Type]", "deposit");

  //     try {
  //       const emailObj = {
  //         to: auth?.user?.email,
  //         subject: paymentTemplate.subject,
  //         text: INVITE,
  //       }
  //       const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
  //       // toast.success(' successfully', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //       // setResendEmail({
  //       //   status:true,
  //       //   email:staff.email
  //       // })
  //     } catch (err: any) {
  //       console.log(err?.message)
  //       // toast.success('Something went wrong!!!', {
  //       //   position: toast.POSITION.BOTTOM_CENTER,
  //       // })
  //     }

  //     // console.log(INVITE)
  //   }, [auth, paymentTemplate, jobs]
  // )

  const buildJobEmailHandler = useCallback(
    async () => {
      let INVITE: any;
      let customer_name = auth?.user?.name
      let customerFirstName = customer_name?.split(" ")?.shift();

      INVITE = template.body.replaceAll("[Customer Name]", customerFirstName || customer_name);

      try {
        const emailObj = {
          to: auth?.user?.email,
          subject: template.subject,
          text: INVITE,
        }
        const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
        // toast.success(' successfully', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
        // setResendEmail({
        //   status:true,
        //   email:staff.email
        // })
      } catch (err: any) {
        console.log(err?.message)
        // toast.success('Something went wrong!!!', {
        //   position: toast.POSITION.BOTTOM_CENTER,
        // })
      }

      // console.log(INVITE)
    }, [auth, template]
  )

  const createJobTasks = async (jobId: any) => {
    let extractedJob = selectedJobDataPayment;
    const headers = {
      Authorization: `Bearer ${auth?.accessToken}`,
    }
    console.log(extractedJob);

    let allPromises: any = [];

    jobTasksTemplates.map((taskTemp: any) => {
      let promise = new Promise(async (resolve, reject) => {
        try {
          const payload = {
            jobId: extractedJob.id,
            title: taskTemp.title,
            description: taskTemp.description,
            status: taskTemp.status,
            assignee: '',
            reporter: 'Reporter',
            set_priority: 'Low',
            estimated_time: '1h 30min',
            logged_time: '1h 30min',
          }
          let data = await client.service(SERVICES.TASK).create(payload, { headers: headers })
          resolve(data)
        } catch (err) {
          reject(err)
        }
      })
      allPromises.push(promise)
    })

    Promise.all(allPromises).then(async (data) => {

      await paymentReceivedEmailHandler("deposit");
      await buildJobEmailHandler();

      toast.success('Success', {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      setTimeout(() => {
        // window.location.reload()
        setPaymentLoader(false);
        navigate(LINKS.PAYMENT_SUCCESS)
      }, 500);
    });

  }

  const isJobActiveHandler = useCallback(
    async (jobId: any) => {
      const jobobj = {
        isJobActive: true,
        // jobStatusChanged: true,
        depositReceived: true,
        jobStatus: "in_progress",
      }


      try {
        await client.service(SERVICES.JOBS).patch(jobId, jobobj)

        await createJobTasks(jobId)
        // setIsJobActive(status)

      } catch (err) {
        toast.error('Something went wrong!!!', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // setIsJobActive(false)
        console.log(err)
      }
    }, [template, auth, jobTasksTemplates, createJobTasks]
  )

  const deleteJobPendingtasks = async (jobId: any) => {
    try {
      const query = {
        jobId: jobId,
      }
      const getAllTask = await client.service(SERVICES.TASK).find({ query })
      if (Array.isArray(getAllTask?.data)) {
        let pendingTasks = getAllTask?.data.filter((item: any) => item.status === 'review')
        let promiseArray: any = [];
        pendingTasks.map((task: any) => {
          const promise = new Promise(async (resolve, reject) => {
            try {
              const response = await client.service(SERVICES.TASK).remove(task.id)
              resolve(true)
            } catch (err) {
              reject(false)
            }
          })
          promiseArray.push(promise)
        })

        Promise.all(promiseArray).then(async (result) => {
          // await fetchJob();
          // await fetchTask();
          return true
        })
      }
    } catch (error: any) {
      console.log(error?.message)
    }
  }

  const fullPaymentHandler = useCallback(
    async (jobId: any) => {
      const jobobj = {
        finalPaymentReceived: true
      }

      try {
        await client.service(SERVICES.JOBS).patch(jobId, jobobj)
        await paymentReceivedEmailHandler("final");
        if (selectedJobDataPayment.buildingFinished) {
          await activeJobEmailHandler();
          await deleteJobPendingtasks(jobId);
        }
        setTimeout(() => {
          setPaymentLoader(false)
          navigate(LINKS.PAYMENT_SUCCESS)
        }, 1000);

      } catch (err) {
        toast.error('Something went wrong!!!', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        // setIsJobActive(false)
        console.log(err)
      }
    }, [auth, activeTemplate, paymentTemplate, selectedJobDataPayment]
  )

  if (loading) {
    return null
  }

  if (isTemp) {
    return null;
  }

  const businessModal = (
    <div className='payment-modal-main'>
      <h4>Business name</h4>
      <p></p>
      <TextField
        label="This name will be used for your website and promotional material."
        id="password"
        placeholder="Type business name..."
        type="text"
        name="password"
        onChange={(e: any) => setBusinessName(e.target.value)}
        // onBlur={handleBlur}
        value={businessName}
      // errors={errors}
      />
      {showBusinessError && <p style={{ color: "red" }}>Required</p>}
    </div>
  )

  const businessModalActionsJSX = (
    <button className="primary button" onClick={async () => {
      if (businessName.trim().length === 0) {
        setShowBusinessError(true)
        return;
      }
      if (auth !== null) {
        try {
          const data = await client.service(SERVICES.USERS).patch(auth.user._id, {
            businessName
          })
          setShowBusinessNameModal(false)
          toast.success('Business name updated successfully', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } catch (err: any) {
          toast.error('Something went wrong!!!', {
            position: toast.POSITION.BOTTOM_CENTER,
          })
        }
      }
    }} >Update</button>
  )
console.log("auth",auth);

  const rightSlot = '';


  //<button className='button primary new' onClick={() => navigate(LINKS.CUSTOMER_ONBOARDING)}>
  //   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <path d="M9.50416 0.979004C9.60541 0.979004 9.68749 1.06109 9.68749 1.16234V8.31234H16.8375C16.9387 8.31234 17.0208 8.39442 17.0208 8.49567V9.504C17.0208 9.60526 16.9387 9.68734 16.8375 9.68734H9.68749V16.8373C9.68749 16.9386 9.60541 17.0207 9.50416 17.0207H8.49582C8.39457 17.0207 8.31249 16.9386 8.31249 16.8373V9.68734H1.16249C1.06124 9.68734 0.979156 9.60526 0.979156 9.504V8.49567C0.979156 8.39442 1.06124 8.31234 1.16249 8.31234H8.31249V1.16234C8.31249 1.06109 8.39457 0.979004 8.49582 0.979004H9.50416Z" fill="white" />
  //   </svg>

  //   Create new
  // </button>

  



  return (
    <div className="jobs" {...props}>
      {/* <h4 className='page-title' >My apps</h4> */}
      <PageHeader
        heading="Apps"
        rightSlot={rightSlot}
      // showBack
      />
      <div className="horizontal-columns-jobs">
        <div className="column-job">
          <div className="column-header">
            <h2 className="column-heading" >New</h2>
            {/* <TooltipInfo title={"New Jobs"} description={"Description"} /> */}
          </div>
          <div className="column-body">
            {jobs.filter((job: any) => job.isSubmitted && !job?.depositReceived).length > 0 ? (
              // <JobsListing
              //   jobs={jobs.filter((job) => job.isSubmitted && (job?.jobStatus === undefined || job?.jobStatus === "new") && !(job?.isJobActive !== false))}
              //   onClickRevokeJob={(e) => revokeJobProposal(e)}
              //   onClickSortJob={''}
              //   handlePinJob={''}
              //   handleUnpinJob={''}
              //   fetchjobs_fn={fetchJobs}
              //   handleDeleteJob={''}
              //   hideHeader={true}
              // />
              jobs.filter((job: any) => job.isSubmitted && !job?.depositReceived).map((job, index) => (
                <NewJobItem
                  key={index}
                  job={job}
                  handleDeleteJob={() => handleDeleteJob(job.id)}
                  // changeJobStatusHandler={(paymentMethodId: string) => paymentHandler(job.id, paymentMethodId)}
                  paymentHandler={() => {
                    setShowPaymentModal(true)
                    setSelectedJobDataPayment(job)
                  }}
                />
              ))
            ) : (
              <NoData
                icon={jobsIcon}
                title="No job is added yet"
              // cta={
              //   <Link to={LINKS.JOBS_CREATE}>
              //     <Button>Create Job</Button>
              //   </Link>
              // }
              />
            )}
          </div>
        </div>
        <div className="column-job">
          <div className="column-header">
            <h2 className="column-heading" >Building</h2>
            {/* <TooltipInfo title={"Building Jobs"} description={"This section will show apps that Postal apps are currently creating for you. Open the pending tasks page to view the apps progress."} /> */}
          </div>
          <div className="column-body">
            {jobs.filter((job: any) => job.isSubmitted && job.depositReceived === true && job.isJobActive && (!job.finalPaymentReceived || !job.buildingFinished)).length > 0 ? (
              <JobsListing
                jobs={jobs.filter((job: any) => job.isSubmitted && job.depositReceived === true && job.isJobActive && (!job.finalPaymentReceived || !job.buildingFinished))}
                onClickRevokeJob={(e) => revokeJobProposal(e)}
                onClickSortJob={''}
                handlePinJob={''}
                handleUnpinJob={''}
                fetchjobs_fn={fetchJobs}
                handleDeleteJob={''}
                hideHeader={true}
                paymentHandler={(job) => {
                  setShowPaymentModal(true)
                  setSelectedJobDataPayment(job)
                }}
              />
            ) : (
              <NoData
                icon={jobsIcon}
                title="No job is added yet"
              // cta={
              //   <Link to={LINKS.JOBS_CREATE}>
              //     <Button>Create Job</Button>
              //   </Link>
              // }
              />
            )}
          </div>
        </div>
        <div className="column-job" style={{ borderRight: 0 }}>
          <div className="column-header">
            <h2 className="column-heading" >Active</h2>
            {/* <TooltipInfo title={"Active Jobs"} description={"Description"} />
            <div className="inactive-jobs-info">
               {jobs.filter((job:any) => job.isSubmitted &&  !job.depositReceived && !job.finalPaymentReceived && !job.buildingFinished).length} Inactive jobs
            </div> */}
          </div>
          <div className="column-body">
            {jobs.filter((job: any) => job.isSubmitted && job.depositReceived && job.finalPaymentReceived && job.buildingFinished).length > 0 ? (
              <JobsListing
                jobs={jobs.filter((job: any) => job.isSubmitted && job.depositReceived && job.finalPaymentReceived && job.buildingFinished)}
                onClickRevokeJob={(e) => revokeJobProposal(e)}
                onClickSortJob={''}
                handlePinJob={''}
                handleUnpinJob={''}
                fetchjobs_fn={fetchJobs}
                handleDeleteJob={''}
                hideHeader={true}
              />
            ) : (
              <NoData
                icon={jobsIcon}
                title="No job is added yet"
              // cta={
              //   <Link to={LINKS.JOBS_CREATE}>
              //     <Button>Create Job</Button>
              //   </Link>
              // }
              />
            )}
          </div>
        </div>
      </div>
      <BaseModal
        isOpen={showBusinessNameModal}
        children={businessModal}
        actionButtons={businessModalActionsJSX}
        handleClose={() => { }}
      />

      <BaseModal
        className='paymentModal'
        isOpen={showPaymentModal}
        children={paymentModalJSX}
        actionButtons={paymentModalActionsJSX}
        handleClose={() => setShowPaymentModal(false)}
      />

    </div>

  )
}

const TooltipInfo = ({ title = "Title", description = "Description", }: any) => {
  const [show, setShow] = useState(false);


  return (
    <div style={{ position: "relative", width: "fit-content" }}>
      <img src={questionIcon} onClick={() => setShow(true)} style={{ cursor: "pointer", marginBottom: -4, marginLeft: 5 }} />
      {show &&
        <div
          style={
            {
              position: "absolute",
              top: 0,
              left: 30,
              background: "darkgreen",
              padding: "10px",
              borderRadius: " 10px",
              minWidth: "250px",
              color: "white"
            }
          }
        >
          <button
            className='cross-btn'
            style={{
              background: "transparent",
              border: "none",
              color: "white",
              marginLeft: "auto",
              display: "block",
              width: "fit-content",
              cursor: "pointer",
              position: "absolute", top: 10, right: 10
            }}
            onClick={() => setShow(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
            </svg>
          </button>
          <h6 style={{ fontWeight: 400 }}>{title}</h6>
          <p>{description}</p>
        </div>
      }
    </div>
  )
}

const NewJobItem = ({ job, handleDeleteJob, paymentHandler }: any) => {
  const navigate = useNavigate()
  const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [image, setImage] = useState(DEFAULT_AVATAR);



  useEffect(() => {
    const fetchTemplateImage = setTimeout(async () => {
      if (job.appImage) {
        const response: any = await axiosInstance.get(`getSignedFileUrl/?fileName=${job.appImage}`)
        if (response?.status) {
          // console.log(response.data.url);
          setImage(response.data.url)
        }
      }
    }, 200)

    return () => clearTimeout(fetchTemplateImage)
  }, [])

  const kebabMenuItems = useMemo(() => {

    let items = [
      // {
      //   label: 'Job details',
      //   icon: checklistIcon,
      //   disabled: false,
      //   onClick: () => {
      //     navigate(getJobDetailsUrl(job.id));
      //   },
      // },
      {
        label: 'Show info',
        icon: infoIcon,
        disabled: false,
        onClick: () => {
          setShowInfoModalOpen(!showInfoModalOpen);
        },
      },
      {
        label: 'Delete',
        icon: deleteIcon,
        disabled: false,
        onClick: () => {
          setDeleteOpen(true);
        },
      },
    ];


    return items;
  }, [job, navigate]);


  return (
    <div className='new-job-item'>
      <div className='new-job-left'>
        <div className='new_job_image'>
          {image ? (
            <img src={image} alt="Job Image" />
          ) : (
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 1V7C9.99855 8.1522 9.59991 9.26868 8.87128 10.1613C8.14265 11.0538 7.12859 11.6679 6 11.9V23C6 23.2652 5.89464 23.5196 5.70711 23.7071C5.51957 23.8946 5.26522 24 5 24C4.73478 24 4.48043 23.8946 4.29289 23.7071C4.10536 23.5196 4 23.2652 4 23V11.9C2.87141 11.6679 1.85735 11.0538 1.12872 10.1613C0.40009 9.26868 0.00145452 8.1522 0 7V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0C1.26522 0 1.51957 0.105357 1.70711 0.292893C1.89464 0.48043 2 0.734784 2 1V7C2.00256 7.61826 2.19608 8.22063 2.55409 8.7247C2.91209 9.22877 3.41709 9.60992 4 9.816V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V9.816C6.58291 9.60992 7.08791 9.22877 7.44591 8.7247C7.80392 8.22063 7.99744 7.61826 8 7V1C8 0.734784 8.10536 0.48043 8.29289 0.292893C8.48043 0.105357 8.73478 0 9 0C9.26522 0 9.51957 0.105357 9.70711 0.292893C9.89464 0.48043 10 0.734784 10 1ZM20 10C19.9553 11.9004 19.4825 13.7663 18.6168 15.4587C17.7511 17.1512 16.5148 18.6265 15 19.775V23C15 23.2652 14.8946 23.5196 14.7071 23.7071C14.5196 23.8946 14.2652 24 14 24C13.7348 24 13.4804 23.8946 13.2929 23.7071C13.1054 23.5196 13 23.2652 13 23V2C12.991 1.62426 13.0937 1.25431 13.295 0.936929C13.4963 0.619551 13.7873 0.369011 14.131 0.217C14.5258 0.0563652 14.9589 0.0142302 15.3773 0.0957442C15.7957 0.177258 16.1813 0.378891 16.487 0.676C18.8064 3.22095 20.0634 6.5573 20 10ZM18 10C18.0284 7.07215 16.9579 4.24008 15 2.063V17.07C16.8741 15.1882 17.9489 12.6553 18 10Z"
                fill="#00B8D9"
              />
            </svg>
          )}
        </div>

        <div className='new_job_content'>
          <h4 className='title'>{job.appTitle}</h4>
          <div className='job_middle_content'>
            <p className='description'>{job.appIndustry}</p>
            {/* <p className='username'>{job.customerName}</p> */}
            <span className='spacer' style={{ display: 'block', width: '1px', background: '#B7C5CC', height: '20px' }}></span>
            <p className='description'>0%</p>
            <p className='job_status' style={{ background: '#FFD3D3', color: '#A13030' }}>New</p>
            <button className='primary button new small' onClick={() => paymentHandler()}>Payment</button>
          </div>
          <div className='job_middle_content'  style={{ margin: '15px 0 0' }}>
            <button className='primary button new small' >How to build this app</button>
            <button className='primary button new small' >Edit</button>
          </div>
          {/* <span className='spacer' style={{ display: 'block', height: '15px' }}></span>
          <p className='business' style={{ color: '#60747E' }}>{job?.customerBusinessName}</p> */}
        </div>
      </div>
      <div className='new-job-right'>
        <div className='new-job-right-inner'>
          {/* <div className="progress-badge" style={{background: "rgba(255, 0, 0, 0.12)", color: "red", marginRight: '10px'}}>Inactive</div> */}
          <KebabMenu items={kebabMenuItems} />
        </div>
        <DeleteModal
          isOpen={deleteOpen}
          handleClose={() => {
            setDeleteOpen(false)
          }}
          body={<ModalPreviewJobTemplate job={job} image={image} />}
          handleDelete={async () => {
            if (await handleDeleteJob()) {
              setDeleteOpen(false)
            }
          }}
          title="Delete job"
          subtitle="Do you wish to delete below job?"
        />
        <BaseModal
          isOpen={showInfoModalOpen}
          children={<ShowInfoModal job={job} />}
          handleClose={() => setShowInfoModalOpen(!showInfoModalOpen)}
        />


      </div>
    </div>
  )
}

export default CustomerDashboard
