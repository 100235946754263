import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { SERVICES, URL_PATH, crypto_secret } from 'utils/constants'
import { USER_ROLES } from 'utils/constants'

import EmailSent from 'components/ActionModal/components/EmailSent'
import EmailPreview from 'components/EmailPreview/EmailPreview'
import Button from 'components/forms/Button/Button'
import SelectField from 'components/forms/SelectField/SelectField'
import TextField from 'components/forms/TextField/TextField'
import axios, { AxiosRequestConfig } from 'axios';

// import { INVITE } from '../StaffInvite'
import './staffInviteForm.sass'
import CryptoJS from 'crypto-js';

interface IAddStaffFormData {
  full_name: string
  email: string
  role: string
}

interface IAddStaffForm {
  INVITE?: string
}

const submitInviteStaff = async (
  values: IAddStaffFormData,
  actions: FormikHelpers<IAddStaffFormData>,
  INVITE:any,
  subject:any
) => {
  try {
 
    const data = await client.service(SERVICES.USERS).create({
      name: values.full_name,
      email: values.email,
      permissions: [values.role],
      isExpired: false,
    })
    toast.success('Staff added successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    // const dynamicQuery = {
    //   title: 'Add Staff',
    // }
    // const template = await client
    //   .service(SERVICES.EMAIL_TEMPLATES)
    //   .find({ query: dynamicQuery })

    // if (template?.data?.length > 0) {
    //   const tempStr = template?.data[0]
    //   tempStr.body = tempStr.body
    //     .replaceAll('[user_name]', values.full_name ?? '')
    //     .replaceAll('[link]', window.location.origin)
    //   const emailObj = {
    //     to: values.email, // Replace with your email address
    //     subject: tempStr.subject,
    //     text: tempStr.body,
    //   }
    //   const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
    // }
    const cu_user_id = (data && data._id) as string;
    const userDatas = [{ email: data && data.email}, { name: data && data.name}, { userid: cu_user_id}]
    var encryptedData = CryptoJS.AES.encrypt(JSON.stringify(userDatas), crypto_secret).toString();
    const StaffInvitation = INVITE.replaceAll(
      '[Full Name]',
      values.full_name ?? ''  
    )
      .replaceAll('[Role]', values.role)
      .replaceAll('[Link]', `${window.location.origin}/staff/invitation?e=${encryptedData}`)
      .replaceAll('[AcceptInvitation]', `${window.location.origin}/staff/invitation?e=${encryptedData}`)

    const emailObj = {
      to: values.email,
      subject: subject,
      text: StaffInvitation,
    }
    const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)

    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}

const InviteSuccess = ({ email }: { email: string }) => {
  return (
    <div className="invite-success">
      <EmailSent email={email} />
      <Link to={LINKS.STAFF}>
        <Button>Go to Staff</Button>
      </Link>
    </div>
  )
}

const FormikForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isSuccess,
  INVITE
}: any) => {
  const navigate = useNavigate()


  const buildEmail = useMemo(() => {
    let template = INVITE;
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        if(key === "full_name"){
          let firstName =  values[key].split(" ").shift()
          template = template.replaceAll(`[Full Name]`, firstName || values[key])
        }
        template = template.replaceAll(`[${key}]`, values[key])
      }
    })
    return template
  }, [values, INVITE])

  const selectData = [
    { id: 1, value: 'administrator', title: USER_ROLES['administrator'] },
    { id: 2, value: 'user', title: USER_ROLES['user'] },
    // { id: 2, value: 'graphic-designer', title: USER_ROLES['graphic-designer'] },
    // {
    //   id: 3,
    //   value: 'frontend-developer',
    //   title: USER_ROLES['frontend-developer'],
    // },
    // {
    //   id: 4,
    //   value: 'backend-developer',
    //   title: USER_ROLES['backend-developer'],
    // },
  ]

  return (
    <div className="add-staffa-form-container half-container">
      <div className="left">
        {isSuccess ? (
          <InviteSuccess email={values.email} />
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              label="Full name"
              id="full_name"
              placeholder="Type your full name..."
              type="text"
              name="full_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.full_name}
              errors={errors}
              required
            />
            <TextField
              label="email"
              id="email"
              placeholder="Type email..."
              type="text"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errors={errors}
              required
            />
            <SelectField
              label="Role"
              id="role"
              placeholder="Select"
              type="text"
              name="role"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.role}
              errors={errors}
              selectData={selectData}
              required
            />
            <div className="buttons-container right">
              <Button
                className= 'button medium-btn cancel-btn'
                type="button"
                disabled={isSubmitting}
                theme="secondary"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button className='medium-btn' type="submit" disabled={isSubmitting}>
                Send invitation email
              </Button>
            </div>
          </form>
        )}
      </div>
      <div className="right">
        <EmailPreview body={buildEmail} />
      </div>
    </div>
  )
}

const StaffInviteForm = ({ ...props }: IAddStaffForm) => {
  
  const handleSubmit = useCallback(submitInviteStaff, [])
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const [templateData, setTemplateData] = useState<any>({});
  const [template, setTemplate] = useState<string>("");
  useEffect(()=>{

    const fetchTemplateTimer = setTimeout(async()=>{
      const configE: AxiosRequestConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          "type": "invite_staff"
        }),
      };
    

      try{
        const templateResult = await axios.request(configE);
        if(templateResult.data.status === 200){
          setTemplate(templateResult.data.template.data[0].body)
          setTemplateData(templateResult.data.template.data[0])
        }else{
          console.log("Template not found !!!")
        }
      }
      catch(err:any){
        console.log("Something went wrong!!!", err.message)
      }
    },200)

    return () => clearTimeout(fetchTemplateTimer)
  },[])

  interface IStaffInviteFormProps {
    staff: any
  }

  const onSubmit = useCallback(
    async (
      values: IAddStaffFormData,
      actions: FormikHelpers<IAddStaffFormData>
    ) => {
      const data = await handleSubmit(values, actions, template, templateData.subject)
      if (data) {
        setEmailSent(true)
      }
    },
    [handleSubmit, crypto_secret, template]
  )

  
  return (
    <Formik
      initialValues={{
        full_name: '',
        email: '',
        role: '',
      }}
      validate={(values: IAddStaffFormData) => {
        const errors: any = {}
        if (!values.full_name) {
          errors.full_name = 'Required'
        }

        if (!values.email) {
          errors.email = 'Required'
        }

        if (!values.role) {
          errors.role = 'Required'
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({ ...props }) => <FormikForm {...props} isSuccess={emailSent} INVITE={template}/>}
    </Formik>
  )
}

export default StaffInviteForm
