import React from 'react'

import BaseModal from './BaseModal'

interface IActionModalProps {
  isOpen: boolean
  handleClose: () => void
  actionButtons?: React.ReactNode
  body?: React.ReactNode
  title?: string
  subtitle?: string
}

const ActionModal = ({
  isOpen,
  handleClose,
  actionButtons = null,
  body,
  title = '',
  subtitle = '',
  ...props
}: IActionModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtons={actionButtons}
    >
      <div className="modal-header">
        <h5 className="title">{title}</h5>
        <div className="subtitle">{subtitle}</div>
      </div>
      {body && <div className="modal-body">{body}</div>}
    </BaseModal>
  )
}

export default ActionModal
