import { ChangeEvent } from 'react'
import { toast } from 'react-toastify'

import axios from 'axios'
import client from 'feathers-client'
import { SERVICES, URL_PATH } from 'utils/constants'

export const pinJobs = async (param: any) => {
  try {
    await client.service(SERVICES.JOBS).patch(param, { pinned: true })
    const data = await client.service(SERVICES.JOBS).find()
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const unpinJobs = async (param: any) => {
  try {
    await client.service(SERVICES.JOBS).patch(param, { pinned: false })
    const data = await client.service(SERVICES.JOBS).find()
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const jobProposalSorting = async (param: any, option: any) => {
  try {
    const data = await client.service(SERVICES.JOBS).find({
      query: {
        // search: 'af',
        $sort: {
          [option]: param,
        },
      },
    })

    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const jobProposalRevoke = async (param: any) => {
  try {
    
    
    await client.service(SERVICES.JOBS).patch(param.id, {})
    const data = await client.service(SERVICES.JOBS).find()

    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const jobFetchingSort = async () => {
  try {
    const data = await client.service(SERVICES.JOBS).find({
      query: {
        // search: 'af',
        $sort: {
          invited: -1,
        },
      },
    })

    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const jobFetchingSearch = async (auth: any) => {
  try {
    const data = await client.service(SERVICES.JOBS).find({
      query: {
        search: auth?.data,
      },
    })

    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const deleteJob = async (param: any, headers: any) => {
  try {
    // console.log("dsfsdfs")
    await client.service(SERVICES.JOBS).remove(param, { headers: headers })
    toast.success('Job deleted successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    // const data = await client.service(SERVICES.JOBS).find({
    //   query: {
    //     search: auth?.data,
    //   },
    // })

    // return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const deleteTask = async (
  editId: string,
  setLoading: (e: any) => void,
  navigate: any
) => {
  setLoading(true)
  const response = await client.service(SERVICES.TASK).remove(editId)
  if (response) {
    toast.success('Task deleted successfully', {
      autoClose: 600,
      position: toast.POSITION.BOTTOM_CENTER,
    })
    setLoading(false)
    navigate(-1)
  } else {
    toast.error('Try again', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    setLoading(false)
  }
}

export const editTask = async (
  editId: string,
  payload: any,
  headers: any,
  setLoading: (e: any) => void,
  navigate: any
) => {
  try {
    const response = await client
      .service(SERVICES.TASK)
      .patch(editId, payload, { headers: headers })
    if (response) {
      toast.success('Task updated successfully', {
        autoClose: 600,
        position: toast.POSITION.BOTTOM_CENTER,
      })
      setLoading(false)
      navigate(-1)
    }
  } catch (error) {
    console.error('Error making API call:', error)
    setLoading(false)
  }
}

export const uploadFiles = async (
  event: ChangeEvent<HTMLInputElement>,
  setFilelist: (e: any) => void,
  fileList: any
) => {
  const files = event.target.files
  if (files && files.length > 0) {
  // const uploadedFiles = []
    // for (let i = 0; i < files.length; i++) {
    //   uploadedFiles.push(files[i].name)
    // }
    const fileListArray = Array.from(files)
    let images: any[] = [];
    const promises = fileListArray?.map((file: any) => {
      return new Promise<void>((resolve) => {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            const base64Image = e.target?.result as string;
            const image = {
              base64: base64Image,
              name: file.name,
              size: file.size,
              type: file.type,
            };
            images.push(image);
            resolve(); // Resolve the promise when the image is processed
          };
          reader.readAsDataURL(file);
        } else {
          resolve(); // Resolve the promise if there's no file
        }
      });
    });

    // Wait for all promises to resolve before sending the POST request
    await Promise.all(promises);

    try {
      const response = await axios.post(
        `${URL_PATH.CLIENT1}uploads`,
        images
      );
      setFilelist([...fileList, ...response.data.data])
    } catch (error) {
      console.error('Upload error:', error);
    }

  }
}

export const removeFile = (
  file: any,
  fileList: any,
  setFilelist: (e: any) => void
) => {
  if (file) {
    const data = fileList.filter((val: any) => val !== file)
    setFilelist(data)
  }
}

export const getFileUrl = async (strs: string[]) => {
  try {
    let urls: any = []
    if (strs?.length > 0) {
      for (const file of strs) {
        const response = await axios.post(`${URL_PATH.FILE1}getSignedFileUrl`, {
          fileName: file,
        })
        urls.push(response.data.url)
      }
    }
    return urls
  } catch (error) {
    console.error('getSignedFileUrl error:', error)
    return []
  }
}

export const createTask = async (
  payload: any,
  headers: any,
  setLoader: (e: any) => void,
  navigate: any,
  setIsButtonEnabled: (e: any) => void
) => {
  try {
    await client.service(SERVICES.TASK).create(payload, { headers: headers })
    setLoader(false)
    navigate(-1)
    toast.success('Task created successfully', {
      autoClose: 800,
      position: toast.POSITION.BOTTOM_CENTER,
    })
  } catch (error) {
    setLoader(false)
    setIsButtonEnabled(false)
    console.error('Error making API call:', error)
  }
}

export const getAllTaskActivities = async (
  editId: string,
  setActivityList: (e: any) => void
) => {
  const query = {
    taskId: editId,
    $sort: {
      createdAt: -1,
    },
  }
  const res = await client.service('activity').find({ query })
  if (Array.isArray(res.data)) {
    setActivityList(res.data)
  }
}
