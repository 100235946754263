import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { TGenericObject } from 'types/base'

import UploadAvatar from 'components/Avatar/UploadAvatar'
import Button from 'components/forms/Button/Button'
import TextArea from 'components/forms/TextArea/TextArea'
import TextField from 'components/forms/TextField/TextField'
import SelectField from 'components/forms/SelectField/SelectField'
import EmailPreview from 'components/EmailPreview/EmailPreview'


export interface IAddAppTemplateFormData {
  title: string
  description: string,
  status: string
}

interface IAppTemplateForm {
  handleSubmit: (
    values: IAddAppTemplateFormData,
    actions: FormikHelpers<IAddAppTemplateFormData>,
    id?:any
  ) => boolean | TGenericObject
  initialValues?: IAddAppTemplateFormData | null
}

const TaskTemplateForm = ({ handleSubmit, initialValues }: IAppTemplateForm) => {

  const navigate = useNavigate()
  const location = useParams()

  const onSubmit = useCallback(
    async (
      values: IAddAppTemplateFormData,
      actions: FormikHelpers<IAddAppTemplateFormData>
    ) => {
      if(Object.keys(location).length > 0){
        const data = await handleSubmit(values, actions, location.templateId)
        if (data) {
          navigate(LINKS.TEMPLATES_TASK)
        }

    }else{
        const data = await handleSubmit(values, actions )
       
        if (data) {
          navigate(LINKS.TEMPLATES_TASK)
        }
      }

    },
    [handleSubmit, navigate,  location]
  )

  const computedInitialValues = useMemo(() => {
    if (initialValues) {
      return {
        title: initialValues.title,
        description: initialValues.description,
        status: initialValues.status
      }
    } else {
      return {
        // icon: '',
        title: '',
        description: '',
        status: ''
      }
    }
  }, [initialValues])

  const selectData = [
    {
      id: 0,
      value: 'review',
      title: 'Review',
    },
    {
      id: 1,
      value: 'in_progress',
      title: 'In Progress',
    },
    {
      id: 2,
      value: 'finished',
      title: 'Finished',
    },
  ]
  
  
  return (
    <Formik
      initialValues={computedInitialValues}
      enableReinitialize
      validate={(values: IAddAppTemplateFormData) => {
        const errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        }

        if (!values.description) {
          errors.description = 'Required'
        }

        if (!values.status) {
          errors.status = 'Required'
        }

        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const wordCount = values.description
          ? String(values.description).split(' ').length
          : 0
        return (
          <div className="form-container">
            <div className="left">
              <form onSubmit={handleSubmit}>
              
                <TextField
                  label="Title"
                  id="app-type-title-input"
                  placeholder="Type app type title..."
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  errors={errors}
                  required
                />
                <TextArea
                  label="Short description"
                  id="app-type-description-input"
                  placeholder="Type app type description..."
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  errors={errors}
                  required
                />
                <SelectField
                    label="Status"
                    placeholder="Select status"
                    type="text"
                    id="status"
                    name="status"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    errors={errors}
                    selectData={selectData}
                    required
                />

                <div className="buttons-container right">
                  <Button
                    className='button medium-btn cancel-btn'
                    type="button"
                    disabled={isSubmitting}
                    theme="secondary"
                    style={{
                      marginRight: '12px',
                    }}
                    onClick={()=>navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button className='button medium-btn' type="submit" disabled={isSubmitting}>
                    Done
                  </Button>
                </div>
              </form>
            </div>
            <div className="right">
            {(()=>{
                let previewJSX = `Title:
${values.title}

Short description:
${values.description}

Status:
${values.status}`
                 
                
                return (
                  <EmailPreview body={previewJSX} />
                )
              })()}
            </div>
          </div>
        )
      }}
    </Formik>
  )
}

export default TaskTemplateForm
