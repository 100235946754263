import { toast } from 'react-toastify'

import client from 'feathers-client'
import { FormikHelpers } from 'formik'
import { TGenericObject } from 'types/base'
import { SERVICES, URL_PATH } from 'utils/constants'

import { IAddAppTemplateFormData } from './forms/AppTemplateForm'
import { IEmailTemplateFormData } from './forms/EmailTemplateForm'
import axios from 'axios'

export const getAppTemplate = async (templateId: string) => {
  try {
    const data = await client.service(SERVICES.APP_TEMPLATES).get(templateId)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}
export const getTaskTemplate = async (templateId: string) => {
  try {
    const data = await client.service(SERVICES.TASK_TEMPLATES).get(templateId)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const uploadImagesHandler = async (fileListArray:any)=>{
  if(fileListArray?.length > 0){
    // let images:any = [];
    // const base64Container = await attachments.map((file:any)=>{
    //   const reader = new FileReader();
    //   reader.onload = (e: ProgressEvent<FileReader>) => {
    //     const base64Image = e.target?.result as string;
    //     const image = {
    //       base64: base64Image,
    //       name: file.name,
    //       size: file.size,
    //       type: file.type,
    //     };
    //     images.push(image);
    //     // return image;
    //     // resolve(); // Resolve the promise when the image is processed
    //   };
    //   reader.readAsDataURL(file);
    // })

    let images: any[] = [];
          const promises = fileListArray?.map((file: any) => {
            return new Promise<void>((resolve) => {
              if (file) {
                const reader = new FileReader();
                reader.onload = (e: ProgressEvent<FileReader>) => {
                  const base64Image = e.target?.result as string;
                  const image = {
                    base64: base64Image,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                  };
                  images.push(image);
                  resolve(); // Resolve the promise when the image is processed
                };
                reader.readAsDataURL(file);
              } else {
                resolve(); // Resolve the promise if there's no file
              }
            });
          });

          // Wait for all promises to resolve before sending the POST request
          await Promise.all(promises);

    try {
      // console.log("response images >>>>", images)
      const response = await axios.post(
        `${URL_PATH.CLIENT1}uploads`,
        images
      );
      // console.log("response >>>>", response.data.data, images)
     return response.data.data
    } catch (error) {
      console.error('Upload error:', error);
    }


  }else{
    console.log("Something went wrong!!!")
  }
}

export const submitAppTemplate = async (
  values: IAddAppTemplateFormData,
  actions: FormikHelpers<IAddAppTemplateFormData>,
  attachments: any
) => {
  try {
    const attachmentsNew = await uploadImagesHandler(attachments)
    const data = await client.service(SERVICES.APP_TEMPLATES).create({
      icon: attachmentsNew?.length > 0 ? attachmentsNew[0] : values.icon,
      type: values.type,
      description: values.description,
      deposit: values.deposit,
      yearlyDiscount: values.yearlyDiscount,
      monthlySubscription: values.monthlySubscription,
      finalPayment: values.finalPayment
    })
    toast.success('App template created successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}



export const submitTaskTemplate = async (
  values: {
    title: string
    description: string
    status: string
  },
  actions: FormikHelpers<{
    title: string
    description: string
    status: string
  }>,

) => {
  // return  values
  try {
    const data = await client.service(SERVICES.TASK_TEMPLATES).create({
      title: values.title,
      description: values.description,
      status: values.status,
    })
    toast.success('Task template created successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    console.log(error.message)
    return false
  }
}

export const updateTaskTemplate = async (
  values: {
    title: string
    description: string
    status: string
  },
  actions: FormikHelpers<{
    title: string
    description: string
    status: string
  }>,
  id: string,
) => {
  try {

    const data = await client.service(SERVICES.TASK_TEMPLATES).patch(id, {
      title: values.title,
      status: values.status,
      description: values.description,
    })
    toast.success('Task template updated successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}
export const updateAppTemplate = async (
  values: IAddAppTemplateFormData,
  actions: FormikHelpers<IAddAppTemplateFormData>,
  attachments: any,
  id: string,
) => {
  try {
    const attachmentsNew = await uploadImagesHandler(attachments)
    const data = await client.service(SERVICES.APP_TEMPLATES).patch(id, {
      icon: attachmentsNew?.length > 0 ? attachmentsNew[0] : values.icon,
      type: values.type,
      description: values.description,
      deposit: values.deposit,
      yearlyDiscount: values.yearlyDiscount,
      monthlySubscription: values.monthlySubscription,
      finalPayment: values.finalPayment
    })
    toast.success('App template updated successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}

export const deleteAppTemplate = async (templateId: string) => {
  try {
    const data = await client.service(SERVICES.APP_TEMPLATES).remove(templateId)
    toast.success('App template deleted successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const deleteTaskTemplate = async (templateId: string) => {
  try {
    const data = await client.service(SERVICES.TASK_TEMPLATES).remove(templateId)
    toast.success('Task template deleted successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const getEmailTemplate = async (templateId: string) => {
  try {
    const data = await client.service(SERVICES.EMAIL_TEMPLATES).get(templateId)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const submitEmailTemplate = async (
  values: IEmailTemplateFormData,
  actions: FormikHelpers<IEmailTemplateFormData>
) => {
  try {
    const data = await client.service(SERVICES.EMAIL_TEMPLATES).create({
      title: values.title,
      subject: values.subject,
      body: values.body,
      type:values.type
    })
    toast.success('Email template created successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}

export const updateEmailTemplate = async (
  id: string,
  values: IEmailTemplateFormData,
   actions: FormikHelpers<IEmailTemplateFormData>
) => {
  try {
    const data = await client.service(SERVICES.EMAIL_TEMPLATES).patch(id, {
      title: values.title,
      subject: values.subject,
      body: values.body,
      type:values.type
    })
    toast.success('Email template updated successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
     actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
     actions.setSubmitting(false)
    return false
  }
}

export const deleteEmailTemplate = async (templateId: string) => {
  try {
    const data = await client
      .service(SERVICES.EMAIL_TEMPLATES)
      .remove(templateId)
    toast.success('Email template deleted successfully', {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    return false
  }
}

export const parseEmailTemplate = (
  template: string,
  formData: TGenericObject
) => {
  return ''
}
