import { ButtonHTMLAttributes } from 'react'

import classNames from 'classnames'

import './button.sass'

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: any
  theme?: 'primary' | 'secondary' | 'transparent'
  fullWidth?: boolean
}

const Button = ({
  children,
  theme = 'primary',
  fullWidth = false,
  className = '',
  ...props
}: IButtonProps) => {
  return (
    <button
      {...props}
      className={classNames(
        theme ? theme : '',
        'button',
        fullWidth ? 'fullwidth' : '',
        className
      )}
    >
      {children}
    </button>
  )
}

export default Button
