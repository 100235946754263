import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import client from 'feathers-client'
import { getPendingJobItemLink } from 'routes/constants'
import { SERVICES, URL_PATH } from 'utils/constants'

import ActionModal from 'components/ActionModal/ActionModal'
import EmailSentModal from 'components/ActionModal/EmailSentModal'
import ModalPreviewAppTemplate from 'components/ActionModal/components/ModalPreviewAppTempate'
import Avatar from 'components/Avatar/Avatar'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import Button from 'components/forms/Button/Button'

import closeIcon from 'assets/close.svg'
import planeIcon from 'assets/paper-plane 1.svg'
import viewIcon from 'assets/view-on.svg'

import { LINKS } from '../../../routes/constants'
import { IJobItem } from '../Jobs'
import axios, { AxiosRequestConfig } from 'axios'

interface IPendingJobItemProps {
  job: IJobItem
  onClickRevokeJob?: (e: any) => void
  fetchjobs_fnitm: (params: any) => any
}

const PendingJobItem = ({
  job,
  onClickRevokeJob,
  fetchjobs_fnitm,
}: IPendingJobItemProps) => {
  const navigate = useNavigate()
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [revokeJobOpen, setRevokeJobOpen] = useState<boolean>(false)

  const closeEmailSent = useCallback(() => {
    setEmailSent(false)
    if (fetchjobs_fnitm) {
      fetchjobs_fnitm(true)
    }
    navigate(LINKS.JOBS_PENDING)
  }, [setEmailSent])

  const closeRevokeJob = useCallback(() => {
    setRevokeJobOpen(false)
  }, [setRevokeJobOpen])

  const revokeJobProposal = useCallback(
    (param: any) => {
      if (onClickRevokeJob) {
        onClickRevokeJob(param)
      }
      closeRevokeJob()
    },
    [closeRevokeJob]
  )

  const onClickEmailSent = useCallback(async (param: any) => {
    const configE: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        "type": "create_job"
      }),
    };
    const template = await axios.request(configE);
    if (template?.data?.template?.data?.length > 0) {
      const tempStr = template?.data?.template?.data[0]
      let customerFirstName =  job.customerName?.split(" ")?.shift();
      tempStr.body = tempStr.body
        .replace('[Customer Name]', customerFirstName || job.customerName)
        .replace('[Link]', `${window.location.origin}/customer/onboarding/${job.id}`)
      const emailObj = {
        to: job.customerEmail,
        subject: tempStr.subject,
        text: tempStr.body,
      }
      await client.service(SERVICES.SENDEMAIL).create(emailObj)
      await client.service(SERVICES.JOBS).patch(param.id, { submitted: true })
    }
    setEmailSent(true)
  }, [])

  const kebabMenuItems = useMemo(
    () => [
      {
        label: 'Revoke',
        icon: closeIcon,
        disabled: false,
        onClick: () => {
          setRevokeJobOpen(true)
        },
      },
      {
        label: 'Resend',
        icon: planeIcon,
        disabled: false,
        onClick: () => {
          onClickEmailSent(job)
        },
      },
      {
        label: 'View details',
        icon: viewIcon,
        disabled: false,
        onClick: () => {
          navigate(getPendingJobItemLink(job.id))
        },
      },
    ],
    [job, navigate]
  )

  return (
    <div className="job-item">
      <div className="left">
        <Avatar
          size={56}
          name={job.name}
          url="https://cdn4.iconfinder.com/data/icons/the-weather-is-nice-today/64/weather_24-512.png"
        />
        <div className="details">
          <div className="title">{job.name}</div>
          <div className="info">
            Sent on {dayjs(job.invited).format('MMM DD, YYYY h:mm A')} to{' '}
            {job.customerName}
          </div>
        </div>
      </div>
      <div className="right">
        <KebabMenu items={kebabMenuItems} />
      </div>
      <EmailSentModal
        isOpen={emailSent}
        handleClose={closeEmailSent}
        email={job.customerEmail}
      />
      <ActionModal
        isOpen={revokeJobOpen}
        handleClose={closeRevokeJob}
        title="Revoke proposal"
        subtitle="Do you wish to revoke the below job proposal?"
        actionButtons={
          <>
            <Button theme="secondary" onClick={closeRevokeJob}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => revokeJobProposal(job)}
            >
              Revoke
            </Button>
          </>
        }
        body={
          <ModalPreviewAppTemplate
            title={job.name}
            subtitle={` Sent on ${dayjs(job.invited).format(
              'MMM DD, YYYY h:mm A'
            )} to 
            ${job.customerName}`}
          />
        }
      />
    </div>
  )
}

export default PendingJobItem
