import { useContext, useState } from "react";
import { Select } from "antd";
import { taskStatus } from "utils/constants";
import { getTaskEditDetailsUrl } from "routes/constants";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";
import { editTask } from "pages/jobs/utils";
import { toast } from 'react-toastify'


const PendingTaskItem = ({task, job}: any) => {
    const [selectedValue, setSelectedValue] = useState<string>(task?.status || '')
    // const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const auth = useContext(AuthContext)
    const editId = task.id;

    const handleEditTask = async (selectedStatus:any) => {
        if (task.title.length && task.title.trim() != '') {
          // setLoading(true)
          if (auth && editId) {
            const headers = {
              Authorization: `Bearer ${auth.accessToken}`,
            }
            const payload = {
              ...task,
              status: selectedStatus
            }
            editTask(editId, payload, headers, ()=>{}, navigate)
          } else {
            // setLoading(false)
          }
        } else {
          toast.error('Task title is required', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 600,
          })
        }
      }
    return (
        <div style={{ display: "flex", justifyContent: "space-between"  , paddingTop: 20, paddingBottom: 20, paddingLeft: "30px" , paddingRight:"30px", borderBottom:"1px solid #00000017" , alignItems: "center" }}>
            <div style={{ display: "flex", cursor:"pointer" }} onClick={()=> navigate(getTaskEditDetailsUrl(task.id))}>
                <div style={{ width: "12px", height: "12px", background: "#df472a", borderRadius: "50%", marginRight: "10px", marginTop: "5px" }}></div>
                <div>
                    <p style={{ margin: "0px" }}><b>{task?.title}</b></p>
                    <p style={{ marginTop: "10px", marginBottom: 0 }}>{job?.jobTitle}</p>
                </div>
            </div>
          
            <Select
                // disabled={isEditDisabled}
                defaultValue={selectedValue}
                style={{ width: 230 }}
                onChange={(value) => {
                    setSelectedValue(value)
                    handleEditTask(value)
                }}
                options={taskStatus}
                // onSearch={onSearch}
                showSearch
                value={selectedValue}
                />
        </div>
    )
} 

export default PendingTaskItem;