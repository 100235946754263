import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import KebabMenu from 'components/KebabMenu/KebabMenu';
import "./card.sass";
import deleteIcon from 'assets/delete.svg'
import infoIcon from 'assets/info-circle.svg'
import axios, { AxiosRequestConfig } from 'axios'
import { URL_PATH } from 'utils/constants'
import { toast } from 'react-toastify';
import { AuthContext } from 'context/AuthContext'

interface ACCOUNTCARD {
    showStatus?: string;
    title: string;
    expiryDate: string;
    image: string;
    checkbox?: boolean;
    onClick?: () => void; // Add onClick prop for checkbox click handling
    checked?: boolean;
    paymentMethodId?: string | null | undefined;
    fetchPaymentMethods?: () => void
}

interface DeleteCardRequest {
    paymentMethodId: string | null | undefined;
}

interface EditCardRequest {
    paymentMethodId: string | null | undefined;
    cardExpMonth: number;
    cardExpYear: number
}

const Card = (props: ACCOUNTCARD) => {
    const { fetchPaymentMethods } = props
    const [showInfoModalOpen, setShowInfoModalOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [loading, setLoading] = useState(false);

    const [cardExpMonth, setCardExpMonth] = useState(0);
    const [cardExpYear, setCardExpYear] = useState(0);

    const auth = useContext(AuthContext)

    const paymentMethodId = props.paymentMethodId

    const fetchPaymentMethod = async () => {
        try {
            setLoading(true)
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}get-payment-method`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    paymentMethodId: paymentMethodId
                }),
            };
            const response = await axios.request(config);
            if (response && response.status === 200 || response.status === 201) {

            }
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPaymentMethod();
    }, [auth?.user?._id]);

    const handleDetachCard = async (request: DeleteCardRequest) => {
        try {
            const detachPaymentDetailsConfig: AxiosRequestConfig = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}detach-payment-method/${auth?.user?._id}`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "paymentMethodId": request.paymentMethodId
                }),
            };
            try {
                const apiResult = await axios.request(detachPaymentDetailsConfig);
                console.log("apiResult >>", apiResult)
                if (apiResult.status === 200 || apiResult.status === 201) {

                    if (apiResult.data.id) {
                        toast.success('Card detached successfully.', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 6000
                        });
                    }
                } else {
                    console.log("Template not found !!!")
                }
            }
            catch (err: any) {
                console.log("Something went wrong!!!", err.message)
            }
        } catch (error) {
            // throw new Error(error.response.data.message || 'Failed to detach card'); // Throw error with appropriate message
        }
    };

    // const handleEditCard = async (request: EditCardRequest) => {
    //     try {
    //         const editCardDetailsConfig: AxiosRequestConfig = {
    //             method: 'patch',
    //             maxBodyLength: Infinity,
    //             url: `${URL_PATH.CLIENT1}update-payment-method/${auth?.user?._id}`,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             data: JSON.stringify({
    //                 "paymentMethodId": request.paymentMethodId,
    //                 "cardExpMonth": request.cardExpMonth,
    //                 "cardExpYear": request.cardExpYear
    //             }),
    //         };
    //         try {
    //             const apiResult = await axios.request(editCardDetailsConfig);
    //             console.log("apiResult >>", apiResult)
    //             if (apiResult.status === 200 || apiResult.status === 201) {

    //                 if (apiResult.data.id) {
    //                     toast.success('Card Edited successfully.', {
    //                         position: toast.POSITION.TOP_CENTER,
    //                         autoClose: 6000
    //                     });
    //                 }
    //             } else {
    //                 console.log("Template not found !!!")
    //             }
    //         }
    //         catch (err: any) {
    //             console.log("Something went wrong!!!", err.message)
    //         }
    //     } catch (error) {
    //         // throw new Error(error.response.data.message || 'Failed to detach card'); // Throw error with appropriate message
    //     }
    // };

    const kebabMenuItems = useMemo(() => {
        let items = [
            // {
            //     label: 'Edit',
            //     icon: infoIcon,
            //     disabled: false,
            //     onClick: () => {
            //         const request: EditCardRequest = {
            //             paymentMethodId: props.paymentMethodId,
            //             cardExpMonth: parseInt(props.expiryDate.split('/')[0]),
            //             cardExpYear: parseInt(props.expiryDate.split('/')[1])
            //         }

            //         handleEditCard(request).then(() => {
            //             // Handle success or show feedback to user
            //             fetchPaymentMethods?.()
            //         }).catch((error) => {
            //             // Handle error or show error message
            //         });

            //         setShowInfoModalOpen(!showInfoModalOpen);
            //     },
            // },
            {
                label: 'Delete',
                icon: deleteIcon,
                disabled: false,
                onClick: () => {
                    const request: DeleteCardRequest = {
                        paymentMethodId: props.paymentMethodId
                    };

                    handleDetachCard(request).then(() => {
                        // Handle success or show feedback to user
                        fetchPaymentMethods?.()
                    }).catch((error) => {
                        // Handle error or show error message
                    });
                    setDeleteOpen(true);
                },
            },
        ];
        return items;
    }, [showInfoModalOpen]);

    return (
        <div className={`account-card ${props.checked ? "active" : ""}`} style={{cursor: "pointer"}} onClick={props.onClick}>
            <div className="account-card-header">
                {props.showStatus && <div className={`account-card-status ${props.showStatus === "Default" ? "active" : "expired"}`}>{props.showStatus}</div>}
                <div className="account-card-options">
                    {props.checkbox ? (
                        <input
                            type="checkbox"
                            checked={props.checked} // Apply checked prop
                            onClick={props.onClick}
                            style={{display: 'none'}}
                        />
                    ) : (
                        <KebabMenu items={kebabMenuItems} />
                    )}
                </div>
            </div>
            <div className="account-card-body">
                <div className="account-card-image">
                    {props.image ? <img src={props.image} alt="" /> : <img src="./assets/card-image.png" alt="" />}
                </div>
                <div className="account-card-headings">
                    <h4 className='account-card-title'>{props.title}</h4>
                    <span className='account-card-sub-text'>Expire date {props.expiryDate}</span>
                </div>
                hhiohk
            </div>
        </div>
    );
}

export default Card;

// import React from 'react'
// import "./card.sass";

// interface ACCOUNTCARD {
//     showStatus?: string
//     title: string
//     expiryDate: string
//     image: string
//   }

// const Card = (props: ACCOUNTCARD) => {
//   return (
//     <div className='account-card'>
//         <div className="account-card-header">
//             {props.showStatus && <div className={`account-card-status ${props.showStatus === "Default" ? "active" : "expired"}`}>{props.showStatus}</div>}
//             <div className="account-card-options">
//                     {/* <button className=''> */}
//                         <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M0.6875 1.41406C0.6875 1.76216 0.825781 2.096 1.07192 2.34214C1.31806 2.58828 1.6519 2.72656 2 2.72656C2.3481 2.72656 2.68194 2.58828 2.92808 2.34214C3.17422 2.096 3.3125 1.76216 3.3125 1.41406C3.3125 1.06597 3.17422 0.732126 2.92808 0.485985C2.68194 0.239843 2.3481 0.101563 2 0.101562C1.6519 0.101563 1.31806 0.239843 1.07192 0.485985C0.825781 0.732126 0.6875 1.06597 0.6875 1.41406ZM0.6875 7.97656C0.6875 8.32466 0.825781 8.6585 1.07192 8.90464C1.31806 9.15078 1.6519 9.28906 2 9.28906C2.3481 9.28906 2.68194 9.15078 2.92808 8.90464C3.17422 8.6585 3.3125 8.32466 3.3125 7.97656C3.3125 7.62847 3.17422 7.29463 2.92808 7.04848C2.68194 6.80234 2.3481 6.66406 2 6.66406C1.6519 6.66406 1.31806 6.80234 1.07192 7.04848C0.825781 7.29463 0.6875 7.62847 0.6875 7.97656ZM0.6875 14.5391C0.6875 14.8872 0.825781 15.221 1.07192 15.4671C1.31806 15.7133 1.6519 15.8516 2 15.8516C2.3481 15.8516 2.68194 15.7133 2.92808 15.4671C3.17422 15.221 3.3125 14.8872 3.3125 14.5391C3.3125 14.191 3.17422 13.8571 2.92808 13.611C2.68194 13.3648 2.3481 13.2266 2 13.2266C1.6519 13.2266 1.31806 13.3648 1.07192 13.611C0.825781 13.8571 0.6875 14.191 0.6875 14.5391Z" fill="#00314D"/>
//                         </svg>

//                     {/* </button> */}
//             </div>
//         </div>
//         <div className="account-card-body">
//             <div className="account-card-image">
//                 {props.image ? <img src={props.image} alt="" /> : <img src="./assets/card-image.png" alt="" />}
//             </div>
//             <div className="account-card-headings">
//                 <h4 className='account-card-title'>{props.title}</h4>
//                 <span className='account-card-sub-text'>Expire date {props.expiryDate}</span>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default Card;