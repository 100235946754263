import { Link, Outlet, useLocation } from 'react-router-dom'

import protonLogo from '/assets/Proton-Original 1.png'
import { AuthContext, authReducer } from 'context/AuthContext'
import './login.sass'
import { useCallback, useReducer, useEffect } from 'react'
import client from 'feathers-client'
import { LINKS } from 'routes/constants'

const LoginRoot = () => {
  const location = useLocation();
  const [auth, dispatch] = useReducer(authReducer, null)
  const checkAuthentication = useCallback(async () => {
    try {
      const auth = await client.reAuthenticate()
      dispatch({
        type: 'added',
        auth,
      })
    } catch (error) {
      // navigate('/login')
    }
  }, [])
  useEffect(() => {
    checkAuthentication()
  }, [checkAuthentication])
  return (
    <AuthContext.Provider
      value={auth}
    >
      <div className="login">
        <div className="login-left">
        
            <img src="/assets/Mask group.png" />
          
        </div>
        <div className="login-right">
          <div className="login-container">
            {/* <div className="login-logo">
              <img src="/assets/Proton-Original 1.png" alt="Proton Logo" />
            </div> */}
            <div className="login-logo">
              {location.pathname === LINKS.LOGIN ?
                <Link to={LINKS.START_PAGE}><img src={"/assets/Proton-Original 1.png"} alt="Proton Logo" /></Link>
                :
                <img src={"/assets/Proton-Original 1.png"} alt="Proton Logo" />
              }
            </div>
            <div className="login-content">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  )
}
export default LoginRoot
