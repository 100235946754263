import { Typography } from 'antd'
import dayjs from 'dayjs'

import '../modal.sass'

function ShowInfoModal({ job }: any) {
  return (
    <div className="main-container">
      <Typography className="heading">Show info</Typography>
      <div className="data-container">
        <Typography className="title">Customer name</Typography>
        <Typography className="value">{job.customerName}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Customer email</Typography>
        <Typography className="value">{job.customerEmail}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Business name</Typography>
        <Typography className="value">{job.customerBusinessName}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Created on</Typography>
        <Typography className="value">
          {dayjs(job.invited).format('YYYY-MM-DD')}
        </Typography>
      </div>
      <div className="data-container">
        <Typography className="title">App type</Typography>
        <Typography className="value">{job.appIndustry}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Developed for</Typography>
        <Typography className="value">{job.developedFor}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Theme</Typography>
        <Typography className="value">{job.appTheme}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Pricing</Typography>
        <Typography className="value">{job.appPrice}</Typography>
      </div>
      <div className="data-container">
        <Typography className="title">Updates</Typography>
        <Typography className="value">{job.appUpdates}</Typography>
      </div>
    </div>
  )
}

export default ShowInfoModal
