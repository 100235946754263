import protonLogo from 'assets/Proton-accent.svg'

import Stepper from './Stepper'
import './customer.sass'

const Header = ({ showStepper, currentStep }: any) => {
  return (
    <div className="onboarding-header" style={{ padding: '0px 200px' }}>
      <div className="logo" style={{ display: 'flex' }}>
        <img src={protonLogo} alt="Proton Logo" />
      </div>
      <div className="stepper_style">
        {showStepper && currentStep >= 1 && currentStep < 4 ? (
          <Stepper currentStep={currentStep-1} />
        ) : currentStep === 0 ? (
          <div className="appType_heading">Select app type</div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default Header
