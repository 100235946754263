import { HTMLAttributes, useCallback } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import TaskTemplateForm from '../forms/TaskTemplateForm'
import '../templates.sass'
import { submitTaskTemplate } from '../utils'

interface IAddAppTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const AddTaskTemplate = ({ ...props }: IAddAppTemplateProps) => {
  const handleSubmit = useCallback(submitTaskTemplate, [])
  return (
    <div {...props} className="add-app-template templates">
      <PageHeader heading="Add task template" showBack />
 
        <TaskTemplateForm 
          handleSubmit={handleSubmit} 
          />

    </div>
  )
}

export default AddTaskTemplate
