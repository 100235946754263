import { useEffect } from 'react'

import { Form, Input, Modal, message } from 'antd'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import TextArea from 'components/forms/TextArea/TextArea'
import TextField from 'components/forms/TextField/TextField'
import Button from 'components/forms/Button/Button'

interface IAddAppTemplateFormData {
  title: string
  description: string
}

interface GetStartedModalProps {
  openGetStartedModal: boolean
  setOpenGetStartedModal: (e: any) => void
  setAppDescription: (e: any) => void
  setAppIndustry: (e: any) => void
  submitHandler: (e?: any) => void
  appDescription: string
  appIndustry: string
}
const GetStartedModal = ({
  openGetStartedModal,
  setOpenGetStartedModal,
  setAppDescription,
  setAppIndustry,
  submitHandler,
  appDescription,
  appIndustry,
}: GetStartedModalProps) => {
  const [form] = Form.useForm()

  const onFinish = () => {
    message.success('Submit success!')
  }

  const onFinishFailed = () => {
    message.error('Submit failed!')
  }

  const handleOk = () => {
    if (appIndustry.trim() !== "" && appDescription.trim() !== "") {
      // setOpenGetStartedModal(false)
      submitHandler()
    }
    else {
      toast.error('All fields are required', {
        autoClose: 600,
        position: toast.POSITION.BOTTOM_CENTER,
      })
    }
  }

  const handleCancel = () => {
    setOpenGetStartedModal(false)
  }
  // useEffect(() => {
  //   setAppIndustry("")
  //   setAppDescription("")
  // }, [])

  console.log(appDescription, appIndustry)
  const onSubmit =   async (values:any, actions:any) =>{
    setAppIndustry(values.title)
    setAppDescription(values.description)
    submitHandler(values)
  }
  return (
    <>
      <Modal
        title="Custom App"
        open={openGetStartedModal}
        // onOk={handleOk}
        onCancel={handleCancel}
        className='cu_app_model'
        footer={<></>}
      >
         <p style={{
            color:'#60747E',
            marginBottom:'12px',
          }}
          >Biusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        {/* <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          
        >
          <Form.Item
            name="text"
            label="App Industry"
            rules={[
              { required: true },
              { warningOnly: true },
              { type: 'string' },
            ]}
          >
            <Input placeholder="Type app industry.." value={appIndustry} onChange={(e) => setAppIndustry(e.target.value)} required/>
          </Form.Item>
          <Form.Item
            name="description"
            label="Short Description"
            rules={[
              { required: true },
              { warningOnly: true },
              { type: 'string' },
            ]}
          >
            <TextArea
              showCount
              maxLength={200}
              style={{ height: 120, resize: 'none' }}
              value={appDescription}
              onChange={(e) => setAppDescription(e.target.value)}
              placeholder="Type short description.."
            />
          </Form.Item>
        </Form> */}
       
        <Formik
          initialValues={{title: appIndustry, description: appDescription}}
          enableReinitialize
          validate={(values: IAddAppTemplateFormData) => {
              const errors: any = {}
              if (!values.title) {
              errors.title = 'Required'
              }
              
              if (!values.description) {
              errors.description = 'Required'
              }

              return errors
          }}
          onSubmit={onSubmit}
         
          >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
          }) => {
              const wordCount = values.description
              ? String(values.description).split(' ').length
              : 0
              return (
              <form onSubmit={handleSubmit} className='cu_modal_form'>
                  
                  <TextField
                      label="App Industry"
                      id="app-type-title-input"
                      placeholder="Type app type title..."
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      errors={errors}
                      required
                  />
                  <TextArea
                      label="Short Description"
                      id="app-type-description-input"
                      placeholder="Type app type description..."
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      errors={errors}
                      required
                  />
                  {/* <div className="wordcount">{wordCount}/20 words</div> */}
                  <div className="buttons-container">
                  {/* <Button
                      type="button"
                      disabled={isSubmitting}
                      theme="secondary"
                      style={{
                      marginRight: '12px',
                      }}
                      onClick={()=>navigate(-1)}
                  >
                      Cancel
                  </Button> */}
                  <Button key="back" onClick={handleCancel} style={{ marginRight:'5px',color: '#00B8D9', borderRadius: '14px',background:'transparent' ,fontSize:'18px',lineHeight:'normal',fontWeight:'600'}}>
                    Cancel
                  </Button>,
                  <Button type="submit" disabled={isSubmitting} style={{ color: '#fff', borderRadius: '14px',fontSize:'18px',lineHeight:'normal',fontWeight:'600' }}>
                      Save
                  </Button>

                  {/* <Button
                    key="submit"
                    className="primary button"
                    style={{ backgroundColor: '#2CBBE8' }}
                    onClick={handleOk}
                  >
                    Get Started
                  </Button>, */}
                  </div>
              </form>
              )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default GetStartedModal
