import React from 'react'
import { Link } from 'react-router-dom';

const PaymentError = () => {
    return (
        <div className='success-main-container'>
            <div className='success-container_ error'>
                <span className='icon-contaoner'>
                    <img src='/assets/no-credit-card.png' alt='payment-icon' />
                </span>
                {/* <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="70" height="70" rx="35" fill="#E5F8FB" />
                    <path d="M44.3744 27.4531H42.7361C42.5064 27.4531 42.2885 27.5586 42.1478 27.7391L32.4846 39.9805L27.851 34.1094C27.7809 34.0204 27.6915 33.9484 27.5896 33.8989C27.4878 33.8494 27.376 33.8236 27.2627 33.8234H25.6244C25.4674 33.8234 25.3807 34.0039 25.4767 34.1258L31.8963 42.2586C32.1963 42.6383 32.7728 42.6383 33.0752 42.2586L44.5221 27.7531C44.6182 27.6336 44.5314 27.4531 44.3744 27.4531Z" fill="#00B8D9" />
                </svg> */}
                <h3>Payment Cancel!</h3>
                <p>Your app payment is received.</p><br />
                <Link to="/customer" className="primary button medium-btn" style={{ width: "100%" }}>
                    {/* <span style={{transform: "rotate(180deg)"}}><img src="/assets/Vector.png" /></span>&nbsp;&nbsp; */}
                    Continue
                </Link>
            </div>
        </div>
    )
}

export default PaymentError;