import './email-preview.sass'

interface IEmailPreviewProps {
  body: string
}

const EmailPreview = ({ body }: IEmailPreviewProps) => {
  return (
    <div className="email-preview">
      <div className="preview-title">Email preview</div>
      <pre className="preview-content">{body}</pre>
    </div>
  )
}

export default EmailPreview
