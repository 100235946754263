import client from 'feathers-client'

export const logout = async () => {
  await client.logout()
  window.location.href = "/login"
}

export const checkAuthentication = async () => {
    try {
      const auth = await client.reAuthenticate();
      return {
        status: true,
        auth
      }
    } catch (error) {
        return {
            status: true,
        }
    }
};

// Function to get image source based on the card brand
export const getImageForBrand = (brand: string) => {
  switch (brand) {
      case 'visa':
          return './assets/visa.png';
      case 'mastercard':
          return './assets/card-image.png';
      case 'amex':
          return './assets/amex.png';
      case 'discover':
          return './assets/discover.png';
      case 'paypal':
          return './assets/paypal.png';
      default:
          return './assets/card-image.png';
  }
};

// Function to get expiry status based on expiry month and year
export const getExpiryStatus = (expMonth: number, expYear: number) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // January is 0, so we add 1

  if (currentYear < expYear || (currentYear === expYear && currentMonth <= expMonth)) {
      return 'Valid';
  } else {
      return 'Expired';
  }
};
