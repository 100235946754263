import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import { FormikHelpers } from 'formik'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import { IEmailTemplateFormData } from './forms/EmailTemplateForm'
import EmailTemplateForm from './forms/EmailTemplateForm'
import { getEmailTemplate, updateEmailTemplate } from './utils'


interface IEditEmailTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const EditEmailTemplate = ({ ...props }: IEditEmailTemplateProps) => {
  const { templateId } = useParams()
  const auth = useContext(AuthContext)
  const [template, setTemplate] = useState(null)

  const fetchEmailTemplate = useCallback(async () => {
    if (auth) {
      const data = await getEmailTemplate(String(templateId))
      setTemplate(data)
    }
  }, [templateId, auth])

  useEffect(() => {
    fetchEmailTemplate()
  }, [fetchEmailTemplate])

  const handleSubmit = useCallback(
    async (
      values: IEmailTemplateFormData,
      actions: FormikHelpers<IEmailTemplateFormData>
    ) => {
      console.log("valus",values);
      
      return await updateEmailTemplate(String(templateId), values,actions)
    },
    [templateId]
  )

  return (
    <div {...props} className="add-email-template templates">
      <PageHeader heading="Edit email template" showBack />
      <EmailTemplateForm handleSubmit={handleSubmit} initialValues={template} />
    </div>
  )
}

export default EditEmailTemplate
