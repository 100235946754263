import { HTMLAttributes,useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { LINKS, getJobDetailsUrl } from 'routes/constants'
import backIcon from 'assets/back.svg'
import './page-header.sass'
import { AuthContext } from 'context/AuthContext'
interface IPageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  heading: string
  showBack?: boolean
  rightSlot?: React.ReactNode
}

const PageHeader = ({
  heading,
  showBack,
  rightSlot,
  ...props
}: IPageHeaderProps) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  return (
    <>
    <div className="page-header" {...props}>
      <div className="left">
        {showBack && <img src={backIcon} alt="" onClick={() => navigate(-1)} />}
        {heading && <h4 className='page-title'>{heading}</h4>}
      </div>
      <div className="right">{rightSlot}</div>
    </div>
      { auth?.user?.permissions?.includes("user") ? (
        <div className='button_div'> 
          <button className='button primary medium-btn' onClick={() => navigate(LINKS.CUSTOMER_predefined)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.50416 0.979004C9.60541 0.979004 9.68749 1.06109 9.68749 1.16234V8.31234H16.8375C16.9387 8.31234 17.0208 8.39442 17.0208 8.49567V9.504C17.0208 9.60526 16.9387 9.68734 16.8375 9.68734H9.68749V16.8373C9.68749 16.9386 9.60541 17.0207 9.50416 17.0207H8.49582C8.39457 17.0207 8.31249 16.9386 8.31249 16.8373V9.68734H1.16249C1.06124 9.68734 0.979156 9.60526 0.979156 9.504V8.49567C0.979156 8.39442 1.06124 8.31234 1.16249 8.31234H8.31249V1.16234C8.31249 1.06109 8.39457 0.979004 8.49582 0.979004H9.50416Z" fill="white" />
            </svg> 
            Design Predefined App
          </button>
          <button className='button primary medium-btn' onClick={() => navigate(LINKS.CUSTOMER_custom)}>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.50416 0.979004C9.60541 0.979004 9.68749 1.06109 9.68749 1.16234V8.31234H16.8375C16.9387 8.31234 17.0208 8.39442 17.0208 8.49567V9.504C17.0208 9.60526 16.9387 9.68734 16.8375 9.68734H9.68749V16.8373C9.68749 16.9386 9.60541 17.0207 9.50416 17.0207H8.49582C8.39457 17.0207 8.31249 16.9386 8.31249 16.8373V9.68734H1.16249C1.06124 9.68734 0.979156 9.60526 0.979156 9.504V8.49567C0.979156 8.39442 1.06124 8.31234 1.16249 8.31234H8.31249V1.16234C8.31249 1.06109 8.39457 0.979004 8.49582 0.979004H9.50416Z" fill="white" />
            </svg>
            Design Custom App
          </button>
        </div>
      ) : ('')}
      </>
  )
}

export default PageHeader
