import { useEffect } from 'react'

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (effectFn: () => void) => useEffect(effectFn, [])

export const useOutsideAlerter = (ref: any, callback = () => {}) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback])
}
