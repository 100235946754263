import { HTMLAttributes, useState } from 'react'

import { LINKS } from 'routes/constants'
import appTemplateIcon from 'assets/more.svg'
import { Link, useLocation } from 'react-router-dom'
import Button from 'components/forms/Button/Button'

// import { title } from 'process'



// const links = [
//     {
//         label: 'Email templates',
//         to: LINKS.TEMPLATES_EMAIL,
//     },
//     {
//         label: 'App templates',
//         to: LINKS.TEMPLATES_APP,
//     },
//     {
//         label: 'Task templates',
//         to: LINKS.TEMPLATES_TASK,
//     },
// ]

const addLinks = [
    {
        index: 1,
        label: 'Add Subscription',
        to: LINKS.TEMPLATES_APP_ADD,
        icon: appTemplateIcon,
    }
]

const SubscriptionHeader = () => {
    const location = useLocation();


    return (
        <div className="subscription-header" >
            <div className="left">
                <h5 className='page-title'>Subscription package</h5>
            </div>
            <div className="right">
                {/* <Button label="Add Subscription" items={addLinks} /> */}
                <Link to="/subscription/add">
                <Button className='new'>Add Subscription</Button>
                </Link>
            </div>
         
        </div>
    )
}

export default SubscriptionHeader
