import { HTMLAttributes } from 'react'

import notificationIcon from 'assets/notifications.svg'

import NoData from 'components/NoData/NoData'

import './notifications.sass'

interface INotificationsProps extends HTMLAttributes<HTMLDivElement> {}
const Notifications = ({ ...props }: INotificationsProps) => {
  return (
   <div style={{height: "100%", display: "flex" }}>
      <div className="notifications" {...props}>
      <NoData icon={notificationIcon} title="No notifications" />
    </div>
   </div>
  )
}

export default Notifications
