import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
  
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import { LINKS } from 'routes/constants'

import NoData from 'components/NoData/NoData'
import Button from 'components/forms/Button/Button'

import jobsIcon from 'assets/jobs.svg'

import JobsListing from './JobsListing'
import './jobs.sass'
import {
  deleteJob,
  jobFetchingSearch,
  jobFetchingSort,
  jobProposalRevoke,
  jobProposalSorting,
  pinJobs,
  unpinJobs,
} from './utils'

export interface IJobItem {
  id: string
  name: string
  customerName: string
  customerEmail: string
  customerBusinessName: string
  invited: number
  invitedEmail: string
  pinned?: boolean
  appIndustry?: string
  appDescription?: string
  appTitle?: string
  suggestedAppTitle?: string
  requestedAppName?: boolean
  developedFor?: 'ios' | 'android' | 'ios+android'
  appTheme?: string
  appPrice?: 'free' | 'paid'
  appUpdates?: 'none' | 'weekly'
  appRequirements?: string
  attachments?: any[]
  submitted?: number // timestamp customer submitted the request
  isSubmitted: boolean
  revoked?: number // timestamp job revoked
  revokedBy?: string // id of user who revoked proposal
  appImage?:string
  activity?: Array<{
    added: number
    addedBy: string
    comment: string
    attachments: any[]
  }>
  jobStatus: string | undefined,
  isJobActive: boolean,
  deposit: Number,
  finalPayment: Number,
  depositReceived: Boolean,
  finalPaymentReceived: Boolean,
  buildingFinished: Boolean,
  monthlySubscription: any,
  yearlyDiscount: any
}

interface IJobsProps extends HTMLAttributes<HTMLDivElement> {}
const Jobs = ({ ...props }: IJobsProps) => {
  const auth = useContext(AuthContext)
  const [loading, setLoading] = useState<boolean>(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [jobs, setJobs] = useState<any[]>([])
  const location = useLocation()
  const fetchJobs = useCallback(async () => {
    if (
      auth &&
      (auth?.data === undefined || auth?.data === null || auth?.data === '')
    ) {
      const jobs = await jobFetchingSort()
      if (Array.isArray(jobs?.data)) {
        setJobs(jobs.data)
      }
    }
    if (auth?.data !== undefined && auth?.data !== null && auth?.data !== '') {
      setTimeout(async () => {
        const jobs = await jobFetchingSearch(auth)
        if (Array.isArray(jobs?.data)) {
          setJobs(jobs.data)
        }
      }, 1000)
    }
    setLoading(false)
  }, [auth])

  useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  const revokeJobProposal = useCallback(async (param: any) => {
    console.log("param.id",param.id);
    const jobs = await jobProposalRevoke(param)
    if (Array.isArray(jobs?.data)) {
      setJobs(jobs.data)
    }
  }, [])

  const handlePinJob = useCallback(async (param: any) => {
    const jobs = await pinJobs(param)
    if (Array.isArray(jobs?.data)) {
      setJobs(jobs.data)
    }
  }, [])

  const handleUnpinJob = useCallback(async (param: any) => {
    const jobs = await unpinJobs(param)
    if (Array.isArray(jobs?.data)) {
      setJobs(jobs.data)
    }
  }, [])

  const sortJobProposal = useCallback(async (param: any, option: any) => {
    const jobs = await jobProposalSorting(param, option)
    if (Array.isArray(jobs?.data)) {
      setJobs(jobs.data)
    }
  }, [])

  const handleDeleteJob = useCallback(async (param: any) => {
    if (auth) {
      const headers = {
        Authorization: `Bearer ${auth.accessToken}`,
      }
      await deleteJob(param, headers)
      fetchJobs()
    }
  }, [])

  if (loading) {
    return null
  }
  // console.log()


  return (
    <div className="jobs flat" {...props}>
      {jobs.length ? (
        <JobsListing
          jobs={location.pathname === LINKS.JOBS ?   jobs.filter((job:any) => job.isSubmitted && job.depositReceived === true && job.isJobActive &&( !job.finalPaymentReceived || !job.buildingFinished)) : jobs}
          onClickRevokeJob={(e) => revokeJobProposal(e)}
          onClickSortJob={sortJobProposal}
          handlePinJob={handlePinJob}
          handleUnpinJob={handleUnpinJob}
          fetchjobs_fn={fetchJobs}
          handleDeleteJob={handleDeleteJob}
          unFilteredJobs={jobs}
        />
      ) : (
        <NoData
          icon={jobsIcon}
          title="No job is added yet"
          // cta={
          //   <Link to={LINKS.JOBS_CREATE}>
          //     <Button>Create Job</Button>
          //   </Link>
          // }
        />
      )}
    </div>
  )
}
export default Jobs
