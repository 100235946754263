import { useCallback, useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { SERVICES } from 'utils/constants'
import { USER_ROLES } from 'utils/constants'

import EmailSent from 'components/ActionModal/components/EmailSent'
import Button from 'components/forms/Button/Button'
import SelectField from 'components/forms/SelectField/SelectField'
import TextField from 'components/forms/TextField/TextField'

// import { INVITE } from '../StaffInvite'
import './staffInviteForm.sass'

interface IAddStaffFormData {
  full_name: string
  email: string
  role: string
}

interface IStaffInviteFormProps {
  staff: any
}

const submitInviteStaff = async (
  staffId: string, // Add staffId as the first argument
  values: IAddStaffFormData,
  actions: FormikHelpers<IAddStaffFormData>
) => {
  try {
    const data = await client.service(SERVICES.USERS).patch(staffId, {
      name: values.full_name,
      email: values.email,
      permissions: [values.role],
    })
    actions.setSubmitting(false)
    return data
  } catch (error: any) {
    toast.error(error.message, {
      position: toast.POSITION.BOTTOM_CENTER,
    })
    actions.setSubmitting(false)
    return false
  }
}

const InviteSuccess = ({ email }: { email: string }) => {
  return (
    <div className="invite-success">
      <EmailSent email={email} />
      <Link to={LINKS.STAFF}>
        <Button>Go to Staff</Button>
      </Link>
    </div>
  )
}

const FormikForm = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isSuccess,
  setFieldValue,
  setErrors,
  staff,
}: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (staff) {
      setFieldValue('full_name', staff.name)
      setFieldValue('email', staff.email)
      setFieldValue('role', staff.permissions[0] || '')
      setErrors()
    }
  }, [staff])

  const selectData = [
    { id: 1, value: 'administrator', title: USER_ROLES['administrator'] },
    { id: 2, value: 'user', title: USER_ROLES['user'] },
    // { id: 2, value: 'graphic-designer', title: USER_ROLES['graphic-designer'] },
    // {
    //   id: 3,
    //   value: 'frontend-developer',
    //   title: USER_ROLES['frontend-developer'],
    // },
    // {
    //   id: 4,
    //   value: 'backend-developer',
    //   title: USER_ROLES['backend-developer'],
    // },
  ]

  return (
    <div className="add-staff-form-container half-container">
      <div className="left">
        <form onSubmit={handleSubmit}>
          <TextField
            label="Full name"
            id="full_name"
            placeholder="Type your full name..."
            type="text"
            name="full_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.full_name}
            errors={errors}
            required
          />
          <TextField
            label="email"
            id="email"
            placeholder="Type email..."
            type="text"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            errors={errors}
            required
          />
          <SelectField
            label="Role"
            id="role"
            placeholder="Select"
            type="text"
            name="role"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.role}
            errors={errors}
            selectData={selectData}
            required
          />
          <div className="buttons-container right">
            <Button
              className='button medium-btn cancel-btn'
              type="button"
              disabled={isSubmitting}
              theme="secondary"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button type="submit" className='button medium-btn' disabled={isSubmitting}>
              Done
            </Button>
          </div>
        </form>
      </div>
      <div className="right"></div>
    </div>
  )
}

interface IStaffInviteFormProps {
  staff: any
  staffId: any // Add this line
}

const StaffInviteForm = ({ staff, staffId }: IStaffInviteFormProps) => {
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (
      values: IAddStaffFormData,
      actions: FormikHelpers<IAddStaffFormData>
    ) => {
      const data = await submitInviteStaff(staffId, values, actions)
      if (data) {
        navigate(-1)
        toast.success('Staff edited successfully', {
          position: toast.POSITION.BOTTOM_CENTER,
        })
      }
    },
    []
  )

  return (
    <Formik
      initialValues={{
        full_name: '',
        email: '',
        role: '',
      }}
      validate={(values: IAddStaffFormData) => {
        const errors: any = {}
        if (!values.full_name) {
          errors.full_name = 'Required'
        }

        if (!values.email) {
          errors.email = 'Required'
        }

        if (!values.role) {
          errors.role = 'Required'
        }
        return errors
      }}
      onSubmit={handleSubmit}
    >
      {(formikProps) => <FormikForm {...formikProps} staff={staff} />}
    </Formik>
  )
}

export default StaffInviteForm
