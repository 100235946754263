import authentication from '@feathersjs/authentication-client'
import { feathers } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { URL_PATH } from 'utils/constants'

const socket = io(URL_PATH.CLIENT1)
const client = feathers();
client.configure(socketio(socket)) // Set up Socket.io client with the socket
client.configure(authentication())

client
  .service('app-templates')
  .on('created', (data: any) => console.log('Created an appTemplate', data))

if (client.io) {
  client.io.on('disconnect', (reason: any) => {
    // Show offline message
  })
}

export default client
