import { HTMLAttributes, ChangeEvent, useRef, useState, useEffect } from 'react'

import { DEFAULT_AVATAR } from 'utils/constants'
import editIcon from "assets/edit.svg"
import Button from 'components/forms/Button/Button'
import './upload-avatar.sass'
import axiosInstance from 'utils/axios'

interface IUploadAvatarProps extends HTMLAttributes<HTMLDivElement> {
  name: string
  type: string
  onChange:(e:any)=> void,
  setAttachments:any,
  attachments:any,
  deafaultImage?:any
}


const UploadAvatar = (props: IUploadAvatarProps) => {
  const fileRef = useRef<HTMLInputElement>(null)
  const [preview, setPreview] = useState(null)

  useEffect(()=>{
    const fetchTempImage = setTimeout(async()=>{
      // console.log("sadsadsadsad")
      if(props.deafaultImage){
        // console.log("sadsadsadsad123132")
        const response:any = await axiosInstance.get(`getSignedFileUrl/?fileName=${props.deafaultImage}`)
        if(response?.status){
          setPreview(response.data.url);
        }
      }
    }, 200)

    return () => clearTimeout(fetchTempImage)
  },[props.deafaultImage])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFileChangePreview(event)
    const files = event.target.files
    if (files && files.length > 0) {
      const allFiles = []
      for (let i = 0; i < files.length; i++) {
        allFiles.push(files[i])
      }
      props.setAttachments([...allFiles])
    }
  }
    // console.log(props.deafaultImage)
  
  const handleFileChangePreview = (event:any) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = (e:any) => {
        // Set the image preview in state or use it directly
        const imageDataURL = e.target.result;
        // For example, you can set the preview in state
        setPreview(imageDataURL);

        // console.log(imageDataURL); // Log the preview URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };
  return (
    <div  className="upload-avatar">
      <div className="avatar-container">
        <div className="icon">
          <img src={props.attachments.length === 0 && !props.deafaultImage ? DEFAULT_AVATAR : preview || DEFAULT_AVATAR} width={"100%"} style={{objectFit:"contain"}} alt=""/>
        </div>
        <label className="edit-button" htmlFor='file_name-input'>
            <img src={editIcon} alt='edit Image' width={"15px"} height={"15px"}/>  
        </label>
        <input
          ref={fileRef}
          style={{ display: 'none' }}
          id="file_name-input"
          type="file"
          name="file"
          onChange={handleFileChange}
          accept="image/*"
        />
      </div>
      {/* <Button theme="secondary" type="button">
        Remove Image
      </Button> */}
    </div>
  )
}

export default UploadAvatar
