import { HTMLAttributes, useMemo } from 'react'

import classNames from 'classnames'
import { USER_ROLES } from 'utils/constants'

import './role-badge.sass'

interface IRoleBadgeProps extends HTMLAttributes<HTMLDivElement> {
  role: keyof typeof USER_ROLES
  className?: string
}

const RoleBadge = ({ role, className, ...props }: IRoleBadgeProps) => {
  const roleLabel = useMemo(() => {
    return USER_ROLES[role]
  }, [role])
  return (
    <div
      {...props}
      className={classNames(className, 'role-badge', {
        teal: role === 'administrator',
      })}
    >
      {roleLabel}
    </div>
  )
}

export default RoleBadge
