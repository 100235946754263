import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Select } from 'antd'
import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'

import Loader from 'components/Loader/Loader'

import EmailTemplatesList from './EmailTemplatesList'
import TemplatesHeader from './TemplateHeader'
import './templates.sass'
import NoData from 'components/NoData/NoData'
import jobsIcon from 'assets/jobs.svg'
interface IEmailTemplatesProps extends HTMLAttributes<HTMLDivElement> { }

export interface IEmailTemplate {
  id: string
  title: string
  subject: string
  body: string
  type:string
}

const EmailTemplates = ({ ...props }: IEmailTemplatesProps) => {
  const auth = useContext(AuthContext)
  const [loading, setLoading] = useState<boolean>(true)
  const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([])
  const [optionValue, setOptionValue] = useState('added')
  const Option = Select
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  const fetchEmailTemplates = useCallback(async () => {
    if (auth && isSuperAdmin) {
      if (
        auth &&
        (auth?.data === undefined || auth?.data === null || auth?.data === '')
      ) {
        const emailTemplates = await client
          .service(SERVICES.EMAIL_TEMPLATES)
          .find({
            query: {
              $sort: {
                added: 1,
              },
            }
          })
        if (Array.isArray(emailTemplates?.data)) {
          setEmailTemplates(
            emailTemplates.data.map((template: any) => ({
              id: String(template.id),
              title: template.title,
              subject: template.subject,
              body: template.body,
              type:template.type
            }))
          )
        }
      }
      if (
        auth?.data !== undefined &&
        auth?.data !== null &&
        auth?.data !== ''
      ) {
        setTimeout(async () => {
          const emailTemplates = await client
            .service(SERVICES.EMAIL_TEMPLATES)
            .find({
              query: {
                search: auth?.data,
              },
            })
          if (Array.isArray(emailTemplates?.data)) {
            setEmailTemplates(
              emailTemplates.data.map((template: any) => ({
                id: String(template.id),
                title: template.title,
                subject: template.subject,
                body: template.body,
                type:template.type
              }))
            )
          }
        }, 1000)
      }
    }
    setLoading(false)
  }, [auth])

  useEffect(() => {
    fetchEmailTemplates()
  }, [fetchEmailTemplates])

  const sortEmailTemplate = useCallback(async (option: any, param: any) => {
    const emailTemplates = await client.service(SERVICES.EMAIL_TEMPLATES).find({
      query: {
        // search: 'af',
        $sort: {
          [option]: param,
        },
      },
    })
    if (Array.isArray(emailTemplates.data)) {
      setEmailTemplates(
        emailTemplates.data.map((template: any) => ({
          id: String(template.id),
          title: template.title,
          subject: template.subject,
          body: template.body,
          type:template.type
        }))
      )
    }
  }, [])

  const selectField = useCallback(() => {
    return (
      <Select
        style={{ width: 180 }}
        onChange={(e) => setOptionValue(e)}
        defaultValue={'added'}
      >
        <Option value="title">Title</Option>
        <Option value="subject">Subject</Option>
        <Option value="added">Date</Option>
      </Select>
    )
  }, [])

  return (
    <div className="templates" {...props}>
      {isSuperAdmin ?
        <>
          <TemplatesHeader
            sortTemplate={sortEmailTemplate}
            optionValue={optionValue}
            selectField={selectField}
          />
          <div className="templates-body">
            {loading ? (
              <div style={{ textAlign: 'center' }}>
                <Loader />
              </div>
            ) : (
              <EmailTemplatesList
                templates={emailTemplates}
                refetch={fetchEmailTemplates}
              />
            )}
          </div>
        </>
        :
        <NoData
          icon={jobsIcon}
          title="You do not have the correct permissions."
        />
      }
    </div>
  )
}

export default EmailTemplates
