import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios, { AxiosRequestConfig } from 'axios'
import { PriceApiURl } from 'utils/constants';

const { Option } = Select;

const currencies = [
    { code: 'usd', country: 'United States', name: 'US Dollar' },
    { code: 'aed', country: 'United Arab Emirates', name: 'Dirham' },
    { code: 'afn', country: 'Afghanistan', name: 'Afghani' },
    { code: 'all', country: 'Albania', name: 'Lek' },
    { code: 'amd', country: 'Armenia', name: 'Dram' },
    { code: 'ang', country: 'Netherlands Antilles', name: 'Guilder' },
    { code: 'aoa', country: 'Angola', name: 'Kwanza' },
    { code: 'ars', country: 'Argentina', name: 'Peso' },
    { code: 'aud', country: 'Australia', name: 'Dollar' },
    { code: 'awg', country: 'Aruba', name: 'Florin' },
    { code: 'azn', country: 'Azerbaijan', name: 'Manat' },
    { code: 'bam', country: 'Bosnia and Herzegovina', name: 'Convertible Mark' },
    { code: 'bbd', country: 'Barbados', name: 'Dollar' },
    { code: 'bdt', country: 'Bangladesh', name: 'Taka' },
    { code: 'bgn', country: 'Bulgaria', name: 'Lev' },
    { code: 'bif', country: 'Burundi', name: 'Franc' },
    { code: 'bmd', country: 'Bermuda', name: 'Dollar' },
    { code: 'bnd', country: 'Brunei', name: 'Dollar' },
    { code: 'bob', country: 'Bolivia', name: 'Boliviano' },
    { code: 'brl', country: 'Brazil', name: 'Real' },
    { code: 'bsd', country: 'Bahamas', name: 'Dollar' },
    { code: 'bwp', country: 'Botswana', name: 'Pula' },
    { code: 'byn', country: 'Belarus', name: 'Belarusian Ruble' },
    { code: 'bzd', country: 'Belize', name: 'Dollar' },
    { code: 'cad', country: 'Canada', name: 'Dollar' },
    { code: 'cdf', country: 'Congo', name: 'Franc' },
    { code: 'chf', country: 'Switzerland', name: 'Franc' },
    { code: 'clp', country: 'Chile', name: 'Peso' },
    { code: 'cny', country: 'China', name: 'Yuan Renminbi' },
    { code: 'cop', country: 'Colombia', name: 'Peso' },
    { code: 'crc', country: 'Costa Rica', name: 'Colon' },
    { code: 'cve', country: 'Cape Verde', name: 'Escudo' },
    { code: 'czk', country: 'Czech Republic', name: 'Koruna' },
    { code: 'djf', country: 'Djibouti', name: 'Franc' },
    { code: 'dkk', country: 'Denmark', name: 'Krone' },
    { code: 'dop', country: 'Dominican Republic', name: 'Peso' },
    { code: 'dzd', country: 'Algeria', name: 'Dinar' },
    { code: 'egp', country: 'Egypt', name: 'Pound' },
    { code: 'etb', country: 'Ethiopia', name: 'Birr' },
    { code: 'eur', country: 'Eurozone', name: 'Euro' },
    { code: 'fjd', country: 'Fiji', name: 'Dollar' },
    { code: 'fkp', country: 'Falkland Islands', name: 'Pound' },
    { code: 'gbp', country: 'United Kingdom', name: 'Pound' },
    { code: 'gel', country: 'Georgia', name: 'Lari' },
    { code: 'gip', country: 'Gibraltar', name: 'Pound' },
    { code: 'gmd', country: 'Gambia', name: 'Dalasi' },
    { code: 'gnf', country: 'Guinea', name: 'Franc' },
    { code: 'gtq', country: 'Guatemala', name: 'Quetzal' },
    { code: 'gyd', country: 'Guyana', name: 'Dollar' },
    { code: 'hkd', country: 'Hong Kong', name: 'Dollar' },
    { code: 'hnl', country: 'Honduras', name: 'Lempira' },
    { code: 'htg', country: 'Haiti', name: 'Gourde' },
    { code: 'huf', country: 'Hungary', name: 'Forint' },
    { code: 'idr', country: 'Indonesia', name: 'Rupiah' },
    { code: 'ils', country: 'Israel', name: 'Shekel' },
    { code: 'inr', country: 'India', name: 'Rupee' },
    { code: 'isk', country: 'Iceland', name: 'Krona' },
    { code: 'jmd', country: 'Jamaica', name: 'Dollar' },
    { code: 'jpy', country: 'Japan', name: 'Yen' },
    { code: 'kes', country: 'Kenya', name: 'Shilling' },
    { code: 'kgs', country: 'Kyrgyzstan', name: 'Som' },
    { code: 'khr', country: 'Cambodia', name: 'Riel' },
    { code: 'kmf', country: 'Comoros', name: 'Franc' },
    { code: 'krw', country: 'South Korea', name: 'Won' },
    { code: 'kyd', country: 'Cayman Islands', name: 'Dollar' },
    { code: 'kzt', country: 'Kazakhstan', name: 'Tenge' },
    { code: 'lak', country: 'Laos', name: 'Kip' },
    { code: 'lbp', country: 'Lebanon', name: 'Pound' },
    { code: 'lkr', country: 'Sri Lanka', name: 'Rupee' },
    { code: 'lrd', country: 'Liberia', name: 'Dollar' },
    { code: 'lsl', country: 'Lesotho', name: 'Loti' },
    { code: 'mad', country: 'Morocco', name: 'Dirham' },
    { code: 'mdl', country: 'Moldova', name: 'Leu' },
    { code: 'mga', country: 'Madagascar', name: 'Ariary' },
    { code: 'mkd', country: 'North Macedonia', name: 'Denar' },
    { code: 'mmk', country: 'Myanmar', name: 'Kyat' },
    { code: 'mnt', country: 'Mongolia', name: 'Tugrik' },
    { code: 'mop', country: 'Macau', name: 'Pataca' },
    { code: 'mur', country: 'Mauritius', name: 'Rupee' },
    { code: 'mvr', country: 'Maldives', name: 'Rufiyaa' },
    { code: 'mwk', country: 'Malawi', name: 'Kwacha' },
    { code: 'mxn', country: 'Mexico', name: 'Peso' },
    { code: 'myr', country: 'Malaysia', name: 'Ringgit' },
    { code: 'mzn', country: 'Mozambique', name: 'Metical' },
    { code: 'nad', country: 'Namibia', name: 'Dollar' },
    { code: 'ngn', country: 'Nigeria', name: 'Naira' },
    { code: 'nio', country: 'Nicaragua', name: 'Cordoba' },
    { code: 'nok', country: 'Norway', name: 'Krone' },
    { code: 'npr', country: 'Nepal', name: 'Rupee' },
    { code: 'nzd', country: 'New Zealand', name: 'Dollar' },
    { code: 'pab', country: 'Panama', name: 'Balboa' },
    { code: 'pen', country: 'Peru', name: 'Sol' },
    { code: 'pgk', country: 'Papua New Guinea', name: 'Kina' },
    { code: 'php', country: 'Philippines', name: 'Peso' },
    { code: 'pkr', country: 'Pakistan', name: 'Rupee' },
    { code: 'pln', country: 'Poland', name: 'Zloty' },
    { code: 'pyg', country: 'Paraguay', name: 'Guarani' },
    { code: 'qar', country: 'Qatar', name: 'Riyal' },
    { code: 'ron', country: 'Romania', name: 'Leu' },
    { code: 'rsd', country: 'Serbia', name: 'Dinar' },
    { code: 'rub', country: 'Russia', name: 'Ruble' },
    { code: 'rwf', country: 'Rwanda', name: 'Franc' },
    { code: 'sar', country: 'Saudi Arabia', name: 'Riyal' },
    { code: 'sbd', country: 'Solomon Islands', name: 'Dollar' },
    { code: 'scr', country: 'Seychelles', name: 'Rupee' },
    { code: 'sek', country: 'Sweden', name: 'Krona' },
    { code: 'sgd', country: 'Singapore', name: 'Dollar' },
    { code: 'shp', country: 'Saint Helena', name: 'Pound' },
    { code: 'sle', country: 'Sierra Leone', name: 'Leone' },
    { code: 'sos', country: 'Somalia', name: 'Shilling' },
    { code: 'srd', country: 'Suriname', name: 'Dollar' },
    { code: 'std', country: 'São Tomé and Príncipe', name: 'Dobra' },
    { code: 'szl', country: 'Eswatini', name: 'Lilangeni' },
    { code: 'thb', country: 'Thailand', name: 'Baht' },
    { code: 'tjs', country: 'Tajikistan', name: 'Somoni' },
    { code: 'top', country: 'Tonga', name: 'Paʻanga' },
    { code: 'try', country: 'Turkey', name: 'Lira' },
    { code: 'ttd', country: 'Trinidad and Tobago', name: 'Dollar' },
    { code: 'twd', country: 'Taiwan', name: 'Dollar' },
    { code: 'tzs', country: 'Tanzania', name: 'Shilling' },
    { code: 'uah', country: 'Ukraine', name: 'Hryvnia' },
    { code: 'ugx', country: 'Uganda', name: 'Shilling' },
    { code: 'uyu', country: 'Uruguay', name: 'Peso' },
    { code: 'uzs', country: 'Uzbekistan', name: 'Som' },
    { code: 'vnd', country: 'Vietnam', name: 'Dong' },
    { code: 'vuv', country: 'Vanuatu', name: 'Vatu' },
    { code: 'wst', country: 'Samoa', name: 'Tala' },
    { code: 'xaf', country: 'Central African CFA', name: 'Franc' },
    { code: 'xcd', country: 'East Caribbean', name: 'Dollar' },
    { code: 'xof', country: 'West African CFA', name: 'Franc' },
    { code: 'xpf', country: 'CFP Franc', name: 'Franc' },
    { code: 'yer', country: 'Yemen', name: 'Rial' },
    { code: 'zar', country: 'South Africa', name: 'Rand' },
    { code: 'zmw', country: 'Zambia', name: 'Kwacha' },
];

interface CurrencySelectProps {
    setSelectedCurrency: (value: any) => void;
    selectedCurrency: any;
}

const formatDate = (dateString: string): string => {
    const date = new Date(dateString); // Convert the string to a Date object
    return date.toLocaleDateString('en-US', {
      month: 'short',  // "Sep"
      day: '2-digit',  // "05"
      year: 'numeric', // "2024"
    });
  }; 
const currencyOptions = currencies.map(({ code, country, name }) => (
    <Option key={code} value={code}>
        {`${country} - ${name}`}
    </Option>
));

const CurrencySelect: React.FC<CurrencySelectProps> = ({ setSelectedCurrency, selectedCurrency }) => {
    const [date, setDate] = useState('');
    const [price, setPrice] = useState('');
    
    useEffect(() => {
        if (selectedCurrency) {
            getDollerPrise(selectedCurrency);
        }
    }, [selectedCurrency]);

    const getDollerPrise = async (currency: any) => {
        try {
            const config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: `${PriceApiURl}usd.json`,

            };
            const response = await axios.request(config);
            if (response && response.status === 200 || response.status === 201) {
                
                if (response.data) {
                    const responseDate = response?.data?.date;
                    if (responseDate) {
                        const formattedDate = formatDate(responseDate); // Pass the string to formatDate
                        setDate(formattedDate);
                    }
                    setPrice(response?.data?.usd?.[currency]);
                }
            }
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        }

    }

    const handleChange = (value: any) => {
        setSelectedCurrency(value);
    };

    return (
        <div className='primary_flex'>
            <label htmlFor="currency">Currency</label>
            <Select
                id="currency"
                value={selectedCurrency || 'usd'}
                onChange={handleChange}
                showSearch
                className='col48'
                placeholder="Select a currency"
            >
                {currencyOptions}
            </Select>
            <p className="udsdiv">
                <strong>1 {selectedCurrency && selectedCurrency?.toUpperCase()} = {price} USD.</strong> Last known conversion rate as of {date}
            </p>
        </div>
    );
};

export default CurrencySelect;