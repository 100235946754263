import { HTMLAttributes, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
import { getStaffEditLink } from 'routes/constants'

import DeleteModal from 'components/ActionModal/DeleteModal'
import Avatar from 'components/Avatar/Avatar'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import RoleBadge from 'components/RoleBadge/RoleBadge'

import deleteIcon from 'assets/delete.svg'
import editIcon from 'assets/edit.svg'

import { IStaffMember } from './Staff'

interface IStaffItemProps extends HTMLAttributes<HTMLDivElement> {
  staff?: IStaffMember
  showAdded?: boolean
  showInvited?: boolean
  handleDeleteUser?: any
}

const mockStaffItem = {
  avatar: '',
  name: 'Victor Fernandes',
  email: 'vic@fdes.pro',
  added: 1687674229951,
  role: 'administrator',
  invited: 1687676249951,
}

const ModalPreviewStaffTemplate = ({ staff }: any) => {
  return (
    <div className="modalPrev">
      <Avatar size={56} name={staff.name} url={staff.avatar} />
      <div className="details">
        <div className="name">{staff.name}</div>
        <div className="bottom">
          <RoleBadge role={staff.role} />
          <span className="mid-dot">&middot;</span>
          <span>{staff.email}</span>
        </div>
      </div>
    </div>
  )
}

const StaffItem = ({
  // @ts-ignore
  staff = mockStaffItem,
  showAdded = false,
  showInvited = false,
  handleDeleteUser,
  ...props
}: IStaffItemProps) => {
  const navigate = useNavigate()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const kebabMenuItems = useMemo(
    () => [
      {
        label: 'Edit',
        icon: editIcon,
        onClick: () => {
          navigate(getStaffEditLink(staff.id))
        },
      },
      {
        label: 'Delete',
        icon: deleteIcon,
        onClick: () => {
          setDeleteOpen(true)
        },
      },
    ],
    []
  )
  return (
    <div className="staff-item" {...props}>
      <div className="left">
        <Avatar size={56} name={staff.name}  />
        <div className="details">
          <div className="name">{staff.name} &nbsp; {!staff.isExpired && "[PENDING]"}</div>
          <div className="bottom">
            <span className='email'>{staff.email}</span>
            <span className='spacer' style={{ display:'block' ,width:'1px',background:'#B7C5CC',height: '20px'}}></span>
            {/* <RoleBadge role={staff.role} /> */}
            <div className="progress-badge" style={{background:"#00800033", color:'green', marginRight:10}}>{staff.role}</div>
          </div>
          <div className="bottom">
            {showAdded && (
                <>
                  <span className='date'>
                    add on {dayjs(staff.added).format('MMM DD, YYYY h:mm A')}
                  </span>
                </>
              )}
              {showInvited && (
                <>
                  <span className='spacer' style={{ display:'block' ,width:'1px',background:'#B7C5CC',height: '20px'}}></span>
                  <span className='date'>
                    invited on{' '}
                    {dayjs(staff.invited).format('MMM DD, YYYY h:mm A')}
                  </span>
                </>
              )}
          </div>
        </div>
      </div>
      <div style={{display:'flex',alignItems:"center"}}>
        {/* {!staff.isExpired && <div className="progress-badge" style={{background: "rgba(255, 0, 0, 0.2)", color: "red", marginRight: "10px"}}>Pending</div>} */}
        <KebabMenu items={kebabMenuItems} />
      </div>
      <DeleteModal
        isOpen={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false)
        }}
        body={<ModalPreviewStaffTemplate staff={staff} />}
        handleDelete={handleDeleteUser}
        title="Delete user"
        subtitle="Do you wish to delete the user?"
      />
    </div>
  )
}

export default StaffItem
