import React from 'react'

import EmailSent from './components/EmailSent'
import Button from 'components/forms/Button/Button'

import BaseModal from './BaseModal'

interface IEmailSentModalProps {
  isOpen: boolean
  handleClose: () => void
  email: string
}

const EmailSentModal = ({
  isOpen,
  handleClose,
  email = '',
  ...props
}: IEmailSentModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtons={
        <>
          <Button onClick={handleClose}>Ok, Got It</Button>
        </>
      }
      {...props}
    >
      <EmailSent email={email} style={{ paddingTop: '24px' }} />
    </BaseModal>
  )
}

export default EmailSentModal
