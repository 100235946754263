import { HTMLAttributes, useCallback } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import AppTemplateForm from './forms/AppTemplateForm'
import './templates.sass'
import { submitAppTemplate } from './utils'

interface IAddAppTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const AddAppTemplate = ({ ...props }: IAddAppTemplateProps) => {
  const handleSubmit = useCallback(submitAppTemplate, [])
  return (
    <div {...props} className="add-app-template templates">
      <PageHeader heading="Add app template" showBack />
        <AppTemplateForm handleSubmit={handleSubmit} />
    </div>
  )
}

export default AddAppTemplate
