import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { LINKS } from 'routes/constants'
import { generateRandomPassword, SERVICES, URL_PATH } from 'utils/constants'
import protonLogo from 'assets/Proton-white.svg'
import EmailSent from 'components/ActionModal/components/EmailSent'
import Button from 'components/forms/Button/Button'
import TextField from 'components/forms/TextField/TextField'
import Header from "pages/customer/Header";
import './contact.sass';
import { AuthContext } from 'context/AuthContext'
import axios, { AxiosRequestConfig } from 'axios';
import emailSentGraphic from 'assets/proton_email_send.png'

interface ICreateJobFormData {
    customer_name: string
    customer_email: string
}

interface ICreateJobForm { }

export const submitCreateJob = async (
    values: ICreateJobFormData,
    actions: FormikHelpers<ICreateJobFormData>,
    auth: any
) => {
    try {
        const newPassword: string = generateRandomPassword(10);
       
        const config: AxiosRequestConfig = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}createUser`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                name: values.customer_name,
                email: values.customer_email,
                password: newPassword
            }),
        };

        const jobCreate:any = await axios.request(config);

       
        if(jobCreate.data.status === 200){
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "customer_access"
                }),
            };
            const siteRootUrl: string = window.location.origin;
            const template = await axios.request(configE);

            if (template?.data?.template?.data?.length > 0) {
                const tempStr = template?.data?.template?.data[0]
                let customerFirstName = values.customer_name?.split(" ")?.shift();
                tempStr.body = tempStr.body.replaceAll("[Customer Name]", customerFirstName).replaceAll("[Link]", `${siteRootUrl}/login?email=${values.customer_email}`).replaceAll("[Email]", values.customer_email).replaceAll("[Password]", newPassword);


                const emailObj = {
                    to: values.customer_email, // Replace with your email address
                    subject: tempStr.subject,
                    text: tempStr.body,
                }

                const email = await client.service(SERVICES.SENDEMAIL).create(emailObj)
                
            }
            // actions.setSubmitting(false)
            // toast.success(``, {
            //     position: toast.POSITION.BOTTOM_CENTER,
            // })
            return true;
        }else{
            toast.error(jobCreate.data.message, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
            return false
        }

       
    } catch (error: any) {
        toast.error(error.message, {
            position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return false
    }
}

const InviteSuccess = ({ email }: { email: string }) => {
    return (
        <div className="invite-success">
            {/* <EmailSent email={email} /> */}
                <div className="email-sent">
                <img
                    src={emailSentGraphic}
                    alt="email sent"
                    width={'250px'}
                    height={'300px'}
                />
                {/* <div className="title">Email sent!</div> */}
                <p>
                    Email was successfully sent to
                    <span className="email-address">{email}</span> 
                </p>
            </div>
            {/* <Link to={LINKS.JOBS}>
                <Button>Go to Jobs</Button>
            </Link> */}
        </div>
    )
}

const FormikForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isSuccess,
    emailTemplate
}: any) => {
    const navigate = useNavigate()
    const buildEmail = useMemo(() => {
        let template = emailTemplate;
        Object.keys(values).forEach((key) => {

            if (values[key]) {
                if (key === "customer_name") {
                    let firstName = values[key].split(" ").shift()
                    template = template.replaceAll(`[Customer Name]`, firstName || values[key])
                }
                template = template.replaceAll(`[${key}]`, values[key])
            }
        })
        return template
    }, [values, emailTemplate])


    return (

        <>
            <div className="login">
                <div className="login-left">
                    <img src="/assets/Mask group.png"></img>
                </div>
                <div className="login-right">
                    <div className="login-container">
                    <div className="login-content">
                    <div className="login-logo">
                        <img src="/assets/Proton-Original 1.png" alt="Proton Logo" />
                    </div>
                        <div className="create- -form-container " >
                            {isSuccess ? (
                                <InviteSuccess email={values.customer_email} />
                            ) : (
                                    <>
                                        <div className="form_header">
                                        <h3 style={{textAlign:'left',
                                            fontWeight:'600',
                                            fontSize:'48px',
                                            color:'#00314D',
                                        }}>Start using Proton</h3>
                                        <p style={{
                                            fontWeight:'400',
                                            fontSize:'18px',
                                            color:'#60747E',
                                        }}>Enter your full name and email address</p>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                        <div className="form_inner">
                                        <TextField
                                            label="Name"
                                            id="customer_name-input"
                                            placeholder="Type name..."
                                            type="text"
                                            name="customer_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.customer_name}
                                            errors={errors}
                                            required />
                                        <TextField
                                            label="Email address"
                                            id="customer_email-input"
                                            placeholder="Type email..."
                                            type="text"
                                            name="customer_email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.customer_email}
                                            errors={errors}
                                            required />
                                            </div>

                                        <div className="buttons-container">
                                            {/* <Button
                                                type="button"
                                                disabled={isSubmitting}
                                                theme="secondary"
                                                style={{
                                                    marginRight: '12px',
                                                }}
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button> */}
                                            <br/>
                                            <Button type="submit" disabled={isSubmitting} style={{width:"100%"}}>
                                                Send
                                                <span><img src="/assets/Vector.png"></img></span>
                                            </Button>
                                        </div>
                                        </form>
                                    </>
                            )}
                        </div>
                    </div>
                    </div>
                </div>

            </div>

            {/* <Header /> */}
        </>
    )
}

const StartPage = ({ ...props }: ICreateJobForm) => {
    const auth = useContext(AuthContext)
    const handleSubmit = useCallback(submitCreateJob, [auth])
    const [emailSent, setEmailSent] = useState<boolean>(false)
    const [template, setTemplate] = useState<string>("")

   const navigate = useNavigate();

    const onSubmit = useCallback(
        async (
            values: ICreateJobFormData,
            actions: FormikHelpers<ICreateJobFormData>
        ) => {
            const form_res = await handleSubmit(values, actions, auth)
            form_res !== false ? setEmailSent(true) : setEmailSent(false)
        },
        [handleSubmit, auth]
    )

    useEffect(() => {

        const fetchTemplateTimer = setTimeout(async () => {
            const configE: AxiosRequestConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}GetEmailTemplate`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    "type": "customer_access"
                }),
            };


            try {
                const templateResult = await axios.request(configE);
                if (templateResult.data.status === 200) {
                    setTemplate(templateResult.data.template.data[0].body)
                } else {
                    console.log("Template not found !!!")
                }
            }
            catch (err: any) {
                console.log("Something went wrong!!!", err.message)
            }
        }, 200)

        return () => clearTimeout(fetchTemplateTimer)
    }, [])

    useEffect(()=>{
        
        if(auth !== null && auth.authentication && auth.user){
          if(auth?.user?.permissions?.includes("temp") || auth?.user?.permissions?.includes("customer")){
            navigate('/customer')
          }else{
            navigate('/jobs')
          }
        }
      },[auth, navigate])

    const isValidEmail = (email:string) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }


    return (
        <Formik
            initialValues={{
                // name: '',
                customer_name: '',
                customer_email: '',
                // business_name: '',
            }}
            validate={(values: ICreateJobFormData) => {
                const errors: any = {}
                // if (!values.name) {
                //   errors.name = 'Required'
                // }

                if (!values.customer_name) {
                    errors.customer_name = 'Required'
                }

                if (!values.customer_email) {
                    errors.customer_email = 'Required'
                }else if (!isValidEmail(values.customer_email)) {
                    errors.customer_email = 'Invalid email'
                }

                // if (!values.business_name) {
                //   errors.business_name = 'Required'
                // }

                return errors
            }}
            onSubmit={onSubmit}
        >
            {({ ...props }) => <FormikForm {...props} isSuccess={emailSent} emailTemplate={template} />}
        </Formik>
    )
}

export default StartPage;
