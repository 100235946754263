import React from 'react'; // Import React

import './TotallTemplates.sass';

type TotallTemplatesProps = {
  totalS: number;
  email: number;
  app: number;
};

const TotallTemplates: React.FC<TotallTemplatesProps>= (props) => {
  const { totalS, email, app} = props;
  return (
    <ul className='job_box_inner box_height'>
      <li className='head_bg_blue'>
        <div className='user_div'>
          <img src="/assets/list (1).png" alt="menu" />
        </div>
      </li>
      <li className='box_li'>
        <span>TOTAL TEMPLATES:</span>
        <strong className='big_text'>{totalS}</strong>
      </li>
      <li className='box_li'>
        <span>EMAIL TEMPLATES:</span>
        <strong className=''>{email}</strong>
      </li>
      <li className='box_li'>
        <span> APP TEMPLATES:</span>
        <strong className=''>{app}</strong>
      </li>
    </ul>
  );
}

export default TotallTemplates;
