import { InputHTMLAttributes, useMemo } from 'react'

import { TGenericObject } from 'types/base'

import './selectField.sass'

type SelectDataType = {
  id?: number
  value?: string
  title?: string
}
interface ITextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  errors?: TGenericObject
  selectData?: SelectDataType[]
  onChange?: any
}

const SelectField = ({
  id,
  label,
  required,
  errors,
  selectData,
  value,
  placeholder,
  defaultValue,
  onChange,
  ...props
}: ITextFieldProps) => {
  const getError = useMemo(() => {
    return props?.name ? errors?.[props?.name] : ''
  }, [errors, props.name])

  return (
    <div className="input-field">
      <div className="header">
        <label htmlFor={id}>
          {label}
          {required && <span className="required-indicator">*</span>}
        </label>
      </div>
      <select id={id} onChange={onChange} value={value}>
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {selectData?.map((val: any,i) => (
          <option key={i} value={val.value}>{val.title}</option>
        ))}
      </select>
      <div className="error">{getError}</div>
    </div>
  )
}

export default SelectField
