import { ChangeEvent, useRef, useState } from 'react'
import Header from '../Header';
import TextArea from 'antd/es/input/TextArea'

import { ReactComponent as CancelIcon } from 'assets/CancelIcon.svg'
import { ReactComponent as UploadIcon } from 'assets/uploadIcon.svg'

import '../../customer.sass'
import { Input, Button } from 'antd'

interface RequirementsProps {
  setAppRequirements: (e: any) => void
  appRequirements: string
  setAttachments: (e: any) => void
  attachments: FileList[],
  setAppTitle: (e: any) => void
  appTitle: string
  customerBusinessName: string
  setCustomerBusinessName: (e: any) => void
  footerJSX: any
}
const Requirements = ({
  setAppRequirements,
  appRequirements,
  setAttachments,
  attachments,
  appTitle,
  setAppTitle,
  customerBusinessName,
  setCustomerBusinessName,
  footerJSX
}: RequirementsProps) => {
  const fileRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const allFiles = []
      for (let i = 0; i < files.length; i++) {
        allFiles.push(files[i])
      }
      setAttachments([...attachments, ...allFiles])
    }
  }

  const removeFile = (val: any) => {
    const data = attachments.filter((file: any) => file !== val)
    setAttachments(data)
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center,'
        }}
      >
        <div className="main_card_popup">
          <div className="header_new">
            <Header currentStep={2} />
            <div className="card_top_heading"
              style={{
                padding: '20px 40px 0px 40px'
              }}
            >
              <h1 style={{color:'#00314D'}}>Requirements</h1>
              <p style={{
                color: '#60747E',
                marginTop: '10px',

              }}
              > Any specific requirements or pointers for the team?</p>
            </div>
          </div>
          <div className="main_section_card"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 8,
              margin: '20px 40px 40px',
              cursor: 'pointer',
              height: "100%",
              overflow: "auto"

            }}
          >
            <div
              style={{
                width: '100%',
                lineHeight: '30px',
              }}
            >
              <div style={{ fontWeight: '600' ,color:'#00314D'}}>App title</div>
              <Input
                placeholder="Type app title.."
                width={'80%'}
                style={{height:'40px'}}
                value={appTitle}
                onChange={(e) => setAppTitle(e.target.value)}
              />
            </div>


            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '30px',
              }}
            >
              <div
                style={{
                  width: '100%',
                  lineHeight: '30px',
                }}
              >
                <div style={{ fontWeight: '600' ,color:'#00314D'}}>Business name</div>
                <Input
                  placeholder="Type app title.."
                  width={'80%'}
                  value={customerBusinessName}
                  style={{height:'40px'}}
                  onChange={(e) => setCustomerBusinessName(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: 8,
                padding: '0px 0px 10px',
                marginTop:'10px'
              }}
            >
              <p className="steps-heading">
                Specific requirements
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  gap: 8,
                  padding: '0px 0px 10px',
                }}
              >
                <TextArea
                  style={{ height: 120, resize: 'none' }}
                  value={appRequirements}
                  onChange={(e) => setAppRequirements(e.target.value)}
                  placeholder="Type your message here"
                />
              </div>
              <div style={{
                width: '100%',
                border: '1px solid #2CBBE8',
                height: '163px',
                borderRadius:'6px',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center'
              }}
              >
                {/* <p className="steps-heading"></p> */}
                <input
                  multiple
                  ref={fileRef}
                  style={{ display: 'none' }}
                  id="file_name-input"
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
                <div className="filelist">
                  {attachments.map((val: any, key) => {
                    return (
                      <div key={key} className="files">
                        <div>{val.name}</div>
                        <i>{val && <CancelIcon onClick={() => removeFile(val)} />}</i>
                      </div>
                    )
                  })}
                </div>
                <div className="cta-btn" onClick={() => fileRef.current?.click()} style={{display:'flex',flexDirection:'column',width:'250px',marginTop:'0', backgroundColor: "transparent"}}>
                  <img src="/assets/upload_image.png" alt="upload image" />
                 <div style={{ fontWeight: '600',color:'#00314D' }}>Choose file</div>
                  <p style={{
                    color: '#60747E'
                  }}
                  > Biusmod tempor incididunt ut labore</p>
                </div>
              </div>

            </div>
          </div>
          
          <div className="footer_new">
                  {footerJSX}
          </div>

        </div>
      </div>
    </div >
  )
}

export default Requirements
