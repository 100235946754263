import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define a type for the slice state
interface CounterState {
    userData: any,
    showSubscriptionModal: boolean
}

// Define the initial state using that type
const initialState: CounterState = {
  userData: {},
  showSubscriptionModal: false
}

export const counterSlice = createSlice({
  name: 'USERSLICE',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<any>) => {
      state.userData = action.payload
    },
    showSubscriptionModalHandler:(state, action: PayloadAction<boolean>)=>{
        state.showSubscriptionModal = action.payload
    }
  },
})

export const { setUserData, showSubscriptionModalHandler } = counterSlice.actions


export default counterSlice.reducer