import { HTMLAttributes, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Select } from 'antd'
import { LINKS } from 'routes/constants'

import PendingStaffListing from './components/pendingStaffListing'
import PageHeader from 'components/Header/PageHeader/PageHeader'
import PendingBanner from 'components/PendingBanner/PendingBanner'
import Button from 'components/forms/Button/Button'

import sortAscIcon from 'assets/sort_asc.svg'
import sortDescIcon from 'assets/sort_desc.svg'

import { IStaffMember } from './Staff'
import StaffItem from './StaffItem'

interface IStaffListingProps extends HTMLAttributes<HTMLDivElement> {
  users: IStaffMember[]
  handleDeleteUser: any
  onClickSortStaff: any
}

const StaffListing = ({
  users,
  handleDeleteUser,
  ...props
}: IStaffListingProps) => {
  const location = useLocation()
  const [sort, setSort] = useState<any>(1)
  const Option = Select.Option
  const [sortOption, setSortOption] = useState('invited')

  const handleSortOption = (value: string) => {
    setSortOption(value)
  }

  const isPendingPage = useMemo(() => {
    return location.pathname === LINKS.STAFF_PENDING
  }, [location])

  const activeStaff = useMemo(() => {
    return users.filter((user) => user.added && user.isExpired)
  }, [users])

  const pendingStaff = useMemo(() => {
    return users.filter((user) => user.invited && !user.isExpired)
  }, [users])

  console.log(users)

  const renderSort = () => {
    return (
      <>
        <div className="sort-title">Sort by</div>
        <Select
          defaultValue="invited"
          style={{ width: 100 }}
          className='custom-dropdown-main'
          popupClassName='custom-dropdown'
          onChange={(e) => handleSortOption(e)}
        >
          <Option value="name">Name</Option>
          <Option value="email">Email</Option>
          <Option value="permissions">Role</Option>
          <Option value="invited">Date</Option>
        </Select>
        <Button
          theme="transparent"
          className="close-button"
          onClick={() => {
            setSort(sort === 1 ? -1 : 1)
            if (props.onClickSortStaff) {
              props.onClickSortStaff(sortOption, sort)
            }
          }}
        >
          <img
            src={sort === 1 ? sortAscIcon : sortDescIcon}
            style={{ height: '24px', width: '24px' }}
            alt="close"
          />
        </Button>
        <Link to={LINKS.STAFF_INVITE}>
          <Button style={{
            padding: "12px 24px 12px 24px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px'
          }}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.50413 0.979248C9.60538 0.979248 9.68746 1.06133 9.68746 1.16258V8.31258H16.8375C16.9387 8.31258 17.0208 8.39466 17.0208 8.49592V9.50425C17.0208 9.6055 16.9387 9.68758 16.8375 9.68758H9.68746V16.8376C9.68746 16.9388 9.60538 17.0209 9.50413 17.0209H8.49579C8.39454 17.0209 8.31246 16.9388 8.31246 16.8376V9.68758H1.16246C1.06121 9.68758 0.979126 9.6055 0.979126 9.50425V8.49592C0.979126 8.39466 1.06121 8.31258 1.16246 8.31258H8.31246V1.16258C8.31246 1.06133 8.39454 0.979248 8.49579 0.979248H9.50413Z" fill="white"/>
          </svg>

            Add Staff
          </Button>
        </Link>
      </>
    )
  }

  return (
    <div className="staff-listing" {...props}>
      <div className={`staff-listing-container ${pendingStaff.length ? "have-pending-jobs" : ""}`}>
        <div className="list">
          <PageHeader
            heading={isPendingPage ? 'Pending staff list' : 'Staff'}
            showBack={isPendingPage}
            rightSlot={renderSort()}
          />
          <div className="list-main">
            {activeStaff.map((staff) => (
                  <StaffItem
                    key={staff.id}
                    staff={staff}
                    handleDeleteUser={() => handleDeleteUser(staff.id)}
                    showAdded
                  />
            ))}
          </div>
          {/* {users.map((staff) => (
            <StaffItem
              key={staff.id}
              staff={staff}
              handleDeleteUser={() => handleDeleteUser(staff.id)}
              showAdded
            />
          ))} */}
        </div>
        {pendingStaff.length > 0 && (
          <div className="pending-staff">
            {/* <PendingBanner
              to={LINKS.STAFF_PENDING}
              text={`${pendingStaff.length} pending invites`}
            /> */}
            <h5 className='pending-staff-title'>{pendingStaff.length}  Pending</h5>
            {pendingStaff.map((staff) => (
                <PendingStaffListing key={staff.id} staff={staff} />
              ))}
          </div>
        )}
      </div>
    
    </div>
  )
}

export default StaffListing
