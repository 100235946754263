import {
    HTMLAttributes,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from 'react'
  import { useParams } from 'react-router-dom'
  
  import { AuthContext } from 'context/AuthContext'
  
  import PageHeader from 'components/Header/PageHeader/PageHeader'
  
  import SubscriptionForm from './forms/SubscriptionForm'

  import { updateSubscriptionHandler, getSubscriptionHandler } from './utils'
  
  interface IEditAppTemplateProps extends HTMLAttributes<HTMLDivElement> {}
  
  const EditAppTemplate = ({ ...props }: IEditAppTemplateProps) => {
    const { subscriptionId } = useParams()
    const auth = useContext(AuthContext)
    const [template, setTemplate] = useState(null)
    const fetchAppTemplate = useCallback(async () => {
      if (auth) {
        const data = await getSubscriptionHandler(String(subscriptionId))
        setTemplate(data)
      }
    }, [subscriptionId, auth])
  
    useEffect(() => {
      fetchAppTemplate()
    }, [fetchAppTemplate])
  
    const handleSubmit = useCallback(updateSubscriptionHandler, [])
    return (
      <div {...props} className="add-app-template templates">
        <PageHeader heading="Edit subscription" showBack />
          <SubscriptionForm handleSubmit={handleSubmit} initialValues={template} extraData={template} />
      </div>
    )
  }
  
  export default EditAppTemplate
  