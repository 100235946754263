import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AuthContext } from 'context/AuthContext'
import dayjs from 'dayjs'
import client from 'feathers-client'
import axiosInstance from 'utils/axios'
import { SERVICES } from 'utils/constants'

import { getAllTaskActivities } from 'pages/jobs/utils'

import ActionModal from 'components/ActionModal/ActionModal'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import Loader from 'components/Loader/Loader'
import Button from 'components/forms/Button/Button'

import attcheDemo from 'assets/attach-demo.jpg'
import closeIcon from 'assets/close.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/download_file.svg'
import planeIcon from 'assets/paper-plane 1.svg'
import PDFIcon from 'assets/pdf-file.svg'

const MenuItems = (props: any) => {
  const { data, handleEdit, handleDelete } = props
  const cols = [
    {
      label: 'Edit',
      icon: planeIcon,
      onClick: () => {
        if (handleEdit) {
          handleEdit(data)
        }
      },
    },
    {
      label: 'Delete',
      icon: closeIcon,
      onClick: () => {
        if (handleDelete) {
          handleDelete(data)
        }
      },
    },
  ]
  return <KebabMenu items={cols} />
}

const RenderImage = (props: any) => {
  const { file } = props
  const [data, setData] = useState()
  
  const getUrlFileURL = async (str: string) => {
    try {
      const response = await axiosInstance.get(`getSignedFileUrl/?fileName=${str}`)
      return response.data.url
    } catch (error) {
      console.error('getSignedFileUrl error:', error)
      return ''
    }
  }

  useEffect(() => {
    getUrlFileURL(file).then((val) => {
      setData(val)
    })
  }, [file.isTrue])

  if (data === undefined) return <img src={attcheDemo} alt="Option 1" />
  return (
    <>
      <div className="file-img">
        {['png', 'jpeg', 'jpg'].indexOf(file?.split('.').pop()) > -1 ? (
          <img src={data} alt="" width={70} height={50} style={{ objectFit: 'contain'}}  />
        ) : (
          <img src={PDFIcon} alt="Option 1" width={70} height={50} style={{ objectFit: 'contain'}} />
        )}
        <button
          type="button"
          onClick={() => {
            window.open(data, '_blank', 'noopener,noreferrer')
          }}
        >
          <i>
            <DownloadFileIcon />
          </i>
        </button>
      </div>
    </>
  )
}

const ActivityDetails = ({
  isTrue,
  activityList,
  setActivityList,
  setActivityDescription,
  setActivityFileList,
  setActivityType,
  setEditCommentId,
}: any) => {
  const { editId } = useParams()
  const scrollRef = useRef<HTMLDivElement>(null)
  const auth = useContext(AuthContext)
  const [deleteActivityOpen, setDeleteActivityOpen] = useState<boolean>(false)
  const [deleteActivityId, setDeleteActivityId] = useState<any>(null)

  const getFirstLetters = (str: string) => {
    const firstLetters = str
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')

    return firstLetters
  }

  const handleDeleteActivity = useCallback(async () => {
    const res = await client
      .service(SERVICES.ACTIVITY)
      .remove(deleteActivityId)
    if (res) {
      setDeleteActivityId('')
      setDeleteActivityOpen(false)
      if (auth && editId) {
        getAllTaskActivities(editId, setActivityList)
        toast.success('Comment deleted successfully', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 600,
        })
      }
    }
    //   const query = {
    //     taskId: editId,
    //     $sort: {
    //       createdAt: -1,
    //     },
    //   }
    //   const activity = await client.service(SERVICES.ACTIVITY).find({ query })
    //   if (Array.isArray(activity?.data)) {
    //     setActivityList(activity.data)
    //     // setActivitys(null)
    //     setTimeout(() => {
    //       // setActivitys(activity.data)
    //     }, 100)
    //   }
    // }
  }, [deleteActivityId])

  const handleEditActivity = useCallback((e: any) => {
    setEditCommentId(e._id)
    setActivityType('edit')
    setActivityDescription(e.description)
    setActivityFileList(e.fileName)
  }, [])
  // async (param: any) => {
  //   getUrlFileURL(param?.fileName).then((item: any) => {
  //     setEditActivity({
  //       id: param._id,
  //       description: param.description,
  //       files: param?.fileName?.map((obj: any, index: number) => ({
  //         name: obj,
  //         url: item[index],
  //       })),
  //     })
  //   })
  // },
  // [editActivity]
  // )
  return (
    <div>
      {activityList ? (
        <>
          <div className="jobs-list">
            {activityList.map((activity: any, index:number) => (
              <div className="job-item-list" key={activityList.length - index}>
                <div className="user-icon">
                  {getFirstLetters(activity.userName)}
                </div>
                <div className="user-details">
                  <div className="user-inner">
                    <div className="user-inner-left">
                      <h6>{activity.userName}</h6>
                      <span>
                        {dayjs(activity.createdAt).format('MMMM D, YYYY')} at{' '}
                        {dayjs(activity.createdAt).format('h:mm A')}
                      </span>
                    </div>
                    <div className="user-inner-right">
                      <MenuItems
                        data={activity}
                        handleEdit={(e: any) => {
                          handleEditActivity(e)
                          if (scrollRef.current) {
                            scrollRef.current.scrollTo(0, 0)
                          }
                        }}
                        handleDelete={(e: any) => {
                          setDeleteActivityId(e._id)
                          setDeleteActivityOpen(true)
                        }}
                      />
                    </div>
                  </div>
                  <p>
                    <b>{activity.customerName}</b>
                    <div style={{ wordWrap: 'break-word', width: '85%' }}>
                      {activity.description}
                    </div>
                  </p>
                  <div className="atteched-files">
                    {activity.fileName?.length > 0 &&
                      activity.fileName.map((file: any, key:number) => (
                        <div className="atteched-file-item">
                          <RenderImage file={file} isTrue={isTrue} key={`${key}-${key}`}  />
                          {/* <span className="file-name">
                            {file?.split('_').join('_ ')}
                          </span> */}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="center">
          <Loader />
        </div>
      )}
      <ActionModal
        isOpen={deleteActivityOpen}
        handleClose={() => setDeleteActivityOpen(false)}
        title="Delete activity"
        subtitle="Do you wish to delete this activity?"
        actionButtons={
          <>
            <Button
              theme="secondary"
              onClick={() => setDeleteActivityOpen(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => handleDeleteActivity()}
            >
              Delete
            </Button>
          </>
        }
      />
    </div>
  )
}

export default ActivityDetails
