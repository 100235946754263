import { HTMLAttributes, useCallback } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import SubscriptionForm from './forms/SubscriptionForm'
// import './templates.sass'
import { submitSubscriptionHandler } from './utils'

interface IAddAppTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const AddAppTemplate = ({ ...props }: IAddAppTemplateProps) => {
  const handleSubmit = useCallback(submitSubscriptionHandler, [])
  return (
    <div {...props} className="add-app-template templates">
        <PageHeader heading="Create subscription package" showBack />
        <SubscriptionForm handleSubmit={handleSubmit} />
    </div>
  )
}

export default AddAppTemplate
