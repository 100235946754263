interface IModalPreviewEmailTemplateProps {
  title: string
  subtitle: string
}

const ModalPreviewEmailTemplate = ({
  title,
  subtitle,
}: IModalPreviewEmailTemplateProps) => {
  return (
    <div className="delete-email-template">
      <h6 className="title">{title}</h6>
      <div className="subtitle">{subtitle}</div>
    </div>
  )
}

export default ModalPreviewEmailTemplate
