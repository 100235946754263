import { useCallback, useEffect, useReducer, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext, authReducer } from 'context/AuthContext'
import client from 'feathers-client'
import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import { SavedCard } from 'types/card';
import './root.sass'
import { SERVICES, URL_PATH } from 'utils/constants'
import BaseModal from 'components/ActionModal/BaseModal'
import { SubscriptionFetchingSort } from 'pages/subscriptions/utils'
import axios from 'axios'
import Card from 'pages/customer/payments/Card'
import { getImageForBrand, getExpiryStatus } from 'utils/helpers'
import { LINKS } from './constants'
import Loader from 'components/Loader/Loader'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import { showSubscriptionModalHandler, setUserData } from 'store/slices/userSlice'
import type { RootState } from '../store/store'
interface PaymentRequest {
  email?: string;
  user_Id?: string;
  paymentMethodId?: string; // Optional property,
  subscription_Id: string;
  plan: string
}

const checkIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z" fill="#00B8D9" />
</svg>;

const crossIcon = <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.4 15L10 11.4L13.6 15L15 13.6L11.4 10L15 6.4L13.6 5L10 8.6L6.4 5L5 6.4L8.6 10L5 13.6L6.4 15ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z" fill="#EB5757" />
</svg>
  ;

const Root = () => {
  const navigate = useNavigate()
  const [auth, dispatch] = useReducer(authReducer, null)
  const reduxDispatcher = useDispatch();
  // const [showSubscriptionModal, setShowSubscriptionModal] = useState<boolean>(false);
  const showSubscriptionModal = useSelector((state:RootState) => state.userSlice.showSubscriptionModal);
  const [subscriptionsList, setSubsScriptionsList] = useState<any>([])
  const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
  const [showPaymentModal, setShowPaymentModal] = useState<any>(false)
  const [selectedSubscription, setSelectedSubscription] = useState<any>({})
  const [selectedPaymentCard, setSelectedPaymentCard] = useState<any>(null)
  const [paymentLoader, setPaymentLoader] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  
  const checkAuthentication = useCallback(async () => {
    try {
      const auth = await client.reAuthenticate()
      dispatch({
        type: 'added',
        auth,
      })
      reduxDispatcher(setUserData(auth))
    } catch (error) {
      navigate('/login')
    }
  }, [navigate])
  
  useEffect(() => {
    checkAuthentication()
  }, [checkAuthentication])

  useEffect(() => {
    const fetchSubscriptions = setTimeout(async () => {
      try {
        const subscriptions = await SubscriptionFetchingSort();
        // Handle the subscriptions data as needed
        setSubsScriptionsList(subscriptions.data || [])
      } catch (error) {
        // Handle any errors that occur during the fetch
        console.error('Error fetching subscriptions:', error);
      }
    }, 100)

    return () => clearTimeout(fetchSubscriptions)
  }, []);

  useEffect(() => {
    if (auth) {
      fetchPaymentMethods();
      console.log(auth)
      reduxDispatcher(setUserData(auth))
    }
  }, [auth]);

  // useEffect(()=>{
  //   console.log("auth >>>>", auth)
  //   if(!!auth && auth.user.subscriptionStatus !== "active"){
  //     reduxDispatcher(showSubscriptionModalHandler(true))
  //   }
  // },[auth])

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${URL_PATH.CLIENT1}get-payment-methods`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          user_Id: auth?.user?._id,
          name: auth?.user?.name,
          email: auth?.user?.email
        }),
      };
      const response = await axios.request(config);
      if (response && (response.status === 200 || response.status === 201)) {
        setSavedCards(response?.data?.data);
        setSelectedPaymentCard(response?.data?.data[0] || [])
        console.log('RESPONSE ======== ', response)
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    } finally {
      setLoading(false)
    }
  };


  const isCustomer = auth?.user?.permissions?.includes("customer");
  const isTemp = auth?.user?.permissions?.includes("temp");
  
  const subscriptionModalJSX = (
    <div className='subscription-modal-main'>
      <div className="subs-header">
        <img src="/assets/proton-original-logo.png" alt="" />
      </div>
      <div className="subs-content">
        <h2 className='page-title'>Select Package</h2>
        <p>Lorem ipsum dolor sit amet, Ut e exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
      </div>
      <div className="subs-packages-container">
        {subscriptionsList.length > 0 && subscriptionsList.map((subscription: any, index: number) => (
          <div className="subs-package" key={index}>
            <div className="subs-uppper">
              <h3 className="packages-title">{subscription.packageTitle}</h3>
              <div className="package-price">
                <span className="currency-sign">$</span>
                <span className='main-price'>{subscription.monthlySubscription}</span>
                <span className='package-duration'>/month</span>
              </div>
              <div className='package-features-list'>
                {subscription.features.length > 0 && subscription.features.map((feature: any, index: number) => (
                  <div className="package-feature-item" key={index}>
                    <div className="item-icon">{feature.checked ? checkIcon : crossIcon}</div>
                    <div className="item-title">{feature.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <button className='subs-btn' onClick={() => {
              reduxDispatcher(showSubscriptionModalHandler(false))
              setSelectedSubscription(subscription);
              setShowPaymentModal(true);
            }}>Subscribe</button>
          </div>
        ))

        }
        {/* <div className="subs-package active">
          <div className="popular-tag">
            Most Popular
          </div>
          <h3 className="packages-title">Standard </h3>
          <div className="package-price">
            <span className="currency-sign">$</span>
            <span className='main-price'>50</span>
            <span className='package-duration'>/month</span>
          </div>
          <div className='package-features-list'>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">Lorem ipsum dolor sit amet,</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">consectetur adipiscing elit, sed do </div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title"> tempor incididunt ut labore et dolore</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">quis nostrud exercitation ullamco laboris</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{crossIcon}</div>
              <div className="item-title">nim ad minim veniam, quis nostrud</div>
            </div>
          </div>
          <button className='subs-btn' onClick={() => setShowSubscriptionModal(false)}>Subscribe</button>
        </div>
        <div className="subs-package">
          <h3 className="packages-title">Pro</h3>
          <div className="package-price">
            <span className="currency-sign">$</span>
            <span className='main-price'>150</span>
            <span className='package-duration'>/month</span>
          </div>
          <div className='package-features-list'>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">Lorem ipsum dolor sit amet,</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">consectetur adipiscing elit, sed do </div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title"> tempor incididunt ut labore et dolore</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">quis nostrud exercitation ullamco laboris</div>
            </div>
            <div className="package-feature-item">
              <div className="item-icon">{checkIcon}</div>
              <div className="item-title">nim ad minim veniam, quis nostrud</div>
            </div>
          </div>
          <button className='subs-btn' onClick={() => setShowSubscriptionModal(false)}>Subscribe</button>
        </div> */}
      </div>
    </div>
  )

  const renderedCards = () => {
    return (

      <div>
        <div className="cards-list" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '40px' }}>
          {loading && (
            <div className="loader"></div>
          )}
          {savedCards?.length ? savedCards.map((card, index) => (
            // card
            <div key={index} style={{ width: '180px', height: '180px' }}>
              <Card
                key={index}
                title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                image={getImageForBrand(card.card.brand)}
                showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                checkbox={true}
                checked={selectedPaymentCard?.card?.last4 === card.card.last4} // Pass checked prop based on selectedCardIndex
                onClick={() => {
                  setSelectedPaymentCard(card)
                }} // Pass onClick function
              // paymentMethodId={paymentMethodId}
              />
            </div>
          ))
            :
            <p>No cards saved yet.</p>
          }
        </div>
      </div>
    )
  }

  const paymentModalJSX = (
    <div className='payment-modal-main'>
      <div className=''>
        <img src="./assets/logo-black-text.png" alt="" />
      </div>
      <div className="credit_card_heading" style={{
        marginTop: '20px',
      }}>
        <h4 style=
          {{
            fontWeight: '600',
            color: '#00314D',
            marginBottom: '10px',
            fontSize: '40px',
            lineHeight: '48px',
          }}>Make Payment</h4>
        <p style={{
          marginBottom: '20px',
          color: '#60747E',
          fontSize: '16px',
          lineHeight: '19.2px',
          fontWeight: '400',
        }}
        >Manage your credit cards and payment options.</p>
      </div>
      <div className="payment_method">
        {renderedCards()}
      </div>
      <div
        className='payment_logo'
        style={{
          display: 'flex',
          gap: '0px 10px',
          marginTop: '50px',
        }}
      >
        <img src="assets/cardlogo.png" />
        <img src="assets/visacardlogo.png" />
        <img src="/assets/paypallogo.png" />
        <img src="/assets/discoverlogo.png" />
        <img src="/assets/amricanlogo.png" />
      </div>
    </div>
  )

  const paymentModalActionsJSX = (
    <>
      <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>

      <button
        disabled={paymentLoader}
        style={{ minWidth: paymentLoader ? 180 : "fit-content" }}
        className="primary button"
        onClick={() => {
          console.log("savedCards", savedCards);

          if (!savedCards.length) {
            navigate(LINKS.CUSTOMER_PAYMENTS)
          } else {
            paymentHandler("MONTHLY")
          }
        }}
      >{savedCards.length ?
        paymentLoader ? <Loader className='white-color' /> : `Pay Now`
        :
        "Add Card"
        }
      </button>
    </>
  )

  const paymentHandler = async (paymentType: any) => {
    // setPaymentLoader(true)       
    const paymentRequest: PaymentRequest = {
      "user_Id": "6666df938da7f5f1ec05fba6",
      "subscription_Id": "666840e30d34e227c4ae9158",
      "paymentMethodId": "",
      "plan": paymentType//MONTHLY//YEARLY
    };


    if (selectedPaymentCard) {
      paymentRequest.paymentMethodId = selectedPaymentCard.id;
    }

    console.log(paymentRequest, selectedSubscription, selectedPaymentCard)
    const configE = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${URL_PATH.CLIENT1}create-payment`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(paymentRequest),
    };

    try {
      // setLoading(true);
      const apiResult = await axios.request(configE);
      console.log("apiResult >>", apiResult);

      const { status, message } = apiResult.data;

      if (status === 400 || status === 500) {
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000
        });

        setTimeout(() => {
          navigate(LINKS.PAYMENT_FAILED);
          setShowPaymentModal(false)
        }, 2000);
      } else if (status === 200 || status === 201) {
        toast.success(message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000
        });

        // if (paymentType === "deposit") {
        //   isJobActiveHandler(selectedJobDataPayment.id);
        // } else {
        //   fullPaymentHandler(selectedJobDataPayment.id);
        // }

        setTimeout(() => {
          navigate(LINKS.PAYMENT_SUCCESS);
          setShowPaymentModal(false);
        }, 3000);
      }
      setPaymentLoader(false);
    } catch (err: any) {
      setPaymentLoader(false);
      console.log("Something went wrong!!!", err.message);

      setTimeout(() => {
        navigate(LINKS.PAYMENT_FAILED);
        setShowPaymentModal(false);
      }, 2000);
    } finally {
      // setLoading(false);
    }
  }

 

  return (
    <>
      <AuthContext.Provider 
       value={auth}
       >
        <div className="container">
          {/* {(isCustomer || isTemp) && jobs.length > 0 && <div style={paymentDivStyle}>If your app is showing as inactive, kindly reach out to us at &nbsp;<a href="tel:0123654789">0123654789</a>&nbsp; for payment processing.</div>} */}
          <div className="body" 
          // style={{
            //   height: `calc(100% - ${(isCustomer || isTemp) && jobs.length > 0 ? "104px" : "64px"})`
          // }}
          >
            <Sidebar />
            <div className="detail">
              <Header onSearch={(e) => {
                dispatch({ type: 'search', data: e })
              }} />
              <div className="main-container">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
        {isCustomer &&
          <>
            <BaseModal
              className='subscriptionModal'
              isOpen={showSubscriptionModal}
              children={subscriptionModalJSX}
              handleClose={() => reduxDispatcher(showSubscriptionModalHandler(false))}
            />

            <BaseModal
              className='paymentModal'
              isOpen={showPaymentModal}
              children={paymentModalJSX}
              actionButtons={paymentModalActionsJSX}
              handleClose={() => setShowPaymentModal(false)}
            />
          </>
        }
      </AuthContext.Provider>
    </>
  )
}

export default Root
