import {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES, USER_ROLES } from 'utils/constants'

import Loader from 'components/Loader/Loader'
import NoData from 'components/NoData/NoData'
import Button from 'components/forms/Button/Button'

import staffIcon from 'assets/staff.svg'

import StaffListing from './StaffListing'
import './staff.sass'

interface IStaffProps extends HTMLAttributes<HTMLDivElement> { }

export interface IStaffMember {
  id: string
  avatar: string
  name: string
  email: string
  added?: number
  role: keyof typeof USER_ROLES
  invited: number
  revoked: number
  isExpired: boolean
}

const Staff = ({ ...props }: IStaffProps) => {
  const [activeStaff, setActiveStaff] = useState<IStaffMember[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const auth = useContext(AuthContext)
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");
  const fetchUsers = useCallback(async () => {
    if (auth && isSuperAdmin) {
      if (
        auth &&
        (auth?.data === undefined || auth?.data === null || auth?.data === '')
      ) {
        const users = await client.service(SERVICES.USERS).find({
          query: {
            $sort: {
              invited: -1,
            },
          },
        })

        setActiveStaff(
          users?.data?.filter((user:any) => user.permissions?.[0] !== "customer" && user.permissions?.[0] !== "temp").map((user: any) => ({
            id: user._id,
            avatar: user.avatar,
            name: user.name,
            email: user.email,
            added: user.added,
            role: user.permissions?.[0],
            invited: user.invited ,
            isExpired: user.isExpired
          }))
        )
      }
      if (
        auth?.data !== undefined &&
        auth?.data !== null &&
        auth?.data !== ''
      ) {
        setTimeout(async () => {
          const users = await client.service(SERVICES.USERS).find({
            query: {
              search: auth?.data,
              $sort: {
                invited: -1,
              },
            },
          })
          if (Array.isArray(users?.data)) {
            setActiveStaff(
              users?.data?.filter((user:any) => user.permissions?.[0] !== "customer" && user.permissions?.[0] !== "temp").map((user: any) => ({
                id: user._id,
                avatar: user.avatar,
                name: user.name,
                email: user.email,
                added: user.added,
                role: user.permissions?.[0],
                invited: user.invited,
                isExpired: user.isExpired
              }))
            )
          }
        }, 1000)
      }
    }

    setLoading(false)
  }, [auth])

  const handleDeleteUser = useCallback(async (id: any) => {
    if (auth) {
      const headers = {
        Authorization: `Bearer ${auth.accessToken}`,
      }
      await client.service(SERVICES.USERS).remove(id, { headers: headers })
      toast.success('User deleted successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
      })
      fetchUsers()
    }
  }, [auth])

  const onClickSortStaff = useCallback(async (option: any, param: number) => {
    // const obj: { [key: string]: any } = {};

    try{
      const users = await client.service(SERVICES.USERS).find({
        query: {
          // search: 'af',
          $sort: {
            [option]: param,
          },
        },
      })
      
      if (Array.isArray(users?.data)) {
        setActiveStaff(
          users?.data?.filter((user:any) => user.permissions?.[0] !== "customer" && user.permissions?.[0] !== "temp").map((user: any) => ({
            id: user._id,
            avatar: user.avatar,
            name: user.name,
            email: user.email,
            added: user.added,
            role: user.permissions?.[0],
            invited: user.invited,
            isExpired: user.isExpired
          }))
        )
      }
    }catch(err:any){
      console.log(option, param, {err})
    }
  }, [])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  if (loading) {
    return <Loader />
  }
  
  return (
    <div className="staff" {...props}>
      {isSuperAdmin ?
        activeStaff.length ? (
          <StaffListing
            users={activeStaff}
            handleDeleteUser={handleDeleteUser}
            onClickSortStaff={onClickSortStaff}
          />
        ) : (
          <NoData
            icon={staffIcon}
            title="No staff member is added"
            cta={
              <Link to="/staff/invite">
                <Button>Add Staff</Button>
              </Link>
            }
          />
        )
        : (
          <NoData
            icon={staffIcon}
            title="You do not have the correct permissions."
          />
        )}

    </div>
  )
}

export default Staff
