import { useState } from 'react'
import Header from '../Header';
import { Card } from 'antd'

import '../../customer.sass'

interface PaymentInfoProps {
  setAppPrice: (e: any) => void
  appPrice: string,
  footerJSX: any
}

const PaymentInfo = ({ setAppPrice, appPrice, footerJSX }: PaymentInfoProps) => {
  return (
    // <div style={{
    //   height: '100%', width: '80%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'center,'

    // }}>
      <div className="main_card_popup">
        <div className="header_new">
          <Header currentStep={0} />
          <div className="card_top_heading"
            style={{
              padding: '20px 40px 0px 40px',
            }}

          >
          
            <h1>Payment info</h1>
            <p style={{
              color: '#60747E',
              marginTop: '10px',

            }}

            >What would be the price of app?</p>
          </div>
        </div>
        
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 8,
            padding: '40px 40px 40px 40px',
            height: "100%"
          }}
        >
          <Card
            style={{
              width: '49%',
              border:
                appPrice === 'free'
                  ? '1px solid #2CBBE8'
                  : '1px solid #B7C5CC',
              height: "fit-content"
            }}
            onClick={() => setAppPrice('free')}
          >
            <div className="cardStyles">
              <div className="card-inner">
                <div>
                { appPrice === 'free'  ?  <img src="/assets/cu_check_mark.png" alt="check mark" /> : <img src="/assets/cu_check_box.png" alt="check box" />}
                  {/* <input type="checkbox"></input> */}
                </div>
              </div>
              <div>
                <div className="steps-heading">Free</div>
                <div>
                  If the app is free to download, there is no cost to publish it on
                  the app store
                </div>
              </div>
              <div>
                <img src="/assets/percentage 1.png" alt="cloud-showers" />
              </div>
            </div>

          </Card>
          <Card
            style={{
              width: '49%',
              border:
                appPrice === 'paid'
                  ? '1px solid #2CBBE8'
                  : '1px solid #B7C5CC',
              height: "fit-content"
            }}
            onClick={() => setAppPrice('paid')}
          >
            <div className="cardStyles">
            <div className="card-inner">
            <div>
             { appPrice !== 'free'  ?  <img src="/assets/cu_check_mark.png" alt="check mark" /> : <img src="/assets/cu_check_box.png" alt="check box" />}
                  {/* <input type="checkbox"></input> */}
                </div>
              </div>
              <div>
              <div className="steps-heading">Paid</div>
              <div>
                For paid apps, developers pay 30% commission to Apple for sales
                made through app store
              </div>
              </div>
              <div>
                <img src="/assets/credit-card 1.png" alt="cloud-showers" />
              </div>
            </div>
          </Card>
        </div>

          <div className="footer_new">
            {footerJSX}
          </div>

      </div>
    // </div>
  )
}

export default PaymentInfo
