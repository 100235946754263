import { HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import jobsIcon from 'assets/jobs.svg'
import { LINKS } from 'routes/constants'

import NoData from 'components/NoData/NoData'
import Button from 'components/forms/Button/Button'

import './dashboard.sass'
import { AuthContext } from 'context/AuthContext'
import client from 'feathers-client'
import { SERVICES } from 'utils/constants'
import PinnedJobSection from 'components/PinnedCard/PinnedJobSection'
import TotalStaff from 'components/Dashboard/TotalStaff/TotalStaff'
import TotallTemplates from 'components/Dashboard/TotallTemplates/TotallTemplates'
import ResendActivity from 'components/Dashboard/ResendActivity/ResendActivity'
import JobsSummary from "components/Dashboard/JobsSummary/totalJobs";

import unpin from 'assets/thumbtack.svg'

interface IDashboardProps extends HTMLAttributes<HTMLDivElement> {}
const Dashboard = ({ ...props }: IDashboardProps) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext)
  const isSuperAdmin = auth?.user?.permissions?.includes("administrator");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dashboardData, setDashboardData] = useState(null)
  const [jobs,setJobs]= useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [userCount, setUserCount] = useState<number>(0);
  const [graphicDesignerCount, setGraphicDesignerCount] = useState(0);
  const [frontendDeveloperCount, setFrontendDeveloperCount] = useState(0);
  const [backendDeveloperCount, setBackendDeveloperCount] = useState(0);

  const [allActivity, setAllActivity] = useState(0);;
  const [emailCounts, setEmailCounts] = useState(0);
  const [appCounts, setAppCounts] = useState(0);


  const [jobsumrytotal, setjobsumrytotal] = useState(0);
  const [jobsumrynew, setjobsumrynew] = useState(0);
  const [jobsumryin_progress, setjobsumryin_progress] = useState(0);
  const [jobsumryreview, setjobsumryreview] = useState(0);
  const [jobsumrypromoted, setjobsumrypromoted] = useState(0);
  const [jobsumryfinished, setjobsumryfinished] = useState(0);

  const fetchJobs = useCallback(async () => {
    if (auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
      const jobs = await client.service(SERVICES.JOBS).find()
      if (Array.isArray(jobs?.data)) {
       setJobs(jobs.data)
      }
    }
    if (auth?.data !== undefined && auth?.data !== null && auth?.data !== '') {
      setTimeout(async () => {
        const jobs = await client.service(SERVICES.JOBS).find({
          query: {
            search: auth?.data,
          }
        })
        if (Array.isArray(jobs?.data)) {
         setJobs(jobs.data)
        }
      }, 1000);
    }
    setLoading(false)
  }, [auth])

  useEffect(()=>{
    navigate("/jobs")
  },[])


  const handleUnpinJob = useCallback(async (param: any) => {
    await client.service(SERVICES.JOBS).patch(param,{pinned:false});
    const jobs = await client.service(SERVICES.JOBS).find();
    if (Array.isArray(jobs?.data)) {
      setJobs(jobs.data)
    }
  }, []);
 
  const pinnedJobs = useMemo(()=>{
    return jobs.filter((job)=>job.pinned == true)
  },[jobs])

/** Staff Start */

interface UserData {
  jobStatus: any
  _id: string;
  name: string;
  email: string;
  permissions: string[];
  avatar: string;
  added: string;
  invited: string;
}

/**get staff count */
const fetchStaffCount = async () => {
  if (isSuperAdmin && auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
    const result = await client.service(SERVICES.USERS).find({
      query: {}
    });
    if (result) {
      setUserCount(result.total);
      const graphicDesigners = result.data.filter((item: UserData) => item.permissions.includes('graphic-designer'));
      const frontendDevelopers = result.data.filter((item: UserData) => item.permissions.includes('frontend-developer'));
      const backendDevelopers = result.data.filter((item: UserData) => item.permissions.includes('backend-developer'));

      setGraphicDesignerCount(graphicDesigners.length);
      setFrontendDeveloperCount(frontendDevelopers.length);
      setBackendDeveloperCount(backendDevelopers.length);
    }
  }
};
/** APP TEMPLATES */
const fetchAppCount = async () => {
  if (isSuperAdmin && auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
    const APP_result = await client.service(SERVICES.APP_TEMPLATES).find({
      query: {}
    });
    if (APP_result) {
      setAppCounts(APP_result.total);
    }
  }
};
/** EMAIL TEMPLATES */
const fetchEmailCount = async () => {
  if (isSuperAdmin && auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
    const result = await client.service(SERVICES.EMAIL_TEMPLATES).find({
      query: {}
    });
    if (result) {
      setEmailCounts(result.total);
    }
  }
};
/** get ACTIVITY */
const fetchActivity = async () => {
  if (auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
    const activityData = await client.service(SERVICES.ACTIVITY).find({
      query: {
        $limit: 10,
        $sort: { createdAt: -1 },
      }
    });
    if (activityData) {
      setAllActivity(activityData);
    }
  }
};

/** job summery */
const fetchJobSumryCount = async () => {
  if (auth && (auth?.data === undefined || auth?.data === null || auth?.data === '')) {
    const result = await client.service(SERVICES.JOBS).find({
      query: {}
    });
    if (result) {

      
      setjobsumrytotal(result.data.filter((job:any) => !!job?.revoked === false).length);
    
      const jobEmptyStatus = result.data.filter((item: UserData) => !item.jobStatus || item.jobStatus === '');
      const jobNewStatus = result.data.filter((item: UserData) => item.jobStatus === 'new');
      const jobin_progress = result.data.filter((item: UserData) => item.jobStatus?.includes('in_progress'));
      const jobreview = result.data.filter((item: UserData) => item.jobStatus?.includes('review'));
      const jobpromoted = result.data.filter((item: UserData) => item.jobStatus?.includes('promoted'));
      const jobfinished = result.data.filter((item: UserData) => item.jobStatus?.includes('finished'));
      const countEmptyStatus = jobEmptyStatus.length;
      const countNewStatus = jobNewStatus.length;
      const jobnews = countEmptyStatus + countNewStatus;
      
      setjobsumrynew(jobnews);
      setjobsumryin_progress(jobin_progress.length);
      setjobsumryreview(jobreview.length);
      setjobsumrypromoted(jobpromoted.length);
      setjobsumryfinished(jobfinished.length);
    }
  }
};

/** Staff End */
useEffect(() => {
  fetchJobs();
  fetchStaffCount();
  fetchEmailCount();
  fetchAppCount();
  fetchActivity();
  fetchJobSumryCount();
}, [fetchJobs])

  useEffect(() => {
    fetchJobs()
  }, [fetchJobs])

  return (
    <div className="dashboard" {...props}>
      <div className='pinnedList'>
        {pinnedJobs.length > 0 && <PinnedJobSection pinnedJobs={pinnedJobs} handleUnpinJob={handleUnpinJob} />}
      </div>
      {jobs.length > 0  ?
        <div className='job_content_wrap'>
          <div className='job_box flex_wrap2'>
            <div className='inner_job_box'>
              <div className='job_box_2'>
                <TotalStaff
                  totalStaff={userCount}
                  graphicDesigner={graphicDesignerCount}
                  frontEndDeveloper={frontendDeveloperCount}
                  backendDeveloper={backendDeveloperCount}
                />
                <TotallTemplates
                  totalS={emailCounts + appCounts}
                  email={emailCounts}
                  app={appCounts}
                />
              </div>
            <JobsSummary
              jobsumrytotal={jobsumrytotal}
              jobsumrynew={jobsumrynew}
              jobsumryin_progress={jobsumryin_progress}
              jobsumryreview={jobsumryreview}
              jobsumrypromoted={jobsumrypromoted}
              jobsumryfinished={jobsumryfinished}
            />
            </div>
          </div>
          <div className='job_box'>
            <ResendActivity allActivity={allActivity} data={undefined} />
          </div>
        </div>
        : (
          <NoData
            icon={jobsIcon}
            title="No job is added yet"
            // cta={
            //   <Link to={LINKS.JOBS_CREATE}>
            //     <Button>Create Job</Button>
            //   </Link>
            // }
          />
        )}
    </div>
  )
}

export default Dashboard
