import React from 'react'

import Button from 'components/forms/Button/Button'

import ActionModal from './ActionModal'

interface IDeleteModalProps {
  isOpen: boolean
  handleClose: () => void
  handleDelete: () => void
  body?: React.ReactNode
  title?: string
  subtitle?: string
}

const DeleteModal = ({
  isOpen,
  handleClose,
  handleDelete,
  body,
  title = 'Delete',
  subtitle = '',
  ...props
}: IDeleteModalProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      handleClose={handleClose}
      body={body}
      title={title}
      subtitle={subtitle}
      actionButtons={
        <>
          <Button theme="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ backgroundColor: '#FF5630', marginLeft: '12px' }}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </>
      }
      {...props}
    />
  )
}

export default DeleteModal
