import React, { useState, useContext, useEffect } from 'react'
import "./index.sass"
import "./form.sass"
import { DEFAULT_AVATAR, SERVICES, stripePromise, URL_PATH } from 'utils/constants'
import Card from './Card'
import { LINKS } from "routes/constants";
import BaseModal from 'components/ActionModal/BaseModal'
import "../customer.sass"
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js'
import { AuthContext } from 'context/AuthContext'
import axios, { AxiosRequestConfig } from 'axios'
import { getImageForBrand, getExpiryStatus } from 'utils/helpers'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from '@emotion/react';
import { SavedCard } from 'types/card'
import visa from "assets/visa2.png"
import mastercard from "assets/MasterCard_Logo.svg.png"
import card1 from "assets/card1.png"
import pluse1 from "assets/pluse1.png"
import card2 from "assets/card2.png"
import card3 from "assets/card3.png"
import card4 from "assets/card4.png"
import card5 from "assets/card5.png"
import { useNavigate } from 'react-router-dom'
import { getPAYMENT_EditCardLink } from 'routes/constants'
import { Button } from 'antd'


const CardImages: any = {
    "visa": card1,
    "mastercard": card2,
    "american": card3,
    "discover": card4,
    "diners": card5,
}

const PaymentsPage = () => {
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [savedCards, setSavedCards] = useState<SavedCard[]>([]);
    const navigate = useNavigate()
    const auth = useContext(AuthContext)
    const elements = useElements();

    const fetchPaymentMethods = async () => {
        try {
            setLoading(true)
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${URL_PATH.CLIENT1}get-payment-methods`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    user_Id: auth?.user?._id,
                    name: auth?.user?.name,
                    email: auth?.user?.email
                }),
            };
            const response = await axios.request(config);
            if (response && (response.status === 200 || response.status === 201)) {
                setSavedCards(response?.data?.data);
            }
        } catch (error) {
            console.error('Error fetching payment methods:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchPaymentMethods();
    }, [auth?.user?._id]);


    const handleDetachCard = async (paymentMethodId:any) => {
        const stripe = await stripePromise;
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const detachPaymentDetailsConfig: AxiosRequestConfig = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${URL_PATH.CLIENT1}detach-payment-method/${auth?.user?._id}`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: JSON.stringify({
                "paymentMethodId": paymentMethodId,
                "allowRedisplay": "",
            }),
        };
        try {
            const apiResult = await axios.request(detachPaymentDetailsConfig);
            //console.log("apiResult >>", apiResult)
            if (apiResult.status === 200 || apiResult.status === 201) {

                if (apiResult.data.id) {
                    toast.success('Card detached successfully.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 6000
                    });
                    fetchPaymentMethods();
                    setTimeout(() => {
                        handleCloseModal();
                    }, 2000);
                }
            } else {
                console.log("Template not found !!!")
            }
        }
        catch (err: any) {
            toast.error("Something went wrong!!!", err.message);
            console.log("Something went wrong!!!", err.message)
        }

    }

    const handleCloseModal = () => {
        setShowPaymentModal(false);
        setSuccessMessage('');
    };

    // const paymentModalJSX = (
    //     <div className='payment-modal-main'>
    //         {loading && (
    //             <div className="loader"></div>
    //         )}
    //         <div className=''>
    //             <img src="./assets/logo-black-text.png" alt="" />
    //         </div>
    //         <div className="credit_card_heading" style={{
    //             marginTop: '20px',
    //         }}>
    //             <h4 style=
    //                 {{
    //                     fontWeight: '600',
    //                     color: '#00314D',
    //                     marginBottom: '10px',
    //                     fontSize: '40px',
    //                     lineHeight: '48px',
    //                 }}>Add credit card</h4>
    //             <p style={{
    //                 marginBottom: '20px',
    //                 color: '#60747E',
    //                 fontSize: '16px',
    //                 lineHeight: '19.2px',
    //                 fontWeight: '400',
    //             }}
    //             >Manage your credit cards and payment options.</p>
    //         </div>

    //         <form onSubmit={handleSubmit}>
    //             <div className="form-row">
    //                 <label>
    //                     Cardholder's Name:
    //                     <input
    //                         type="text"
    //                         value={cardholderName}
    //                         onChange={(e) => setCardholderName(e.target.value)}
    //                         required
    //                     />
    //                 </label>
    //             </div>
    //             <div className="form-row mt-10">
    //                 <label>
    //                     Card Details:
    //                     <div style={{ marginTop: '10px', marginBottom: "20px" }}>
    //                         <CardElement
    //                             options={{
    //                                 style: {
    //                                     base: {
    //                                         fontSize: '20px',
    //                                         color: '#424770',
    //                                         '::placeholder': {
    //                                             color: '#aab7c4',
    //                                         },
    //                                     },
    //                                     invalid: {
    //                                         color: '#9e2146',
    //                                     },
    //                                 },
    //                             }}
    //                         />
    //                     </div>
    //                 </label>
    //             </div>
    //             {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
    //             <button disabled={loading} className='primary button new mt-5' type="submit" onClick={handleSubmit} style={{ marginLeft: 'auto' }}>Save Payment Method</button>
    //         </form>
    //         <div
    //             className='payment_logo'
    //             style={{
    //                 display: 'flex',
    //                 gap: '0px 10px',
    //                 marginTop: '50px',
    //             }}
    //         >
    //             <img src="assets/cardlogo.png" />
    //             <img src="assets/visacardlogo.png" />
    //             <img src="/assets/paypallogo.png" />
    //             <img src="/assets/discoverlogo.png" />
    //             <img src="/assets/amricanlogo.png" />
    //         </div>
    //     </div>
    // )

    // const paymentModalActionsJSX = (
    //     <>
    //         <button className="cancel_button" style={{ cursor: "pointer" }} onClick={() => setShowPaymentModal(false)}>Cancel</button>
    //         {/* <button className="primary button new" onClick={() => setShowPaymentModal(false)} >Pay now</button> */}
    //     </>
    // )

    return (
        <div className='payments-main-container'>
            {loading && (
                <div className="loader"></div>
            )}
            <section>
                <div>
                    <div className='manage_billing'>
                        <h2>Manage billing methods</h2>
                        <p>Add, update, or remove your billing methods.</p>
                    </div>
                    <div className='manage_billing2'>
                        <h2>Primary</h2>
                        <p>Your primary billing method is used for all recurring payments.</p>
                    </div>

                    <div className='primary_div'>
                        <div className=' primary_flex'>
                            {savedCards?.length ? savedCards?.map((item: any, index: any) => (
                                <div key={index} className='primary_col'>
                                    <div className='visaending'>
                                        <div className='cardsdiv'>
                                            <img src={CardImages[item?.card?.brand]} alt='edit Image' width={"100%"} height={"100%"} />
                                        </div>
                                        <p>{`${item?.card?.brand ? item.card.brand.charAt(0).toUpperCase() + item.card.brand.slice(1).toLowerCase() : ''} ending in ${item?.card?.last4 || '****'}`}</p>
                                        <p style={{paddingLeft:'30px'}} >{item?.card?.country}</p>
                                    </div>
                                    <div>
                                    <a className='editbtnpay' style={{ cursor: 'pointer' }} onClick={() => navigate(getPAYMENT_EditCardLink(item?.id))} >Edit</a>
                                    <a className='deletebtnpay' style={{ cursor: 'pointer' }} onClick={() =>  handleDetachCard(item?.id)} >Remove</a>
                                    </div>
                                </div>
                            ))
                                :
                                (<p>No cards saved yet.</p>)
                            }
                        </div>

                    </div>
                    <p className='pri_par'>You need a primary billing method when you have active contracts or a balance due. To remove this one, set a new primary billing method first.
                        Add a billing method</p>

                    <div className='addbillingdiv'>
                        <svg fill="#2cbbe8" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                            width="24px" height="24px" viewBox="0 0 45.402 45.402"
                        >
                            <g>
                                <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
                                    c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
                                    c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
                                    c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"/>
                            </g>
                        </svg>
                        <a href={`#`} onClick={() => navigate(LINKS.PAYMENT_AddCard)} className='addbilling'>Add a billing method</a>
                    </div>


                </div>
            </section>
            {/* <div className="payments-left-side">
                <h4 className="page-title">
                    Payment Method
                </h4>
                <div style={{ height: "15px" }}></div>
                <p className='page-sub-text'>
                    Manage your credit cards and payment options.
                </p>
                <div style={{ height: "20px" }}></div>
                <button className='primary button new add-card-button' onClick={() => setShowPaymentModal(true)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2969 3.5625H12.7031C12.8281 3.5625 12.8906 3.625 12.8906 3.75V20.25C12.8906 20.375 12.8281 20.4375 12.7031 20.4375H11.2969C11.1719 20.4375 11.1094 20.375 11.1094 20.25V3.75C11.1094 3.625 11.1719 3.5625 11.2969 3.5625Z" fill="white" />
                        <path d="M4.125 11.1094H19.875C20 11.1094 20.0625 11.1719 20.0625 11.2969V12.7031C20.0625 12.8281 20 12.8906 19.875 12.8906H4.125C4 12.8906 3.9375 12.8281 3.9375 12.7031V11.2969C3.9375 11.1719 4 11.1094 4.125 11.1094Z" fill="white" />
                    </svg>
                    Add new card
                </button>

            </div>

            <div className="payments-right-side">
                <div className="cards-list">
                    {savedCards?.length ? savedCards.map((card, index) => (
                        // card
                        <Card
                            key={index}
                            title={card.card.brand === 'paypal' ? card.card.brand : `Ending in ${card.card.last4}`}
                            expiryDate={card.card.exp_month + '/' + card.card.exp_year}
                            image={getImageForBrand(card.card.brand)}
                            showStatus={getExpiryStatus(card.card.exp_month, card.card.exp_year)}
                            paymentMethodId={card.id}
                            fetchPaymentMethods={fetchPaymentMethods}
                        />
                    )) :
                        <p>No cards saved yet.</p>
                    }
                </div>
            </div> */}

            {successMessage && (
                <div className="success-message">{successMessage}</div>
            )}

            {/* <BaseModal
                className="paymentModal"
                isOpen={showPaymentModal}
                children={paymentModalJSX}
                actionButtons={paymentModalActionsJSX}
                handleClose={() => setShowPaymentModal(false)}
            /> */}
        </div>
    )
}

export default PaymentsPage;