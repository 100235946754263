import { useEffect, useState } from 'react'

import {
  // AndroidFilled,
  // AppleFilled,
  // CheckOutlined,
  // MobileFilled,
  // MobileOutlined,
} from '@ant-design/icons'
import { Button, Card, Input } from 'antd'

import '../../customer.sass'

interface appInfoProps {
  setAppTitle: (e: any) => void
  appTitle: string,
  customerBusinessName: string,
  setCustomerBusinessName: (e: any) => void,
  setAppTheme: (e: any) => void
  setDevelopedFor: (e: any) => void
  setColor: (e: any) => void
  appTheme: string
  developedFor: string
  color: string
}

const AppInfo = ({
  setAppTitle,
  appTitle,
  customerBusinessName,
  setCustomerBusinessName,
  setAppTheme,
  setDevelopedFor,
  appTheme,
  developedFor,
  setColor,
  color
}: appInfoProps) => {
  const [currentColorID, setCurrentColorId] = useState(1)

  const data = [
    { id: 1, color: '#6B71E5' },
    { id: 2, color: '#0091FF' },
    { id: 3, color: '#2CBBE8' },
    { id: 4, color: '#61D3FE' },
    { id: 5, color: '#48DC6B' },
    { id: 6, color: '#FF4866' },
    { id: 7, color: '#FE5533' },
    { id: 8, color: '#FFA600' },
    { id: 9, color: '#FFD100' },
  ]


  const handleSelectClick = (ele: any) => {
    setCurrentColorId(ele.id)
    setColor(ele.color)
  }

  return (
    <div style={{ height: '100%', width: '85%' }}>
      <div
        style={{
          display: 'flex',
          width: '80%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <div
          style={{
            width: '500px',
            lineHeight: '30px',
          }}
        >
          <div style={{ fontWeight: '500' }}>App title</div>
          <Input
            placeholder="Type app title.."
            width={'80%'}
            value={appTitle}
            onChange={(e) => setAppTitle(e.target.value)}
          />
        </div>
        <div>OR</div>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <div style={{ lineHeight: '20px', width: '130px' }}>
            <div className="steps-heading">Get the app name</div>
            <div style={{ fontSize: '12px' }}>For a small fee</div>
          </div>
          <Button type="primary" style={{ backgroundColor: '#2CBBE8' }}>
            Subscribe
          </Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '80%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '30px',
          marginTop:10
        }}
      >
        <div
          style={{
            width: '500px',
            lineHeight: '30px',
          }}
        >
          <div style={{ fontWeight: '500' }}>Business name</div>
          <Input
            placeholder="Type app title.."
            width={'80%'}
            value={customerBusinessName}
            onChange={(e) => setCustomerBusinessName(e.target.value)}
          />
        </div>
      </div>
      <p className="steps-heading">Developed for</p>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 8,
        }}
      >
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card key={0}
            style={{
              border:
                developedFor === 'ios&android'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setDevelopedFor('ios&android')}
          >
            <div className="icons-container">
              {/* <AppleFilled className="icons" />
              <AndroidFilled className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">IOS & Android</div>
        </div>
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card key={1}
            style={{
              border:
                developedFor === 'ios'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setDevelopedFor('ios')}
          >
            <div className="icons-container">
              {/* <AppleFilled className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">IOS</div>
        </div>
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card key={2}
            style={{
              border:
                developedFor === 'android'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setDevelopedFor('android')}
          >
            <div className="icons-container">
              {/* <AndroidFilled className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">Android</div>
        </div>
      </div>
      <p className="steps-heading">Choose app theme</p>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 8,
        }}
      >
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card key={3}
            style={{
              border:
                appTheme === 'light&dark'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setAppTheme('light&dark')}
          >
            <div className="icons-container">
              {/* <MobileFilled className="icons" /> */}
              {/* <MobileOutlined className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">Light & Dark</div>
        </div>
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card  key={4}
            style={{
              border:
                appTheme === 'light'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setAppTheme('light')}
          >
            <div className="icons-container">
              {/* <MobileOutlined className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">Light</div>
        </div>
        <div style={{ width: '16%', textAlign: 'center' }}>
          <Card key={5}
            style={{
              border:
                appTheme === 'dark'
                  ? '2px solid #2CBBE8'
                  : '2px solid transparent',
            }}
            onClick={() => setAppTheme('dark')}
          >
            <div className="icons-container">
              {/* <MobileFilled className="icons" /> */}
            </div>
          </Card>
          <div className="icons-name">Dark</div>
        </div>
      </div>
      <p className="steps-heading">Choose color palette</p>
      <div style={{ display: 'flex' }}>
        {data.map((ele,index) => {
          return (
            <div key={index}
              onClick={() => handleSelectClick(ele)}
              className="color-palatte-container"
              style={{
                backgroundColor: ele.color,
              }}
            >
              {/* {ele.color === color ? (
                <CheckOutlined style={{ color: '#fff', fontSize: '20px' }} />
              ) : null} */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AppInfo
