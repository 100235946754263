import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import client from 'feathers-client'
import { Formik, FormikHelpers } from 'formik'
import { SERVICES, URL_PATH, generateRandomPassword } from 'utils/constants'
import protonLogo from 'assets/Proton-white.svg'

import Button from 'components/forms/Button/Button'
import TextField from 'components/forms/TextField/TextField'

// import './contact.sass';
import { AuthContext } from 'context/AuthContext'
import axios, { AxiosRequestConfig } from 'axios';
import PageHeader from 'components/Header/PageHeader/PageHeader'
import { logout } from 'utils/helpers'
import './EditProfile.sass'

interface ICreateJobFormData {
    fullName: string
    password: string
    businessName: string
    email: string
}

interface ICreateJobForm { }

export const submitUpdatedInfo = async (
    values: ICreateJobFormData,
    actions: FormikHelpers<ICreateJobFormData>,
    auth: any,
    passHidden:any
) => {
    
    try {
            const data = await client.service(SERVICES.USERS).patch(auth.user._id, 
                !!passHidden ?
                    { 
                        name: values.fullName,
                        password: passHidden,
                        businessName: values.businessName
                    }
                :
                    {
                        name: values.fullName,
                        businessName: values.businessName
                    }
            )
            
            actions.setSubmitting(false)
    
            // const user = await client.authenticate({
            //     strategy: 'local',
            //     email: values.email,
            //     password: values.password,
            //   })
             
            //   window.location.href = "/"
            toast.success("Profile info is updated successfully.", {
                position: toast.POSITION.BOTTOM_CENTER,
            })
            setTimeout(() => {
                // logout();
                window.location.reload();
            }, 1000);
            return data
        
      } catch (error: any) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        })
        actions.setSubmitting(false)
        return false
      }
}



const FormikForm = ({
    auth,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    generatePasswordHandler,
    copyTextToClipboard,
    setFullname,
    onPasswordChange,
    password,
    setBusinessName,
    isCustomer
}: any) => {

    return (

        <>
            {/* <div className="login" style={{width:"100%"}}>
                <div className="login-container">
                <div className="login-logo">
                    <img src={protonLogo} alt="Proton Logo" />
                </div>
                <div className="login-content"> */}
                    <div style={{width:"100%"}}>
                        <PageHeader heading="Update Info" showBack />
                        <div className="create-job-form-container " style={{maxWidth:'500px', margin:'auto',}}>
                            <div className="top_image_section">
                            <div className="main_image_section">
                            <div className="update-info_image">
                                <img src={auth?.user?.avatar ? auth?.user?.avatar  : "/assets/profile_image.png"} />
                            </div>
                            <div className="camera_image">
                                <img src="/assets/camera_icon.png" />
                            </div>
                            </div>
                            <div className="image_section_heading">
                            <h4>{auth?.user?.name}</h4>
                            {/* <p>@kevin_tera</p> */}
                            </div>
                            </div>
                            <form className="update_profile" onSubmit={handleSubmit}>
                                {/* <h3 style={{textAlign:'center'}}>Update Info</h3> */}
                                <TextField
                                    label="Full Name"
                                    id="fullName"
                                    placeholder="Type name..."
                                    type="text"
                                    name="fullName"
                                    onChange={(e:any)=>{
                                        handleChange(e)
                                        setFullname(e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.fullName}
                                    errors={errors}
                                    required />
                                {isCustomer && 
                                    <TextField
                                        label="Business Name"
                                        id="businessName"
                                        placeholder="Type business name..."
                                        type="text"
                                        name="businessName"
                                        onChange={(e:any)=>{
                                            handleChange(e)
                                            setBusinessName(e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        value={values.businessName}
                                        errors={errors}
                                        required 
                                    />
                                }
                                <TextField
                                    label="Email"
                                    id="email"
                                    placeholder="Type email..."
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    disabled
                                    />
                                
                                <TextField
                                    label="Password"
                                    id="password"
                                    placeholder="Enter password..."
                                    type="text"
                                    name="password"
                                    onChange={(e:any)=>onPasswordChange(e.target.value)}
                                    onBlur={handleBlur}
                                    value={password}
                                    errors={errors}
                                    />

                                <div className="buttons-container">
                                    {/* <div className='generate_pass_div' style={{display:"flex", gap:10, width:'100%', marginTop: '20px' }}>
                                    
                                        <button 
                                            className='primary button generate_btn' 
                                            style={{width:'50%'}}
                                            onClick={generatePasswordHandler}
                                        >Generate Suggested Password</button>
                                        <button 
                                            className='primary button copy_btn' 
                                            style={{ width:'50%'}}
                                            onClick={copyTextToClipboard}
                                        >Copy To Clipboard</button>
                                    </div> */}
                                    {/* <br/> */}
                                    <Button type="submit" disabled={isSubmitting} style={{width:"100%"}}>
                                        Update
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                {/* </div>
                </div>
            </div> */}

            {/* <Header /> */}
        </>
    )
}

const EditProfile = ({ ...props }: ICreateJobForm) => {
    const auth = useContext(AuthContext)
    const handleSubmit = useCallback(submitUpdatedInfo, [auth])
    const [fullName, setFullname] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [email, setEmail] = useState("")
    const [generatedPassword, setGeneratedPassword] = useState("")

    const [password, setPassword] = useState("");
    const [passHidden, setPassHidden] = useState("");
    const hideAllRef:any = useRef(null);
    const isCustomer = auth?.user?.permissions?.includes("customer");
    const navigate = useNavigate();
    const onSubmit = useCallback(
        async (
            values: ICreateJobFormData,
            actions: FormikHelpers<ICreateJobFormData>
        ) => {
            const form_res = await handleSubmit(values, actions, auth, passHidden)
        },
        [handleSubmit, auth, passHidden]
    )

    const onPasswordChange = (e:any) => {
        console.log(password, passHidden)
        const passwordValue = e;
        const hiddenPassValue = passHidden;
    
        let showLength = 1;
        let offset = passwordValue.length - hiddenPassValue.length;
    
        if (offset > 0) {
          setPassHidden(hiddenPassValue + passwordValue.substring(hiddenPassValue.length, hiddenPassValue.length + offset));
        } else if (offset < 0) {
          setPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset));
        }
    
        // Change the visible string
        setPassword(passwordValue.substring(0, passwordValue.length - showLength).replace(/./g, "•") + passwordValue.substring(passwordValue.length - showLength, passwordValue.length));
    
        // Set the timer
        clearTimeout(hideAllRef?.current);
        hideAllRef.current = setTimeout(() => {
          setPassword(passwordValue.replace(/./g, "•"));
        }, 1000);
      };


    useEffect(() => {
        if(!!auth){
            setFullname(auth?.user?.name || "");
            setBusinessName(auth?.user?.businessName || "");
            setEmail(auth?.user?.email || "");
        }
    },[auth])
    


    

    const generatePasswordHandler = (e:any) => {
        e.preventDefault()
        const generatedPassword = generateRandomPassword(10);
        setPassword(generatedPassword)
        // console.log(generatedPassword)
        setTimeout(() => {
            onPasswordChange(generatedPassword)
        }, 1000);
    }
      const isWeakPassword = (password: any): boolean => {
        // Minimum number of characters required
        const minLength: number = 8;
        // Regular expression for special characters
        const specialCharRegex: RegExp = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
        // Check if the password meets the criteria
        return password.length < minLength || !specialCharRegex.test(password);
      }
    
    
      const copyTextToClipboard = (e:any) => {
        e.preventDefault();
        if(passHidden.trim().length > 0){
          navigator.clipboard.writeText(passHidden)
            .then(() => {
              // setCopySuccess('Text copied to clipboard!');
              toast.success('Password successfully copied to the clipboard!', {
                position: toast.POSITION.BOTTOM_CENTER,
              });
    
            })
            .catch(err => {
              // console.error('Error copying text: ', err);
              // setCopySuccess('Failed to copy text!');
              toast.error('Something went wrong!!!', {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            });
        }
      };

    return (
        <Formik
            initialValues={{
                // name: '',
                fullName: fullName,
                password: password,
                businessName: businessName,
                email: email
            }}
            enableReinitialize
            validate={(values: ICreateJobFormData) => {
                const errors: any = {}
                // if (!values.name) {
                //   errors.name = 'Required'
                // }

                if (!values.fullName) {
                    errors.fullName = 'Required'
                }
                
                if (isCustomer && !values.businessName) {
                    errors.businessName = 'Required'
                }

                // if (!values.password) {
                //     errors.password = 'Required'
                // }

                if (passHidden && isWeakPassword(passHidden)) {
                    errors.password = `Use at least 8 characters and at least one special character.`;
                }

                // if (!values.business_name) {
                //   errors.business_name = 'Required'
                // }

                return errors
            }}
            onSubmit={onSubmit}
        >
            {({ ...props }) => <FormikForm 
                {...props} 
                auth={auth}
                isCustomer={isCustomer}
                setFullname={setFullname} 
                setBusinessName={setBusinessName} 
                generatePasswordHandler={generatePasswordHandler} 
                copyTextToClipboard={copyTextToClipboard}  
                password={password}
                onPasswordChange={onPasswordChange}
            />}
        </Formik>
    )
}

export default EditProfile
