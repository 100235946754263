import { HTMLAttributes, useState } from 'react'

import { LINKS } from 'routes/constants'

import TabLinks from 'components/TabLinks/TabLinks'
import DropdownButton from 'components/forms/Button/DropdownButton/DropdownButton'
import sortAscIcon from 'assets/sort_asc.svg'
import sortDescIcon from 'assets/sort_desc.svg'

import emailIcon from 'assets/mail.svg'
import appTemplateIcon from 'assets/more.svg'
import Button from 'components/forms/Button/Button'
import "./templates.sass"
import { Select } from 'antd'
import { Link, useLocation } from 'react-router-dom'
// import { title } from 'process'

interface ITemplatesHeaderProps extends HTMLAttributes<HTMLDivElement> {
  sortTemplate:any;
  optionValue?: string;
  selectField?:any
}

const links = [
  {
    label: 'Email templates',
    to: LINKS.TEMPLATES_EMAIL,
  },
  {
    label: 'App templates',
    to: LINKS.TEMPLATES_APP,
  },
  {
    label: 'Task templates',
    to: LINKS.TEMPLATES_TASK,
  },
]

const addLinks = [
  {
    label: 'Add email template',
    to: LINKS.TEMPLATES_EMAIL_ADD,
    icon: emailIcon,
  },
  {
    label: 'Add app template',
    to: LINKS.TEMPLATES_APP_ADD,
    icon: appTemplateIcon,
  },
  {
    label: 'Add task template',
    to: LINKS.TEMPLATES_TASK_ADD,
    icon: appTemplateIcon,
  },
]

const TemplatesHeader = ({ ...props }: ITemplatesHeaderProps) => {
  const location = useLocation();
  const Option=  Select.Option
  // const [optionValue, setOptionValue]= useState("title")
  
  const [sort, setSort] = useState<any>(1);
 
  //  const SelectField = useCallback(()=>{
  //   return(
  //   <Select style={{ width: 180 }} onChange={(e)=>setOptionValue(e)}  defaultValue={"select"}>
  //     <Option value="title">Title</Option>
  //     <Option value="subject">Subject</Option>
  //   </Select>
  //   )
  // },[])

  return (
    <div className="templates-header" {...props}>
      <div className="left">
        {/* <TabLinks links={links} /> */}
        <h5 className='page-title'>Templates</h5>
        
      </div>
      <div className="right">
        {/* <div className='label'>Sorting:</div>
        {props.selectField && <props.selectField/>}
        <Button
            theme="transparent"
            className="close-button"
            onClick={() => {
              setSort(sort === 1 ? -1 : 1);
              if(props.sortTemplate){
                if(props.optionValue){
                  props.sortTemplate(props.optionValue,sort)
                }else{
                  props.sortTemplate(sort)
                }
              }
          }}
        >
          <img src={sort === 1 ? sortAscIcon : sortDescIcon} style={{ height: '24px', width: '24px' }} alt="close" />
        </Button> */}
        <DropdownButton label="Add template" items={addLinks} />
      </div>
      <div className="bar">
        <div className="jobs-tabs-bar">
            {links.map((link, index)=>(
              <Link to={link.to} key={index}> 
                <button className={`primary button pri_btn ${location.pathname === link.to  ? "primary_active" :""}`}>
                    {link.label}
                </button>
              </Link>
            ))}
          </div>
      </div>
    </div>
  )
}

export default TemplatesHeader
