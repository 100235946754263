import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'
import dayjs from 'dayjs'
import client from 'feathers-client'
import axiosInstance from 'utils/axios'
import { SERVICES } from 'utils/constants'

import ActionModal from 'components/ActionModal/ActionModal'
import KebabMenu from 'components/KebabMenu/KebabMenu'
import Loader from 'components/Loader/Loader'
import Button from 'components/forms/Button/Button'

import attcheDemo from 'assets/attach-demo.jpg'
import closeIcon from 'assets/close.svg'
import { ReactComponent as DownloadFileIcon } from 'assets/download_file.svg'
import planeIcon from 'assets/paper-plane 1.svg'
import PDFIcon from 'assets/pdf-file.svg'

const MenuItems = (props: any) => {
  const { data, handleEdit, handleDelete } = props
  const cols = useMemo(
    () => [
      {
        label: 'Edit',
        icon: planeIcon,
        onClick: () => {
          if (handleEdit) {
            handleEdit(data)
          }
        },
      },
      {
        label: 'Delete',
        icon: closeIcon,
        onClick: () => {
          if (handleDelete) {
            handleDelete(data)
          }
        },
      },
    ],
    []
  )
  return <KebabMenu items={cols} />
}

const RenderImage = (props: any) => {
  const { file } = props
  const [data, setData] = useState()
  const auth = useContext(AuthContext)

  const getUrlFileURL = async (str: string) => {
    try {
      const response = await axiosInstance.get(`getSignedFileUrl/?fileName=${str}`)
      return response.data.url
    } catch (error) {
      console.error('getSignedFileUrl error:', error)
      return ''
    }
  }

  //use effect to fetch on mount
  useEffect(() => {
    getUrlFileURL(file).then((val) => {
      setData(val)
    })
  }, [])

  if (data === undefined) return <img src={attcheDemo} alt="Option 1" />

  return (
    <>
      <div className="file-img">
        {['png', 'jpeg', 'jpg'].indexOf(file?.split('.').pop()) > -1 ? (
          <img src={data} title={file} alt="" />
        ) : (
          <img src={PDFIcon} alt="Option 1" />
        )}
        <button
          type="button"
          onClick={() => {
            window.open(data, '_blank', 'noopener,noreferrer')
          }}
        >
          <i>
            <DownloadFileIcon />
          </i>
        </button>
      </div>
    </>
  )
}

const JobActivityComments = ({
  activitys,
  handleEditActivity,
  setActivitys,
}: any) => {
  const [deleteActivityOpen, setDeleteActivityOpen] = useState<boolean>(false)
  const [deleteActivityId, setDeleteActivityId] = useState<any>(null)
  const { jobId } = useParams()
  const auth = useContext(AuthContext)

  const scrollRef = useRef<HTMLDivElement>(null)
  const getFirstLetters = (str: string) => {
    const firstLetters = str
      .split(' ')
      .map((word) => word.charAt(0))
      .join('')

    return firstLetters
  }

  const handleDeleteActivity = useCallback(async () => {
    await client.service(SERVICES.ACTIVITY).remove(deleteActivityId, {})
    setDeleteActivityOpen(false)
    const query = {
      jobId: jobId,
      $sort: {
        createdAt: -1,
      },
    }
    const activity = await client.service(SERVICES.ACTIVITY).find({ query })
    if (Array.isArray(activity?.data)) {
      setActivitys(null)
      setTimeout(() => {
        setActivitys(activity.data)
      }, 100)
    }
  }, [jobId, deleteActivityId])

  return (
    <div>
      {activitys ? (
        <>
          <div className="jobs-list">
            {activitys.map((activity: any) => (
              <div className="job-item-list">
                <div className="user-icon">
                  {getFirstLetters(activity.userName)}
                </div>
                <div className="user-details">
                  <div className="user-inner">
                    <div className="user-inner-left">
                      <h6>{activity.userName}</h6>
                      <span>
                        {dayjs(activity.createdAt).format('MMMM D, YYYY')} at{' '}
                        {dayjs(activity.createdAt).format('h:mm A')}
                      </span>
                    </div>
                    <div className="user-inner-right">
                      <MenuItems
                        data={activity}
                        handleEdit={(e: any) => {
                          handleEditActivity(e)
                          if (scrollRef.current) {
                            scrollRef.current.scrollTo(0, 0)
                          }
                        }}
                        handleDelete={(e: any) => {
                          setDeleteActivityId(e._id)
                          setDeleteActivityOpen(true)
                        }}
                      />
                    </div>
                  </div>
                  <p>
                    <b>{activity.customerName}</b>
                    <div style={{ wordWrap: 'break-word', width: '85%' }}>
                      {activity.description}
                    </div>
                  </p>
                  <div className="atteched-files">
                    {activity.fileName?.length > 0 &&
                      activity.fileName.map((file: any) => (
                        <div className="atteched-file-item">
                          <RenderImage file={file} />
                          <span className="file-name">
                            {file?.split('_').join('_ ')}
                          </span>
                        </div>
                      ))}
                    {/* <div className='atteched-file-item'>
                              <div className='file-img'>
                                <img src={attcheDemo} />
                                <button type="button"><i><DownloadFileIcon />
                                </i></button>
                              </div>
                              <span className='file-name'>My weather app.apk</span>
                            </div> */}
                  </div>
                </div>
              </div>
            ))}
            {/* <div className='job-item-list'>
                      <div className='user-icon'>JD</div>
                      <div className='user-details'>
                        <h6>John Doe</h6>
                        <span>April 13, 2023 at 10:38 AM</span>
                        <p><b>Albert Flores</b> Please review the mobile app screens added. I have included APK file just install this in your phone and try to play with the app features. I have also added IOS versions. Do let me know which one you like the most!!!</p>
                        <div className='atteched-files'>
                          <div className='atteched-file-item'>
                            <div className='file-img'>
                              <img src={attcheDemo} />
                              <button type="button"><i><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" fill="white" fill-opacity="0.01" />
                                <path d="M8.90714 14.41C8.83097 14.3331 8.74031 14.272 8.6404 14.2303C8.54049 14.1887 8.43331 14.1672 8.32505 14.1672C8.2168 14.1672 8.10962 14.1887 8.00971 14.2303C7.9098 14.272 7.81914 14.3331 7.74297 14.41C7.58852 14.566 7.50188 14.7767 7.50188 14.9963C7.50188 15.2158 7.58852 15.4265 7.74297 15.5825L9.37964 17.2325C9.46348 17.3172 9.56329 17.3845 9.67328 17.4303C9.78328 17.4762 9.90128 17.4999 10.0205 17.4999C10.1397 17.4999 10.2577 17.4762 10.3677 17.4303C10.4777 17.3845 10.5775 17.3172 10.6613 17.2325L12.2605 15.6217C12.4152 15.4656 12.502 15.2547 12.502 15.035C12.502 14.8153 12.4152 14.6044 12.2605 14.4483C12.1843 14.3713 12.0936 14.3101 11.9936 14.2684C11.8936 14.2266 11.7863 14.2051 11.678 14.2051C11.5696 14.2051 11.4623 14.2266 11.3624 14.2684C11.2624 14.3101 11.1717 14.3713 11.0955 14.4483L10.0205 15.5317L8.90714 14.41Z" fill="#5E6C84" />
                                <path d="M10.8355 16.6608L10.8346 8.33833C10.8346 7.87583 10.4613 7.5 10.0013 7.5C9.5413 7.5 9.16797 7.875 9.16797 8.33917L9.1688 16.6617C9.1688 17.1242 9.54214 17.5 10.0021 17.5C10.4621 17.5 10.8355 17.125 10.8355 16.6608Z" fill="#5E6C84" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6163 4.56667C6.40223 4.53192 6.18568 4.51464 5.9688 4.515C3.6313 4.515 1.66797 6.35 1.66797 8.74833C1.66797 11.1742 3.65547 13.3333 5.91797 13.3333H8.3188V11.6725H5.91797C4.53714 11.6725 3.32214 10.2867 3.32214 8.74917C3.32214 7.2675 4.5463 6.17667 5.9088 6.17667H5.9188C6.24297 6.17667 6.49047 6.21833 6.72714 6.30167L6.8688 6.35417C7.37297 6.56083 7.59797 6.14917 7.59797 6.14917L7.72297 5.92667C8.3313 4.80417 9.55714 4.18 10.8196 4.16C11.6573 4.16836 12.4632 4.48118 13.0872 5.0401C13.7111 5.59902 14.1104 6.36584 14.2105 7.1975L14.2488 7.48083C14.2488 7.48083 14.308 7.91833 14.803 7.91833C14.8138 7.91833 14.813 7.9225 14.8221 7.9225H15.0338C15.9805 7.9225 16.6805 8.72167 16.6805 9.72083C16.6805 10.7267 15.858 11.6725 14.9555 11.6725H11.6521V13.3333H14.9555C16.7555 13.3333 18.3346 11.6292 18.3346 9.72083C18.3346 8.05417 17.2413 6.66833 15.7196 6.32583C15.1305 4.07 13.1755 2.5325 10.8146 2.5C9.1688 2.51667 7.5638 3.25 6.6163 4.56667Z" fill="#5E6C84" />
                              </svg>
                              </i></button>
                            </div>
                            <span className='file-name'>My weather app.apk</span>

                          </div>
                          <div className='atteched-file-item'>
                            <div className='file-img'>
                              <img src={attcheDemo} />
                              <button type="button"><i><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" fill="white" fill-opacity="0.01" />
                                <path d="M8.90714 14.41C8.83097 14.3331 8.74031 14.272 8.6404 14.2303C8.54049 14.1887 8.43331 14.1672 8.32505 14.1672C8.2168 14.1672 8.10962 14.1887 8.00971 14.2303C7.9098 14.272 7.81914 14.3331 7.74297 14.41C7.58852 14.566 7.50188 14.7767 7.50188 14.9963C7.50188 15.2158 7.58852 15.4265 7.74297 15.5825L9.37964 17.2325C9.46348 17.3172 9.56329 17.3845 9.67328 17.4303C9.78328 17.4762 9.90128 17.4999 10.0205 17.4999C10.1397 17.4999 10.2577 17.4762 10.3677 17.4303C10.4777 17.3845 10.5775 17.3172 10.6613 17.2325L12.2605 15.6217C12.4152 15.4656 12.502 15.2547 12.502 15.035C12.502 14.8153 12.4152 14.6044 12.2605 14.4483C12.1843 14.3713 12.0936 14.3101 11.9936 14.2684C11.8936 14.2266 11.7863 14.2051 11.678 14.2051C11.5696 14.2051 11.4623 14.2266 11.3624 14.2684C11.2624 14.3101 11.1717 14.3713 11.0955 14.4483L10.0205 15.5317L8.90714 14.41Z" fill="#5E6C84" />
                                <path d="M10.8355 16.6608L10.8346 8.33833C10.8346 7.87583 10.4613 7.5 10.0013 7.5C9.5413 7.5 9.16797 7.875 9.16797 8.33917L9.1688 16.6617C9.1688 17.1242 9.54214 17.5 10.0021 17.5C10.4621 17.5 10.8355 17.125 10.8355 16.6608Z" fill="#5E6C84" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6163 4.56667C6.40223 4.53192 6.18568 4.51464 5.9688 4.515C3.6313 4.515 1.66797 6.35 1.66797 8.74833C1.66797 11.1742 3.65547 13.3333 5.91797 13.3333H8.3188V11.6725H5.91797C4.53714 11.6725 3.32214 10.2867 3.32214 8.74917C3.32214 7.2675 4.5463 6.17667 5.9088 6.17667H5.9188C6.24297 6.17667 6.49047 6.21833 6.72714 6.30167L6.8688 6.35417C7.37297 6.56083 7.59797 6.14917 7.59797 6.14917L7.72297 5.92667C8.3313 4.80417 9.55714 4.18 10.8196 4.16C11.6573 4.16836 12.4632 4.48118 13.0872 5.0401C13.7111 5.59902 14.1104 6.36584 14.2105 7.1975L14.2488 7.48083C14.2488 7.48083 14.308 7.91833 14.803 7.91833C14.8138 7.91833 14.813 7.9225 14.8221 7.9225H15.0338C15.9805 7.9225 16.6805 8.72167 16.6805 9.72083C16.6805 10.7267 15.858 11.6725 14.9555 11.6725H11.6521V13.3333H14.9555C16.7555 13.3333 18.3346 11.6292 18.3346 9.72083C18.3346 8.05417 17.2413 6.66833 15.7196 6.32583C15.1305 4.07 13.1755 2.5325 10.8146 2.5C9.1688 2.51667 7.5638 3.25 6.6163 4.56667Z" fill="#5E6C84" />
                              </svg>
                              </i></button>
                            </div>
                            <span className='file-name'>My weather app.apk</span>
                          </div>
                        </div>
                      </div>
                    </div> */}
          </div>
        </>
      ) : (
        <div className="center">
          <Loader />
        </div>
      )}
      <ActionModal
        isOpen={deleteActivityOpen}
        handleClose={() => setDeleteActivityOpen(false)}
        title="Delete activity"
        subtitle="Do you wish to delete this activity?"
        actionButtons={
          <>
            <Button
              theme="secondary"
              onClick={() => setDeleteActivityOpen(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => handleDeleteActivity()}
            >
              Delete
            </Button>
          </>
        }
      />
    </div>
  )
}

export default JobActivityComments
