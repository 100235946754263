import Reac, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { URL_PATH, crypto_secret, generateRandomPassword } from 'utils/constants';
import './login.sass';
import CryptoJS from 'crypto-js';

import { Link, useNavigate } from 'react-router-dom'
import TextField from 'components/forms/TextField/TextField';
import Button from 'components/forms/Button/Button';


const LoginUpdate = () => {
  // const [generatedPassword, setGeneratedPassword] = useState("")
  const [password, setPassword] = useState("");
  const [passHidden, setPassHidden] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPassHidden, setConfirmPassHidden] = useState("");
  const hideAllRef:any = useRef(null);
  const hideConfirmAllRef:any = useRef(null);
  const navigate = useNavigate();

  const siteCurrentUrl: string = window.location.href;
  const url = new URL(siteCurrentUrl);
  const searchParams = url.search;
  const token = searchParams.slice(searchParams.indexOf('=') + 1);

  const encryptionKey = crypto_secret;

  const onPasswordChange = (e:any) => {
    console.log(password, passHidden)
    const passwordValue = e;
    const hiddenPassValue = passHidden;

    let showLength = 1;
    let offset = passwordValue.length - hiddenPassValue.length;

    if (offset > 0) {
      setPassHidden(hiddenPassValue + passwordValue.substring(hiddenPassValue.length, hiddenPassValue.length + offset));
    } else if (offset < 0) {
      setPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset));
    }

    // Change the visible string
    setPassword(passwordValue.substring(0, passwordValue.length - showLength).replace(/./g, "•") + passwordValue.substring(passwordValue.length - showLength, passwordValue.length));

    // Set the timer
    clearTimeout(hideAllRef?.current);
    hideAllRef.current = setTimeout(() => {
      setPassword(passwordValue.replace(/./g, "•"));
    }, 1000);
  };

  const onConfirmPasswordChange = (e:any) => {
    console.log(password, passHidden)
    const passwordValue = e;
    const hiddenPassValue = confirmPassHidden;

    let showLength = 1;
    let offset = passwordValue.length - hiddenPassValue.length;

    if (offset > 0) {
      setConfirmPassHidden(hiddenPassValue + passwordValue.substring(hiddenPassValue.length, hiddenPassValue.length + offset));
    } else if (offset < 0) {
      setConfirmPassHidden(hiddenPassValue.substring(0, hiddenPassValue.length + offset));
    }

    // Change the visible string
    setConfirmPassword(passwordValue.substring(0, passwordValue.length - showLength).replace(/./g, "•") + passwordValue.substring(passwordValue.length - showLength, passwordValue.length));

    // Set the timer
    clearTimeout(hideConfirmAllRef?.current);
    hideConfirmAllRef.current = setTimeout(() => {
      setConfirmPassword(passwordValue.replace(/./g, "•"));
    }, 1000);
  };

  const decryptUserId = (encryptedUserId: string) => {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedUserId, encryptionKey);
    const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  const submitUpdate = async (
    values: { password: string; confirmPassword: string },
    actions: FormikHelpers<{ password: string; confirmPassword: string }>
  ) => {
    try {
      // Check if passwords match
      if (values.password !== values.confirmPassword) {
        toast.error('Passwords do not match', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        actions.setSubmitting(false);
        return;
      }

      const decryptedUserId = decryptUserId(token);
      
      const requestData = {
        password: passHidden, // Replace with the actual new password
      };
      
      // Define the URL for the Feathers.js service.
      const serviceUrl = `${URL_PATH.CLIENT1}/password-update/${decryptedUserId}`;
      
      // Define the request configuration.
      const requestConfig = {
        method: 'put',
        url: serviceUrl,
        headers: {
          'Content-Type': 'application/json',
        },
        data: requestData,
      };


      const response = await axios.request(requestConfig);

      if (response.data.message != "Password updated successfully") {
        toast.error(response.data.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        // Password update was successful
        toast.success('Password updated successfully', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        navigate('/jobs')
      }
    } catch (error) {
      console.error(error);

      toast.error('An unexpected error occurred', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    actions.setSubmitting(false);
  };

  const generatePasswordHandler = (e:any) => {
    e.preventDefault()
    const generatedPassword = generateRandomPassword(10);
    setPassword(generatedPassword)
    setConfirmPassword(generatedPassword)

    setTimeout(() => {
      onPasswordChange(generatedPassword)
      onConfirmPasswordChange(generatedPassword)
    }, 1000);
    // console.log(generatedPassword)
  }
  const isWeakPassword = (passwordtext: any): boolean => {
    // Minimum number of characters required
    const minLength: number = 8;
    // Regular expression for special characters
    const specialCharRegex: RegExp = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    // Check if the password meets the criteria
    return password.length < minLength || !specialCharRegex.test(passwordtext);
  }


  const copyTextToClipboard = (e:any) => {
    e.preventDefault();
    if(passHidden.trim().length > 0){
      navigator.clipboard.writeText(passHidden)
        .then(() => {
          // setCopySuccess('Text copied to clipboard!');
          toast.success('Password successfully copied to the clipboard!', {
            position: toast.POSITION.BOTTOM_CENTER,
          });

        })
        .catch(err => {
          // console.error('Error copying text: ', err);
          // setCopySuccess('Failed to copy text!');
          toast.error('Something went wrong!!!', {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  return (
    <div className="login-update">
      <h3>Update Password</h3>
      <Formik
        initialValues={{
          password: password,
          confirmPassword: confirmPassword
        }}
        validate={(values) => {
          const errors: { password?: string; confirmPassword?: string } = {};
          if (!passHidden) {
            errors.password = 'Password is required';
          }
          
          if (passHidden && isWeakPassword(passHidden)) {
            errors.password = `Use at least 8 characters and at least one special character.`;
          }

          if (!confirmPassHidden) {
            errors.confirmPassword = 'Confirm Password is required';
          }
          if (passHidden !== confirmPassHidden) {
            errors.confirmPassword = 'Passwords do not match';
          }
          return errors;
        }}
        onSubmit={submitUpdate}
        enableReinitialize
      >
        <Form>
          <div className="form-group">
            <label htmlFor="password" style={{margin: "5px 0px", display:"block"}}>Password</label>
            <Field
              type="text"
              name="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e:any) => onPasswordChange(e.target.value)}
              placeholder="Type new password..."
            />
            <ErrorMessage name="password" component="div" className="error" style={{margin: "5px 0px"}} />
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword"  style={{margin: "5px 0px", display:"block"}}>Confirm Password</label>
            <Field
              type="text"
              name="confirmPassword"
              id="confirmPassword"
              className="form-control"
              value={confirmPassword}
              onChange={(e:any) => onConfirmPasswordChange(e.target.value)}
              placeholder="Confirm new password..."
            />
            <ErrorMessage
              name="confirmPassword"
              component="div"
              className="error"
              style={{margin: "5px 0px"}}
            />
          </div>
          <div className='form-group' style={{margin: "10px 0px"}}>
           {/*<label htmlFor="confirmPassword"  style={{margin: "5px 0px", display:"block"}}>Generate Password</label>*/}
            <div className='generate_pass_div' style={{display:"flex", gap:10, width:'100%', marginTop: '20px' }}>
           
              <button 
                className='primary button generate_btn' 
                style={{width:'50%'}}
                onClick={generatePasswordHandler}
              >Generate Suggested Password</button>
              <button 
                className='primary button copy_btn' 
                style={{ width:'50%'}}
                onClick={copyTextToClipboard}
              >Copy To Clipboard</button>
            </div>
          </div>
          <Button
            type="submit"
            fullWidth
            style={{
              marginTop: '8px',
            }}
          >
            Update
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

export default LoginUpdate;