// import Stepper from '../../Stepper'
const Header = ({ currentStep }: any) => {
    console.log( Array(3).fill("_"))
    return (
        <div className="logo" style={{ display: 'flex', padding: '20px 40px 0px 40px' }}>
            <img src={`/assets/Proton-Original_black.png`} alt="Proton Logo" width={`111px`} height={`39px`} style={{ objectFit: 'contain' }} />
            {/* <Stepper currentStep={currentStep} /> */}
            <div className="progress_bar">
                {Array(3).fill("_").map((item, index)=>(
                    <img src={`/assets/${index === currentStep ? "color_border" : "color_border_no"}.png`} style={{height: 5, width:62, marginRight: 5}} alt="" />
                ))}
            </div>
        </div>
    )
}

export default Header
