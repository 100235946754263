import React from 'react';
import './staff.sass';

type TotalStaffProps = {
  totalStaff: number;
  graphicDesigner: number;
  frontEndDeveloper: number;
  backendDeveloper: number;
};

const TotalStaff: React.FC<TotalStaffProps>= (props) => {
  const { totalStaff, graphicDesigner, frontEndDeveloper, backendDeveloper } = props;
  return (
    <ul className='job_box_inner box_height'>
      <li className='head_bg_green'>
        <div className='user_div'>
          <img src="/assets/hub (1).png" alt="menu" />
        </div>
      </li>
      <li className='box_li'>
        <span>TOTAL STAFF</span>
        <strong className='big_text'>{totalStaff}</strong>
      </li>
      <li className='box_li'>
        <span>GRAPHIC DESIGNER</span>
        <strong className=''>{graphicDesigner}</strong>
      </li>
      <li className='box_li'>
        <span>FRONT END DEVELOPER</span>
        <strong className=''>{frontEndDeveloper}</strong>
      </li>
      <li className='box_li'>
        <span>BACKEND DEVELOPER</span>
        <strong className=''>{backendDeveloper}</strong>
      </li>
    </ul>
  );
}

export default TotalStaff;
