import { HTMLAttributes } from 'react'

import './loader.sass'

interface ILoaderProps extends HTMLAttributes<HTMLDivElement> {}

const Loader = ({ ...props }: ILoaderProps) => {
  return (
    <div className={`lds-ellipsis ${props.className || ""}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
