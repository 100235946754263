import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { getEmailTemplateLink } from 'routes/constants'

import EmailPreview from '../../components/EmailPreview/EmailPreview'
import DeleteModal from 'components/ActionModal/DeleteModal'
import ModalPreviewEmailTemplate from 'components/ActionModal/components/ModalPreviewEmailTempate'
import KebabMenu, { IKebabMenuItem } from 'components/KebabMenu/KebabMenu'

import deleteIcon from 'assets/delete.svg'
import editIcon from 'assets/edit.svg'

import { IEmailTemplate } from './EmailTemplates'
import { deleteEmailTemplate } from './utils'
import { TEMPLATES_TYPE } from '../../utils/constants'
interface IEmailTemplateItemProps {
  template: IEmailTemplate
  refetch?: () => void
  isActive?: boolean
}
const EmailTemplateItem = ({
  template,
  refetch = () => {},
  isActive,
}: IEmailTemplateItemProps) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  const navigate = useNavigate()

  const deleteTemplate = useCallback(async () => {
    const data = await deleteEmailTemplate(template.id)

    if (data) {
      refetch()
    }
  }, [template, refetch])

  const templateActions: IKebabMenuItem[] = [
    {
      label: 'Edit',
      icon: editIcon,
      onClick: () => {
        navigate(getEmailTemplateLink(template.id))
      },
    },
    {
      label: 'Delete',
      icon: deleteIcon,
      onClick: () => {
        setDeleteOpen(true)
      },
    },
  ]

  return (
    <div
      className={classNames('email-template-item', {
        active: isActive,
      })}
    >
      <div className="left">
        <img src="/assets/temp-image.png" alt="" />
        <div>
          <div className="title">{template?.title} ({TEMPLATES_TYPE[template?.type as keyof typeof TEMPLATES_TYPE]})</div>
          <div className="subject">{template?.subject} </div>
        </div>
      </div>
      <div className="right">
        <KebabMenu items={templateActions} />
      </div>
      <DeleteModal
        isOpen={deleteOpen}
        handleClose={() => {
          setDeleteOpen(false)
        }}
        body={
          <ModalPreviewEmailTemplate
            title={template.title}
            subtitle={template.subject}
          />
        }
        handleDelete={deleteTemplate}
        title="Delete email template"
        subtitle="Do you wish to delete the email template?"
      />
    </div>
  )
}

interface IEmailTemplatesProps extends HTMLAttributes<HTMLDivElement> {
  templates: IEmailTemplate[]
  refetch?: () => void
}

const EmailTemplatesList = ({
  templates,
  refetch,
  ...props
}: IEmailTemplatesProps) => {
  const [selectedEmailId, setSelectedEmailId] = useState<string | null>(null)

  useEffect(() => {
    const item = templates.find((template) => template.id === selectedEmailId)
    if (!item && templates.length) {
      setSelectedEmailId(templates[0]?.id)
    }
  }, [templates, selectedEmailId])

  const selectedEmailBody = useMemo(() => {
    const item = templates.find((template) => template.id === selectedEmailId)
    return item ? item.body : ''
  }, [selectedEmailId, templates])

  return (
    <div className="email-templates-list">
      <div className="list">
        {templates.map((template) => (
          <div
            onClick={() => setSelectedEmailId(template.id)}
            key={template.id}
          >
            <EmailTemplateItem
              template={template}
              isActive={selectedEmailId === template.id}
              refetch={refetch}
            />
          </div>
        ))}
      </div>
      <div className="preview">
        <EmailPreview body={selectedEmailBody} />
      </div>
    </div>
  )
}

export default EmailTemplatesList
