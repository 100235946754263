import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import arrowRightIcon from 'assets/arrow-right-s-line.svg'

import Button, { IButtonProps } from '../Button'
import './dropdown-button.sass'

interface IDropdownButtonItem {
  to?: string
  label: string
  icon?: string
  onClick?: () => void
}

interface IDropdownButtonProps extends Omit<IButtonProps, 'children'> {
  label: string
  items?: Array<IDropdownButtonItem>
}

const DropdownItem = ({ item }: { item: IDropdownButtonItem }) => {
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    if (item.to) {
      navigate(item.to)
    } else if (item.onClick) {
      item.onClick()
    }
  }, [item, navigate])

  return (
    <div onClick={handleClick} className="dropdown-item">
      <img src={item.icon} alt="" />
      <div>{item.label}</div>
    </div>
  )
}

const DropdownItems = ({ items }: { items: IDropdownButtonItem[] }) => {
  return (
    <div className="dropdown-items-container">
      {items.map((item) => (
        <DropdownItem key={item.label} item={item} />
      ))}
    </div>
  )
}

const DropdownButton = ({ label, items, ...props }: IDropdownButtonProps) => {
  const [isOpen, toggleIsOpen] = useState(false)

  const handleButtonClick = useCallback(
    (e: any) => {
      toggleIsOpen(!isOpen)
    },
    [isOpen, toggleIsOpen]
  )

  return (
    <div
      className={classNames('dropdown-button', {
        open: isOpen,
      })}
    >
      <Button {...props} onClick={handleButtonClick}>
        {label} <img src={arrowRightIcon} alt="" />
      </Button>
      {isOpen && (
        <div className="dropdown-items">
          <DropdownItems items={items || []} />
        </div>
      )}
    </div>
  )
}

export default DropdownButton
