import { HTMLAttributes, useCallback } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import StaffInviteForm from './forms/StaffInviteForm'

// import './templates.sass'
// import { submitEmailTemplate } from './utils'

// export interface IEmailTemplateFormData {
//   title: string
//   subject: string
//   body: string
// }
// export const INVITE = `Hi there, [full_name],

// Thanks for joining the Postal Apps mission, and welcome!

// At Postal Apps, we believe in [brand principles] and providing top-of-the-line [product/service] for our customers.

// Share the love on social media with #postalapp or send [link] to a friend and you’ll get [special offer] as a token of our appreciation.

// [Sign off]`

// export const INVITE = `Hi there, [full_name],
// You are invited to join Postal apps as [role]
// At Postal Apps, we believe in [brand principles] and providing top-of-the-line [product/service] for our customers.
// Share the love on social media with #postalapp or send [link] to a friend and you’ll get [special offer] as a token of our appreciation.
// `

interface IAddEmailTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const StaffInvite = ({ ...props }: IAddEmailTemplateProps) => {
  return (
    <div {...props} className="add-email-template templates">
      <PageHeader heading="Invite user" showBack />
      <StaffInviteForm />
    </div>
  )
}

export default StaffInvite
