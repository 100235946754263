import { HTMLAttributes, useCallback } from 'react'

import PageHeader from 'components/Header/PageHeader/PageHeader'

import EmailTemplateForm from './forms/EmailTemplateForm'
import './templates.sass'
import { submitEmailTemplate } from './utils'

interface IAddEmailTemplateProps extends HTMLAttributes<HTMLDivElement> {}

const AddEmailTemplate = ({ ...props }: IAddEmailTemplateProps) => {
  const handleSubmit = useCallback(submitEmailTemplate, [])
  return (
    <div {...props} className="add-email-template templates">
      <PageHeader heading="Add email template" showBack />
      <EmailTemplateForm handleSubmit={handleSubmit} />
    </div>
  )
}

export default AddEmailTemplate
